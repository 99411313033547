import {
  TimeFrame,
  TimeFrameWidgetSetting,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { indicate } from '@clean-code/shared/common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { IChartSettings } from '../models/chart-settings';
import { TimeSeriesInput } from '../models/timeseries-input';

export abstract class BaseChart<TChartSetting extends IChartSettings> {
  public identifier$ = new BehaviorSubject<TimeSeriesInput>(null);
  public selectedTimeFrame$ = new BehaviorSubject<TimeFrame>('M_3');
  public chartSettings$ = new BehaviorSubject<TChartSetting>(null);
  public isLoading$ = new BehaviorSubject<boolean>(true);

  public paramChange$ = combineLatest([
    this.identifier$,
    this.chartSettings$.pipe(startWith(<TChartSetting>null)),
    this.selectedTimeFrame$,
  ]).pipe(
    filter(([item]: [TimeSeriesInput, TChartSetting, TimeFrame]) => !!item)
  );
}

export abstract class LineChartBaseChart<
  TChartSetting extends IChartSettings
> extends BaseChart<TChartSetting> {
  // protected chart: DevExtremeLineChartComponent;
}

export interface BaseChartWithMultipleTimeSeriesParams
  extends TimeFrameWidgetSetting {
  identifiers: TimeSeriesInput[];
  showTable?: boolean;
}

export abstract class BaseChartWithMultipleTimeSeries {
  public params$ = new BehaviorSubject<BaseChartWithMultipleTimeSeriesParams>(
    null
  );
  public isLoading$ = new BehaviorSubject<boolean>(true);

  public paramChange$ = this.params$.pipe(indicate(this.isLoading$));
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AkitaSearchBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { OfferPermissions } from '@backoffice/shared/bo/util-permissions';
import { Area } from '@clean-code/backoffice/area/util-api';
import {
  DataTableParameters,
  HttpResponseHelper,
  ObjectHelper,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { ID, PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, combineLatest, from, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OfferPowerStore } from '../+state/offer-power.store';
import { HknType } from '../models/hkn-type';
import { Offer } from '../models/offer';
import { OfferCrmLookupOpportunity } from '../models/offer-crm-lookup-opportunity';
import { OfferPower } from '../models/offer-power';
import { OfferPowerContract } from '../models/offer-power-contract';
import { OfferPowerHkn } from '../models/offer-power-hkn';
import { OfferTimeFrame } from '../models/offer-time-frame';
import { OfferTradingRequest } from '../models/offer-trading-request';
import { HknValueValidator } from './hkn-value-validator';
import { OFFER_POWER_PAGINATOR } from './offer-power-paginator';
import { IOfferService } from './offer.service';
@Injectable({
  providedIn: 'root',
})
export class OfferPowerService
  extends AkitaSearchBaseService<OfferPower>
  implements IOfferService
{
  private url = this.configService.settings['api'] + 'offer/graphql';
  private orderUrl = this.configService.settings['api'] + 'orderpower/graphql';

  private uploadUrl = this.configService.settings['api'] + 'offer/';

  public readonly entityFields = `
    id
    areaDeliveryAreaId
    areaDeliveryArea
    areaMarketAreaId
    areaMarketArea
    companyId
    company
    lastComment
    deliveryPurchase
    forecastId
    forecast
    forwardPriceCurveVersionId
    forwardPriceCurveVersion
    grossMargin
    marketPriceRiskDays
    name
    segmentId
    segment
    supplierId
    supplier
    offerId
    timeFrames
    {
      start
      end
      timeFrame
    }
    hknConfigurations
    {
      deliveryYear
      value
      percent
      hknTypeId
      isCopy
    }
    costingType
  `;

  constructor(
    protected store: OfferPowerStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    @Inject(OFFER_POWER_PAGINATOR)
    private paginatorRef: PaginatorPlugin<OfferPower>,
    protected permissionService: NgxPermissionsService,
    private activatedRoute: ActivatedRoute
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<OfferPower>> {
    const query = `
    query ($params: DataTableParametersType!){
      offersPower(params: $params){
          perPage
          currentPage
          total
          lastPage
          data {
            ${this.entityFields}
            canDelete
            createdBy
            createdById
            createdDate
          }
        }
      }
    `;
    const variables = { params };

    return this.graphqlService.query<PaginationResponse<OfferPower>>(
      query,
      variables,
      this.url
    );
  }

  public getById$(id: ID): Observable<OfferPower> {
    const query = `
    query($id: String!) {
      offerPower(id: $id) {
         ${this.entityFields}
      }
    }
    `;
    const variables = { id };
    return this.graphqlService
      .query<OfferPower>(query, variables, this.url, { redirectTo404: true })
      .pipe(
        tap((data) => {
          this.store.upsert(data.id, data);
          this.store.setActive(data.id);
          this.invalidateCache();
        })
      );
  }

  public add$(offerPower: OfferPower) {
    const query = `
      mutation ($offerPower: OfferPowerInsertType!)
      {
        insertOfferPower(offerPower: $offerPower) {
          ${this.entityFields}
        }
      }
    `;

    offerPower.timeFrames = offerPower.timeFrames.filter((x) => x !== null);
    offerPower = ObjectHelper.cloneObject(offerPower);

    delete offerPower.id;

    const variables = { offerPower };

    return this.graphqlService
      .query<OfferPower>(query, variables, this.url)
      .pipe(
        tap((newOfferPower) => {
          this.upsertAndRefresh(newOfferPower);
          this.paginatorRef.refreshCurrentPage();
        })
      );
  }

  public delete$(id: ID) {
    const query = `
      mutation ($id: String!)
      {
        deleteOfferPower(id: $id)
      }
      `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url).pipe(
      tap((value) => {
        if (value) {
          this.store.remove(id);
          this.invalidateCache();
          this.paginatorRef.refreshCurrentPage();
        }
      })
    );
  }

  getVersions$(offerId: ID): Observable<Offer[]> {
    const query = `
    query($offerId: String!) {
      offerPowerVersions(offerId: $offerId) {
        id
        offerId
        versionId
        costingType
        contractProductId
        contractMarketAreaId
        updatedDate
        deliveryRange
        company
        companyId
        forecast
        forecastId
        name
        supplierId
        supplier
        areaDeliveryAreaId
        areaDeliveryArea
        createdDate
        createdBy
        areaMarketArea
      }
    }
    `;
    const variables = { offerId };
    return this.graphqlService.query<Offer[]>(query, variables, this.url);
  }

  marketAreaByOffer$(id: ID): Observable<Area[]> {
    const query = `
    query($id: String!) {
      offerPowerMarketAreas(id: $id) {
        id
        name
        country
      }
    }
    `;
    const variables = { id };
    return this.graphqlService.query<Area[]>(query, variables, this.url);
  }

  offerHknBaseConfigurations$(
    segmentId: string,
    supplierId: string,
    deliveryAreaId: string,
    offerTimeFrames: OfferTimeFrame[]
  ): Observable<OfferPowerHkn[]> {
    const input = {
      supplierId: +supplierId,
      segmentId: +segmentId,
      deliveryAreaId: +deliveryAreaId,
      offerTimeFrames: offerTimeFrames,
    };

    const query = `
        query ($input: OfferPowerHknConfigurationQueryInput!){
          offerHknBaseConfigurations(input: $input)
          {
            id
            deliveryYear
            hknTypeId
            hknType
            {
              id
              name
            }
            value
            percent
          }
        }`;

    return this.graphqlService.query<OfferPowerHkn[]>(query, { input }).pipe(
      map((data) => {
        data.forEach((x) => (x.hknTypeId = x.hknTypeId.toString()));
        return data;
      })
    );
  }

  offerHknTypeConfigurationValues$(
    segmentId: ID,
    supplierId: ID,
    deliveryAreaId: ID,
    year: number,
    hknTypeId: ID
  ): Observable<number> {
    const query = `
    query($segmentId: ID!, $supplierId: ID!, $deliveryAreaId: ID!, $year: Int!, $hknTypeId: ID!) {
      offerHknTypeConfigurationValues(segmentId: $segmentId, supplierId: $supplierId, deliveryAreaId: $deliveryAreaId, year: $year, hknTypeId: $hknTypeId)
    }
    `;
    const variables = {
      segmentId,
      supplierId,
      deliveryAreaId,
      year,
      hknTypeId,
    };
    return this.graphqlService.query<number>(query, variables, this.orderUrl);
  }

  public validOfferHknTypes$(
    segmentId: string,
    supplierId: string,
    deliveryAreaId: string
  ): Observable<HknType[]> {
    const query = `
    query($segmentId: ID!, $supplierId: ID!, $deliveryAreaId: ID!) {
      validOfferHknTypes(segmentId: $segmentId, supplierId: $supplierId, deliveryAreaId: $deliveryAreaId) {
        id
        name
      }
    }
    `;
    const variables = { segmentId, supplierId, deliveryAreaId };
    return this.graphqlService.query<HknType[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  public createHknFormGroup(offerPower: OfferPowerHkn): UntypedFormGroup {
    if (offerPower.isCopy == null) {
      offerPower.isCopy = false;
    }

    const form = new UntypedFormGroup({
      deliveryYear: new UntypedFormControl(offerPower.deliveryYear, [
        Validators.required,
      ]),
      value: new UntypedFormControl(offerPower.value, [Validators.required]),
      percent: new UntypedFormControl(offerPower.percent, [
        Validators.required,
      ]),
      hknTypeId: new UntypedFormControl(offerPower.hknTypeId, [
        Validators.required,
      ]),
      isCopy: new UntypedFormControl(offerPower.isCopy),
    });

    form.controls.deliveryYear.disable();
    form.controls.value.disable();

    return form;
  }

  public exportFormulaToExcel$(
    id: ID,
    marketAreaId: ID,
    productId: ID
  ): Observable<boolean> {
    const headers = new HttpHeaders({
      'Access-Control-Expose-Headers': 'Content-Disposition',
    });

    return this.httpClient
      .get(
        this.uploadUrl + '' + id + '/export/' + marketAreaId + '/' + productId,
        {
          responseType: 'blob' as 'json',
          observe: 'response',
          headers,
        }
      )
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          const fileName = HttpResponseHelper.getHttpResponseFileName(response);
          const url = window.URL.createObjectURL(response.body);
          const anchor = document.createElement('a');
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }),
        map(() => true),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public toOffer$(offerId: ID): Observable<boolean> {
    const query = `
      mutation ($offerId: String!)
      {
        offerPowerToOffer(offerId: $offerId)
      }
    `;

    const variables = { offerId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public toCrmOffer$(offerId: ID): Observable<OfferCrmLookupOpportunity> {
    const query = `
      mutation ($offerId: String!)
      {
        offerPowerToOffer(offerId: $offerId)
        {
          hasPartnerNumber
          opportunities
          {
            partnerNo
            partnerName
            partnerCompanyRegNo
            partnerOwner
            pSCustomerSegment
            kAM
            kAMID
            opportunityNr
            opportunityName
            amount
            lineOfBusiness
            deliveryPeriodFrom
            deliveryPeriodTill
          }
        }
      }
    `;

    const variables = { offerId };

    return this.graphqlService.query<OfferCrmLookupOpportunity>(
      query,
      variables,
      this.url
    );
  }

  public sendOfferToCrm$(
    offerId: ID,
    areaId: ID,
    productId: ID,
    opportunityNumber: string,
    opportunityPartnerNumber: string,
    deliveryPeriodFrom: string,
    deliveryPeriodTill: string
  ): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!, $areaId: ID!, $productId: ID!, $opportunityNumber: String!, $opportunityPartnerNumber: String!, $deliveryPeriodFrom: String!, $deliveryPeriodTill: String!)
      {
        offerPowerToOfferWithCrmRequest(offerId: $offerId, areaId: $areaId, productId: $productId, opportunityNumber: $opportunityNumber, opportunityPartnerNumber: $opportunityPartnerNumber, deliveryPeriodFrom: $deliveryPeriodFrom, deliveryPeriodTill: $deliveryPeriodTill)
      }
    `;

    const variables = {
      offerId,
      areaId,
      productId,
      opportunityNumber,
      opportunityPartnerNumber,
      deliveryPeriodFrom,
      deliveryPeriodTill,
    };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public toContract$(offerId: ID, offerPricingIds: ID[]): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!, $offerPricingIds: [ID]!)
      {
        offerPowerToContract(offerId: $offerId, offerPricingIds: $offerPricingIds)

      }
    `;

    const variables = { offerId, offerPricingIds };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  public downgradeToOffer$(
    offerId: ID,
    marketAreaId: ID,
    productId: ID
  ): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!, $marketAreaId: ID!, $productId: ID!)
      {
        offerPowerDowngradeContractToOffer(offerId: $offerId, marketAreaId: $marketAreaId, productId: $productId)
      }
    `;

    const variables = { offerId, marketAreaId, productId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public downgradeToCosting$(offerId: ID): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!)
      {
        offerPowerDowngradeOfferToCosting(offerId: $offerId)
      }
    `;

    const variables = { offerId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public hasDowngradePermission$(): Observable<boolean> {
    return from(
      this.permissionService.hasPermission(
        OfferPermissions.offerPowerVersionAdmin
      )
    );
  }

  public offerContractPrefilled(
    pricingIds: ID[]
  ): Observable<OfferPowerContract[]> {
    const query = `
    query($pricingIds: [ID]!) {
      offerContractsPrefilled(pricingIds: $pricingIds)
      {
        amount
        grossMargin
        fixedPrice
        fixedSurcharge
        companyId
        offerOfferId
        areaMarketAreaId
        productId
        product
        pricingId
        timeFrame {
          start
          end
          timeFrame
        }
        baseRatioLoad
        peakRatioLoad
        hknOrders {
          year
          percent
          value
          hknType
          hknTypeId
          timeFrameId
        }
        upperLimit
        lowerLimit
        name
        enableIndexFormula
      }
    }
    `;
    const variables = { pricingIds };
    return this.graphqlService.query<OfferPowerContract[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  public searchContract$(
    params: DataTableParameters
  ): Observable<OfferPowerContract[]> {
    const query = `
    query ($params: DataTableParametersType!){
      offerContractSearch(params: $params){
          id
          company
          product
          areaMarketArea
          amount
          baseRatioLoad
          peakRatioLoad
          fixedSurcharge
          name
        }
      }
    `;
    const variables = { params };

    return this.graphqlService.query<OfferPowerContract[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  public insertOfferContracts$(
    contracts: OfferPowerContract[]
  ): Observable<boolean> {
    const query = `
      mutation ($contracts: [OfferPowerContractInsertType]!)
      {
        insertOfferContracts(contracts: $contracts)
      }
    `;

    contracts = ObjectHelper.cloneObject(contracts);
    contracts.forEach((contract: any) => {
      if (contract.orderHkn) {
        contract.hknOrders?.forEach((hknOrders: any) => {
          delete hknOrders.percent;
        });
      } else {
        delete contract.hknOrders;
      }

      delete contract.hknValues;
      delete contract.orderHkn;
      delete contract.isOpen;
      delete contract.id;
      delete contract.percent;

      if (!contract.fixedSurcharge && contract.fixedPrice) {
        contract.fixedSurcharge = 0;
      }
    });

    const variables = { contracts };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  sendTradeRequest$(
    template: string,
    offerPricingIds: ID[]
  ): Observable<boolean> {
    const query = `
      mutation ($template: String!, $offerPricingIds: [ID]!)
      {
        sendTradeRequest(template: $template, offerPricingIds: $offerPricingIds)
      }
    `;

    const variables = { template, offerPricingIds };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  getTradingRequest$(
    id: string,
    offerPricingIds: ID[]
  ): Observable<OfferTradingRequest> {
    const query = `
    query ($id: ID!, $offerPricingIds: [ID]!) {
      powerTradingRequest(id: $id, offerPricingIds: $offerPricingIds) {
        forecast
        marketArea
        customer
        keyAccountManagers
        subject
        isProductionEnvironment
        environmentType
        timeFrameData
        {
          name
          start
          end
          monthlyData
        }
      }
    }
  `;
    const variables = { id, offerPricingIds };
    return this.graphqlService.query<OfferTradingRequest>(
      query,
      variables,
      this.url
    );
  }

  public createContractFormGroup(
    contract: OfferPowerContract
  ): UntypedFormGroup {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(contract.id),
      amount: new UntypedFormControl(contract.amount, [Validators.required]),
      grossMargin: new UntypedFormControl(+contract.grossMargin.toFixed(2)),
      fixedPrice: new UntypedFormControl(contract.fixedPrice),
      fixedSurcharge: new UntypedFormControl(
        +contract.fixedSurcharge.toFixed(2),
        [Validators.required]
      ),
      companyId: new UntypedFormControl(contract.companyId, [
        Validators.required,
      ]),
      offerOfferId: new UntypedFormControl(contract.offerOfferId, [
        Validators.required,
      ]),
      areaMarketAreaId: new UntypedFormControl(contract.areaMarketAreaId, [
        Validators.required,
      ]),
      productId: new UntypedFormControl(contract.productId, [
        Validators.required,
      ]),
      product: new UntypedFormControl(contract.product, [Validators.required]),
      pricingId: new UntypedFormControl(contract.pricingId, [
        Validators.required,
      ]),
      timeFrame: new UntypedFormControl(contract.timeFrame, [
        Validators.required,
      ]),
      baseRatioLoad: new UntypedFormControl(
        +contract.baseRatioLoad.toFixed(4),
        [Validators.required]
      ),
      peakRatioLoad: new UntypedFormControl(
        +contract.peakRatioLoad.toFixed(4),
        [Validators.required]
      ),
      hknOrders: new UntypedFormArray([], HknValueValidator(contract.amount)),
      orderHkn: new UntypedFormControl(true, [Validators.required]),
      isOpen: new UntypedFormControl(null, [Validators.required]),
      upperLimit: new UntypedFormControl(contract.upperLimit, [
        Validators.required,
      ]),
      lowerLimit: new UntypedFormControl(contract.lowerLimit, [
        Validators.required,
      ]),
      name: new UntypedFormControl(contract.name, [Validators.required]),
      spotPercentage: new UntypedFormControl(100, [Validators.required]),
      balancingEnergyPercentage: new UntypedFormControl(0, [
        Validators.required,
      ]),
    });

    if (!!contract.spotPercentage && contract.spotPercentage !== 100) {
      form.controls.spotPercentage.setValue(contract.spotPercentage);
    }

    if (
      !!contract.balancingEnergyPercentage &&
      contract.balancingEnergyPercentage !== 0
    ) {
      form.controls.balancingEnergyPercentage.setValue(
        contract.balancingEnergyPercentage
      );
    }

    form.controls.product.disable();

    if (!contract.enableIndexFormula) {
      form.controls.baseRatioLoad.disable();
      form.controls.peakRatioLoad.disable();
      form.controls.fixedSurcharge.disable();
      form.controls.grossMargin.disable();

      form.controls.fixedPrice.setValidators(Validators.required);
      form.controls.fixedPrice.markAsTouched();
    }

    form.controls.orderHkn.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            form.controls.hknOrders.enable();
          } else {
            form.controls.hknOrders.disable();
          }
        })
      )
      .subscribe();

    const hkn = form.controls.hknOrders as UntypedFormArray;

    if (contract.hknOrders) {
      contract.hknOrders.forEach((x) =>
        hkn.push(this.createHknValueFormGroup(x, contract))
      );
    }

    form.controls.amount.valueChanges
      .pipe(
        tap((value: number) => {
          form.controls.hknOrders.setValidators([]);
          form.controls.hknOrders.setValidators([HknValueValidator(value)]);

          hkn.controls.forEach((hknForm: UntypedFormGroup) => {
            const cal = (hknForm.controls.percent.value / 100) * value;
            hknForm.controls.amountInMWh.setValue(cal);
          });
        })
      )
      .subscribe();

    return form;
  }

  public createHknValueFormGroup(
    hkn: OfferPowerHkn,
    contract: OfferPowerContract
  ): UntypedFormGroup {
    const cal = (hkn.percent / 100) * contract.amount;

    const form = new UntypedFormGroup({
      amountInMWh: new UntypedFormControl(cal, [Validators.required]),
      price: new UntypedFormControl(hkn.value, [Validators.required]),
      hknType: new UntypedFormControl(hkn.hknType, [Validators.required]),
      hknTypeId: new UntypedFormControl(hkn.hknTypeId, [Validators.required]),
      start: new UntypedFormControl(contract.timeFrame.start, [
        Validators.required,
      ]),
      end: new UntypedFormControl(contract.timeFrame.end, [
        Validators.required,
      ]),
      companyId: new UntypedFormControl(contract.companyId, [
        Validators.required,
      ]),
      tradeType: new UntypedFormControl('BUY', [Validators.required]),
      percent: new UntypedFormControl({ value: hkn.percent, disabled: true }),
    });

    form.controls.hknType.disable();
    form.statusChanges
      .pipe(
        tap(() => {
          if (form.controls.hknType.enabled) {
            form.controls.hknType.disable();
          }
        })
      )
      .subscribe();
    return form;
  }

  public editViewMode$(): Observable<boolean> {
    return combineLatest([
      from(this.permissionService.hasPermission(OfferPermissions.powerEdit)),
      this.activatedRoute.paramMap.pipe(
        map((params) => params.get('mode')),
        map((id) => !!id && id === 'readOnly')
      ),
    ]).pipe(map(([edit, readOnly]) => edit && !readOnly));
  }

  getOfferPreview(id: ID): Observable<Offer> {
    const query = `query($id: Int!){
      offerPowerById(id: $id) {
        id
        name
        offerId
        versionId
        company
        {
          name
        }
      }
    }
    `;

    //   offerPowerVersionsById(id: $id) {
    //     id
    //     name
    //     company
    //     {
    //       name
    //     }
    //   }
    // }

    const variables = { id };

    return this.graphqlService.query<Offer>(query, variables);
  }

  offerVersionsById(id: ID): Observable<Offer[]> {
    const query = `query($id: Int!){
      offerPowerVersionsById(id: $id) {
        id
        name
        company
        {
          name
        }
        OfferId
      }
    }
    `;

    const variables = { id };

    return this.graphqlService.query<Offer[]>(query, variables);
  }
}

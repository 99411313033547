import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { addAlpha } from './helpers/color-helper';
import { TailwindThemeConfig } from './tailwind-theme-config';
import { TailwindTheme } from './tailwind-theme.interface';
import {
  updateThemeVariables,
  updateThemeVariablesList,
} from './tailwind-util';
@Injectable()
export class TailwindThemeService {
  colors: Map<string, string>;

  constructor(
    private _http: HttpClient,
    @Inject(DOCUMENT) private readonly document: any,
    private config: TailwindThemeConfig
  ) {
    this.changeTheme(this.isDarkMode);
  }

  loadConfig(): Promise<void> {
    const configUrl =
      this.config?.configUrl || './assets/config/tailwind-theme.config.json';
    return (
      this._http
        .get(`${configUrl}`)
        // .pipe(
        //   switchMap((configObject: { themeUrl: string }) => {
        //     return this._http.get(configObject.themeUrl);
        //   })
        // )
        .toPromise()
        .then((themeData: TailwindTheme) => {
          updateThemeVariables(themeData, this.document);
          this.colors = updateThemeVariablesList(themeData);
        })
        .catch((_) => {
          console.error('There was an error while loading the Tailwind Theme.');
        })
    );
  }

  getColor(name: string, opacity = 1): string {
    const color = this.colors.get(name);
    return addAlpha(color, opacity);
  }

  getColors(groupName: string): string[] {
    return [...this.colors.entries()]
      .filter((x) => x[0].indexOf(groupName) > -1)
      .map(([k]) => k);
  }

  public get isDarkMode() {
    return localStorage.getItem('darkMode') === 'true' ?? false;
  }

  public changeTheme(isDarkMode: boolean) {
    localStorage.setItem('darkMode', isDarkMode + '');

    if (isDarkMode) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
    }
  }
}

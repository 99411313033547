import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs';
import { ConfirmDeleteComponent } from './dialogs/confirm-delete/confirm-delete.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmService {
  private dialogConfig: MatDialogConfig = {
    width: '400px',
    disableClose: true,
  };

  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    showCancelButton: boolean = true,
    icon?: IconDefinition,
    iconColor?: string
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      this.dialogConfig
    );

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.showCancelButton = showCancelButton;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.iconColor = iconColor;

    return dialogRef.afterClosed();
  }

  public delete(
    customMessage?: string,
    customTitle?: string,
    icon?: IconDefinition
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(
      ConfirmDeleteComponent,
      this.dialogConfig
    );

    if (customMessage) {
      dialogRef.componentInstance.customMessage = customMessage;
    }

    if (customTitle) {
      dialogRef.componentInstance.customTitle = customTitle;
    }

    if (icon) {
      dialogRef.componentInstance.faDeleteIcon = icon;
    }

    return dialogRef.afterClosed();
  }

  public open(
    title: string,
    message: string,
    type: Type<any>,
    data: any = null,
    config?: MatDialogConfig<any>
  ): Observable<any> {
    // if (width !== null) {
    //   this.dialogConfig.width = width;
    // }
    this.dialogConfig.data = data;

    if (config) {
      config.disableClose = false;
      config.data = data;
      config.disableClose = true;
    }

    const dialogRef = this.dialog.open(type, config || this.dialogConfig);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.data = data;

    return dialogRef.afterClosed();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoService } from '@ngneat/transloco';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import {
  catchError,
  filter,
  map,
  ReplaySubject,
  share,
  tap,
  timer,
} from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthQuery } from '../+state/auth.query';
import { AuthStore } from '../+state/auth.store';
import { AuthUser } from '../models/auth-user';
import { IAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  // private userUrl = this.configService.settings['api'] + 'account/users';
  // private userPasswordUrl: string;
  // secretKey = 'YourSecretKeyForEncryption&Descryption';

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private authStore: AuthStore,
    private permissionsService: NgxPermissionsService,
    private roleService: NgxRolesService,
    private toastService: ToastService,
    private translocoService: TranslocoService,
    authQuery: AuthQuery,
    @Inject('IAuthService') private authService: IAuthService
  ) {
    authQuery.userData$
      .pipe(
        filter((user: AuthUser) => !!user && !!user.permissions),
        tap((user: AuthUser) => {
          this.loadPermissions(user);
        })
      )
      .subscribe();
  }

  public getUserProfile(): Observable<AuthUser> {
    const url = this.configService.settings['api'] + 'userProfile/me';
    return this.httpClient.get<AuthUser>(url).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: () => timer(10 * 1000),
      }),
      map((user: AuthUser) => {
        return {
          ...user,
          sub: user.id,
        };
      }),
      tap((user: AuthUser) => {
        this.fillState(user);
        const language = user.language?.toLowerCase();
        if (language && this.translocoService.getActiveLang() !== language) {
          this.translocoService.setActiveLang(language);
        }
      }),
      catchError((error) => {
        // On Error remove al permissions
        this.roleService.flushRoles();
        this.permissionsService.flushPermissions();
        throw error;
      })
    );
  }

  public loadPermissions(user: AuthUser) {
    user.roles.forEach((role: string) => {
      this.roleService.addRole(role, () => true);
    });
    this.permissionsService.loadPermissions(user.permissions);
  }

  //this was in account.service.ts before, but this would be a circular dependency
  public updateLanguage(language: string) {
    const url = this.configService.settings['api'] + 'userProfile/me/language';
    //TODO: set language in AuthUser in query!
    return this.httpClient.put(url, { language: language.toUpperCase() }).pipe(
      catchError((error) => {
        console.error(error);
        this.toastService.showError('common.ERROR');
        throw error;
      })
    );
  }

  private async fillState(user: AuthUser) {
    this.authStore.update({ user: user });

    this.authStore.update({
      accessToken: await this.authService.getAccessToken(),
    });
  }

  // public changePassword(user: ChangePassword): Observable<any> {

  //   // user.currentPassword = CryptoJS.SHA256(user.currentPassword);
  //   // user.newPassword = CryptoJS.SHA256(user.newPassword);

  //   return this.httpClient.put(this.userUrl + '/me/change_password', user);
  // }

  // public newPassword(user: ChangePassword): Observable<any> {

  //   // user.currentPassword = CryptoJS.SHA256(user.currentPassword);
  //   // user.newPassword = CryptoJS.SHA256(user.newPassword);

  //   return this.httpClient.put(this.userPasswordUrl + '/me/new_password', user);
  // }

  // public resetPassword(user: ChangePassword): Observable<any> {
  //   // user.newPassword = CryptoJS.SHA256(user.newPassword);
  //   return this.httpClient.post(this.userUrl + '/reset-password', user);
  // }

  // public requestPassword(reset: ResetPassword): Observable<any> {
  //   return this.httpClient.post(this.userUrl + '/generate-email', reset);
  // }
}

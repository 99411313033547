type PermissionValues =
  | 'limit-report.power.admin'
  | 'limit-report.power.generate-passive-positions'
  | 'limit-report.power.observe-passive-positions'
  | 'limit-report.power.editor'
  | 'limit-report.power.passive-strategy-market-price'
  | 'limit-report.power.assign-to-contract'
  | 'limit-report.power.email-notification-all-signals'
  | 'limit-report.power.push-notification-all-signals'
  | 'limit-report.gas.admin'
  | 'limit-report.gas.generate-passive-positions'
  | 'limit-report.gas.observe-passive-positions'
  | 'limit-report.gas.editor'
  | 'limit-report.gas.passive-strategy-market-price'
  | 'limit-report.gas.assign-to-contract'
  | 'limit-report.gas.email-notification-all-signals'
  | 'limit-report.gas.push-notification-all-signals'
  | 'limit-report.other.admin'
  | 'limit-report.other.observe-passive-positions'
  | 'limit-report.other.editor'
  | 'limit-report.other.passive-strategy-market-price'
  | 'limit-report.other.email-notification-all-signals'
  | 'limit-report.other.push-notification-all-signals'
  | 'limit-report.legacy-signal-monitoring';

export class LimitReportPermissions {
  public static readonly limitReportPowerAdmin: PermissionValues =
    'limit-report.power.admin';
  public static readonly limitReportPowerGeneratePassivePositions: PermissionValues =
    'limit-report.power.generate-passive-positions';
  public static readonly limitReportPowerObservePassivePositions: PermissionValues =
    'limit-report.power.observe-passive-positions';
  public static readonly limitReportPowerEditor: PermissionValues =
    'limit-report.power.editor';
  public static readonly limitReportPowerPassiveStrategyMarketPrice: PermissionValues =
    'limit-report.power.passive-strategy-market-price';
  public static readonly limitReportPowerAssignToContract: PermissionValues =
    'limit-report.power.assign-to-contract';
  public static readonly limitReportPowerEmailNotificationAllSignals: PermissionValues =
    'limit-report.power.email-notification-all-signals';
  public static readonly limitReportPowerPushNotificationAllSignals: PermissionValues =
    'limit-report.power.push-notification-all-signals';

  public static readonly limitReportGasAdmin: PermissionValues =
    'limit-report.gas.admin';
  public static readonly limitReportGasGeneratePassivePositions: PermissionValues =
    'limit-report.gas.generate-passive-positions';
  public static readonly limitReportGasObservePassivePositions: PermissionValues =
    'limit-report.gas.observe-passive-positions';
  public static readonly limitReportGasEditor: PermissionValues =
    'limit-report.gas.editor';
  public static readonly limitReportGasPassiveStrategyMarketPrice: PermissionValues =
    'limit-report.gas.passive-strategy-market-price';
  public static readonly limitReportGasAssignToContract: PermissionValues =
    'limit-report.gas.assign-to-contract';
  public static readonly limitReportGasEmailNotificationAllSignals: PermissionValues =
    'limit-report.gas.email-notification-all-signals';
  public static readonly limitReportGasPushNotificationAllSignals: PermissionValues =
    'limit-report.gas.push-notification-all-signals';

  public static readonly limitReportOtherAdmin: PermissionValues =
    'limit-report.other.admin';
  public static readonly limitReportOtherObservePassivePositions: PermissionValues =
    'limit-report.other.observe-passive-positions';
  public static readonly limitReportOtherEditor: PermissionValues =
    'limit-report.other.editor';
  public static readonly limitReportOtherPassiveStrategyMarketPrice: PermissionValues =
    'limit-report.other.passive-strategy-market-price';
  public static readonly limitReportOtherEmailNotificationAllSignals: PermissionValues =
    'limit-report.other.email-notification-all-signals';
  public static readonly limitReportOtherPushNotificationAllSignals: PermissionValues =
    'limit-report.other.push-notification-all-signals';

  public static readonly limitReportLegacySignalMonitoring: PermissionValues =
    'limit-report.legacy-signal-monitoring';

  public static readonly sideNavPermission = [
    LimitReportPermissions.limitReportPowerAdmin,
    LimitReportPermissions.limitReportPowerEditor,
    LimitReportPermissions.limitReportGasAdmin,
    LimitReportPermissions.limitReportGasEditor,
    LimitReportPermissions.limitReportOtherAdmin,
    LimitReportPermissions.limitReportOtherEditor,
  ];

  public static readonly tabPermission = [
    LimitReportPermissions.limitReportPowerAdmin,
    LimitReportPermissions.limitReportPowerEditor,
    LimitReportPermissions.limitReportGasAdmin,
    LimitReportPermissions.limitReportGasEditor,
    LimitReportPermissions.limitReportOtherAdmin,
    LimitReportPermissions.limitReportOtherEditor,
  ];
}

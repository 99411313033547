import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'cc-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent {
  @HostBinding('class.page-component') pageComponent = true;
}

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import {
  DeltaType,
  TableTimeSeries,
  TimeSeriesFacade,
  TimeSeriesInput,
  WidgetTypeConfig,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import {
  faEdit,
  faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { TableDetailsDialogComponent } from '../table-widget-details-dialog/table-widget-details-dialog.component';
import { TableWidgetDetailsEditDialogComponent } from '../table-widget-details-edit-dialog/table-widget-details-edit-dialog.component';

@Component({
  standalone: true,
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.css'],
  imports: [CommonModule, TranslocoModule, VarDirective],
})
export class TableWidgetComponent extends BaseWidgetTypeContentComponent<WidgetTypeConfig> {
  public data$: Observable<TableTimeSeries[]>;
  public faTriangle = faExclamationTriangle;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public deltaType: DeltaType;
  public editMode$: Observable<boolean>;
  public faEdit = faEdit;

  private confirmService = inject(ConfirmService);

  public constructor() {
    super();

    const timeSeriesFacade = inject(TimeSeriesFacade);

    this.data$ = this.configObservable.pipe(
      filter((config: WidgetTypeConfig) => !!config),
      tap(
        (config: WidgetTypeConfig) =>
          (this.deltaType = config.settings.deltaType)
      ),
      switchMap((config: WidgetTypeConfig) =>
        timeSeriesFacade
          .getTimeSeriesTableData$(
            config?.dataSources?.map((x) => x.settings.identifier),
            'DAY',
            true
          )
          .pipe(indicate(this.isLoading$))
      )
    );
  }

  public openDetails(
    tableTimeSeries: TableTimeSeries,
    dataSource: TimeSeriesInput
  ) {
    this.confirmService
      .open(
        '',
        '',
        TableDetailsDialogComponent,
        {
          tableTimeSeries,
          name: tableTimeSeries.longName,
          dataSource,
        },
        {
          minWidth: '90vw',
          minHeight: '90vh',
        }
      )
      .subscribe();
  }

  public openDetailsConfig(dataSource: TimeSeriesInput) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '750px';

    this.confirmService
      .open(
        '',
        '',
        TableWidgetDetailsEditDialogComponent,
        dataSource,
        dialogConfig
      )
      .pipe(
        filter((config: { value: TimeSeriesInput; action: string }) => !!config)
      )
      .subscribe();
  }
}

import dayjs from 'dayjs';

export namespace GraphQLFragments {
  /**
   * @param language - lowercase 2 digits (e.g. 'de', 'en')
   * @returns graphql localization fragment
   */
  export const localizationMediumFragment = (
    language: string = dayjs.locale()
  ) => {
    //Seems to be a bug in HC v11, if I remove cultureCode from the returned values
    //the localizations are empty!
    return `fragment localizationMediumFragment on LocalizationSetMedium {
              localizations(where: { cultureCode: { eq: "${language}" } }) {
                  cultureCode
                  value
                }
            }`;
  };

  export const localizationLongFragment = (
    language: string = dayjs.locale()
  ) => {
    //Seems to be a bug in HC v11, if I remove cultureCode from the returned values
    //the localizations are empty!
    return `fragment localizationLongFragment on LocalizationSetLong {
              localizations(where: { cultureCode: { eq: "${language}" } }) {
                  cultureCode
                  value
                }
            }`;
  };

  export const localizationMediumAllFragment = () => {
    //Seems to be a bug in HC v11, if I remove cultureCode from the returned values
    //the localizations are empty!
    return `fragment localizationMediumFragment on LocalizationSetMedium {
              id
              localizations(where: {  }) {
                  id
                  cultureCode
                  value
                }
            }`;
  };

  export const localizationLongAllFragment = () => {
    //Seems to be a bug in HC v11, if I remove cultureCode from the returned values
    //the localizations are empty!
    return `fragment localizationLongFragment on LocalizationSetLong {
              id
              localizations(where: {  }) {
                  id
                  cultureCode
                  value
                }
            }`;
  };
}

import { Component, Input } from '@angular/core';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { ActionButton } from '../action-button/action-button';

@Component({
  selector: 'cc-add-action-button',
  templateUrl: '../action-button/action-button.html',
  styleUrls: ['../action-button/action-button.scss'],
})
export class AddButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'common.ADD';

  @Input()
  public override icon = faPlus;

  @Input()
  public override displayText = 'common.ADD';
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { ConfirmModule } from '@clean-code/shared/components/ui-confirm';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AnalysisWidgetDialogComponent } from './analysis-widget-dialog/analysis-widget-dialog.component';
import { AnalysisWidgetComponent } from './analysis-widget.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatTableModule,
    MatDatepickerModule,

    TranslocoModule,
    TranslocoLocaleModule,
    PdfViewerModule,

    FontAwesomeModule,
    ConfirmModule,
    FormContainerModule,
  ],
  declarations: [AnalysisWidgetComponent, AnalysisWidgetDialogComponent],
  exports: [AnalysisWidgetComponent, AnalysisWidgetDialogComponent],
})
export class NewsFeatureAnalysisWidgetModule {}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { IFormBaseService } from '@clean-code/shared/util/util-component-services';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { TradingCalendar } from '../entities/trading-calendar';

@Injectable({ providedIn: 'root' })
export class TradingCalendarDataService
  implements
    EditSearchDataService<TradingCalendar>,
    IFormBaseService<TradingCalendar>
{
  public readonly entityFields = `
      id
      name
      isDefault
      country
    `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<DataServiceReturn<TradingCalendar>> {
    const par = this.graphqlService.mapDataTableParameters(params);
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);

    const where = this.graphqlService.mapDataTableFilterParameters(
      params.filters
    );

    const query = `
      query{
          areaTradingCalendarAll(
          ${par}
          ${order}
          ${where}
          ){
              totalCount
              items
              {
                  ${this.entityFields}
              }
              pageInfo{
                  hasNextPage
                  hasPreviousPage
              }
          }
      }`;

    return this.graphqlService
      .query<PagingResponse<TradingCalendar>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getById$(id: ID): Observable<TradingCalendar> {
    const query = `
      query($id: Int!) {
          areaTradingCalendarById(id: $id) {
              ${this.entityFields}
          }
      }`;

    return this.graphqlService.query<TradingCalendar>(
      query,
      {
        id,
      },
      null,
      { redirectTo404: true }
    );
  }

  update$(input: TradingCalendar): Observable<ID> {
    const mutation = `
        mutation ($input: AreaTradingCalendarAddInput!){
          areaTradingCalendarUpdate(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  add$(input: TradingCalendar): Observable<ID> {
    const mutation = `
        mutation ($input: AreaTradingCalendarAddInput!){
          areaTradingCalendarAdd(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
        mutation ($id: Int!){
              areaTradingCalendarDelete(id: $id)
          }`;

    return this.graphqlService.mutation<any>(mutation, { id }, null, {
      ignoreNullCheckFilter: true,
    });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthUser } from '../models/auth-user';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class ProfileResolver  {
  constructor(private readonly accountService: AccountService) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.accountService.getUserProfile();
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  CompanyRelationState,
  CompanyRelationStore,
} from './company-relation.store';

@Injectable({ providedIn: 'root' })
export class CompanyRelationQuery extends QueryEntity<CompanyRelationState> {
  selectSearchTerm$ = this.select('searchTerm');
  public readOnly$ = this.select('readOnly');

  constructor(protected store: CompanyRelationStore) {
    super(store);
  }

  get searchTerm() {
    return this.getValue().searchTerm;
  }
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NavigationQuery } from './navigation.query';
import { NavigationState, NavigationStore } from './navigation.store';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public pageTitle$ = this.navigationQuery.select(
    (state: NavigationState) => state
  );

  private history: string[] = [];

  constructor(
    private navigationStore: NavigationStore,
    private routerQuery: RouterQuery,
    private navigationQuery: NavigationQuery,
    private location: Location,
    private router: Router
  ) {
    // this.router
    //   .selectParams('id')
    //   .pipe(
    //     map((parameter) => parameter === 'new'),
    //     tap((isNew: boolean) => {
    //       if (isNew) {
    //         const data = this.routerQuery.getData();
    //         if (!!data.translate) {
    //           this.set(data.translate + '.NEW');
    //         }
    //       }
    //     })
    //   )
    //   .subscribe();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  public set(key: string, value: string = '') {
    return this.navigationStore.update({ key: key, value: value });
  }

  public setValue(value: string) {
    const data = this.routerQuery.getData();
    if (!!data.translate && !!value) {
      this.set(data.translate + '.TITLE', value);
    } else if (!!data.translate) {
      this.set(data.translate + '.NEW');
    } else {
      this.set(null);
    }
  }

  public getParameter(key: string) {
    return this.routerQuery.getParams(key);
  }

  public getParameters() {
    return this.routerQuery.getParams();
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TerminWidgetData } from '../entities/termin-widget-data';
import { FofTerminDataService } from '../infrastructure/fof-termin.data.service';

@Injectable({ providedIn: 'root' })
export class TerminWidgetFacade {
  constructor(private service: FofTerminDataService) {}

  getAll$(): Observable<TerminWidgetData> {
    return this.service.getAll$().pipe();
  }
}

import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { Company } from '../models/company';

export interface CompanyState extends BaseState<Company> {
  searchTerm: string;
  readOnly: boolean;
  filters: any[];
}

function createInitialState(): CompanyState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company' })
export class CompanyStore extends BaseStore<Company> {
  constructor() {
    super(createInitialState());
  }
}

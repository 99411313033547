import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface IActionButton {
  tooltip: string;
  icon: IconDefinition;
  disabled: boolean;
}

@Directive()
export abstract class ActionButton implements IActionButton {
  @Input()
  public tooltip: string;

  @Input()
  public disabledTooltip = true;

  @Input()
  public icon: any;

  @Input()
  public disabled = false;

  @Input()
  public showText = true;

  @Input()
  public displayText: string;

  @Input()
  public isMenu = false;

  @Input()
  public spin = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onClick = new EventEmitter<any>();

  public click(event?: any) {
    this.onClick.emit(event);
  }

  public get isDisabled(): boolean {
    return this.disabled;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({ name: 'safeHtml' })
export class SafeFile implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string, ..._args: any[]): any {
    return this.sanitizer.bypassSecurityTrustHtml(
      DOMPurify.sanitize(html, { ADD_ATTR: ['style'] })
    );
  }
}

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { indicate } from '@clean-code/shared/common';
import { LoadingIndicatorComponent } from '@clean-code/shared/components/ui-progress-bar';
import {
  DashboardFacade,
  IDashboardListDto,
  IDashboardPanelPreviewDto,
} from '@clean-code/shared/dashboard/dashboard/domain';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { DashboardPanelPreviewComponent } from '../dashboard-panel-preview/dashboard-panel-preview.component';

@Component({
  standalone: true,
  selector: 'dashboard-dashboard-reference',
  templateUrl: './dashboard-reference.component.html',
  styleUrls: ['./dashboard-reference.component.scss'],
  imports: [
    CommonModule,
    TranslocoModule,
    DashboardPanelPreviewComponent,
    LoadingIndicatorComponent,
    VarDirective,
  ],
})
export class DashboardReferenceComponent {
  @ViewChild('form', { static: false }) public form: UntypedFormControl;

  @Input() public data: IDashboardListDto;
  @Input() public multi = false;
  @Input() public showFooter = true;

  @Output() public callback = new EventEmitter<{
    operation: string;
    data: IDashboardListDto;
  }>();

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public dashboards$ = this.dashboardFacade
    .getAssignable$()
    .pipe(indicate(this.isLoading$));

  private selectedDashboards: IDashboardPanelPreviewDto[] = [];

  constructor(
    private dashboardFacade: DashboardFacade,
    private toastService: ToastService
  ) {}

  public isSelected(dashboard: IDashboardPanelPreviewDto) {
    return this.selectedDashboards.some((d) => d.id === dashboard.id);
  }

  public toggleSelection(dashboard: IDashboardPanelPreviewDto) {
    if (this.multi) {
      if (this.isSelected(dashboard)) {
        const index = this.selectedDashboards.findIndex(
          (d) => d.id === dashboard.id
        );
        if (index > -1) {
          this.selectedDashboards.splice(index, 1);
        }
      } else {
        this.selectedDashboards.push(dashboard);
      }
    } else {
      this.selectedDashboards.splice(0, this.selectedDashboards.length);
      this.selectedDashboards.push(dashboard);

      this.save();
    }
  }

  public save() {
    // TODO: one request to reference multiple dashboards
    const results: IDashboardListDto[] = [];

    this.selectedDashboards.forEach((d) => {
      this.dashboardFacade.addReference(d);
      this.callback.next(undefined);
      //           operation: 'reference',
      //           data: results[0],
      //         });
      // this.dashboardFacade
      //   .reference$({
      //     id: d.id,
      //   } as IDashboardReferenceDto)
      //   .subscribe((result: IDashboardListDto) => {
      //     if (result) {
      //       this.toastService.showSuccess(
      //         'DASHBOARDPANEL.MESSAGES.REFERENCE.SUCCESS'
      //       );

      //       results.push(result);
      //       if (results.length === this.selectedDashboards.length) {
      //         this.callback.next({
      //           operation: 'reference',
      //           data: results[0],
      //         });
      //       }
      //     }
      //   });
    });
  }

  public cancel() {
    // callback to close
    this.callback.next(undefined);
  }
}

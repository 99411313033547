import { KeyValue } from '@angular/common';
import {
  ChartTimeSeries,
  TimeSeries,
} from '@backoffice-frontend/time-series-identifier/api';
import { Observable, map } from 'rxjs';
import { LineChartModel } from '../models/line-chart.model';

export function transformChartTimeSeriesData(
  source$: Observable<ChartTimeSeries[]>
): Observable<LineChartModel[]> {
  return source$.pipe(
    map((values: ChartTimeSeries[]) => {
      const array = new Array<LineChartModel>();

      values.forEach((value: ChartTimeSeries) => {
        const data = {
          label: value.frontOfficeName,
          data: value.values.map((val: TimeSeries) => ({
            date: new Date(val.tradeDate),
            value: val.value,
          })),
          yAxisTitle: value.unit,
        } as LineChartModel;

        if (value.error) {
          data.error = {
            key: value.frontOfficeName,
            value: value.error.message,
          } as KeyValue<string, string>;
        }

        array.push(data);
      });

      return array;
    })
  );
}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
<div *ngIf="data$ | async; let data" class="content h-full overflow-auto">
  <table class="table-layout">
    <thead>
      <tr>
        <th scope="col">{{ 'master-data.COMPANY' | transloco }}</th>
        <th scope="col">{{ 'master-data.VOLUME' | transloco }}</th>
        <th scope="col">{{ 'master-data.OPEN' | transloco }}</th>
        <th scope="col">{{ 'master-data.CLOSED' | transloco }}</th>
      </tr>
    </thead>
    <tbody class="overflow-y-scroll">
      <!-- Odd row -->
      <tr
        class="border-b"
        *ngFor="let element of data"
        global-tooltip="{{ (element.value.volume | number : '.2-2') + ' GWh' }}"
      >
        <td
          class="hover:text-gray-500 cursor-pointer"
          *ngIf="config?.filter.energySource; let energySource"
          [routerLink]="[
            '/company-' +
              energySource.toLowerCase() +
              '/' +
              element.value.companyId +
              '/' +
              energySource.toLowerCase() +
              '/contract'
          ]"
        >
          {{ element.key }}
        </td>
        <td class="text-right">
          {{ element.value.volume | number : '1.0-0' }} GWh
        </td>

        <td class="text-right">
          {{ element.value.open | number : '1.0-0' }} GWh
        </td>
        <td class="text-right">
          {{ element.value.closed | number : '1.0-0' }} GWh
        </td>
      </tr>
    </tbody>
  </table>
</div>

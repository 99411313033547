import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { Product } from '../models/product';
import { ProductStore } from './product.store';

@Injectable({ providedIn: 'root' })
export class ProductQuery extends AkitaSearchQueryEntity<Product> {
  constructor(protected store: ProductStore) {
    super(store);
  }
}

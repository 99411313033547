import { CostingPermissionValues } from './surcharge-permissions';

export class OfferPermissions {
  public static readonly offerPowerAdmin: CostingPermissionValues =
    'offer.power.admin';
  public static readonly offerPowerVersionAdmin: CostingPermissionValues =
    'offer.power.versionadmin';

  public static readonly offerGasAdmin: CostingPermissionValues =
    'offer.gas.admin';
  public static readonly offerGasVersionAdmin: CostingPermissionValues =
    'offer.gas.versionadmin';

  public static readonly powerView: CostingPermissionValues =
    'offer.power.view';
  public static readonly powerEdit: CostingPermissionValues =
    'offer.power.edit';
  public static readonly powerCreate: CostingPermissionValues =
    'offer.power.create';
  public static readonly powerDelete: CostingPermissionValues =
    'offer.power.delete';
  public static readonly powerForwardPriceCurveVersion: CostingPermissionValues =
    'offer.power.forward_price_curve_version';
  public static readonly offerPowerHknTypes: CostingPermissionValues =
    'offer.power.hkn-types';

  public static readonly gasView: CostingPermissionValues = 'offer.gas.view';
  public static readonly gasEdit: CostingPermissionValues = 'offer.gas.edit';
  public static readonly gasCreate: CostingPermissionValues =
    'offer.gas.create';
  public static readonly gasDelete: CostingPermissionValues =
    'offer.gas.delete';
  public static readonly gasForwardPriceCurveVersion: CostingPermissionValues =
    'offer.gas.forward_price_curve_version';

  public static readonly powerTabPermisssion = [
    OfferPermissions.powerView,
    OfferPermissions.powerEdit,
    OfferPermissions.powerCreate,
    OfferPermissions.powerDelete,
    OfferPermissions.offerPowerAdmin,
  ];
  public static readonly powerSideBarPermisssion = [
    OfferPermissions.powerView,
    OfferPermissions.powerEdit,
    OfferPermissions.powerCreate,
    OfferPermissions.powerDelete,
    OfferPermissions.offerPowerAdmin,
  ];

  public static readonly gasTabPermisssion = [
    OfferPermissions.gasView,
    OfferPermissions.gasEdit,
    OfferPermissions.gasCreate,
    OfferPermissions.gasDelete,
    OfferPermissions.offerGasAdmin,
  ];
  public static readonly gasSideBarPermisssion = [
    OfferPermissions.gasView,
    OfferPermissions.gasEdit,
    OfferPermissions.gasCreate,
    OfferPermissions.gasDelete,
    OfferPermissions.offerGasAdmin,
  ];
}

import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  HostBinding,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  CorrelationChartWidgetTypeConfig,
  CorrelationWidgetFacade,
  MarketChartCorrelation,
} from '@backoffice-frontend/market/domain';
import { mapTimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { ChartJsLibModule } from '@backoffice-frontend/shared/components/ui-chart-js';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { indicate } from '@clean-code/shared/common';
import { BaseChartWidget } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { ChartOptions } from 'chart.js';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  map,
  switchMap,
} from 'rxjs';
import { CorrelationChartWidgetSettingsComponent } from '../widget-settings/correlation-chart-widget-settings.component';

export enum CorrelationChartWidgetTypes {
  WidgetType = 'CorrelationChartWidget',
}
@Component({
  selector: 'correlation-chart-widget',
  standalone: true,
  templateUrl: './correlation-chart-widget.component.html',
  styleUrls: ['./correlation-chart-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    DxChartModule,
    DxPivotGridModule,

    TranslocoModule,
    FontAwesomeModule,

    CorrelationChartWidgetSettingsComponent,
    ChartJsLibModule,
    ProgressBarModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'correlationChartWidget', //snake-casing is changed from transloco when using selectTranslate with scope
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class CorrelationChartWidgetComponent extends BaseChartWidget<CorrelationChartWidgetTypeConfig> {
  @HostBinding('class') class = 'correlation-chart-widget';
  public isLoading$ = new Subject<boolean>();

  public jsChartOptions$ = new BehaviorSubject<ChartOptions>({});

  private service = inject(CorrelationWidgetFacade);
  private translate = inject(TranslocoService);
  private translocoScope = inject(TRANSLOCO_SCOPE);

  public data$ = combineLatest([
    this.translate.selectTranslate('BASE', {}, this.translocoScope),
    this.config$,
  ]).pipe(
    map(([_, config]) => config),
    filter((config: CorrelationChartWidgetTypeConfig) => !!config),
    mapTimeFrame(),
    switchMap((config: CorrelationChartWidgetTypeConfig) =>
      this.service
        .marketChartCorrelation$(
          config.identifier,
          config.dataSources.map((x) => x.settings.identifier),
          config.settings.timeFrame,
          config.settings.type
        )
        .pipe(indicate(this.isLoading$))
    ),
    map((values: MarketChartCorrelation[]) => {
      const array = new Array<any>();
      // if (values && values.length === 1) {
      //   this.yAxisTitle = ChartHelper.createYAxisChartTitle(values[0].unit);
      // }
      values.forEach((value: MarketChartCorrelation) => {
        this.jsChartOptions$.next({
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
            //translationservice.translate loads synchronous, therefore the .selectTranslate is needed first on top (combineLatest)
            subtitle: {
              display: true,
              text: `${this.translate.translate(
                'correlationChartWidget.BASE'
              )}: ${value.type} | ${this.translate.translate(
                'correlationChartWidget.COMPARISON'
              )}: ${value.base} `,
              position: 'bottom',
            },
          },
        });

        const data = {
          label: value.comparison,
          data: value.values.map((val: KeyValue<Date, number>) => ({
            x: new Date(val.key),
            y: val.value,
          })),
        };

        array.push(data);
      });

      return array;
    })
    // this.checkScalesUnit()
  );

  updateChart(): void {}
}

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  EnumService,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { DirectivesModule } from '@backoffice-frontend/shared/directives/common';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { TimeSeriesDynamicSelectionGroupComponent } from '@backoffice-frontend/time-series-identifier/ui-time-series-dynamic-selection';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { FormDirectiveModule } from '@clean-code/shared/directives/ui-form';
import {
  UtilDateModule,
  dateJsProviders,
} from '@clean-code/shared/util/util-date';
import { atLeastOne } from '@clean-code/shared/util/util-form';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { Dayjs } from 'dayjs';
import { filter } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CorrelationWidgetTypeConfig } from '../models/widget-type-config';

@Component({
  selector: 'correlation-widget-settings',
  standalone: true,
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,

    TranslocoModule,

    UtilDateModule,
    DirectivesModule,
    TimeSeriesDynamicSelectionGroupComponent,
    FormDirectiveModule,
  ],
  providers: [
    dateJsProviders(),
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'correlation-widget-settings',
        alias: 'correlation-widget-settings',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class CorrelationWidgetSettingsComponent extends BaseWidgetTypeConfigComponent<CorrelationWidgetTypeConfig> {
  override form = new FormGroup(
    {
      settings: new FormGroup({
        timeFrame: new FormControl<TimeFrame>('M_3'),
        startDate: new FormControl<Dayjs>(null),
        weeks: new FormControl(false, { nonNullable: true }),
        months: new FormControl(false, { nonNullable: true }),
        years: new FormControl(false, { nonNullable: true }),
      }),
      dataSources: new FormArray([]),
    },
    {
      validators: atLeastOne(Validators.required, [
        'settings.timeFrame',
        'settings.startDate',
      ]),
    }
  );

  private enumService = inject(EnumService);
  public timeFrames$ = this.enumService.getEnumType$('TimeSeriesTimeFrame');

  public faTimes = faTimes;

  constructor() {
    super();

    this.form
      .get('settings.startDate')
      .valueChanges.pipe(
        filter((value: Dayjs) => !!value),
        tap(() => this.form.get('settings.timeFrame').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.form
      .get('settings.timeFrame')
      .valueChanges.pipe(
        filter((value: TimeFrame) => !!value),
        tap(() => this.form.get('settings.startDate').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }

  public get dataSources(): FormArray {
    return this.form.get('dataSources') as FormArray;
  }

  clearStartDate(event: any) {
    event.stopPropagation();
    this.form.get('settings.startDate').reset();
  }
}

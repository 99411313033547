import { ArrayHelper } from '@clean-code/shared/common';
import dayjs from 'dayjs';

function getSharedTooltip(info: any) {
  const stringArray = ArrayHelper.distinct(info.points, 'seriesName')
    .map((i: any) => {
      return `<div class="flex flex-row space-x-2">
        <span>${i.seriesName}:</span>
        <span class="font-bold">${
          typeof i.value == 'number'
            ? //parseFloat(i.value.toFixed(2)).toLocaleString()
              i.value.toLocaleString('de', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            : i.value
        }</span>
      </div>`;
    })
    .join('');

  let timestamp = Date.parse(info.argumentText);

  return `
  <div class="flex flex-col text-center text-layout-page-container-text w-full h-full">
    <div class="border-b-2 px-1 mb-1 font-bold">
    ${
      // ObjectHelper.isDate(info.argumentText)
      // ?

      !isNaN(timestamp)
        ? dayjs(info.argumentText).format('L')
        : info.argumentText

      // : info.argumentText
    }
    </div>

    ${stringArray}

  </div>
  `;
}

function getSingleTooltip(info: any) {
  return `
<div class="flex flex-col text-center text-layout-page-container-text w-full h-full">
  <div class="border-b-2 px-1 mb-1 font-bold">
  ${
    // ObjectHelper.isDate(info.argumentText)
    // ?
    dayjs(info.argumentText).format('L')
    // : info.argumentText
  }
  </div>
  <div class="flex flex-row space-x-2">
    <span>${info.seriesName}:</span>
    <span class="font-bold">${
      typeof info.value == 'number'
        ? //parseFloat(info.value.toFixed(2)).toLocaleString()
          info.value.toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        : info.value
    }</span>
  </div>
</div>
`;
}

export function simpleTooltip(info: any) {
  return {
    html:
      info.points?.length > 0 ? getSharedTooltip(info) : getSingleTooltip(info),
    // `<div><div class='tooltip-header'>${info.argumentText}</div>` +
    // "<div class='tooltip-body'><div class='series-name'>" +
    // `<span class='top-series-name'>${info.points[0].seriesName}</span>` +
    // ": </div><div class='value-text'>" +
    // `<span class='top-series-value'>${info.points[0].valueText}</span>` +
    // "</div><div class='series-name'>" +
    // // `<span class='bottom-series-name'>${info.points[1].seriesName}</span>` +
    // ": </div><div class='value-text'>" +
    // // `<span class='bottom-series-value'>${info.points[1].valueText}</span>` +
    // '% </div></div></div>',
  };
}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<div
  *ngIf="data$ | async; let data"
  class="w-full h-full flex flex-col disable-select"
  [ngClass]="data.diffAbsolute >= 0 ? 'positive' : 'negative'"
>
  <h6 class="energy-source">{{ data.energySource }}</h6>

  <h5 class="timeIdentifier cursor-pointer" (click)="openPopup()">
    {{ data.timeIdentifierName }}
  </h5>

  <h4 class="value cursor-pointer" (click)="openPopup(data)">
    {{ data.currentValue | number : '1.0-2' }} {{ data.unit }}
  </h4>

  <br />
  <div
    class="difference cursor-pointer"
    (click)="
      openPopup({
        identifier: data.identifier,
        identifierId: data.identifierId
      })
    "
  >
    <ng-container *ngIf="calculationType === 'ABSOLUTE'">
      <h6 class="absolute">
        {{ data.diffAbsolute | number : '1.0-2' }} {{ data.unit }}
      </h6>
    </ng-container>

    <ng-container *ngIf="calculationType === 'RELATIVE'">
      <h6 class="percent">{{ data.diffPercent | percent : '1.0-2' }}</h6>
    </ng-container>
  </div>

  <div class="difference cursor-pointer" (click)="openPopup(data)">
    <h6 class="absolute">
      {{
        data.valueTimeStamp
          | date : (data.isIntradayValue ? 'short' : 'shortDate')
      }}
    </h6>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NavigationState, NavigationStore } from './navigation.store';

@Injectable({ providedIn: 'root' })
export class NavigationQuery extends Query<NavigationState> {
  constructor(override store: NavigationStore) {
    super(store);
  }
}

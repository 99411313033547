import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewsFeatureTerminWidgetModule } from '@backoffice-frontend/news/feature-termin-widget';
import {
  NewsFeatureWidgetModule,
  NewsWidgetComponent,
} from '@backoffice-frontend/news/feature-widget';
import { DashboardComponent } from '@clean-code/shared/components/ui-dashboard';
import { TranslocoModule } from '@ngneat/transloco';
import { InfoComponent } from './info.component';

const dynamics = [NewsWidgetComponent];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: InfoComponent,
      },
    ]),
    TranslocoModule,
    NewsFeatureWidgetModule,
    NewsFeatureTerminWidgetModule,
    DashboardComponent,
  ],
  declarations: [InfoComponent],
  exports: [dynamics, InfoComponent],
})
export class InfoModule {}

<div class="flex flex-col sm:flex-row sm:space-x-2 sm:justify-between">
  <cc-time-span-toggle
    [timeFrame]="selectedTimeFrame$"
    [deliveryDay]="deliveryDay"
  ></cc-time-span-toggle>
</div>
<ng-container *ngIf="dataSource$ | async; let dataSource">
  <div class="flex flex-col">
    <fof-index-chart
      class="!m-5"
      [identifier]="dataSource"
      [timeFrame]="selectedTimeFrame$ | async"
      [hideClosingText]="hideClosingValueHeader"
      (deliveryDayChanged)="deliveryDayChanged($event)"
      [legend]="legend"
    >
    </fof-index-chart>
    <fof-comparison-chart
      *ngIf="!!dataSource?.additionalDataSources?.length > 0"
      class="!m-5 h-96"
      [params]="comparisonData$ | async"
      [legend]="legend"
    >
    </fof-comparison-chart>
  </div>
  <div class="flex flex-col" *ngIf="dataSource">
    <fof-spot-chart
      class="!m-5"
      *ngIf="dataSource?.spot?.enabled"
      [spotConfigurationId]="+dataSource?.spot?.spotConfigurationId"
      [spotType]="dataSource?.spot?.spotType"
      [legend]="legend"
    >
    </fof-spot-chart>
    <fof-aroon-chart
      *ngIf="dataSource?.aroon?.enabled"
      class="!m-5"
      [identifier]="dataSource"
      [timeFrame]="selectedTimeFrame$ | async"
      [settings]="dataSource.aroon"
      [legend]="legend"
    ></fof-aroon-chart>
    <fof-ma-chart
      *ngIf="dataSource?.sma?.enabled"
      class="!m-5"
      [identifier]="dataSource"
      [timeFrame]="selectedTimeFrame$ | async"
      [settings]="dataSource.sma"
      [legend]="legend"
    ></fof-ma-chart>
    <fof-bollinger-band-chart
      *ngIf="dataSource?.bollinger?.enabled"
      class="!m-5"
      [identifier]="dataSource"
      [timeFrame]="selectedTimeFrame$ | async"
      [settings]="dataSource.bollinger"
      [legend]="legend"
    ></fof-bollinger-band-chart>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
import { DevExtremeChartDateAggregation } from '@clean-code/shared/common';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { RxFor } from '@rx-angular/template/for';
import { LetDirective } from '@rx-angular/template/let';
import { DxChartComponent, DxChartModule } from 'devextreme-angular/ui/chart';
import { LineChartDataModel, LineChartModel } from '../models/line-chart.model';
import { simpleTooltip } from '../tooltips/simple-tooltip';
import { customizeText } from '../utils/custom-text-helper';

export interface LineChartState {
  series: { label: string; color: string }[];
  data: any[];
  legend: boolean;
  yAxisTitle: string;
}

export type PointEventData = {
  date: Date;
  value: number;
};

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  imports: [CommonModule, DxChartModule, LetDirective, RxFor],
  providers: [RxState<LineChartState>],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevExtremeLineChartComponent {
  @ViewChild(DxChartComponent, { static: false })
  public chart: DxChartComponent;

  @Output()
  pointClickEvent = new EventEmitter<PointEventData>();
  public simpleTooltip = simpleTooltip;
  @Input() aggregationConfig: DevExtremeChartDateAggregation;
  public customText = customizeText;
  @Input() showGridLines = true;
  @Input() zoomable = true;
  @Input()
  legend: boolean;

  @Input()
  xAxesLabelFormat = { format: 'shortDate' };

  private state: RxState<LineChartState> = inject(RxState<LineChartState>);
  public state$ = this.state.select().pipe(selectSlice(['series', 'data']));
  public yAxisTitle$ = this.state.select('yAxisTitle');
  private tailwindThemeService = inject(TailwindThemeService);
  public colors = this.tailwindThemeService.getColors('chart-color');

  @Input()
  public set data(values: LineChartModel[]) {
    if (values) {
      const series = values.map((x, index) => {
        const color = this.tailwindThemeService.getColor(
          this.colors[index % (this.colors.length - 1)]
        );

        return {
          label: x.label,
          color: color
        };
      });

      const resultMap = new Map<string | Date, any>();
      values.forEach((value: LineChartModel) => {
        this.state.set({ yAxisTitle: value.yAxisTitle });
        // var result = new Map(value.data.map(i => [i.date, i.value]));
        value.data.forEach((data: LineChartDataModel) => {
          if (!(data.date instanceof Date) && !data.name) {
            throw new Error(
              'Missing `date` or `name` param in LineChartDataModel'
            );
          }

          let arg;
          if (data.date instanceof Date) {
            arg = data.date;
          }
          if (data.name) {
            arg = `${data.name}&nbsp;`;
          }

          let obj = resultMap.get(arg);

          if (!obj) {
            obj = {};
          }
          obj[value.label] = data.value;

          resultMap.set(arg, obj);
        });
      });

      const result = new Array<object>();

      resultMap.forEach((value, key) => {
        const map = new Map([['date', key]]);

        Object.keys(value).forEach(function(property) {
          map.set(property, value[property]);
        });
        const obj = Object.fromEntries(map);

        result.push(obj);
      });

      this.state.set({
        series,
        data: result
      });
    }
  }

  onPointClick(e: any) {
    const value = {
      date: e.target.argument,
      value: e.target.value
    } as PointEventData;
    this.pointClickEvent.emit(value);
  }
}

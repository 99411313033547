import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { FormDirectiveModule } from '@clean-code/shared/directives/ui-form';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@ngneat/transloco';
import { FormRowDirective } from 'shared/directives/ui-layout';
import { FormContainerComponent } from './form-container.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDatepickerModule,

    TranslocoModule,

    FormDirectiveModule,
    ActionButtonModule, // TODO: remove reduce deep dependency
    UtilDateModule,
    FormRowDirective,
  ],
  declarations: [FormContainerComponent],
  exports: [
    FormContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslocoModule,
    FormDirectiveModule,
    ActionButtonModule,
    MatProgressBarModule,
    UtilDateModule,
    FormRowDirective,
  ],
})
export class FormContainerModule {}

import { BaseEntity } from '@clean-code/shared/common';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { BaseState, BaseStore } from './search-base.state';

export abstract class AkitaSearchQueryEntity<
  T extends BaseEntity
> extends QueryEntity<BaseState<T>> {
  public formChange$ = this.select('akitaForm').pipe(
    filter((value) => !!value)
  );
  public selectSearchTerm$ = this.select('searchTerm');
  public readOnly$ = this.select('readOnly');
  public selectFilters$ = this.select('filters');

  constructor(protected store: BaseStore<T>) {
    super(store);
  }

  public get searchTerm() {
    return this.getValue().searchTerm;
  }

  public get readOnly() {
    return this.getValue().readOnly;
  }

  public get filters() {
    return this.getValue().filters;
  }

  public selectActive(): Observable<T> {
    return super.selectActive().pipe(debounceTime(300));
  }
}

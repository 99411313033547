<div [formGroup]="formGroup">
  <div class="group-gap divide-y" [formArrayName]="formCtrlName">
    <div class="flex flex-row justify-between my-2 items-center">
      <span class="label">{{
        caption ?? 'time-series-identifier.widgets.DATA_SOURCES' | transloco
      }}</span>

      <cc-add-action-button
        displayText="time-series-identifier.widgets.ADD_SYMBOL"
        (onClick)="addDataSource()"
      >
      </cc-add-action-button>

      <!-- Tooltip adjustment handling -->
      <fa-icon
        class="cursor-pointer float-right"
        global-tooltip="{{
          'time-series-identifier.widgets.settings.TOOLTIP' | transloco
        }}"
        [icon]="faQuestionCircle"
      ></fa-icon>
    </div>

    <div class="mb-3">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container
          *ngFor="let item of dataSources.controls; let i = index"
          [formGroupName]="i"
        >
          <div
            cdkDrag
            cdkDragPreviewContainer="parent"
            class="flex flex-row w-full items-center bg-white px-1"
          >
            <fa-icon
              cdkDragHandle
              [icon]="faGripLines"
              class="cursor-move text-gray-800 mr-2"
            ></fa-icon>
            <div
              formGroupName="settings"
              class="flex flex-row space-x-2 w-full"
            >
              <!-- Time Series Selection, Adjustment, Gaps, etc. -->
              <time-series-dynamic-selection
                class="w-full"
                formGroupName="identifier"
              ></time-series-dynamic-selection>
            </div>

            <!-- Delete Button -->
            <fa-icon
              class="hover:cursor-pointer hover:text-red-600"
              [icon]="faTrashAlt"
              (click)="removeDataSource(i)"
            ></fa-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { TerminWidgetData } from '../entities/termin-widget-data';
import { GroupedTermin } from './../entities/grouped-termin';

@Injectable({ providedIn: 'root' })
export class FofTerminDataService {
  public readonly entityFields = `
        id
        description
        location
        start
        end
        internal
        display
        createdDate
        createdBy
    `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(): Observable<TerminWidgetData> {
    const query = `
            query {
                terminGetAll {
                    events {
                        ${this.entityFields}
                    }
                    months {
                      month
                      isActive
                      days
                      {
                        position
                        day
                        dayOfWeek
                        date
                        isToday
                        isEvent
                        isInternalEvent
                        isInFirstWeek
                        isInLastWeek
                        events {
                          ${this.entityFields}
                        }
                      }
                    }
                }
            }`;

    return this.graphqlService.query<TerminWidgetData>(query);
  }

  getById$(id: ID): Observable<GroupedTermin> {
    const query = `
            query($id: Int!) {
                terminGetById(id: $id) {
                    ${this.entityFields}
                }
            }`;
    return this.graphqlService.query<GroupedTermin>(query, { id });
  }
}

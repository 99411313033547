import { BaseEntity, FilterParam, ID } from '@clean-code/shared/common';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfigOptions,
} from '@datorama/akita';

export interface BaseState<T extends BaseEntity>
  extends EntityState<T, ID>,
    ActiveState {
  searchTerm: string;
  readOnly: boolean;
  filters: FilterParam[];
}

export class BaseStore<T extends BaseEntity> extends EntityStore<BaseState<T>> {
  constructor(state: BaseState<T>, options?: StoreConfigOptions) {
    super(initStore(state), options);
  }
}

export function initStore<T extends BaseEntity>(
  init: BaseState<T>
): BaseState<T> {
  init.loading = false;
  return init;
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  MediaQueryService,
  NavigationService,
} from '@clean-code/shared/common';
import { FormUiStateService } from '@clean-code/shared/util/util-state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { LetDirective } from '@rx-angular/template/let';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { TableStateService } from '../services/table-state.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  standalone: true,
  selector: 'cc-table-preview',
  styleUrls: ['./table-preview.component.scss'],
  templateUrl: './table-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    FontAwesomeModule,
    LetDirective,
  ],
})
export class TablePreviewComponent implements OnDestroy {
  @HostBinding('class') class = 'cc-table-preview';

  private deviceDetectorService = inject(DeviceDetectorService);

  public _width = this.deviceDetectorService.isDesktop() ? '50%' : '90%';
  @Input()
  public set width(value: string) {
    if (value && this.deviceDetectorService.isDesktop()) {
      this._width = value;
    }
  }

  public get width() {
    return this._width;
  }

  public faTimes = faTimes;
  public faAngleLeft = faAngleLeft;
  public showBack$ = new Subject<boolean>();

  @Input()
  showBack = true;

  public mode$ = this.mediaQueryService.isLowerThanMedium$.pipe(
    map((value: boolean) => (value ? 'over' : 'side'))
  );

  public open$ = new BehaviorSubject<boolean>(false);

  private closeSubject = new Subject<void>();
  public stateService: FormUiStateService;

  constructor(
    private router: Router,
    public mediaQueryService: MediaQueryService,
    private changeDetectorRef: ChangeDetectorRef,
    public navigation: NavigationService,
    private tableStateService: TableStateService,
    private activatedRoute: ActivatedRoute
  ) {
    router.events
      .pipe(
        debounceTime(100),
        map(
          (_) =>
            router.url.includes('preview') || router.url.includes('history')
        ),
        tap((value: boolean) => {
          this.open$.next(value);
          this.changeDetectorRef.markForCheck();
          const urlArray = router.url.split('/');
          const previewIndex = urlArray.indexOf('preview');

          this.showBack$.next(
            this.showBack && urlArray.length > previewIndex + 2
          );
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.stateService = inject(FormUiStateService, {
      optional: true,
    });
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  close() {
    this.router.navigate(['./'], { relativeTo: this.activatedRoute });
    this.tableStateService.clear();
    this.tableStateService.resetSelection();
  }

  back() {
    this.router.navigate(['./preview', this.tableStateService?.selectedId], {
      relativeTo: this.activatedRoute,
    });
    this.tableStateService.clear();
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDashboardListDto } from '@clean-code/shared/dashboard/dashboard/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { DashboardReferenceComponent } from '../dashboard-reference/dashboard-reference.component';

@Component({
  standalone: true,
  templateUrl: './dashboard-reference-dialog.component.html',
  styleUrls: ['./dashboard-reference-dialog.component.css'],
  imports: [FontAwesomeModule, TranslocoModule, DashboardReferenceComponent],
})
export class DashboardReferenceDialogComponent {
  faTimes = faTimes;
  constructor(
    public dialogRef: MatDialogRef<DashboardReferenceDialogComponent>
  ) {}

  public onSave(result: { operation: string; data: IDashboardListDto }): void {
    this.dialogRef.close();
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}

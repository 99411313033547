import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  TerminWidgetQuery,
  TerminWidgetStore,
} from '@backoffice-frontend/news/domain';
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'cc-termin-widget-dialog',
  templateUrl: './termin-widget-dialog.component.html',
})
export class TerminWidgetDialogComponent implements OnInit {
  public formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    description: new UntypedFormControl(null, [Validators.required]),
    location: new UntypedFormControl(null, [Validators.required]),
    start: new UntypedFormControl(null, [Validators.required]),
    end: new UntypedFormControl(null, [Validators.required]),
    display: new UntypedFormControl(true, [Validators.required]),
    internal: new UntypedFormControl(false, [Validators.required]),
  });

  public faChevronRight = faChevronRight;
  public faChevronLeft = faChevronLeft;
  public faTimes = faTimes;

  public selectActive$ = this.query.selectActive();

  constructor(
    private query: TerminWidgetQuery,
    public store: TerminWidgetStore
  ) {}

  ngOnInit() {
    this.formGroup.controls.internal.disable();
  }

  public previous() {
    this.store.setActive({ prev: true });
  }

  public next() {
    this.store.setActive({ next: true });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataTableParameters,
  FilterOperator,
  FilterParam,
  ID,
  ObjectHelper,
  PagingResponse,
  SortBy,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GlobalErrorHandlerService } from '@clean-code/shared/util/util-error';
import { Dayjs } from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { AuthQuery } from '@clean-code/shared/auth/util-auth';
import { GraphQLRequest } from './models/graphql-request';
import { checkError } from './operator/check-error-operator';
@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(
    private httpClient: HttpClient,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private configService: ConfigService,
    private authQuery: AuthQuery
  ) {}

  getAll<T>(
    params: DataTableParameters,
    queryName: string,
    entityFields: string
  ): Observable<PagingResponse<T>> {
    const par = this.mapDataTableParameters(params);
    const where = this.mapDataTableFilterParameters2(params.filters);

    const order = this.mapDataTableSortParametersTemp(params.sortBy);

    const query = `
      query {
          ${queryName}(
              ${par}
              ${order}
              ${where}
          ){
              items
              {
                  ${entityFields}
              }
              pageInfo{
                  hasNextPage
                  hasPreviousPage
              }
              totalCount
          }
      }
  `;
    return this.query<PagingResponse<T>>(query);
  }

  getById<T>(
    id: ID,
    queryName: string,
    entityFields: string,
    condition?: string
  ): Observable<T> {
    const where = condition ? condition : `where: { id: { eq: ${id} } }`;
    const query = `
    query {
      ${queryName}(${where}) {
        ${entityFields}
      }
    }
  `;
    return this.singleQuery<T>(query);
  }

  public query<T>(
    query: unknown,
    variables?: unknown,
    url?: string,
    params?: {
      ignoreNullCheckFilter?: boolean;
      redirectTo404?: boolean;
      check404Property?: string;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    if (params?.redirectTo404) {
      headers = headers.set('X-404-OnNull', 'true');
      if (params.check404Property) {
        headers = headers.set('X-404-OnNull-Property', params.check404Property);
      }
    }

    if (!url) {
      url = this.configService.settings['graphql'];
    }

    const request = {
      query: query,
      variables: variables,
    };

    return this.httpClient.post<T>(url, request, { headers }).pipe(
      checkError(),
      map((response: any) => response.data[Object.keys(response.data)[0]]),
      catchError((error) => {
        this.globalErrorHandlerService.handleError(error);
        return of(null);
      }),
      filter((value) => {
        if (params?.ignoreNullCheckFilter || typeof value === 'boolean') {
          return true;
        }
        return !!value;
      })
    );
  }

  public singleQuery<T>(query: unknown, url?: string): Observable<T> {
    return this.query<T[]>(query, null, url, { redirectTo404: true }).pipe(
      map((response: T[]) => {
        if (response.length > 0) {
          return response[0];
        }
        return null;
      })
    );
  }

  public SubQuery<T>(query: any, variables?: any, url?: string): Observable<T> {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');

    const request = {
      query: query,
      variables: variables,
    } as GraphQLRequest;

    return this.httpClient.post<T>(url, request, { headers }).pipe(
      map((response: any) => {
        const firstLvl = Object.keys(response.data)[0];
        const secondLvl = Object.keys(response.data[firstLvl])[0];

        return response.data[firstLvl][secondLvl];
      }),
      catchError((error) => {
        this.globalErrorHandlerService.handleError(error);
        return of(null);
      }),
      filter((value) => !!value)
    );
  }

  public mutation<T>(
    mutation: any,
    variables?: any,
    url?: string,
    params?: { ignoreNullCheckFilter?: boolean; redirectTo404?: boolean }
  ): Observable<T> {
    return this.query<T>(mutation, variables, url, params);
  }

  public getAllFieldsFromQuery(fieldsString: string) {
    return fieldsString
      .replace(/ /g, '')
      .split('\n')
      .filter((x) => !!x);
  }

  public mapDataTableParameters(params: DataTableParameters): string {
    if (!params.perPage) {
      params.perPage = 50;
    }
    if (!params.page) {
      params.page = 0;
    }

    let par = 'skip:' + params.page * params.perPage;
    par += ', take:' + params.perPage;

    return par;
  }

  public mapDataTableSortParameters(sortBy?: SortBy[]): string {
    //nothing to sort?
    if (!sortBy || sortBy.length === 0) {
      return '';
    }
    //track items
    const sortItems: InternalSort[] = [];

    //TODO: find nested objects?
    sortBy.forEach((sort: SortBy) => {
      const splits = sort.prop.split('.');

      if (splits.length < 2) {
        //self field sort
        const self = sortItems.find((value) => value.entitiy === '');
        if (self) {
          self.fields.push({ field: splits[0], dir: sort.dir });
        } else {
          sortItems.push({
            entitiy: '',
            fields: [{ field: splits[0], dir: sort.dir }],
          });
        }
      } else {
        //entity field sort
        const entity = sortItems.find((value) => value.entitiy === splits[0]);
        if (entity) {
          entity.fields.push({ field: splits[1], dir: sort.dir });
        } else {
          sortItems.push({
            entitiy: splits[0],
            fields: [{ field: splits[1], dir: sort.dir }],
          });
        }
      }
    });

    return `order: { ${sortItems
      .map((sort) => {
        const fields = sort.fields
          .map((field) => `${field.field}:${field.dir}`)
          .join(', ');
        return sort.entitiy === '' ? fields : `${sort.entitiy}: { ${fields}}`;
      })
      .join(', ')} }`;
  }

  public mapDataTableSortParametersTemp(sortBy?: SortBy[]): string {
    let order = ``;

    if (!sortBy || sortBy.length === 0) {
      return order;
    }

    sortBy.forEach((item) => {
      if (item.prop.includes('.')) {
        const itemSplit = item.prop.split('.');
        order = `${item.dir}`;
        for (let i = itemSplit.length - 1; i >= 0; i--) {
          order = `{ ${itemSplit[i]}: ${order} }`;
        }
      } else {
        order = `{ ${item.prop}: ${item.dir} }`;
      }
    });

    order = 'order: ' + order;
    return order;
  }

  public mapDataTableSortParametersOld(sortBy: SortBy[]): string {
    let order = '';
    if (sortBy && sortBy.length > 0) {
      order = 'order: { ';

      //TODO: better for multi-sort with sub fields would be to keep track of the objects before the '.'

      sortBy.forEach((sort: SortBy, index: number) => {
        if (index > 0) {
          order += ', ';
        }

        const splits = sort.prop.split('.');
        if (splits.length > 1) {
          order +=
            splits[0] +
            ': { ' +
            splits[1] +
            ': ' +
            sort.dir.toLocaleUpperCase() +
            ' }';
        } else {
          order += sort.prop + ': ' + sort.dir.toLocaleUpperCase();
        }
      });

      order += ' }';
    }
    return order;
  }

  public mapDataTableFilterParameters(
    filters: FilterParam[],
    conditions?: string,
    skip?: string[]
  ) {
    const keyOperator: FilterKeyOperator[] = [];
    let value: string | number | Dayjs | null = null;
    const whereClauses: string[] = [];
    let whereClause = '';

    if (conditions) {
      whereClause += conditions;
    }

    const getKeysFromFilters = (thisFilters: FilterParam[]) => {
      if (thisFilters && thisFilters.length > 0) {
        thisFilters.forEach((x) => {
          if (x.conditions) {
            whereClause += x.conditions;
          }
          if (x.filters) {
            getKeysFromFilters(x.filters);
          }

          if (x.key && (!skip || !skip.includes(x.key))) {
            // keys.push(x.key);

            if (x.operator) {
              keyOperator.push({ key: x.key, operator: x.operator });
            } else {
              keyOperator.push({
                key: x.key,
                operator: FilterOperator.contains,
              });
            }
          }

          if (x.value && value === null) {
            value = this.getValue(x.value);
          }
        });
      }
    };

    getKeysFromFilters(filters);

    if (keyOperator.length > 0 && value) {
      keyOperator.forEach((ko) => {
        if (ko.key.includes('.')) {
          whereClauses.push(this.objectKeyWhereClause(ko, value));
        } else {
          whereClauses.push(`{ ${ko.key}: { ${ko.operator}: ${value} }}`);
        }
      });
    }

    if (whereClauses && whereClauses.length > 0) {
      whereClause += `{or: [ ${whereClauses.join(', ')} ]}`;
    }

    whereClause = `where: {and: [${whereClause}]}`;
    return whereClause;
  }

  private objectKeyWhereClause(
    ko: FilterKeyOperator,
    value: string | number | Dayjs
  ) {
    let useSome = false;
    let whereClause = `${ko.operator}: ${value}`;

    if (ko.key.endsWith('.asArray')) {
      useSome = true;
      ko.key = ko.key.replace('.asArray', '');
    }

    const keySplit = ko.key.split('.');

    for (let i = keySplit.length - 1; i >= 0; i--) {
      if (i === keySplit.length - 1 && useSome) {
        whereClause = `some: { ${keySplit[i]}: { ${whereClause} } }`;
      } else {
        whereClause = `${keySplit[i]}: { ${whereClause} }`;
      }
    }
    whereClause = `{ ${whereClause} }`;
    return whereClause;
  }

  public mapDataTableFilterParameters2(
    filters: FilterParam[],
    conditions?: string
  ) {
    const keys: string[] = [];
    let value: string | number | Dayjs | null = null;
    const whereClauses: string[] = [];
    let whereClause = '';

    const keysFromFilters = (thisFilters: FilterParam[]) => {
      if (thisFilters && thisFilters.length > 0) {
        thisFilters.forEach((x) => {
          if (x.filters) {
            keysFromFilters(x.filters);
          }
          if (x.key) {
            if (!x.conditions) {
              keys.push(x.key);
            } else {
              whereClause += x.conditions;
            }
          }
          if (x.value) {
            value = this.getValue(x.value);
          }
        });
      }
    };

    keysFromFilters(filters);

    if (keys.length > 0 && value) {
      keys.forEach((key) => {
        if (key.includes('.')) {
          whereClauses.push(this.objectKeyWhereClause2(key, value));
        } else {
          whereClauses.push(`{ ${key}: { contains: ${value} }}`);
        }
      });
    }

    if (whereClauses && whereClauses.length > 0) {
      whereClause += `{or: [ ${whereClauses.join(', ')} ]}`;
    }

    whereClause = `where: {and: [${whereClause}]}`;
    return whereClause;
  }

  private objectKeyWhereClause2(key: string, value: string | number | Dayjs) {
    let useSome = false;
    let whereClause = `contains: ${value}`;

    if (key.endsWith('.asArray')) {
      useSome = true;
      key = key.replace('.asArray', '');
    }

    const keySplit = key.split('.');

    for (let i = keySplit.length - 1; i >= 0; i--) {
      if (i === keySplit.length - 1 && useSome) {
        whereClause = `some: { ${keySplit[i]}: { ${whereClause} } }`;
      } else {
        whereClause = `${keySplit[i]}: { ${whereClause} }`;
      }
    }
    whereClause = `{ ${whereClause} }`;
    return whereClause;
  }

  private getValue(value: string | number | Dayjs) {
    if (!!value && ObjectHelper.isDate(value)) {
      //@ts-ignore
      return '"' + (value as Dayjs).local().format() + '"';
    }
    return value;
  }
}

class InternalSort {
  entitiy: string;
  fields: FieldsInternal[];
}

class FieldsInternal {
  field: string;
  dir: 'ASC' | 'DESC';
}

export interface FilterKeyOperator {
  key: string;
  operator: FilterOperator;
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { IFormBaseService } from '@clean-code/shared/util/util-component-services';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TimeSeriesIdentifier } from '../models/time-series-identifier';
import { TimeSeriesIdentifierLocalized } from '../models/time-series-widget';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierListService
  implements
    EditSearchDataService<TimeSeriesIdentifier>,
    IFormBaseService<TimeSeriesIdentifier>
{
  public readonly entityFields = `
      id
      name
      baseIdentifier
      peakIdentifier
      year
      quarter
      month
      startDate
      areaMarketAreaId
      areaMarketArea
      {
        name
      }
      isCostingIdentifier
      isSpread
      areaDeliveryAreaId
      isSoftDeleted
      isActive
      energySource
      unit
      createdBy
      updatedBy
      createdDate
      updatedDate
      typeId
      workingDaysFromEnd
    `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<TimeSeriesIdentifier>> {
    const par = this.graphqlService.mapDataTableParameters(params);
    const order = this.graphqlService.mapDataTableSortParametersTemp(
      params.sortBy
    );
    let where = this.graphqlService.mapDataTableFilterParameters2(
      params.filters
    );

    const query = `
      query{
        timeSeriesIdentifierAll(
          ${par}
          ${order}
          ${where}
          ){
              totalCount
              items
              {
                  ${this.entityFields}
              }
              pageInfo{
                  hasNextPage
                  hasPreviousPage
              }
          }
      }`;

    return this.graphqlService
      .query<PagingResponse<TimeSeriesIdentifier>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getById$(id: ID): Observable<TimeSeriesIdentifier> {
    const query = `
      query($id: Int!) {
          timeSeriesIdentifierById(id: $id) {
              ${this.entityFields}
          }
      }`;

    return this.graphqlService.query<TimeSeriesIdentifier>(query, { id });
  }

  getByMarketAreaId$(marketAreaId: ID): Observable<TimeSeriesIdentifier[]> {
    const query = `
      query($marketAreaId: Int!) {
          timeSeriesIdentifierByMarketArea(marketAreaId: $marketAreaId) {
              ${this.entityFields}
          }
      }`;

    return this.graphqlService.query<TimeSeriesIdentifier[]>(query, {
      marketAreaId: +marketAreaId,
    });
  }

  update$(input: TimeSeriesIdentifier): Observable<ID> {
    const mutation = `
        mutation ($input: TimeSeriesIdentifierAddInput!){
          timeSeriesIdentifierUpdate(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  add$(input: TimeSeriesIdentifier): Observable<ID> {
    const mutation = `
        mutation ($input: TimeSeriesIdentifierAddInput!){
            timeSeriesIdentifierAdd(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
        mutation ($id: Int!){
            timeSeriesIdentifierDelete(id: $id)
          }`;

    return this.graphqlService.mutation<any>(mutation, { id });
  }

  timeSeriesLocalized$(
    symbols: string[]
  ): Observable<TimeSeriesIdentifierLocalized[]> {
    const query = `
    query{
      timeSeriesLocalized(symbols: [${symbols
        .map((x) => '"' + x + '"')
        .join(',')}])
      {
        id
        identifier
        frontOfficeName
        longName
      }
    }`;

    return this.graphqlService.query<TimeSeriesIdentifierLocalized[]>(
      query,
      null,
      null
    );
  }

  public getEndDate$(
    areaMarketAreaId: number,
    year: number,
    quarter: number,
    workingDaysFromEnd: number
  ): Observable<Date> {
    const query = `
        query ($areaMarketAreaId: Int, $year: Int!, $quarter: Int, $workingDaysFromEnd: Int!){
            timeSeriesIdentifierEndDate(areaMarketAreaId: $areaMarketAreaId, year: $year, quarter: $quarter, workingDaysFromEnd: $workingDaysFromEnd)
          }`;

    return this.graphqlService.query<Date>(query, {
      areaMarketAreaId,
      year,
      quarter,
      workingDaysFromEnd,
    });
  }
}

<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="flex flex-col lg:flex-row lg:h-56 space-x-4"
  *ngIf="data$ | async; let data"
>
  <cc-line-chart
    [data]="data.chart"
    class="w-full lg:w-3/4"
    [legend]="legend"
  ></cc-line-chart>
  <div class="w-full lg:w-1/4">
    <div class="text-center">
      {{
        'market-dashboard-details.AROON_TITLE'
          | transloco: { value: settings?.period }
      }}
    </div>
    <table class="table-fixed">
      <tbody>
        <tr>
          <td class="w-56">
            {{ 'market-dashboard-details.AROON_UP' | transloco }}
          </td>
          <td class="w-28">{{ data.table.up | number: '.2-2' }}</td>
        </tr>
        <tr>
          <td>
            {{ 'market-dashboard-details.AROON_DOWN' | transloco }}
          </td>
          <td class="w-28">{{ data.table.down | number: '.2-2' }}</td>
        </tr>
        <tr>
          <td>
            {{ 'market-dashboard-details.TREND' | transloco }}
          </td>
          <td>
            <fof-aroon-trend-icon
              [delta]="data.table.trend"
            ></fof-aroon-trend-icon>
          </td>
          <!-- <td>{{ data.table.trend | percent: '.2-2' }}</td> -->
        </tr>
      </tbody>
    </table>
    <ul class="list-disc !m-5">
      <li class="break-words">
        {{ 'market-dashboard-details.AROON_TIP' | transloco }}
      </li>
      <li class="break-words">
        {{ 'market-dashboard-details.AROON_TIP2' | transloco }}
      </li>
    </ul>
  </div>
</div>

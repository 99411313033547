import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  effect,
  inject,
  untracked,
} from '@angular/core';
import {
  IWidgetTypeContentComponent,
  WidgetTypeProvider,
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { TranslocoModule } from '@ngneat/transloco';
import { DynamicComponent, DynamicModule } from 'ng-dynamic-component';

@Component({
  standalone: true,
  selector: 'dashboard-widget-type-content',
  templateUrl: './widget-type-content.component.html',
  styleUrls: ['./widget-type-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, DynamicModule, TranslocoModule],
})
export class WidgetTypeContentComponent {
  @HostBinding('class') class = 'dashboard-widget-type-content';
  public contentCmponent: { new (): IWidgetTypeContentComponent } | undefined;
  public headerTemplate: TemplateRef<any>;

  @ViewChild(DynamicComponent) set component(value: DynamicComponent) {
    this.headerTemplate = (
      value?.componentRef?.instance as IWidgetTypeContentComponent
    )?.headerTemplate;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public outputs: any;

  private widgetTypeProvider = inject(WidgetTypeProvider);
  private widgetFacade = inject(DashboardWidgetFacade);
  public widget = this.widgetFacade.widget;
  public inputs = this.widgetFacade.inputs;

  constructor() {
    effect(
      () => {
        const widget = this.widget();
        untracked(() => {
          if (widget) {
            const provider = this.widgetTypeProvider.getProvider(
              widget.type as string
            );
            this.contentCmponent = provider ? provider.contentCmp : undefined;
          }
        });
      },
      { allowSignalWrites: true }
    );
  }
}

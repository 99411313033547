<div class="animate-pulse flex flex-col space-x-4 w-1/2">
  <div class="flex-1 space-y-4 py-1">
    <div class="h-4 bg-primary-color rounded w-3/4"></div>
    <div class="space-y-2">
      <div class="h-4 bg-primary-color rounded"></div>
      <div class="h-4 bg-primary-color rounded w-5/6"></div>
    </div>

    <div class="h-4 bg-primary-color rounded w-3/4"></div>
    <div class="space-y-2">
      <div class="h-4 bg-primary-color rounded"></div>
      <div class="h-4 bg-primary-color rounded w-5/6"></div>
    </div>

    <div class="h-4 bg-primary-color rounded w-3/4"></div>
    <div class="space-y-2">
      <div class="h-4 bg-primary-color rounded"></div>
      <div class="h-4 bg-primary-color rounded w-5/6"></div>
    </div>
  </div>
  <div class="text-center">{{ 'common.LOADING' | transloco }}</div>
</div>

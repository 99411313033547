import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { NormCubicMeterQuery } from '../+state/norm-cubic-meter.query';

export const NORM_CUBIC_METER = new InjectionToken('NORM_CUBIC_METER', {
  providedIn: 'root',
  factory: () => {
    const query = inject(NormCubicMeterQuery);
    return new PaginatorPlugin(query, { startWith: 0 });
  },
});

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AccountService } from '@clean-code/shared/auth/util-auth';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { debounceTime, first, startWith, tap } from 'rxjs/operators';

//angular
registerLocaleData(localeDe);
registerLocaleData(localeEn);

//devextreme
loadMessages(deMessages);
loadMessages(enMessages);

//dayjs comes with en locale as default
declare var require: any;
require('dayjs/locale/de');
dayjs.extend(utc);

//https://github.com/armanozak/angular-dynamic-locale <- source for implementation

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private initialized = false;

  get currentLocale(): string {
    return this.translocoService.getActiveLang();
  }

  get availableLanguages(): Array<string> {
    return this.translocoService
      .getAvailableLangs()
      .map((lang: any) => lang.label);
  }

  languageChangesWithDefault$ = this.translocoService.langChanges$.pipe(
    startWith(this.translocoService.getActiveLang()),
    debounceTime(100)
  );

  activeLanguage$ = this.languageChangesWithDefault$;
  activeLanguageSignal = toSignal(this.languageChangesWithDefault$);

  private langToLocaleMapping = {
    en: 'en-US',
    de: 'de-DE',
    fr: 'fr-FR',
  };

  public get configuredLanguages(): Array<{
    id: string;
    label: string;
    locale: string;
    selectable: boolean;
  }> {
    const availableLanguages = this.translocoService.getAvailableLangs();
    return availableLanguages.map((lang: any) => {
      Object.entries(this.langToLocaleMapping).find(([key, value]) => {
        if (key === lang.id) {
          lang.locale = value;
          return true;
        }
        return false;
      });
      return lang;
    });
  }

  constructor(
    private translocoService: TranslocoService,
    private accountService: AccountService,
    @Optional()
    @SkipSelf()
    otherInstance: LanguageService
  ) {
    if (otherInstance) {
      throw 'LanguageService should have only one instance.';
    }
    this.initLocale();

    config({ defaultCurrency: 'EUR' });
  }

  private subscribeToLangChange() {
    this.activeLanguage$
      .pipe(
        tap((lang) => {
          locale(lang);

          //dayjs uses de-DE
          let localeId: string;
          Object.entries(this.langToLocaleMapping).find(([key, value]) => {
            if (key === lang) {
              localeId = value;
              return true;
            }
            return false;
          });

          dayjs.locale(localeId);
        })
      )
      .subscribe();
    // this.translate.onLangChange.subscribe(async () => {
    //   const { shouldReuseRoute } = this.router.routeReuseStrategy;
    //   this.setRouteReuse(() => false);
    //   this.router.navigated = false;
    //   await this.router.navigateByUrl(this.router.url).catch(noop);
    //   this.setRouteReuse(shouldReuseRoute);
    // });
  }

  //no need to set this from outside
  private initLocale(/*localeId: string, defaultLocaleId?: string*/) {
    if (this.initialized) {
      return;
    }

    // if (defaultLocaleId) {
    //   this.setDefaultLocale(defaultLocaleId);
    // }

    // this.setLanguage(localeId);
    this.subscribeToLangChange();

    this.initialized = true;
  }

  setDefaultLocale(localeId: string) {
    this.translocoService.setDefaultLang(localeId);
  }

  setLanguage(localeId: string) {
    this.accountService
      .updateLanguage(localeId)
      .pipe(
        tap(() => this.translocoService.setActiveLang(localeId)),
        first()
      )
      .subscribe();
  }

  public get availableLanguage(): Array<string> {
    return ['DE', 'EN'];
  }
}

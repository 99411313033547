type PermissionValues = 'user-limit.access';

export class UserLimitPermissions {
  public static readonly userLimitAccess: PermissionValues =
    'user-limit.access';

  public static readonly sideNavPermission = [
    UserLimitPermissions.userLimitAccess,
  ];
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { PageContainerComponent } from './page-container.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    TranslocoModule,

    FontAwesomeModule,
  ],
  declarations: [PageContainerComponent],
  exports: [PageContainerComponent, FontAwesomeModule],
})
export class PageContainerModule {}

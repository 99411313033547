import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { DashboardWidgetEditComponent } from '../widget-edit/widget-edit.component';

@Component({
  standalone: true,
  templateUrl: './widget-edit-dialog.component.html',
  styleUrls: ['./widget-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    TranslocoModule,
    FontAwesomeModule,
    DashboardWidgetEditComponent,
  ],
})
export class DashboardWidgetEditDialogComponent {
  @HostBinding('class') class = 'widget-add-dialog-component';
  faTimes = faTimes;
  public constructor(
    public dialogRef: MatDialogRef<DashboardWidgetEditDialogComponent>
  ) {}

  public onAddedResult(data: {
    operation: string;
    data: IDashboardWidgetDetailDto;
  }) {
    this.dialogRef.close(data?.data);
  }

  public onClose() {
    this.dialogRef.close();
  }
}

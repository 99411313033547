<h1 class="bo-widget-header">
  <ng-container
    *ngIf="headerTemplate ?? defaultHeaderTemplate"
    [ngTemplateOutlet]="defaultHeaderTemplate"
  ></ng-container>
</h1>

<div class="bo-widget-content">
  <ng-content></ng-content>
</div>

<ng-template #defaultHeaderTemplate>
  <ng-content select="[widget-header]"></ng-content>
</ng-template>

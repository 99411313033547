import { Injectable } from '@angular/core';
import { DataTableParameters } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import orderBy from 'lodash.orderby';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewsTranslated } from '../entities/news';
import { FofNewsDataService } from '../infrastructure/fof-news.data.service.service';

@Injectable({ providedIn: 'root' })
export class WidgetFacade {
  constructor(
    private service: FofNewsDataService,
    private translateService: TranslocoService
  ) {}

  public getAll$(params: DataTableParameters): Observable<NewsTranslated[]> {
    return this.service
      .getAll$(params)
      .pipe(map((value: PaginationResponse<NewsTranslated>) => value.data));
  }

  public getAllApa$(): Observable<NewsTranslated[]> {
    return this.service.getAllApa$();
  }

  /**
   * Gets the news according to the news type.
   * ATTENTION: ESTMK News params NOT implemented server side!
   * @param source
   * @param params
   * @returns
   */
  public getNews$(
    source: 'ALL' | 'APA' | 'ESTMK',
    params?: DataTableParameters
  ) {
    if (source === 'ALL') {
      return forkJoin({
        news: this.getAll$(params ?? {}),
        apaNews: this.getAllApa$(),
      }).pipe(
        map((values) => {
          const newsCombined = [].concat(values.news);
          if (values.apaNews) {
            return newsCombined.concat(values.apaNews);
          }
          return newsCombined;
        }),
        map((values: NewsTranslated[]) =>
          orderBy(values, ['createdDate', 'position'], ['desc', 'asc'])
        )
      );
    } else if (source === 'APA') {
      return this.getAllApa$();
    } else if (source === 'ESTMK') {
      return this.getAll$(params ?? {}).pipe(
        map((values: NewsTranslated[]) =>
          orderBy(values, ['createdDate', 'position'], ['desc', 'asc'])
        )
      );
    } else {
      throw new Error('Source ' + source + ' not supported');
    }
  }

  // public getById$(id: ID): Observable<NewsTranslated> {
  //     return this.service.getById$(id);
  // }
}

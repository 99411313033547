<ng-container *rxLet="state$; let state">
  <dx-chart
    (onPointClick)="onPointClick($event)"
    [dataSource]="state.data"
    class="w-full h-full px-1 pb-2"
  >
    <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>
    <ng-container *ngIf="aggregationConfig && aggregationConfig.enabled">
      <dxi-series
        *rxFor="let series of state.series"
        [aggregationInterval]="aggregationConfig.intervalType"
        [color]="series.color"
        [name]="series.label"
        [valueField]="series.label"
      >
        <dxo-aggregation [enabled]="true" [method]="aggregationConfig.method">
        </dxo-aggregation>
      </dxi-series>
    </ng-container>
    <ng-container *ngIf="!aggregationConfig || !aggregationConfig.enabled">
      <dxi-series
        *rxFor="let series of state.series"
        [color]="series.color"
        [name]="series.label"
        [valueField]="series.label"
      >
      </dxi-series>
    </ng-container>
    <dxi-value-axis title="{{ yAxisTitle$ | async }}">
      <!-- high priority -->
      <dxo-label [customizeText]="customText"></dxo-label>
    </dxi-value-axis>
    <dxo-common-series-settings argumentField="date" type="line">
      <dxo-point [size]="3" [visible]="true"></dxo-point>
    </dxo-common-series-settings>
    <!-- <dxo-margin [bottom]="20"></dxo-margin> -->
    <dxo-argument-axis
      [label]="xAxesLabelFormat"
      discreteAxisDivisionMode="crossLabels"
    >
      <dxo-grid [visible]="showGridLines"></dxo-grid>
    </dxo-argument-axis>
    <dxo-legend
      [visible]="legend"
      horizontalAlignment="center"
      itemTextPosition="bottom"
      verticalAlignment="bottom"
    >
    </dxo-legend>
    <!-- <dxo-export [enabled]="true"></dxo-export> -->
    <dxo-tooltip
      [customizeTooltip]="simpleTooltip"
      [enabled]="true"
      [shared]="true"
      [zIndex]="9999"
    >
    </dxo-tooltip>

    <dxo-zoom-and-pan *ngIf="zoomable" argumentAxis="both" valueAxis="none">
    </dxo-zoom-and-pan>
  </dx-chart>
</ng-container>

import { Injectable } from '@angular/core';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameUtilService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { Area } from '@clean-code/backoffice/area/util-api';
import {
  BaseEntity,
  GraphQLFragments,
  ID,
  LocalizationSet,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import dayjs, { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AroonSummary } from '../models/aroon-summary';
import { BollingerBandSummary } from '../models/bollinger-with-index';
import { AroonSettings } from '../models/settings/aroon-settings';
import { BollingerSettings } from '../models/settings/bollinger-settings';
import { SmaSettings } from '../models/settings/sma-settings';
import { DeliveryDayType, SpotType } from '../models/spot-type';
import { TimeSeriesAnalysisIdentifierDataLocalized } from '../models/time-series-analysis-identifier-data';
import { ChartTimeSeries, TableTimeSeries } from '../models/time-series-widget';
import {
  DeltaType,
  TimeSeriesIdentifierInput,
} from '../models/timeseries-input';
import { SmaGroupWithIndex } from './../models/sma-group-with-index';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesDataService {
  constructor(private graphqlService: GraphqlService) {}

  getTimeSeriesChartData$(
    ids: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame,
    showIntraDay = true
  ): Observable<ChartTimeSeries[]> {
    // query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput, $showIntraDay:Boolean!){
    // chartTimeSeries(symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame, showIntraDay: $showIntraDay)
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput, $showIntraDay:Boolean!){
      chartTimeSeries(symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame, showIntraDay: $showIntraDay)
      {
        values
        {
          value
          tradeDate
        }
        identifierId
        identifier
        frontOfficeName
        longName
        sortOrder
        deliveryDay
        error {
          message
        }
        unit
      }
    }`;

    const variables = {
      symbols: ids,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
      showIntraDay,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<ChartTimeSeries[]>(query, variables, null);
  }

  chartComparisonTimeSeries$(
    symbols: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame
  ): Observable<ChartTimeSeries[]> {
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput){
      chartComparisonTimeSeries(symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame)
      {
        values
        {
          tradeDate
          value
        }
        unit
        identifierId
        identifier
        frontOfficeName
        longName
        deliveryDay
      }
    }`;

    const variables = {
      symbols: symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<ChartTimeSeries[]>(query, variables);
  }

  getTimeSeriesTableData$(
    symbols: TimeSeriesIdentifierInput[],
    deltaType: DeltaType = 'DAY',
    showIntraDay: boolean
  ): Observable<TableTimeSeries[]> {
    const query = `
    query ($symbols: [TimeSeriesInput!]!, $deltaType: DeltaType!, $showIntraDay: Boolean!){
      tableTimeSeries(symbols: $symbols, deltaType: $deltaType, showIntraDay: $showIntraDay)
      {
        identifier
        identifierId
        value
        shortName
        longName
        unit
        deltaIntraday
        deltaDay
        deltaMonth
        deltaYear
        intraDay
        intraDayDateTime
        baseDate,
        adjustment,
        sortOrder,
        ignoreGaps,
        calculationType,
        error {
          message
        }
      }
    }`;

    return this.graphqlService.query<TableTimeSeries[]>(
      query,
      { symbols, deltaType, showIntraDay },
      null
    );
  }

  getAroonChartData$(
    id: TimeSeriesIdentifierInput,
    settings: AroonSettings,
    timeFrame: TimeFrame
  ): Observable<AroonSummary> {
    const query = `
    query($symbol: TimeSeriesInput!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput) {
      aroonChartData(symbol: $symbol, periode: ${settings.period}, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame)
      {
        table
        {
          up
          down
          trend
        }
        values
        {
          aroonUp
          aroonDown
          oscillator
          date
        }
        unit
      }
    }`;

    const variables = {
      symbol: id,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<AroonSummary>(query, variables, null);
  }

  getMAChartData$(
    timeseriesInput: TimeSeriesIdentifierInput,
    settings: SmaSettings,
    timeFrame: TimeFrame
  ): Observable<SmaGroupWithIndex> {
    const query = `query($symbol: TimeSeriesInput!, $periode: [Int!], $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput) {
      maChartData(symbol: $symbol, periode: $periode, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame) {
        name
        closingValues {
          value
          tradeDate
        }
        groups
        {
          name
          values
          {
            sma
            date
          }
        }
        table
        {
          name
          lastValue
          unit
        }
        unit
    }
  }`;

    const variables = {
      symbol: timeseriesInput,
      periode: settings.periode,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<SmaGroupWithIndex>(query, variables, null);
  }

  getBollingerBandData$(
    identifier: TimeSeriesIdentifierInput,
    settings: BollingerSettings,
    timeFrame: TimeFrame
  ): Observable<BollingerBandSummary> {
    const query = `query($symbol: TimeSeriesInput!, $lookbackPeriod: Int!, $standardDeviation: Int!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput)
    {
      bollingerBandData(symbol: $symbol, lookbackPeriod: $lookbackPeriod, standardDeviation: $standardDeviation, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame){
        name
        closingValues
        {
          tradeDate
          value
        }
        bollinger
        {
          sma
          upperBand
          lowerBand
          percentB
          zScore
          width
          date
        }
        table {
          beforeLastDayPrice
          sma
          upper
          lower
          width
          unit
        }
        unit
      }
    }`;

    const variables = {
      symbol: identifier,
      lookbackPeriod: settings.period,
      standardDeviation: settings.standardDeviation,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<BollingerBandSummary>(
      query,
      variables,
      null
    );
  }

  /**
   * Will return only translations for the current selected language
   *
   * @param energySourceId - The id of the EnergySource
   * @param from - The start date of the data to be returned, if null refers to today
   * @param to - The ned date of the data to be returned, if null referes to today + 6 years
   */
  timeSeriesIdentifierDataByEnergySource$(
    energySourceId: ID,
    from: Dayjs = null,
    to: Dayjs = null
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.getTimeSeriesAnalysisIdentifier(
      `where: { typeId: {eq: ${energySourceId}}},`,
      from,
      to
    );
  }

  /**
   * Will return only translations for the current selected language
   *
   * @param areaMarketAreaId - The id of the MarketArea
   * @param from - The start date of the data to be returned, if null refers to today
   * @param to - The ned date of the data to be returned, if null referes to today + 6 years
   */
  public timeSeriesIdentifierDataByMarketArea$(
    areaMarketAreaId: ID,
    from: Dayjs = null,
    to: Dayjs = null
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.getTimeSeriesAnalysisIdentifier(
      `where: { areaMarketAreaId: {eq: ${areaMarketAreaId}}},`,
      from,
      to
    );
  }

  /**
   * Will return only translations for the current selected language
   *
   * @param categoryId - The id of the TimeSeriesCategory
   * @param from - The start date of the data to be returned, if null refers to today
   * @param to - The ned date of the data to be returned, if null referes to today + 6 years
   */
  public timeSeriesIdentifierDataByCategory$(
    categoryId: ID,
    from: Dayjs = null,
    to: Dayjs = null
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.getTimeSeriesAnalysisIdentifier(
      `where: { categoryId: {eq: ${categoryId}}},`,
      from,
      to
    );
  }

  private getTimeSeriesAnalysisIdentifier(
    whereClause: string,
    from: Dayjs = null,
    to: Dayjs = null
  ) {
    if (from === null) {
      from = dayjs();
    }
    if (to === null) {
      to = dayjs().add(4, 'year');
    }

    const query = `
    query timeSeriesIdentifierDataByCategory {
      timeSeriesIdentifierData 
      (
        ${whereClause}
        order: { id: ASC } 
      ) 
      {
        id
        name
        identifier
        unit
        frontOfficeName {
          ...localizationMediumFragment
        }
        longName {
          ...localizationMediumFragment
        }
        identifierData(
          where: { and: [ {timeStamp: { gt: "${from.toISOString()}"} }, { timeStamp: { lt: "${to.toISOString()}" } }] }
          ) {
          symbol
          timeStamp
          actualAdjustment
          frontOfficeName {
            ...localizationMediumFragment
          }
          longName {
            ...localizationMediumFragment
          }
        }
      }
    }
    
    ${GraphQLFragments.localizationMediumFragment()}

    `;

    return this.graphqlService
      .query<TimeSeriesAnalysisIdentifierInternal[]>(query, null)
      .pipe(this.mapToIdentifierData());
  }

  private mapToIdentifierData() {
    return map((array: TimeSeriesAnalysisIdentifierInternal[]) => {
      var result = new Array<TimeSeriesAnalysisIdentifierDataLocalized>();

      if (array && array.length > 0) {
        array.forEach((value) => {
          if (value.identifierData && value.identifierData.length > 0) {
            value.identifierData.forEach((data) => {
              const entry = {
                identifierId: value.id,
                identifier: data.symbol,
                timeStamp: data.timeStamp,
                unit: value.unit,
                adjustment: data.actualAdjustment,
                frontOfficeName:
                  data.frontOfficeName &&
                  data.frontOfficeName.localizations &&
                  data.frontOfficeName.localizations.length > 0
                    ? data.frontOfficeName.localizations.find(() => true).value
                    : value.name,
                longName:
                  data.longName &&
                  data.longName.localizations &&
                  data.longName.localizations.length > 0
                    ? data.longName.localizations.find(() => true).value
                    : value.name,
              } as TimeSeriesAnalysisIdentifierDataLocalized;

              result.push(entry);
            });
          } else {
            const entry = {
              identifierId: value.id,
              identifier: value.identifier,
              timeStamp: null,
              unit: value.unit,
              adjustment: 0,
              frontOfficeName:
                value.frontOfficeName &&
                value.frontOfficeName.localizations &&
                value.frontOfficeName.localizations.length > 0
                  ? value.frontOfficeName.localizations.find(() => true).value
                  : value.name,
              longName:
                value.longName &&
                value.longName.localizations &&
                value.longName.localizations.length > 0
                  ? value.longName.localizations.find(() => true).value
                  : value.name,
            } as TimeSeriesAnalysisIdentifierDataLocalized;

            result.push(entry);
          }
        });
      }

      return result;
    });
  }
}

//internal classes for mapping
interface TimeSeriesAnalysisIdentifierDataInternal {
  id: string; //GUID
  symbol: string;
  timeStamp: Date;
  actualAdjustment: number;
  frontOfficeName: LocalizationSet;
  longName: LocalizationSet;
}

interface TimeSeriesAnalysisIdentifierInternal extends BaseEntity {
  name: string;
  identifier: string;
  areaMarketAreaId: number;
  areaMarketArea: Area;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  createdDate: string;
  updatedDate: string;
  unit: string;
  typeId: number;
  categoryId: number;
  parserType: string;
  parserId: number;
  frontOfficeNameId: number;
  frontOfficeName: LocalizationSet;
  longNameId: number;
  longName: LocalizationSet;
  deliveryDay: boolean;
  spotConfigurationId: number;
  settingSpotConfiguration: any; //TODO
  spotType: SpotType;
  deliveryDayType: DeliveryDayType;
  identifierData: TimeSeriesAnalysisIdentifierDataInternal[];
}

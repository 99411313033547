import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[searchRow]',
})
export class SearchRowDirective {
  @HostBinding('class') elementClass = 'flex flex-col sm:flex-row sm:space-x-2';
}

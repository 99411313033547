import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { TimeSeriesIdentifier } from '../models/time-series-identifier';
import { TimeSeriesIdentifierStore } from './time-series-identifier.store';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierQuery extends AkitaSearchQueryEntity<TimeSeriesIdentifier> {
  constructor(store: TimeSeriesIdentifierStore) {
    super(store);
  }
}

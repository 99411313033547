import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationItem } from '../models/navigation-item';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  imports: [CommonModule, RouterModule, TranslocoModule, FontAwesomeModule],
})
export class NavigationItemComponent {
  @Input()
  item: NavigationItem;
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  GraphQLFragments,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { Observable, map } from 'rxjs';
import { EnergySource, EnergySourceLocalized } from '../models/energy-source';
@Injectable({
  providedIn: 'root',
})
export class EnergySourceService
  implements EditSearchDataService<EnergySource>
{
  private localizedEntityFields = `
    id
    name {
      ...localizationMediumFragment
    }
    costingDaysValid
    crmOfferDaysValid
    amountLimitInGwh
    bandOrdersLowerLimit
    forecastHolidayToleranceInPercent
    kind
  `;

  private entityFields = `
    id
    name {
      id
      localizations
      {
          localizationSetId
          id
          value
          cultureCode
      }
    }
    costingDaysValid
    crmOfferDaysValid
    amountLimitInGwh
    bandOrdersLowerLimit
    forecastHolidayToleranceInPercent
    kind
  `;

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public getAll$(
    params: DataTableParameters = {}
  ): Observable<PagingResponse<EnergySource>> {
    //TODO: should you want to use pagination, use Observable<PagingResponse<EnergySource>>
    const param = this.graphqlService.mapDataTableParameters(params);
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);
    const where = this.graphqlService.mapDataTableFilterParameters(
      params.filters
    );

    const query = `
    query masterDataEnergySources {
      masterDataEnergySources(
            ${param}
            ${order}
            ${where}
        ) {
          items {
            ${this.localizedEntityFields}
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
    }
    ${GraphQLFragments.localizationMediumFragment()}
    `;

    return this.graphqlService.query<PagingResponse<EnergySource>>(query);
  }

  public getAllLocalized$(
    params: DataTableParameters = {}
  ): Observable<PagingResponse<EnergySourceLocalized>> {
    //TODO: should you want to use pagination, use Observable<PagingResponse<EnergySourceLocalized>>
    return this.getAll$(params).pipe(this.mapToLocalizedData());
  }

  public getById$(id: ID): Observable<EnergySource> {
    const query = `
        query masterDataEnergySources ($id:Int!)
        {
            masterDataEnergySources(
              where: {
                id: { eq: $id}
            }) {
              items {
                ${this.entityFields}
              }
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              totalCount
            }
        }
    `;

    const variables = { id: id };
    return this.graphqlService
      .query<PagingResponse<EnergySource>>(query, variables, null, {
        redirectTo404: true,
        check404Property: 'items',
      })
      .pipe(
        mapGraphqlPaging(1, 1),
        map((value) => (value.data.length === 1 ? value.data[0] : null)) //todo: throw error?
      );
  }

  public getByIdLocalized$(id: ID): Observable<EnergySource> {
    const query = `
        query ($id:Int!)
        {
            masterDataEnergySource(id:$id) {
              ${this.localizedEntityFields}
            }
        }
        ${GraphQLFragments.localizationMediumFragment()}
    `;

    const variables = { id: id };
    return this.graphqlService.query<EnergySource>(query, variables);
  }

  add$(energySource: EnergySource): Observable<number> {
    const query = `
        mutation($energySource: EnergySourceInput!){
          energySourceAdd(input: $energySource){
            }
          }
    `;
    const variables = { energySource: energySource };

    return this.graphqlService.query<number>(query, variables, null, {
      ignoreNullCheckFilter: true,
    });
  }

  public update$(energySource: EnergySource): Observable<boolean> {
    const query = `
        mutation($energySource: EnergySourceInput!){
            energySourceUpdate(input: $energySource){
            }
          }
    `;
    const variables = { energySource: energySource };

    return this.graphqlService.query<boolean>(query, variables, null, {
      ignoreNullCheckFilter: true,
    });
  }

  private mapToLocalizedData() {
    return map((array: PagingResponse<EnergySource>) => {
      const result = new Array<EnergySourceLocalized>();

      if (array && array.items.length > 0) {
        array.items.forEach((value) => {
          const data = <EnergySourceLocalized>{
            id: value.id,
            name: value.name.localizations.find(() => true).value,
            costingDaysValid: value.costingDaysValid,
            crmOfferDaysValid: value.crmOfferDaysValid,
            amountLimitInGwh: value.amountLimitInGwh,
            bandOrdersLowerLimit: value.bandOrdersLowerLimit,
            forecastHolidayToleranceInPercent:
              value.forecastHolidayToleranceInPercent,
            kind: value.kind,
          };

          result.push(data);
        });
      }

      return {
        items: result,
      } as PagingResponse<EnergySourceLocalized>;
    });
  }

  public delete$(id: ID): Observable<boolean> {
    const query = `
        mutation($id: Int!){
            energySourceDelete(id: $id){
            }
          }
    `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, null);
  }
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { AnalysisLocalized } from '../entities/analysis';

@Injectable({ providedIn: 'root' })
export class FofAnalysisDataService {
  public readonly entityFields = `
        id
        title
        date
        files
        {
          id
          name
          fileName
        }
    `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<AnalysisLocalized>> {
    const query = `
            query {
                analysisGetAll {
                    items {
                        ${this.entityFields}
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }`;

    return this.graphqlService
      .query<PagingResponse<AnalysisLocalized>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getById$(id: ID): Observable<AnalysisLocalized> {
    const query = `
            query($id: Int!) {
                analysisGetById(id: $id) {
                    ${this.entityFields}
                }
            }`;
    return this.graphqlService.query<AnalysisLocalized>(query, { id });
  }

  getLatest$(): Observable<AnalysisLocalized[]> {
    const query = `
            query {
                analysisGetLatest {
                    ${this.entityFields}
                }
            }`;
    return this.graphqlService.query<AnalysisLocalized[]>(query);
  }
}

export type LoadProfilePermissionValues =
  | 'loadprofile.import.manage'
  | 'loadprofile.edit'
  | 'loadprofile.create'
  | 'loadprofile.details'
  | 'loadprofile.delete'
  | 'standardized-load-profile.edit'
  | 'standardized-load-profile.create'
  | 'standardized-load-profile.delete'
  | 'loadprofile-limit.create'
  | 'loadprofile-limit.edit'
  | 'loadprofile-limit.delete'
  | 'loadprofile.gas'
  | 'loadprofile.power';

export class LoadProfilePermissions {
  public static readonly import: LoadProfilePermissionValues =
    'loadprofile.import.manage';

  public static readonly edit: LoadProfilePermissionValues = 'loadprofile.edit';
  public static readonly create: LoadProfilePermissionValues =
    'loadprofile.create';
  public static readonly delete: LoadProfilePermissionValues =
    'loadprofile.delete';
  public static readonly details: LoadProfilePermissionValues =
    'loadprofile.details';

  public static readonly gas: LoadProfilePermissionValues = 'loadprofile.gas';
  public static readonly power: LoadProfilePermissionValues =
    'loadprofile.power';

  public static readonly editLimit: LoadProfilePermissionValues =
    'loadprofile-limit.edit';
  public static readonly createLimit: LoadProfilePermissionValues =
    'loadprofile-limit.create';
  public static readonly deleteLimit: LoadProfilePermissionValues =
    'loadprofile-limit.delete';

  public static readonly loadProfileTabPermisssion = [
    LoadProfilePermissions.details,
    LoadProfilePermissions.edit,
    LoadProfilePermissions.create,
    LoadProfilePermissions.import,
    LoadProfilePermissions.delete,
  ];

  public static readonly loadProfileLimitTabPermisssion = [
    LoadProfilePermissions.editLimit,
    LoadProfilePermissions.createLimit,
    LoadProfilePermissions.deleteLimit,
  ];
}

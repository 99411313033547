import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PageContainerComponent } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { TranslocoModule } from '@ngneat/transloco';
import { SpreadsComponent } from './spreads.component';

const routes: Route[] = [
  {
    path: '',
    component: SpreadsComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@frontoffice/spread/feature-individual-spreads-chart').then(
            (mod) => mod.SpreadFeatureIndividualSpreadsChartModule
          ),
        data: {
          translate: 'individual-spreads',
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    PageContainerComponent,
  ],
  declarations: [SpreadsComponent],
})
export class SpreadsModule {}

import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameStrict
} from '../models/time-frame';

@Injectable({
  providedIn: 'root',
})
export class TimeFrameUtilService {
  public static get timeFrameEnumQuery() {
    return `
    query {
      __type(name: "TimeSeriesTimeFrame"){
        name
        enumValues {
          name
        }
      }
    }
  `;
  }

  public static setTimeFrameVariable(
    timeFrame: TimeFrame,
    variables: { timeFrame: string; individualTimeFrame: IndividualTimeFrame }
  ) {
    if (this.isStrictTimeFrame(timeFrame)) {
      variables.timeFrame = timeFrame.toString();
    } else {
      variables.individualTimeFrame = <IndividualTimeFrame>timeFrame;
    }
  }

  public static getIndividualTimeFrame(
    timeFrame: TimeFrameStrict,
    useWholeMonth: boolean,
    deliveryDay: boolean
  ): IndividualTimeFrame {
    if (!timeFrame) {
      return null;
    }

    //4 days offset
    const end =
      useWholeMonth && dayjs() > dayjs().date(1).add(4, 'day')
        ? dayjs().endOf('month')
        : deliveryDay
        ? dayjs().add(1, 'day')
        : dayjs();

    switch (timeFrame) {
      case 'D_10': {
        return { start: dayjs().subtract(deliveryDay ? 10 : 11, 'days'), end };
      }
      case 'M_3': {
        return { start: end.add(1, 'day').subtract(3, 'months'), end };
      }
      case 'M_6': {
        return { start: end.add(1, 'day').subtract(6, 'months'), end };
      }
      case 'Y_1': {
        return { start: end.add(1, 'day').subtract(1, 'year'), end };
      }
      case 'Y_5': {
        return { start: end.add(1, 'day').subtract(5, 'years'), end };
      }
      case 'ALL': {
        return { start: dayjs('1990-01-01'), end };
      }
    }
  }

  public static isStrictTimeFrame(timeFrame: TimeFrame) {
    if (typeof timeFrame === 'string' || timeFrame instanceof String) {
      return true;
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import {
  AuthStore,
  IAuthConfigService,
} from '@clean-code/shared/auth/util-auth';
import {
  AuthConfig,
  NullValidationHandler,
  OAuthService,
} from 'angular-oauth2-oidc';

@Injectable()
export class AuthConfigService implements IAuthConfigService {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly authConfig: AuthConfig,
    private readonly authStore: AuthStore
  ) {}

  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      // setup oauthService
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      // subscribe to token events
      // this.oauthService.events
      //   .pipe(
      //     // tap((event) => console.info('event', event)),
      //     filter((e: any) => e instanceof OAuthSuccessEvent),
      //     filter((e: any) => e.type === 'token_received'),
      //     tap((e: OAuthSuccessEvent) => {
      //       console.debug(e);
      //     }),
      //     delay(700),
      //     switchMap((_) =>
      //       this.accountService.getUserProfile().pipe(timeout(10000))
      //     ),
      //     first()
      //   )
      //   .subscribe();

      //   // filter((e: any) => {
      //   //   return e.type === 'token_received';
      //   // })
      // )
      // .subscribe(() => this.handleNewToken());
      // disabling keycloak for now
      // resolveFn();
      // continue initializing app or redirect to login-page

      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then((isLoggedIn) => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            this.authStore.update({
              accessToken: this.oauthService.getAccessToken(),
            });
            resolveFn();
          } else {
            this.oauthService.initCodeFlow();
            rejectFn();
          }
        });
    });
  }
}

import { Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { TimeSeriesIdentifierListService } from '../infrastructure/time-series-identifier-list.service';
import { TimeSeriesIdentifier } from '../models/time-series-identifier';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierListFacade
  implements EditSearchDataService<TimeSeriesIdentifier>
{
  constructor(
    private timeSeriesIdentifierListService: TimeSeriesIdentifierListService
  ) {}

  public getById$(id: ID): Observable<TimeSeriesIdentifier> {
    return this.timeSeriesIdentifierListService.getById$(id);
  }

  public getByMarketAreaId$(id: ID): Observable<TimeSeriesIdentifier[]> {
    return this.timeSeriesIdentifierListService.getByMarketAreaId$(id);
  }

  public getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<TimeSeriesIdentifier>> {
    return this.timeSeriesIdentifierListService.getAll$(params);
  }

  public add$(input: TimeSeriesIdentifier): Observable<ID> {
    return this.timeSeriesIdentifierListService.add$(input);
  }

  public update$(input: TimeSeriesIdentifier): Observable<ID> {
    return this.timeSeriesIdentifierListService.update$(input);
  }

  public delete$(id: ID): Observable<boolean> {
    return this.timeSeriesIdentifierListService.delete$(id);
  }

  public getEndDate$(
    areaMarketAreaId: number,
    year: number,
    quarter: number,
    workingDaysFromEnd: number
  ): Observable<Date> {
    return this.timeSeriesIdentifierListService.getEndDate$(
      areaMarketAreaId,
      year,
      quarter,
      workingDaysFromEnd
    );
  }
}

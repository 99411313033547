import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { TimeSeriesIdentifierParser } from '../entities/time-series-analysis-identifier';
import { TimeSeriesIdentifierParserStore } from './time-series-identifier-parser.store';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierParserQuery extends AkitaSearchQueryEntity<TimeSeriesIdentifierParser> {
  constructor(protected store: TimeSeriesIdentifierParserStore) {
    super(store);
  }
}

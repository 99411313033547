import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SplashScreenService } from '@clean-code/shared/common';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  selector: 'clean-code-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterModule],
})
export class AppComponent {
  title = 'frontoffice';

  private splashState = inject(SplashScreenService);
  public showSplash$ = this.splashState.showSplash$;

  constructor(titleService: Title) {
    titleService.setTitle(environment.name);
  }
}

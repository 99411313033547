<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<ng-container *ngIf="data$ | async">
  <table
    mat-table
    [dataSource]="query.selectAll() | async"
    class="market-table"
  >
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="!p-1" mat-cell *matCellDef="let element">
        <div class="news-item-logo-wrapper">
          <img
            *ngIf="element.type === 'APA'"
            class="news-item-logo"
            src="{{
              element.imageUrl ? element.imageUrl : 'assets/img/apa.png'
            }}"
            alt=""
          />
          <img
            *ngIf="element.type === 'ESTMK'"
            class="news-item-logo"
            src="assets/img/logo_estmk.svg"
            alt=""
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="w-full">
        <div class="item-news-element">
          <div class="text-primary-color dark:text-primary-color">
            {{ element.name }}
          </div>
          <div class="item-news-abstract">
            {{ element.subName }}
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="item-news-date text-right" *matCellDef="let element">
        {{
          element.createdDate
            | translocoDate : { dateStyle: 'short', timeStyle: 'short' }
        }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="row !h-20 cursor-pointer"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="select(row)"
    ></tr>
  </table>
</ng-container>

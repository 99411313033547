import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { Area } from '../models/area';

export const stateName = 'delivery-area';

export interface DeliveryAreaState extends BaseState<Area> {}

function createInitialState(): DeliveryAreaState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: stateName })
export class DeliveryAreaStore extends BaseStore<Area> {
  constructor() {
    super(createInitialState());
  }
}

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { Surcharge } from '../models/surcharge';
import { SurchargeStore } from './surcharge.store';

@Injectable({ providedIn: 'root' })
export class SurchargeQuery extends AkitaSearchQueryEntity<Surcharge> {
  constructor(protected store: SurchargeStore) {
    super(store);
  }
}

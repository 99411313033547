declare var require: any;
export const useSilentRefreshForCodeFlow = true;
export const useHash = false;
const server_url = 'https://markt.e-steiermark.com';

const auth = {
  issuer: 'https://login.e-steiermark.com/auth/realms/estmk',
  redirectUri: window.location.origin,
  clientId: 'estmk-marktapp',
  responseType: 'code',
  scope: 'openid profile email',
  silentRefreshRedirectUri: `${window.location.origin}/assets/silent-refresh.html`,
  useSilentRefresh: true,
  showDebugInformation: true,
  sessionChecksEnabled: false,
  timeoutFactor: 0.8,
  clearHashAfterLogin: true,
  resourceServer: {
    allowedUrls: [
      'https://frontofficeapi.esn.at',
      'https://marktexapi.esn.at',
      'https://markt.e-steiermark.com',
    ],
    sendAccessToken: true,
  },
};

export const environment = {
  production: true,
  name: 'Marktdaten',
  env: 'test',
  api: server_url + '/api/',
  graphql: server_url + '/graphql',
  applicationInsightsinstrumentationKey:
    'b6z4pfh5vofyvl5yvueepqv6me3i1o3mm1rtogml',
  version: require('../../package.json').version,
  origins: ['localhost', server_url + '/api', server_url + '/graphql'],
  auth,
  signalR: {
    api: server_url + '/',
  },
  instrumentationKey: '7c3066f0-6fac-42dc-9c1f-bf880e984519',
  sentryEnv: 'Production',
};

import { Injectable } from '@angular/core';
import {
  BaseEntity,
  DataTableParameters,
  FilterParam,
  ObjectHelper,
  PagingResponse,
} from '@clean-code/shared/common';
import { ID, PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { BaseStore } from '../../base-state/search-base.state';

@Injectable()
export abstract class AkitaSearchBaseService<T extends BaseEntity> {
  abstract getAll$(
    params: DataTableParameters
  ): Partial<Observable<PaginationResponse<T> | PagingResponse<T> | T[]>>;
  abstract getById$(id: ID): Observable<T>;

  constructor(protected store: BaseStore<T>) {}

  public setActive(id: ID) {
    this.store.setActive(id);
  }

  public upsertMany(value: T[]) {
    this.store.upsertMany(value);
  }

  public upsertAndSetActive(id: ID, entity: T) {
    this.store.upsert(id, entity);
    this.store.setActive(id);
  }

  public removeActive(id: ID) {
    this.store.removeActive(id);
  }

  public updateReadOnly(readOnly: boolean) {
    this.store.update({ readOnly });
  }

  public updateSearchTerm(searchTerm: string) {
    this.store.update({ searchTerm });
    this.invalidateCache();
  }

  public updateFilter(filters: FilterParam[]) {
    this.store.update({ filters });
    this.invalidateCache();
  }

  public upsertFilter(filter: FilterParam) {
    const filters = ObjectHelper.cloneObject(this.store.getValue().filters);

    const index = filters.findIndex((x) => x.key === filter.key);

    if (index > -1) {
      filters.splice(index, 1);
    }

    filters.push(filter);
    this.store.update({ filters });
    this.invalidateCache();
  }

  public removeFilter(filterName: string) {
    const filters = ObjectHelper.cloneObject(this.store.getValue().filters);

    const index = filters.findIndex((x) => x.key === filterName);

    if (index > -1) {
      filters.splice(index, 1);
    }

    this.updateFilter(filters);
  }

  protected invalidateCache() {
    this.store.setHasCache(false);
  }

  protected upsertAndRefresh(newEntity: T) {
    this.store.upsert(newEntity.id, newEntity);
    this.setActive(newEntity.id);
    this.invalidateCache();
  }

  protected removeAndRefresh(id: ID) {
    this.store.remove(id);
    this.invalidateCache();
  }
}

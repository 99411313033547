import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, forwardRef, OnDestroy } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { ID } from '@clean-code/shared/common';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { takeUntil } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { SettingSpotConfigurationIdentifier } from '../../models/setting-spot-configuration-identifier';
import { SettingSpotConfigurationIdentifierService } from './../../services/setting-spot-configuration-identifier.service';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'backoffice-frontend-setting-spot-configuration-identifier-details',
  templateUrl: './setting-spot-configuration-identifier-details.component.html',
  providers: [
    {
      provide: CanDeactivateForm,
      useExisting: forwardRef(
        () => SettingSpotConfigurationIdentifierDetailsComponent
      ),
    },
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatTooltipModule,
    MatCheckboxModule,
    PageContainerModule,
    FormContainerModule,
    PipesModule,
    MatSlideToggleModule,

    MatSelectModule,
    NgSelectModule,
    ReactiveFormsModule,
    VarDirective,
  ],
})
export class SettingSpotConfigurationIdentifierDetailsComponent
  extends CanDeactivateForm<SettingSpotConfigurationIdentifier>
  implements AfterViewInit, OnDestroy
{
  //public timeSeriesIdentifierId: string;
  public spotConfigurationId: string;
  public spotConfigurationIdentifierId: ID;
  //public marketAreaName: string;
  //public energySourceEnum = EnergySource
  public energySource: string;
  public country: string;
  public stockMarket: string;
  // public idKeyName = 'spotConfigurationIdentifierId';

  public energySource$ = this.activatedRoute.paramMap
    .pipe(
      map((params) => params.get('energySource')?.toUpperCase()),
      tap((energySource) => (this.energySource = energySource)),
      takeUntil(this.closeSubject)
    )
    .subscribe();

  public country$ = this.activatedRoute.paramMap
    .pipe(
      map((params) => params.get('country')),
      tap((country) => (this.country = country)),
      takeUntil(this.closeSubject)
    )
    .subscribe();

  public stockMarket$ = this.activatedRoute.paramMap
    .pipe(
      map((params) => params.get('stockMarket')),
      tap((stockMarket) => (this.stockMarket = stockMarket)),
      takeUntil(this.closeSubject)
    )
    .subscribe();

  formGroup = new UntypedFormGroup({
    identifier: new UntypedFormControl(null, [Validators.required]),
    hour: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(24),
    ]),
    spotConfigurationId: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private settingSpotConfigurationIdentifierService: SettingSpotConfigurationIdentifierService
  ) {
    super(settingSpotConfigurationIdentifierService);
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.closeSubject))
      .subscribe((params) => {
        this.spotConfigurationId = params.get('idSpot');
        this.spotConfigurationIdentifierId = params.get('id');
        this.formGroup.controls.spotConfigurationId.setValue(
          +this.spotConfigurationId
        );
      });
  }

  protected add(): void {
    const settingSpotConfigurationIdentifier = this.formGroup
      .value as SettingSpotConfigurationIdentifier;

    delete settingSpotConfigurationIdentifier.id;
    this.settingSpotConfigurationIdentifierService
      .add$(settingSpotConfigurationIdentifier)
      .pipe(
        first(),
        tap((value: SettingSpotConfigurationIdentifier) => {
          this.toastService.showSuccess();
          this.router.navigate([
            'spotConfigurationIdentifier',
            this.spotConfigurationId,
            this.energySource,
            this.country,
            this.stockMarket,
            'preview',
            value.id,
          ]);
        }),
        tap((_) => this.tableStateService.refresh()),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }

  protected update(): void {
    const settingSpotConfigurationIdentifier = this.formGroup
      .value as SettingSpotConfigurationIdentifier;
    settingSpotConfigurationIdentifier.id = this.spotConfigurationIdentifierId;

    this.settingSpotConfigurationIdentifierService
      .update$(settingSpotConfigurationIdentifier)
      .pipe(
        first(),
        tap(() => {
          this.toastService.showSuccess();
          this.tableStateService.refresh();
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }
}

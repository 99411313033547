export class MasterDataHelper {
  public static getYears(): number[] {
    const date = new Date();

    const min =
      date.getMonth() > 11 ? date.getFullYear() + 1 : date.getFullYear();
    const max = min + 4;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }
}

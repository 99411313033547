import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { OfferPowerQuery } from '../+state/offer-power.query';

export const OFFER_POWER_PAGINATOR = new InjectionToken(
  'OFFER_POWER_PAGINATOR',
  {
    providedIn: 'root',
    factory: () => {
      const offerPowerQuery = inject(OfferPowerQuery);
      return new PaginatorPlugin(offerPowerQuery, { startWith: 0 });
    },
  }
);

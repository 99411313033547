<ng-container *rxLet="state$; let state">
  <dx-chart id="chart" [dataSource]="state.data" class="w-full h-full">
    <dxi-series
      *rxFor="let series of state.series"
      [valueField]="series.label"
      [name]="series.label"
      [type]="series.type"
      [color]="series.color"
      [pane]="series.pane"
    >
      <dxo-aggregation
        [enabled]="!!series?.aggregate"
        [method]="series?.aggregate?.method"
      >
      </dxo-aggregation>
    </dxi-series>

    <dxo-common-series-settings argumentField="date" [ignoreEmptyPoints]="true">
      <dxo-point [visible]="false"></dxo-point>
      <dxo-aggregation-interval [months]="1"> </dxo-aggregation-interval>
    </dxo-common-series-settings>

    <dxo-argument-axis
      #argumentAxisSettings
      argumentType="datetime"
      [aggregationInterval]="aggregationInterval"
      [label]="{ format: 'shortDate' }"
    >
      <dxo-label overlappingBehavior="stagger"></dxo-label>
      <dxo-tick-interval [months]="1"></dxo-tick-interval>
    </dxo-argument-axis>

    <dxi-pane name="topPane" *ngIf="state.showPane.showTopPane"></dxi-pane>
    <dxi-pane
      name="bottomPane"
      *ngIf="state.showPane.showBottomPane"
    ></dxi-pane>
    <dxi-value-axis pane="bottomPane">
      <dxo-grid [visible]="true"></dxo-grid>
      <dxo-title [text]="yAxisTitle$ | async"></dxo-title>
    </dxi-value-axis>
    <dxi-value-axis pane="topPane">
      <dxo-grid [visible]="true"></dxo-grid>
      <dxo-title [text]="yAxisTitle$ | async"></dxo-title>
    </dxi-value-axis>
    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
    </dxo-legend>
    <dxo-tooltip
      [enabled]="true"
      [shared]="false"
      [customizeTooltip]="simpleTooltip"
    >
    </dxo-tooltip>
    <dxo-zoom-and-pan argumentAxis="both" valueAxis="none"> </dxo-zoom-and-pan>
  </dx-chart>
</ng-container>

import { Injectable } from '@angular/core';
import { SystemClaims } from '@backoffice/shared/bo/util-permissions';
import {
  EnergySourceEnum,
  EnumValue,
  EnumValues,
} from '@clean-code/shared/bo/util-enum';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, combineLatest, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  private url = this.configService.settings['graphql'];

  private areaUrl = this.configService.settings['api'] + 'area/graphql';

  //TODO: Change areaUrl

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private permissionService: NgxPermissionsService
  ) {}

  getEnumType$(enumType: string): Observable<EnumValue[]> {
    const query = `
      query {
        __type(name: "${enumType}"){
          name
          enumValues {
            name
          }
        }
      }
    `;

    return this.graphqlService
      .query<EnumValues>(query, null, this.url)
      .pipe(map((value: EnumValues) => value.enumValues));
  }

  public getEnergySources$(): Observable<EnumValue[]> {
    const query = `
      query {
        __type(name: "EnergySourceEnum"){
          name
          enumValues {
            name
          }
        }
      }
    `;

    return combineLatest([
      from(this.permissionService.hasPermission(SystemClaims.KAM_POWER)),
      from(this.permissionService.hasPermission(SystemClaims.KAM_GAS)),
      from(
        this.permissionService.hasPermission(SystemClaims.ENERGY_SOURCE_POWER)
      ),
      from(
        this.permissionService.hasPermission(SystemClaims.ENERGY_SOURCE_GAS)
      ),
    ]).pipe(
      switchMap(
        ([
          hasKamPowerPermission,
          hasKamGasPermission,
          hasPowerPermission,
          hasGasPermission,
        ]) =>
          this.graphqlService.query<EnumValues>(query, null, this.areaUrl).pipe(
            map((value: EnumValues) => value.enumValues),
            map((values: EnumValue[]) => {
              if (
                (hasKamPowerPermission && hasKamGasPermission) ||
                (hasPowerPermission && hasGasPermission)
              ) {
                return values;
              }

              if (hasKamPowerPermission || hasPowerPermission) {
                return values.filter((x) => x.name === EnergySourceEnum.Power);
              }

              if (hasKamGasPermission || hasGasPermission) {
                return values.filter((x) => x.name === EnergySourceEnum.Gas);
              }

              return [];
            })
          )
      )
    );
  }

  public getEnergySourcesWithCustomPermission$(
    powerPermisssion: string,
    gasPermission: string
  ): Observable<EnumValue[]> {
    const query = `
      query {
        __type(name: "EnergySourceEnum"){
          name
          enumValues {
            name
          }
        }
      }
    `;

    return combineLatest([
      from(this.permissionService.hasPermission(powerPermisssion)),
      from(this.permissionService.hasPermission(gasPermission)),
    ]).pipe(
      switchMap(([hasPowerPermission, hasGasPermission]) =>
        this.graphqlService.query<EnumValues>(query, null, this.areaUrl).pipe(
          map((value: EnumValues) => value.enumValues),
          map((values: EnumValue[]) => {
            if (hasPowerPermission && hasGasPermission) {
              return values;
            } else if (hasPowerPermission) {
              return values.filter((x) => x.name === EnergySourceEnum.Power);
            } else if (hasGasPermission) {
              return values.filter((x) => x.name === EnergySourceEnum.Gas);
            }

            return [];
          })
        )
      )
    );
  }
}

import { Injectable } from '@angular/core';
import { BaseEntity, ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { AkitaSearchBaseService } from './search.service';

@Injectable()
export abstract class AkitaSearchEditBaseService<
  T extends BaseEntity
> extends AkitaSearchBaseService<T> {
  abstract delete$(id?: ID): Partial<Observable<void | boolean>>;
  abstract add$(entity: T): Partial<Observable<T | ID | boolean>>;
  abstract update$(entity: T): Partial<Observable<T | ID | boolean>>;
}

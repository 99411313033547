import { Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { SettingSpotConfigurationIdentifier } from '../models/setting-spot-configuration-identifier';
@Injectable({
  providedIn: 'root',
})
export class SettingSpotConfigurationIdentifierService
  implements EditSearchDataService<SettingSpotConfigurationIdentifier>
{
  private url = this.configService.settings['api'] + 'setting/graphql';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}
  getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<SettingSpotConfigurationIdentifier>> {
    throw new Error('Method not implemented.');
  }

  public getAllSettingSpotConfigurationIdentifier$(
    settingSpotConfigurationId: ID
  ): Observable<SettingSpotConfigurationIdentifier[]> {
    const query = `
        query  ($spotConfigurationId:ID!) {
            settingSpotConfigurationIdentifiers(spotConfigurationId:$spotConfigurationId) {
                id
                identifier
                hour
            }
        }
    `;

    const variables = { spotConfigurationId: settingSpotConfigurationId };
    return this.graphqlService.query<SettingSpotConfigurationIdentifier[]>(
      query,
      variables,
      this.url
    );
  }

  public getById$(id: ID): Observable<SettingSpotConfigurationIdentifier> {
    const query = `
        query ($id:ID!)
        {
            settingSpotConfigurationIdentifier(id:$id) {
                id
                identifier
                hour
            }
        }
    `;

    const variables = { id };
    return this.graphqlService.query<SettingSpotConfigurationIdentifier>(
      query,
      variables,
      this.url
    );
  }

  public add$(
    settingSpotConfigurationIdentifier: SettingSpotConfigurationIdentifier
  ): Observable<SettingSpotConfigurationIdentifier> {
    const query = `
        mutation($settingSpotConfigurationIdentifier: SettingSpotConfigurationIdentifierInsertType!){
            insertSettingSpotConfigurationIdentifier(settingSpotConfigurationIdentifier: $settingSpotConfigurationIdentifier){
              id
              identifier
              hour
            }
          }
    `;
    const variables = { settingSpotConfigurationIdentifier };

    return this.graphqlService.query<SettingSpotConfigurationIdentifier>(
      query,
      variables,
      this.url
    );
  }

  public update$(
    settingSpotConfigurationIdentifier: SettingSpotConfigurationIdentifier
  ): Observable<SettingSpotConfigurationIdentifier> {
    const query = `
        mutation($settingSpotConfigurationIdentifier: SettingSpotConfigurationIdentifierUpdateType!){
            updateSettingSpotConfigurationIdentifier(settingSpotConfigurationIdentifier: $settingSpotConfigurationIdentifier){
              id
              identifier
              hour
            }
          }
    `;
    const variables = { settingSpotConfigurationIdentifier };

    return this.graphqlService.query<SettingSpotConfigurationIdentifier>(
      query,
      variables,
      this.url
    );
  }

  public delete$(id: ID): Observable<boolean> {
    const query = `
        mutation($id:ID!) {
            deleteSettingSpotConfigurationIdentifier(id: $id)
        }
    `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }
}

import { Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { TradingCalendar } from '../entities/trading-calendar';

import { TradingCalendarDataService } from '../infrastructure/trading-calendar.data.service';

@Injectable({ providedIn: 'root' })
export class TradingCalendarListFacade
  implements EditSearchDataService<TradingCalendar>
{
  constructor(private tradingCalendarDataService: TradingCalendarDataService) {}

  public getById$(id: ID): Observable<TradingCalendar> {
    return this.tradingCalendarDataService.getById$(id);
  }

  public getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<TradingCalendar>> {
    return this.tradingCalendarDataService.getAll$(params);
  }

  public add$(input: TradingCalendar): Observable<ID> {
    return this.tradingCalendarDataService.add$(input);
  }

  public update$(input: TradingCalendar): Observable<ID> {
    return this.tradingCalendarDataService.update$(input);
  }

  public delete$(id: ID): Observable<boolean> {
    return this.tradingCalendarDataService.delete$(id);
  }
}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
<div *ngIf="data$ | async; let data" class="flex w-full h-full">
  <dx-pie-chart
    id="pie"
    class="w-full h-full"
    palette="ocean"
    [dataSource]="data"
  >
    <dxo-legend [visible]="false"></dxo-legend>
    <dxo-common-series-settings>
      <dxo-label [visible]="false"></dxo-label>
    </dxo-common-series-settings>
    <dxo-common-annotation-settings
      type="custom"
      template="template"
      [allowDragging]="true"
      position="inside"
      [opacity]="0.85"
    >
    </dxo-common-annotation-settings>
    <dxi-annotation *ngFor="let d of data" [data]="d" [argument]="d.key">
    </dxi-annotation>
    <dxi-series argumentField="key" valueField="value"></dxi-series>

    <svg *dxTemplate="let annotation of 'template'" class="annotation">
      <text x="0" y="60">
        <tspan dy="5" x="0" class="caption">{{ annotation.data.key }}:</tspan>
        <tspan dx="5">{{ annotation.data.value | number : '.2-2' }}</tspan>
      </text>
    </svg>
  </dx-pie-chart>
</div>

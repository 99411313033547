import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { OfferGas } from '../models/offer-gas';
import { OfferGasStore } from './offer-gas.store';

@Injectable({ providedIn: 'root' })
export class OfferGasQuery extends AkitaSearchQueryEntity<OfferGas> {
  constructor(protected store: OfferGasStore) {
    super(store);
  }
}

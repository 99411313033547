<cc-dashboard-page-container *ngIf="data$ | async; let news">
  <ng-container title>
    <h2>{{ news.name }}</h2>
  </ng-container>

  <div page-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label>{{ 'news.NAME' | transloco }}</mat-label>
          <input matInput value="{{ news.name }}" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ 'news.SUB_NAME' | transloco }}</mat-label>
          <input matInput value="{{ news.subName }}" />
        </mat-form-field>
      </div>
      <div class="flex flex-col gt-md:flex-row">
        <mat-form-field>
          <mat-label>{{ 'news.END_DATE' | transloco }}</mat-label>
          <input matInput value="{{ news.endDate | translocoDate }}" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'common.CREATE_DATE' | transloco }}</mat-label>
          <input
            matInput
            value="{{
              news.createdDate
                | translocoDate : { dateStyle: 'medium', timeStyle: 'medium' }
            }}"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</cc-dashboard-page-container>

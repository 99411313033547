<div class="widget-reference-component">
  <cc-loading-indicator *ngIf="isLoading$ | async"></cc-loading-indicator>
  <widget-preview
    *ngFor="let widget of widgets$ | async"
    [widget]="widget"
    (click)="toggleSelection(widget)"
    class="px-2"
    [ngClass]="{ selected: isSelected(widget) }"
  >
  </widget-preview>

  <div class="empty" *ngIf="(widgets$ | async)?.length === 0">
    {{ 'DASHBOARDPANEL.NOWIDGETS.REFERENCED' | transloco }}
  </div>
</div>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialog } from '../base-dialog';
@Component({
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends BaseDialog {
  constructor(public override dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    super(dialogRef);
  }
}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
<div *ngIf="data$ | async; let data">
  <!-- <div class="tile">
    <span class="title">Customer</span>
    <span class="title">{{ config?.companyId }}</span>
  </div> -->

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.customerName"
    ></div>
    <div
      class="flex flex-row sm:w-2/3 items-center"
      title="{{ data.customer.name }}"
    >
      <span class="circle" ngClass="{'red': !data.customer.active}"></span>
      <div class="truncate">
        {{ data.customer.name }}
      </div>
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.customerSegment"
    ></div>
    <div class="sm:w-2/3" title="{{ data.customer.segment }}">
      {{ data.customer.segment }}
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.users"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.customer.users.active }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.customer.users.inactive }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.profiles"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.loadProfile.active }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.loadProfile.inactive }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.forecast"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.forecast.active }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.forecast.inactive }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.offers"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.offer.active }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.offer.inactive }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.closedValues"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.open' | transloco }}"
        >{{ data.ieb.open }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.closed' | transloco }}"
        >{{ data.ieb.closed }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.closedValues"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.open' | transloco }}"
        >{{ data.ieb.intraday }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.closed' | transloco }}"
        >{{ data.ieb.spot }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.hkn"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.orders' | transloco }}"
        >{{ data.hkn.open | number: '1.0-0' }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.order' | transloco }}"
        >{{ data.hkn.closed | number: '1.0-0' }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.spreadSecurity"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.open' | transloco }}"
        >{{ data.ieb.spreadSecurity.open }}</span
      >
      <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.closed' | transloco }}"
        >{{ data.ieb.spreadSecurity.closed }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.limitOrder"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.ieb.limitOrder }}</span
      >
      <!-- <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.ieb.limitOrder.closed }}</span
      > -->
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.strategyMssages"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.ieb.strategyMssages }}</span
      >
      <!-- <span
        class="right"
        title="{{ 'widgetType-MarketCustomerAnalysis.inactive' | transloco }}"
        >{{ data.ieb.strategyMssages.inactive }}</span
      > -->
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.limitReports"
    ></div>
    <div class="sm:w-2/3 ratio">
      <span
        class="left"
        title="{{ 'widgetType-MarketCustomerAnalysis.active' | transloco }}"
        >{{ data.limitReports }}</span
      >
    </div>
  </div>

  <div class="flex flex-row space-x-1">
    <div
      class="sm:w-1/3"
      transloco="widgetType-MarketCustomerAnalysis.kam"
    ></div>
    <div
      class="sm:w-2/3 flex flex-row space-x-1"
      title="{{ data.customer.kam }}"
    >
      <div class="flex flex-col divide-y w-1/2">
        <div class="font-bold">
          {{ 'bo.common.energysources.POWER' | transloco }}
        </div>
        <div *ngFor="let item of data.customer.kam.power">
          {{ item }}
        </div>
      </div>
      <div class="flex flex-col divide-y w-1/2">
        <div class="font-bold">
          {{ 'bo.common.energysources.GAS' | transloco }}
        </div>
        <div *ngFor="let item of data.customer.kam.gas">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</div>

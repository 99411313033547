import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OAuthGuard } from '@backoffice-frontend/shared/auth/util-oauth';
import {
  NoPermissionLayoutComponent,
  NoPermissionLayoutModule,
} from '@clean-code/shared/auth/ui-auth';
import { ProfileResolver } from '@clean-code/shared/auth/util-auth';
import { dashboardRoutes } from '@clean-code/shared/dashboard/dashboard/feature-dashboard-view';
import { TailwindLayoutModule } from '@clean-code/shared/frontoffice/ui-layout';
import { LayoutComponent } from '@clean-code/shared/templates/ui-tailwind-full-width';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,

    canActivate: [OAuthGuard],
    resolve: {
      profile: ProfileResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full',
      },
      {
        path: 'notfound',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.NotFoundModule
          ),
      },
      {
        path: 'start',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-start').then(
            (mod) => mod.StartModule
          ),
      },
      {
        path: 'info',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.InfoModule
          ),
      },
      {
        path: 'market',
        loadChildren: () =>
          import('@backoffice-frontend/market/feature-dashboard').then(
            (mod) => mod.MarketFeatureDashboardModule
          ),
        data: { canEdit: false },
      },
      {
        path: 'wiki',
        loadChildren: () =>
          import('@backoffice-frontend/wiki/feature-readonly-list').then(
            (mod) => mod.WikiFeatureReadonlyListModule
          ),
      },
      {
        path: 'spreads',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.SpreadsModule
          ),
        data: {
          translate: 'individual-spreads',
        },
      },
      {
        path: 'limit-report',
        loadChildren: () =>
          import('@frontoffice/limit-report/ui-limit-report-list').then(
            (mod) => mod.LimitReportListModule
          ),
      },
      {
        path: 'limits',
        loadChildren: () =>
          import('@clean-code/user-limit/feature-user-limit-list').then(
            (mod) => mod.UserLimitListModule
          ),
      },
      {
        path: 'imprint',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.ImprintModule
          ),
      },
      {
        path: 'disclaimer',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.DisclaimerModule
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.ContactModule
          ),
      },
      {
        path: 'kam',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.KamModule
          ),
      },
      {
        path: 'team',
        loadChildren: () =>
          import('@clean-code/shared/frontoffice/ui-layout').then(
            (mod) => mod.TeamModule
          ),
      },
      dashboardRoutes,
    ],
  },
  {
    path: '',
    component: NoPermissionLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((mod) => mod.LoginModule),
      },
      {
        path: 'forbidden',
        loadChildren: () =>
          import('@clean-code/shared/auth/ui-auth').then(
            (mod) => mod.ForbiddenModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'notfound',
  },
];
// { path: '', redirectTo: 'contracts', pathMatch: 'full'},
// { path: 'contracts', component: ContractCardContainer }

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      // enableTracing: true,
    }),
    TailwindLayoutModule,
    NoPermissionLayoutModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div *ngIf="!mobile">
  <div class="group flex items-center justify-center gap-3 px-2 py-2">
    <button
      type="button"
      class="flex items-center cursor-pointer"
      aria-expanded="false"
      aria-haspopup="true"
      (click)="toggleUserInfo()"
    >
      <fa-icon
        [icon]="faUser"
        size="2x"
        class="text-sm fa-fw w-8 hover:text-primary-color"
        [ngClass]="showUserInfo ? 'text-primary-color' : ''"
      ></fa-icon>
      <fa-icon
        [icon]="faChevronDown"
        size="xs"
        class="mr-3"
        [classes]="['fa-rotate-' + (showUserInfo ? '180' : 0)]"
        [ngClass]="showUserInfo ? 'text-primary-color' : 'text-gray-400'"
      ></fa-icon>
      <!-- <div class="font-medium text-sm text-ellipsis">
        {{ fullName$ | async }}
      </div> -->
    </button>
    <notifications-notification-center
      class="cursor-pointer"
    ></notifications-notification-center>
  </div>

  <div *ngIf="showUserInfo" class="flex flex-col" (click)="(toggleUserInfo)">
    <div
      class="ml-2 font-medium text-sm text-ellipsis text-layout-text-navigation"
    >
      {{ fullName$ | async }}
    </div>
    <div class="w-full border-t border-gray-200 mb-1"></div>
    <div class="content">
      <div class="mx-3 mb-1 text-sm text-gray-500 hover:text-primary-color">
        <a routerLink="/limits">
          {{ 'fof.USERLIMITS' | transloco }}
        </a>
      </div>

      <div
        *ngIf="showKam$ | async"
        class="mx-3 mb-1 text-sm text-gray-500 hover:text-primary-color"
      >
        <a routerLink="/kam">
          {{ 'fof.KAM' | transloco }}
        </a>
      </div>

      <div class="mx-3 mb-1 text-sm text-gray-500 hover:text-primary-color">
        <a routerLink="/team">
          {{ 'fof.TEAM' | transloco }}
        </a>
      </div>

      <div class="m-3 text-sm text-gray-500 hover:text-primary-color">
        <button (click)="logOut()">
          {{ 'login.LOGOUT' | transloco }}
        </button>
      </div>
    </div>

    <!-- <div
    class="group flex items-center pr-2 py-2 rounded-md text-layout-text-navigation hover:text-gray-900 hover:bg-primary-color-50 hover:cursor-pointer"
  >
    <notifications-notification-center></notifications-notification-center>
  </div> -->
  </div>
</div>

<div *ngIf="mobile" class="flex flex-row lg:mx-2 lg:my-3">
  <notifications-notification-center></notifications-notification-center>

  <button
    mat-button
    [matMenuTriggerFor]="menu"
    type="button"
    class="max-w-xs bg-layout-page-container dark:bg-layout-dark-page-container flex items-center text-sm"
    id="user-menu-button"
    aria-expanded="false"
    aria-haspopup="true"
  >
    <span class="sr-only">Open user menu</span>
    <fa-icon [icon]="faUser" size="2x"></fa-icon>
  </button>
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <div class="card">
    <div class="header">
      <div class="title">
        <div class="name">{{ fullName$ | async }}</div>
        <!-- <div class="extra">
          {{
            'notification.topnav.unread'
              | transloco: { unreadCount: unreadCount }
          }}.
        </div> -->
      </div>
    </div>
    <div class="content">
      <div class="px-3 py-1 text-sm text-gray-500 hover:text-primary-color">
        <a routerLink="/limits">
          {{ 'fof.USERLIMITS' | transloco }}
        </a>
      </div>

      <div class="relative m-2">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-start">
          <span class="pr-2 bg-secondary-color text-sm text-gray-500">
            {{ 'fof.COUNTERPART' | transloco }}
          </span>
        </div>
      </div>

      <div
        *ngIf="showKam$ | async"
        class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color"
      >
        <a routerLink="/kam">
          {{ 'fof.KAM' | transloco }}
        </a>
      </div>

      <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
        <a routerLink="/team">
          {{ 'fof.TEAM' | transloco }}
        </a>
      </div>

      <!-- <div class="relative m-2">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-start">
          <span class="pr-2 bg-secondary-color text-sm text-gray-500">
            {{ 'common.SETTINGS' | transloco }}
          </span>
        </div>
      </div> -->

      <div class="flex flex-col">
        <div class="text-center my-3">
          <mat-button-toggle-group
            [value]="languageService.activeLanguage$ | async"
            (change)="setLanguage($event)"
          >
            <mat-button-toggle
              *ngFor="let lang of langs$ | async"
              value="{{ lang.id }}"
              class="text-center items-center content-center"
            >
              <img class="h-4" src="assets/img/Flag_{{ lang.id }}.svg" />
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="text-center">
          <button mat-button (click)="logOut()">
            {{ 'login.LOGOUT' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-menu>

import { Directive, Input, ViewChild, inject } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
} from '@angular/forms';

@Directive()
export class ControlValueAccessorConnector implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @Input()
  formControlName: string;

  controlContainer = inject(ControlContainer);

  private _formControl: FormControl;
  @Input()
  set formControl(value: FormControl) {
    this._formControl = value;
  }

  get formControl(): FormControl {
    if (this._formControl) {
      return this._formControl;
    }

    this._formControl = this.control;

    return this._formControl;
  }

  private get control(): FormControl {
    if (!this.formControlName && !this._formControl) {
      throw new Error(
        'Either formControl or formControlName must be provided.'
      );
    }

    return (
      (this._formControl as FormControl) ||
      (this.controlContainer.control.get(this.formControlName) as FormControl)
    );
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }
}

<div
  *ngIf="!isMenu"
  fxFlex="nogrow"
  matTooltip="{{ (disabledTooltip ? '' : tooltip) | transloco}}"
>
  <button
    type="button"
    mat-button
    color="primary"
    class="menu-btn"
    (click)="click($event)"
    [disabled]="isDisabled"
  >
    <div class="flex flex-row space-x-1">
      <fa-icon *ngIf="!!icon" [icon]="icon" [spin]="spin"></fa-icon>
      <div #wrapper class="flex flex-row">
        <!-- need a wrapper to get child's number -->
        <ng-content></ng-content>
      </div>
      <span
        class="font-bold"
        *ngIf="wrapper.childNodes.length === 0 && showText"
        >{{ displayText | transloco }}</span
      >
    </div>
  </button>
</div>
<button
  *ngIf="isMenu"
  mat-menu-item
  color="primary"
  class="menu-btn"
  (click)="click($event)"
  [disabled]="isDisabled"
>
  <div class="flex flex-row x-space-1">
    <fa-icon *ngIf="!!icon" [icon]="icon"></fa-icon>
    <span class="font-bold">{{ displayText | transloco }}</span>
  </div>
</button>

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupWithEmitValuesDirective } from '@clean-code/shared/directives/ui-form';
import { MarketHknCustomersWidgetTypeConfig } from '../market-hkn-customers';
@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-customers-config',
  templateUrl: './market-hkn-customers-config.component.html',
  styleUrls: ['./market-hkn-customers-config.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    WidgetBaseFilterComponent,
    PatchFormGroupWithEmitValuesDirective,
  ],
})
export class MarketHknCustomersConfigComponent extends BaseWidgetTypeConfigComponent<MarketHknCustomersWidgetTypeConfig> {
  form = new UntypedFormGroup({
    filter: new UntypedFormControl(null, [Validators.required]),
  });

  public close() {
    // TODO: dashboard state
    // this.callback.next();
  }
}

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { TranslocoService } from '@ngneat/transloco';

const componentName = 'MatPaginatorIntlService';

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 */
@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(
    private translateService: TranslocoService,
    private languageService: LanguageService //  private readonly logger: NGXLogger
  ) {
    super();

    console.debug(`${componentName}:: Initializing`);

    // React whenever the language is changed
    this.languageService.activeLanguage$.subscribe((_) => {
      this.translateLabels();
    });

    console.debug(`${componentName}:: Initialized`);
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translateService
      ? this.translateService.translate('ui-mat-table.paginator.OF')
      : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex =
      page * pageSize > length
        ? (Math.ceil(length / pageSize) - 1) * pageSize
        : page * pageSize;

    this.translateLabels();
    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(
    translate: TranslocoService,
    languageService: LanguageService
  ): void {
    this.translateService = translate;
    this.languageService = languageService;

    this.languageService.activeLanguage$.subscribe(() => {
      this.translateLabels();
    });
  }

  //TODO: use translate with builtin watcher .selectTranslate() ?
  translateLabels(): void {
    this.firstPageLabel = this.translateService.translate(
      'ui-mat-table.paginator.FIRST_PAGE'
    );
    this.itemsPerPageLabel = this.translateService.translate(
      'ui-mat-table.paginator.ITEMS_PER_PAGE'
    );
    this.lastPageLabel = this.translateService.translate(
      'ui-mat-table.paginator.LAST_PAGE'
    );
    this.nextPageLabel = this.translateService.translate(
      'ui-mat-table.paginator.NEXT_PAGE'
    );
    this.previousPageLabel = this.translateService.translate(
      'ui-mat-table.paginator.PREVIOUS_PAGE'
    );
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}

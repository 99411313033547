<nav class="flex flex-col space-y-2">
  <app-navigation-item [item]="firstItem$ | async"></app-navigation-item>

  <span dashboard-menu class="dashboard-menu"></span>
  <ng-template
    *ngFor="let item of other$ | async"
    [ngxPermissionsOnly]="item.permission ? item.permission : []"
  >
    <app-navigation-item [item]="item"></app-navigation-item>
  </ng-template>
</nav>

import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { LocalizationSet } from '@clean-code/shared/common';
import { BaseFormChild } from '@clean-code/shared/components/ui-form-controls';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseTranslationComponent
  extends BaseFormChild
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input()
  label = 'common.TRANSLATED_VALUE';

  @Input()
  cultureCode: string;

  @Input()
  length: number;

  @Input()
  required = false;

  @Input()
  longText = false;

  @Input()
  containerClasses: string;

  @Input()
  controlClasses: string;

  public baseContainerClasses: string;

  public formGroup = new UntypedFormGroup({
    id: new FormControl<number>(null),
    localizations: new UntypedFormArray([]),
  });

  public subscriptions: Subscription[] = [];

  constructor(private languageService: LanguageService) {
    super();
  }

  override writeValue(value: LocalizationSet) {
    this.formGroup.reset();
    this.localizations.clear();

    if (value) {
      this.formGroup.controls.id.setValue(value.id);
      this.languageService.configuredLanguages.forEach(
        (cult: { id: string; label: string; locale: string }) => {
          const culture = value.localizations.find(
            (x: any) => x.cultureCode === cult.id
          );

          const form = this.createLocalizationForm(cult.id);
          form.controls.localizationSetId.setValue(value.id);

          if (!culture) {
            this.localizations.push(form);
          } else {
            form.patchValue(culture);
            this.localizations.push(form);
          }
        }
      );

      // this.formGroup.patchValue(value);
    }

    if (!value) {
      this.languageService.configuredLanguages.forEach(
        (cult: { id: string; label: string; locale: string }) => {
          this.localizations.push(this.createLocalizationForm(cult.id));
        }
      );
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.baseContainerClasses = 'grid grid-cols-' + this.count + ' gap-4';
  }

  private createLocalizationForm(cultureCode: string): UntypedFormGroup {
    const validators = [];
    if (this.length) {
      validators.push(Validators.maxLength(this.length));
    }
    if (this.required) {
      validators.push(Validators.required);
    }
    return new FormGroup({
      id: new FormControl<number>(null),
      localizationSetId: new FormControl<number>(null),
      cultureCode: new FormControl<string>(cultureCode, [Validators.required]),
      value: new FormControl<string>(null, validators),
    });
  }

  public get count(): number {
    const length = this.languageService.configuredLanguages.length;
    return length;
  }

  public get localizations(): UntypedFormArray {
    return this.formGroup.get('localizations') as UntypedFormArray;
  }
}

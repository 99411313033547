<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<ng-container *ngIf="data$ | async; let data; else: elseBlock">
  <table class="table-layout" *ngVar="config$ | async; let config">
    <thead>
      <tr>
        <th>{{ 'time-series-identifier.widgets.DATA_SOURCES' | transloco }}</th>
        <th
          [innerHtml]="
            hideClosingValueHeader
              ? ''
              : ('time-series-identifier.widgets.CLOSING_PRICE' | transloco)
          "
        ></th>
        <th class="w-[14%]"></th>

        <th class="w-[4%]"></th>
        <ng-container [ngSwitch]="deltaType">
          <ng-container *ngSwitchCase="'DAY'">
            <th class="whitespace-nowrap">∆ 1 T</th>
          </ng-container>
          <ng-container *ngSwitchCase="'MONTH'">
            <th class="whitespace-nowrap">∆ 1 M</th>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <th class="whitespace-nowrap">DELTATYPE MISSING!</th>
          </ng-container>
        </ng-container>
        <th class="whitespace-nowrap">∆ 1 J</th>
      </tr>
    </thead>
    <tbody *ngVar="editMode$ | async; let editMode">
      <tr *ngFor="let item of data; let i = index">
        <td
          class="cursor-pointer hover:underline"
          (click)="
            !editMode
              ? openDetails(item, config.dataSources[i])
              : openDetailsConfig(config.dataSources[i]?.settings, i)
          "
        >
          {{ item.shortName }}

          <fa-icon
            *ngIf="!!item?.error?.message"
            [icon]="faTriangle"
            class="text-red-600"
            global-tooltip="{{ item?.error?.message | transloco }}"
          ></fa-icon>
          <fa-icon *ngIf="editMode" [icon]="faEdit"></fa-icon>
        </td>

        <td>
          {{ item.value | number : '.2-2' }}
        </td>

        <td>{{ item.unit }}</td>

        <!-- Icon depending on Day or Month -->
        <td class="delta" *ngIf="item.deltaDay || item.deltaMonth">
          <ng-container [ngSwitch]="deltaType">
            <ng-container *ngSwitchCase="'DAY'">
              <ng-container [ngSwitch]="item.calculationType">
                <ng-container *ngSwitchCase="'RELATIVE'">
                  <fof-trend-icon
                    [deltaPercent]="item.deltaDay"
                    iconSize="lg"
                  ></fof-trend-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'ABSOLUTE'">
                  <fof-trend-icon
                    [deltaAbsolute]="item.deltaDay"
                    iconSize="lg"
                  ></fof-trend-icon>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MONTH'">
              <ng-container [ngSwitch]="item.calculationType">
                <ng-container *ngSwitchCase="'RELATIVE'">
                  <fof-trend-icon
                    [deltaPercent]="item.deltaMonth"
                    iconSize="lg"
                  ></fof-trend-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'ABSOLUTE'">
                  <fof-trend-icon
                    [deltaAbsolute]="item.deltaMonth"
                    iconSize="lg"
                  ></fof-trend-icon>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="!item.deltaDay && !item.deltaMonth">
          <fof-trend-icon [deltaPercent]="0" iconSize="lg"></fof-trend-icon>
        </td>
        <!-- Delta Day or Month -->
        <td class="delta">
          <ng-container [ngSwitch]="deltaType">
            <ng-container *ngSwitchCase="'DAY'">
              <ng-container [ngSwitch]="item.calculationType">
                <ng-container *ngSwitchCase="'RELATIVE'">
                  {{
                    !item.deltaDay ? '-' : (item.deltaDay | percent : '.2-2')
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'ABSOLUTE'">
                  {{ !item.deltaDay ? '-' : (item.deltaDay | number : '.2-2') }}
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MONTH'">
              <ng-container [ngSwitch]="item.calculationType">
                <ng-container *ngSwitchCase="'RELATIVE'">
                  {{
                    !item.deltaMonth
                      ? '-'
                      : (item.deltaMonth | percent : '.2-2')
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'ABSOLUTE'">
                  {{
                    !item.deltaMonth ? '-' : (item.deltaMonth | number : '.2-2')
                  }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
        <!-- Delta Year -->
        <td class="delta" *ngIf="item.deltaYear">
          <ng-container [ngSwitch]="item.calculationType">
            <ng-container *ngSwitchCase="'RELATIVE'">
              {{ item.deltaYear | percent : '.2-2' }}
            </ng-container>
            <ng-container *ngSwitchCase="'ABSOLUTE'">
              {{ item.deltaYear | number : '.2-2' }}
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="!item.deltaYear">-</td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #elseBlock>
  <div class="no-chart-data flex flex-row justify-center items-center">
    <ng-container> {{ 'common.LOADING' | transloco }}</ng-container>
  </div>
</ng-template>

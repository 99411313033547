import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({ name: 'yesNo', standalone: true })
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class YesNoPipe implements PipeTransform {
  constructor(private translateService: TranslocoService) {}
  transform(value: boolean) {
    return this.translateService.translate(
      'common.' + (value || false).toString().toUpperCase()
    );
  }
}

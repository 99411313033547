<div
  *ngVar="config(); let config"
  [formGroup]="form"
  [patchFormGroupWithEmitValues]="config"
>
  <div formGroupName="settings">
    <div class="flex flex-row items-center space-x-1">
      <mat-form-field>
        <input
          matInput
          formControlName="startDate"
          [matDatepicker]="picker"
          [placeholder]="'time-series-identifier.INDEX_START_DATE' | transloco"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-checkbox formControlName="showTable">{{
        'time-series-identifier.comparison-widget.SHOW_TABLE' | transloco
      }}</mat-checkbox>
      <mat-checkbox formControlName="showInPercentage">{{
        'time-series-identifier.comparison-widget.SHOW_IN_PERCENTAGE'
          | transloco
      }}</mat-checkbox>
    </div>
  </div>
  <time-series-dynamic-selection-group
    [formGroup]="form"
    [dataSource]="config?.dataSources"
    [calculationType]="calculationType$ | async"
  ></time-series-dynamic-selection-group>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReadonlyFormFacade } from '@backoffice-frontend/news/domain';
import { ID } from '@clean-code/shared/common';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'news-readonly-form',
  templateUrl: './readonly-form.component.html',
  styleUrls: ['./readonly-form.component.scss'],
})
export class ReadonlyFormComponent {
  private closeSubject = new Subject<void>();
  public data$ = this.activatedRoute.paramMap.pipe(
    map((params) => +params.get('id')),
    filter((id: ID) => !!id),
    switchMap((id: ID) => this.readonlyFormFacade.getById$(id)),
    takeUntil(this.closeSubject)
  );

  constructor(
    private readonlyFormFacade: ReadonlyFormFacade,
    private activatedRoute: ActivatedRoute
  ) {}
}

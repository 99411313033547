<div class="flex flex-col">
  <!--Select-->
  <div class="sm:hidden">
    <mat-form-field class="">
      <mat-label>{{ 'time-span-toggle.TIME_FRAME' | transloco }}</mat-label>
      <mat-select [formControl]="timeFrameControlSelect">
        <mat-option
          *ngFor="let item of timeFrames$ | async"
          value="{{ item.name }}"
          >{{ 'time-span-toggle.' + item.name | transloco }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <!--Toggles-->
  <div class="hidden sm:block">
    <mat-button-toggle-group
      class="!h-6 !w-[390px]"
      [formControl]="timeFrameControl"
      name="timeFrame"
      aria-label="Time Frame"
      #toggleGroup
    >
      <mat-button-toggle
        [value]="item.name"
        *ngFor="let item of timeFrames$ | async"
        >{{ 'time-span-toggle.' + item.name | transloco }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <!-- Individual TimeFrame -->
  <ng-container *ngIf="!hideIndividualTimeFrame">
    <div
      *ngxPermissionsOnly="permissions.individualDate"
      class="mt-0 ml-0 sm:mt-0.5 sm:ml-1.5 flex flex-row items-baseline"
    >
      <span class="hidden sm:block whitespace-nowrap text-xs font-bold">
        {{ 'time-span-toggle.INDIVIDUAL' | transloco }}
      </span>
      <mat-form-field
        class="text-xs ml-2 !w-[100px] !h-[40px]"
        (click)="pickerStart.open()"
      >
        <input
          matInput
          [matDatepicker]="pickerStart"
          [formControl]="individualStartDateControl"
          class="!font-medium !text-[13px] hover:cursor-pointer"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerStart"
          class="text-base"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [touchUi]="isMobile"
          #pickerStart
          disabled="false"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        class="text-xs ml-2 !w-[100px] !h-[40px]"
        (click)="pickerEnd.open()"
      >
        <input
          matInput
          [matDatepicker]="pickerEnd"
          [formControl]="individualEndDateControl"
          class="!font-medium !text-[13px] hover:cursor-pointer"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerEnd"
          class="text-base"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [touchUi]="isMobile"
          #pickerEnd
          disabled="false"
        ></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-container>
</div>

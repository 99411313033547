import { CommonModule } from '@angular/common';
import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { DashboardMenuComponent } from '@clean-code/shared/dashboard/dashboard/feature-dashboard-menu';
import { NgxPermissionsModule } from 'ngx-permissions';
import { filter, map } from 'rxjs';
import { NavigationItem } from '../models/navigation-item';
import { NavigationItemComponent } from '../navigation-item/navigation-item.component';
import { SideNavService } from '../services/side-nav.service';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    NavigationItemComponent,
    DashboardMenuComponent,
    NgxPermissionsModule,
  ],
})
export class NavigationComponent {
  @HostBinding('class') class = 'app-navigation';
  public firstItem$ = this.sideNavService.navigationItems$.pipe(
    filter((items: NavigationItem[]) => items.length > 1),
    map((items: NavigationItem[]) => items[0])
  );

  public other$ = this.sideNavService.navigationItems$.pipe(
    filter((items: NavigationItem[]) => items.length > 1),
    map((items: NavigationItem[]) => items.slice(1))
  );
  constructor(public sideNavService: SideNavService) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ReadonlyFormComponent } from '@backoffice-frontend/news/feature-readonly-form';
import { NewsComponent } from './news.component';

const routes: Route[] = [
  {
    path: '',
    component: NewsComponent,
    children: [
      {
        path: ':id',
        component: ReadonlyFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [NewsComponent],
})
export class NewsModule {}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { OfferGasQuery } from '../+state/offer-gas.query';

export const OFFER_GAS_PAGINATOR = new InjectionToken('OFFER_GAS_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const offerGasQuery = inject(OfferGasQuery);
    return new PaginatorPlugin(offerGasQuery, { startWith: 0 });
  },
});

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { ActionButtonComponent } from './action-button/action-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { SaveIconButtonComponent } from './save-button/save-icon-button/save-icon-button.component';
import { ShowButtonComponent } from './show-button/show-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    FontAwesomeModule,
    TranslocoModule,
  ],
  declarations: [
    ActionButtonComponent,
    AddButtonComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    SaveButtonComponent,
    SaveIconButtonComponent,
    ShowButtonComponent,
    CancelButtonComponent,
    // ConfirmDeleteComponent,
  ],
  exports: [
    ActionButtonComponent,
    AddButtonComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    SaveButtonComponent,
    SaveIconButtonComponent,
    ShowButtonComponent,
    TranslocoModule,
    CancelButtonComponent,
    // ConfirmDeleteComponent,
  ],
})
export class ActionButtonModule {}

<page-container>
  <ng-container title>{{ 'kam.HEADER' | transloco }}</ng-container>

  <ng-container *ngIf="this.companies.length > 1">
    <div class="ml-auto mr-5 md:w-96">
      <mat-form-field class="pt-6">
        <mat-label>{{ 'kam.COMPANY' | transloco }}</mat-label>
        <mat-select
          [(value)]="selectedCompany"
          (selectionChange)="companyChanged()"
        >
          <mat-option *ngFor="let company of companies" [value]="company">{{
            company.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
    <div class="text-center pb-10 md:pt-4">
      <h1 class="title">
        {{ 'kam.TITLE' | transloco }}
      </h1>
    </div>
    <cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

    <!-- gesamtcontainer -->
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <div *ngFor="let kam of kams$ | async">
        <!-- einzelner eintrag -->
        <div
          class="w-full bg-layout-sidenav dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg sahdow-lg overflow-hidden border border-gray-300 dark:border-gray-200"
        >
          <fa-icon
            class="float-right mr-4 mt-3"
            [ngClass]="
              kam.type === CompanyRelationTypeEnum.KeyAccountManagerPower
                ? 'power-icon'
                : 'gas-icon'
            "
            [icon]="
              kam.type === CompanyRelationTypeEnum.KeyAccountManagerPower
                ? faPlug
                : faBurn
            "
            [title]="
              kam.type === CompanyRelationTypeEnum.KeyAccountManagerPower
                ? ('kam.POWER' | transloco)
                : ('kam.GAS' | transloco)
            "
          ></fa-icon>
          <div class="text-left h-full p-8 sm:p-6">
            <p class="text-base font-normal">
              {{ kam.company.name }}
            </p>
            <p class="text-xl lg:text-lg xl:text-xl font-bold mb-2">
              {{ kam.person.preTitle }} {{ kam.person.fullName }}
              {{ kam.person.postTitle }}
            </p>
            <p class="text-base font-normal">
              {{ 'kam.EMAIL' | transloco }}
              <a
                class="text-base lg:text-sm xl:text-base font-normal text-primary-color underline"
                href="mailto:{{ kam.person.publicMail }}"
                >{{ kam.person.publicMail }}</a
              >
            </p>
            <p class="text-base font-normal">
              {{ 'kam.PHONE' | transloco }}
              <a
                class="text-base lg:text-sm xl:text-base font-normal text-primary-color underline"
                href="tel:{{ kam.person.phoneNumber }}"
                >{{ kam.person.phoneNumber }}</a
              >
            </p>
            <p>
              <a
                class="text-base font-normal text-primary-color underline"
                href="https://www.e-steiermark.com/kontakt/Kundenservicecenter.aspx"
                target="_blank"
                >{{ 'kam.SERVICECENTER' | transloco }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</page-container>

import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  AreasPublicFacade,
  ShortArea,
} from '@clean-code/backoffice/area/util-api';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'area-dropdown',
  templateUrl: './area-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AreaDropdownComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,

    TranslocoModule,
  ],
})
export class AreaDropdownComponent extends ControlValueAccessorConnector {
  public energySource$ = new BehaviorSubject<string>(null);
  public type$ = new BehaviorSubject<string>(null);
  public useIntAsId$ = new BehaviorSubject<boolean>(false);

  @Input()
  public set energySource(value: string) {
    this.energySource$.next(value);
  }

  @Input()
  public set type(value: string) {
    this.type$.next(value);
  }

  @Input()
  public clearable: boolean;

  @Input()
  public set useIntAsId(value: boolean) {
    this.useIntAsId$.next(value);
  }

  @Input()
  public placeholder: string;

  public marketAreas$ = combineLatest([
    this.type$,
    this.energySource$,
    this.useIntAsId$,
  ]).pipe(
    filter(([type, _, _useId]: [string, string, boolean]) => !!type),
    switchMap(([type, energySource, useIntAsId]: [string, string, boolean]) => {
      let result: Observable<ShortArea[]>;
      if (!energySource) {
        result = this.AreasPublicFacade.getAllShortAreas$().pipe(
          map((values) => values.items)
        );
      } else {
        result = this.AreasPublicFacade.getByEnergySource$(type, energySource);
      }

      if (useIntAsId) {
        return result.pipe(
          map((values: ShortArea[]) =>
            values.map((value: ShortArea) => {
              return {
                ...value,
                id: +value.id,
              } as ShortArea;
            })
          )
        );
      }

      return result;
    })
  );

  @Input()
  public set marketAreas(values: ShortArea[]) {
    this.marketAreas$ = of(values);
  }

  constructor(private AreasPublicFacade: AreasPublicFacade) {
    super();
  }
}

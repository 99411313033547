import { Injectable } from '@angular/core';
import { GraphQLFragments, ID } from '@clean-code/shared/common';
import {
  IDashboardWidgetConfigDto,
  IDashboardWidgetCorrelation,
  IDashboardWidgetDetailDto,
  IDashboardWidgetDto,
  IDashboardWidgetReferenceDto,
} from '@clean-code/shared/components/ui-dashboard';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import {
  getWidgetDetailParams,
  getWidgetDetailWithTranslationsParams,
} from './params';

@Injectable({ providedIn: 'root' })
export class DashboardWidgetDataService {
  public static readonly getAssignableParams = `
    id
    name
    type
    color
    creator {
      id
      date
      caption
    }
  `;

  constructor(private graphqlService: GraphqlService) {}

  getAssigned$(dashboardId: number): Observable<IDashboardWidgetDetailDto[]> {
    const query = `
      query($dashboardId: Int!) {
        dashboardWidgetGetAssigned(dashboardId: $dashboardId) {
            ${getWidgetDetailParams}
        }
      }
    `;

    return this.graphqlService.query<IDashboardWidgetDetailDto[]>(query, {
      dashboardId,
    });
  }

  getAssignable$(dashboardId: ID): Observable<IDashboardWidgetDto[]> {
    const query = `
      query($dashboardId: Int!) {
        dashboardWidgetGetAssignable(dashboardId: $dashboardId) {
            ${DashboardWidgetDataService.getAssignableParams}
        }
      }
    `;

    return this.graphqlService.query<IDashboardWidgetDto[]>(query, {
      dashboardId,
    });
  }

  getAvailableTypes$(): Observable<string[]> {
    const query = `
      query() {
        dashboardWidgetGetAvailableTypes() {
        }
      }
    `;

    return this.graphqlService.query<string[]>(query, {});
  }

  getById$(id: ID): Observable<IDashboardWidgetDetailDto> {
    const query = `
      {
        dashboardWidgetAll(where: {id:{eq: ${id}}}) {
            ${getWidgetDetailWithTranslationsParams}
        }
      }
    `;

    return this.graphqlService.singleQuery<IDashboardWidgetDetailDto>(query);
  }

  add$(
    input: IDashboardWidgetDetailDto
  ): Observable<IDashboardWidgetDetailDto> {
    const mutation = `
      mutation ($input: DashboardWidgetAddInput!) {
        dashboardWidgetAdd(input: $input)
        {
            ${getWidgetDetailParams}
        }
      }

      ${GraphQLFragments.localizationMediumFragment()}
      ${GraphQLFragments.localizationLongFragment()}
    `;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<IDashboardWidgetDetailDto>(
      mutation,
      variables
    );
    //TODO: dashboard state
    // .pipe(
    //   tap((value: IDashboardWidgetDetailDto) =>
    //     // this.store.upsert(value.id, value)
    //   )
    // );
  }

  reference$(
    input: IDashboardWidgetReferenceDto
  ): Observable<IDashboardWidgetDetailDto> {
    const mutation = `
      mutation ($input: DashboardWidgetReferenceInput!) {
        dashboardWidgetReference(input: $input)
        {
            ${getWidgetDetailParams}
        }
      }

      ${GraphQLFragments.localizationMediumFragment()}
      ${GraphQLFragments.localizationLongFragment()}
    `;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<IDashboardWidgetDetailDto>(
      mutation,
      variables
    );
  }

  update$(
    input: IDashboardWidgetDetailDto
  ): Observable<IDashboardWidgetDetailDto> {
    const mutation = `
      mutation ($input: DashboardWidgetUpdateInput!) {
        dashboardWidgetUpdate(input: $input)
      }
    `;
    const newInput = { ...input };

    delete newInput.x;
    delete newInput.y;
    delete newInput.cols;
    delete newInput.rows;
    delete newInput.creator;
    delete newInput.tenant;
    delete newInput.minItemRows;
    delete newInput.minItemCols;

    const variables = {
      input: newInput,
    };

    return this.graphqlService.mutation<IDashboardWidgetDetailDto>(
      mutation,
      variables
    );
  }

  updateConfig$(
    input: IDashboardWidgetConfigDto
  ): Observable<IDashboardWidgetDetailDto> {
    const mutation = `
      mutation ($input: DashboardWidgetUpdateConfigInput!) {
        dashboardWidgetUpdateConfig(input: $input)
      }
    `;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<IDashboardWidgetDetailDto>(
      mutation,
      variables
    );
  }

  updateCorrelation$(
    input: IDashboardWidgetCorrelation
  ): Observable<IDashboardWidgetDetailDto> {
    const mutation = `
      mutation ($input: DashboardWidgetCorrelationUpdateInput!) {
        dashboardWidgetUpdateCorrelation(input: $input)
        {
            ${getWidgetDetailParams}
        }
      }
    `;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<IDashboardWidgetDetailDto>(
      mutation,
      variables
    );
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
      mutation {
        dashboardWidgetDelete(widgetId: ${id})
      }
    `;

    return this.graphqlService.mutation<boolean>(mutation, {}).pipe(
      tap(() => {
        // TODO: dashboard state this.store.remove(id);
      })
    );
  }

  remove$(o: IDashboardWidgetDetailDto): Observable<boolean> {
    const mutation = `
      mutation {
        dashboardWidgetRemove(dashboardId: ${o.dashboardId}, widgetId: ${o.id})
      }
    `;

    return this.graphqlService.mutation<boolean>(mutation, {}).pipe(
      tap(() => {
        // TODO: dashboard state this.store.remove(o.id);
      })
    );
  }

  dashboardWidgetGetByDashboardId$(id: ID): Observable<IDashboardWidgetDto[]> {
    const query = `
      query($id: Int!) {
        dashboardWidgetGetByDashboardId(id: $id) {
          id
          type
        }
      }
    `;

    return this.graphqlService.query<IDashboardWidgetDto[]>(query, {
      id,
    });
  }
}

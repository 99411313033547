import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  IndividualTimeFrame,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  ComparisonWidgetTypeConfig,
  DataSource,
} from '@backoffice-frontend/time-series-identifier/domain';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { TimeSpanToggleComponent } from '@clean-code/shared/components/ui-time-span-toggle';
import { ComparisonChartComponent } from '@clean-code/time-series-identifier/ui-comparison-chart';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'comparison-chart-widget-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,

    MatDialogModule,

    FontAwesomeModule,
    ProgressBarModule,
    ComparisonChartComponent,
    TimeSpanToggleComponent,
  ],
})
export class ChartDialogComponent {
  @HostBinding('class') class = 'chart-comparison-widget-dialog';

  public faXmarkLarge = faXmarkLarge;
  public selectedTimeFrame$ = new BehaviorSubject<TimeFrame>('M_3');
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public data$ = this.selectedTimeFrame$.pipe(
    map((timeFrame: TimeFrame) => {
      return {
        ...this.data,
        identifiers: this.data.config.dataSources.map(
          (x: DataSource) => x.settings
        ),
        timeFrame: timeFrame,
        showInPercentage: this.data.config.settings.showInPercentage,
        showTable: this.data.config.settings.showTable,
      };
    })
  );

  constructor(
    public dialogRef: MatDialogRef<ChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      widget: IDashboardWidgetDetailDto;
      config: ComparisonWidgetTypeConfig;
    }
  ) {
    const timeFrame = data.config.settings.startDate
      ? ({
          start: dayjs(data.config.settings.startDate),
          end: dayjs(),
        } as IndividualTimeFrame)
      : data.config.settings.timeFrame;

    this.selectedTimeFrame$.next(timeFrame);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickClose(): void {
    this.dialogRef.close();
  }
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { HknTypeQuery } from '../+state/hkn-type.query';

export const HKN_TYPE = new InjectionToken('HKN_TYPE', {
  providedIn: 'root',
  factory: () => {
    const hknTypeQuery = inject(HknTypeQuery);
    return new PaginatorPlugin(hknTypeQuery, { startWith: 0 });
  },
});

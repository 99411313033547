import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { MatColumnDef } from '@angular/material/table';
import {
  FilterParam,
  ID,
  ObjectHelper,
  PagingResponse,
  SortBy,
} from '@clean-code/shared/common';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, Subject, combineLatest, filter, map, tap } from 'rxjs';
export class TableSetting {
  multi: false;
  idKeyName: string;
}

export class TableState {
  isEmpty = true;
  sort: SortBy[];
  filters: FilterParam[];
  searchTerm: string;

  pageIndex: number;
  pageSize: number;
  totalCount: number;

  data: PagingResponse<any>;
  hiddenTableColumns: string[];
  columnDef: MatColumnDef[];
  selected: TableSelection;
}

export class TableSelection {
  selectionCount = 0;
  hasMultipleSelection = false;
  hasValue = false;
  multiSelection: any[];
  selectedId: ID;
  singleSelect: any;
}

export const TABLE_SETTINGS: InjectionToken<TableSetting> =
  new InjectionToken<TableSetting>('TableSetting');

@Injectable()
export class TableStateService {
  private state: RxState<TableState> = inject(RxState<TableState>);

  public loadDataFromRouting$ = combineLatest([
    this.data$,
    this.selectedId$,
  ]).pipe(
    filter(
      ([_data, id]: [PagingResponse<any>, ID]) => this.singleSelect?.id !== id
    ),
    tap(([data, id]: [PagingResponse<any>, ID]) => {
      this.selectObjectById(data, id);
    })
  );

  // public tableColumns$ = this.state.select('tableColumns');
  public hiddenTableColumns$ = this.state.select('hiddenTableColumns');
  public columnDef$ = this.state.select('columnDef');

  private _refreshTrigger$ = new Subject<boolean>();
  private _defaultSort: SortBy[] = [{ prop: 'name', dir: 'ASC' }];
  private selection: SelectionModel<any>;

  private initSelectionState: TableSelection = {
    selectionCount: 0,
    hasMultipleSelection: false,
    hasValue: false,
    multiSelection: [],
    selectedId: null,
    singleSelect: null,
  };

  private initState = {
    sort: this._defaultSort,
    pageIndex: 0,
    filters: new Array<FilterParam>(),
    totalCount: 0,
    pageSize: 50,
    tableColumns: new Array<string>(),
    select: this.initSelectionState,
    hiddenTableColumns: [],
    isEmpty: true,
    data: {} as PagingResponse<any>,
    columnDef: [],
    selected: this.initSelectionState,
    searchTerm: null,
  } as TableState;

  constructor() {
    this.state.set(this.initState);
    const tableSettings =
      (inject(TABLE_SETTINGS, { optional: true }) as TableSetting) ??
      new TableSetting();

    this.selection = new SelectionModel<any>(tableSettings.multi, []);
    const selectionChanged$ = this.selection.changed.pipe(
      map((selection: SelectionChange<any>) => {
        if (selection.source.selected.length < 1) {
          return this.initSelectionState;
        } else {
          const selectionState = new TableSelection();
          selectionState.selectionCount = this?.selection?.selected.length;
          selectionState.hasMultipleSelection =
            this?.selection?.selected.length > 1;
          selectionState.hasValue = this.selection.hasValue();
          selectionState.singleSelect = this.singleSelect;
          selectionState.multiSelection = this.multiSelection;
          return selectionState;
        }
      })
    );

    this.state.set({
      sort: this._defaultSort,
    });

    this.state.connect(selectionChanged$, (s, v) => ({
      selected: { ...s.selected },
    }));
  }

  public select(row: any): void {
    this.selection.select(row);
    const v = {
      ...this.state.get(),
      selected: {
        ...this.state.get().selected,
        singleSelect: row,
        selectedId: row.id,
      },
    };
    this.state.set(v);
  }
  public clear() {
    this.selection.clear();
  }

  public reset() {
    this.state.set(this.initState);
  }

  public toggle(row: any) {
    this.selection.toggle(row);
  }
  public isSelected(row: any): boolean {
    return this.selection.isSelected(row);
  }

  public get state$(): Observable<TableState> {
    return this.state.select();
  }

  public get selectionState$(): Observable<TableSelection> {
    return this.state.select().pipe(map((value: TableState) => value.selected));
  }

  public setDefaultSort() {
    this.state.set({ sort: this._defaultSort });
  }

  public setSort(sort: SortBy[]) {
    this.state.set({ sort });
  }

  public setSort2(sort: SortBy[]) {
    this.state.set({ sort });
    this._defaultSort = sort;
  }

  public get sort$(): Observable<SortBy[]> {
    return this.state.select('sort');
  }

  public get searchTerm(): string {
    return this.state.get().searchTerm;
  }

  public get filters(): FilterParam[] {
    return this.state.get().filters;
  }

  public get searchTerm$(): Observable<string> {
    return this.state.select('searchTerm');
  }

  public updateSearchTerm(term: string): void {
    this.state.set({
      searchTerm: term,
    });
  }

  public updateFilter(filter: FilterParam[]) {
    this.state.set({
      filters: filter,
    });
  }

  public upsertFilters(filters: FilterParam[]) {
    const filterList = ObjectHelper.cloneObject(this.filters);

    filters.forEach((element: FilterParam) => {
      this.upsertFilter(element, filterList);
    });

    this.state.set({
      filters: filterList,
    });
  }

  public removeFilter(filterKey: string) {
    const filterList = ObjectHelper.cloneObject(this.filters);

    const removeFilterRec = (
      filters: FilterParam[],
      filterKey: string
    ): void => {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].key === filterKey) {
          filters.splice(i, 1);
          return;
        }
        if (filters[i].filters && filters[i].filters.length > 0) {
          removeFilterRec(filters[i].filters, filterKey);
        }
      }
    };

    removeFilterRec(filterList, filterKey);
    this.state.set({
      filters: filterList,
    });
  }

  private upsertFilter(filter: FilterParam, filters: FilterParam[]) {
    const index = filters.findIndex((x) => x.key === filter.key);

    if (index > -1) {
      filters.splice(index, 1);
    }

    filters.push(filter);
  }

  public get filters$(): Observable<FilterParam[]> {
    return this.state.select('filters');
  }

  public get singleSelect(): any {
    if (this.selection?.selected?.length === 1) {
      return this.selection.selected[0];
    }

    return null;
  }

  public get multiSelection(): any[] {
    return this.selection?.selected;
  }

  public setPaging(pageIndex: number) {
    this.state.set({
      pageIndex,
    });
  }

  public get pageIndex$(): Observable<number> {
    return this.state.select('pageIndex');
  }

  public get paginationState$(): Observable<{
    pageIndex: number;
    totalCount: number;
    pageSize: number;
  }> {
    return this.state
      .select()
      .pipe(selectSlice(['pageIndex', 'totalCount', 'pageSize']));
  }

  public get data$(): Observable<PagingResponse<any>> {
    return this.state.select('data');
  }

  public get selectedId$(): Observable<ID> {
    return this.state
      .select('selected', 'selectedId')
      .pipe(filter((id: ID) => id !== 0));
  }

  public get selectedId(): ID {
    return this.state.get().selected.selectedId;
  }

  public get singleSelect$(): Observable<any> {
    return this.state.select('selected', 'singleSelect');
  }

  public resetSelection() {
    this.selection.clear();

    const v = {
      ...this.state.get(),
      selected: this.initSelectionState,
    };

    this.state.set(v);
  }

  public resetFilters() {
    this.updateFilter([]);
  }

  public resetSearchTerm() {
    this.updateSearchTerm(null);
  }

  public setData(data: PagingResponse<any>) {
    this.state.set({
      data,
      totalCount: data?.totalCount,
      pageSize: data?.perPage,
    });
  }

  public getData(): PagingResponse<any> {
    return this.state.get().data;
  }

  public refresh() {
    return this._refreshTrigger$.next(true);
  }

  public updateHiddenTableColumns(value: string[]): void {
    this.state.set({
      hiddenTableColumns: value,
    });
  }

  public updateColumnDefColumns(value: MatColumnDef[]): void {
    this.state.set({
      columnDef: value,
    });
  }

  public get refreshTrigger$() {
    return this._refreshTrigger$.asObservable();
  }

  private selectObjectById(data: PagingResponse<any>, id: ID): void {
    const objects = data.items ?? data.nodes;

    if (objects) {
      const object = objects.find((x) => x.id === id);
      if (object) {
        this.select(object);
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { IFormBaseService } from '@clean-code/shared/util/util-component-services';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import {
  TradingCalendarHoliday,
  TradingCalendarHolidayAddInput,
} from '../entities/trading-calendar-holiday';

@Injectable({ providedIn: 'root' })
export class TradingCalendarHolidayDataService
  implements
    EditSearchDataService<TradingCalendarHoliday>,
    IFormBaseService<TradingCalendarHoliday>
{
  public readonly entityFields = `
      id
      name
      date
      tradingCalendarId
    `;

  public calendarId: ID;

  constructor(
    private graphqlService: GraphqlService,
    public activatedRoute: ActivatedRoute
  ) {}

  getAll$(
    params: DataTableParameters
  ): Observable<DataServiceReturn<TradingCalendarHoliday>> {
    return this.graphqlService.getAll(
      params,
      'areaTradingCalendarHolidayAll',
      this.entityFields
    );
  }

  getById$(id: ID): Observable<TradingCalendarHoliday> {
    const query = `
      query($id: Int!) {
          areaTradingCalendarHolidayById(id: $id) {
              ${this.entityFields}
          }
      }`;

    return this.graphqlService.query<TradingCalendarHoliday>(
      query,
      {
        id,
      },
      null,
      { redirectTo404: true }
    );
  }

  update$(input: TradingCalendarHolidayAddInput): Observable<ID> {
    const mutation = `
        mutation ($input: AreaTradingCalendarHolidayAddInput!){
          areaTradingCalendarHolidayUpdate(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  add$(input: TradingCalendarHolidayAddInput): Observable<ID> {
    const mutation = `
        mutation ($input: AreaTradingCalendarHolidayAddInput!){
          areaTradingCalendarHolidayAdd(input: $input)
          }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
        mutation ($id: Int!){
              areaTradingCalendarHolidayDelete(id: $id)
          }`;

    return this.graphqlService.mutation<any>(mutation, { id }, null, {
      ignoreNullCheckFilter: true,
    });
  }

  public addDefaultHolidays$(
    from: Dayjs,
    to: Dayjs,
    tradingCalendarId: ID
  ): Observable<boolean> {
    const mutation = `
        mutation ($from: DateTime!, $to: DateTime! $tradingCalendarId: Int!){
              areaTradingCalendarAddDefaultHolidays(from: $from, to: $to, tradingCalendarId: $tradingCalendarId)
          }`;

    return this.graphqlService.mutation<boolean>(mutation, {
      from,
      to,
      tradingCalendarId,
    });
  }
}

<div
  *ngIf="widget(); let widget"
  class="widget-type-dialog-config-component"
  class="{{ widget?.type }} flex flex-col h-full"
>
  <div class="flex flex-row w-full px-2 py-3 justify-between">
    <h2>
      {{ widget?.name.localizations[0].value }}
      {{ 'DASHBOARDWIDGET.CONFIG' | transloco }}
    </h2>

    <button mat-icon-button (click)="close()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div
    *ngIf="component$ | async; let component"
    class="w-full h-full overflow-y-auto p-3 overflow-x-hidden"
  >
    <div>
      <ndc-dynamic
        [ndcDynamicComponent]="component"
        [ndcDynamicInputs]="inputs$ | async"
        [ndcDynamicOutputs]="outputs"
        (ndcDynamicCreated)="componentCreated($event)"
      >
      </ndc-dynamic>
    </div>

    <div *ngIf="!component">
      <span class="loading">{{ 'common.LOADING' | transloco }}</span>
    </div>

    <div *ngIf="!component">
      <span class="unknown">{{
        'DASHBOARDWIDGET.MESSAGES.TYPE.UNKNOWN' | transloco
      }}</span>
    </div>
  </div>

  <div class="flex flex-row space-x-1 justify-end">
    <cc-cancel-action-button (onClick)="close()"></cc-cancel-action-button>
    <cc-save-action-button (onClick)="save()"></cc-save-action-button>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { UserLimit } from '../entities/user-limit';
import { USER_LIMIT_SERVICE } from '../infrastructure/user-limit-service-token';
import { UserLimitService } from '../infrastructure/user-limit.service';

@Injectable({ providedIn: 'root' })
export class UserLimitListFacade implements EditSearchDataService<UserLimit> {
  constructor(
    @Inject(USER_LIMIT_SERVICE) protected service: UserLimitService
  ) {}
  public getById$(id: ID): Observable<UserLimit> {
    return this.service.getById$(id);
  }

  public getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<UserLimit>> {
    return this.service.getAll$(params);
  }

  public add$(input: UserLimit): Observable<ID> {
    return this.service.add$(input) as Observable<ID>;
  }

  public update$(input: UserLimit): Observable<ID> {
    return this.service.update$(input) as Observable<ID>;
  }

  public delete$(id: ID): Observable<boolean> {
    return this.service.delete$(id) as Observable<boolean>;
  }
}

<ng-container *rxLet="state$; let state">
  <button
    class="notification-center mx-2 text-layout-sidenav-text dark:text-layout-dark-sidenav-text relative"
    mat-ripple
    mat-button
    [matMenuTriggerFor]="notificationCenter"
  >
    <fa-icon
      *ngIf="state.unreadCount === 0"
      [icon]="faBell"
      size="2x"
    ></fa-icon>
    <fa-icon
      *ngIf="state.unreadCount > 0"
      [icon]="faBellOn"
      size="2x"
    ></fa-icon>
    <span *ngIf="state.unreadCount > 0" class="unread-count">{{
      state.unreadCount
    }}</span>
    <span
      *ngIf="state.connectionState !== connectedState"
      class="absolute text-red-500 top-[-6px] right-0"
      >X</span
    >
  </button>
  <mat-menu #notificationCenter="matMenu" class="notification-center-menu">
    <ng-container
      *ngIf="state.connectionState === connectedState; else disconnected"
    >
      <div
        class="flex flex-col p-1 min-w-[200px] max-w-[500px] origin-top-right divide-y focus:outline-none text-layout-sidenav-text dark:text-layout-dark-sidenav-text"
      >
        <div class="py-1" role="none">
          <div class="text-center">
            {{ 'notification.PLURAL' | transloco }}
          </div>
          <!-- <div class="extra">
            {{
              'notification.topnav.unread'
                | transloco: { unreadCount: unreadCount }
            }}.
          </div> -->
        </div>
        <div class="py-1 px-2" role="none">
          <div class="text-center" *ngIf="state.messages.length === 0">
            {{ 'notification.EMPTY' | transloco }}
          </div>
          <ul role="list" class="divide-y divide-gray-100">
            <li
              class="flex justify-between gap-x-6 py-5"
              *ngFor="let item of state.messages"
            >
              <notifications-notification-host
                [notification]="item"
              ></notifications-notification-host>
            </li>
          </ul>
        </div>

        <div
          class="flex flex-row w-full self-center justify-center text-center cursor-pointer text-primary-color"
          [ngClass]="{ hidden: state.unreadCount === 0 }"
          (click)="setAllRead($event)"
        >
          {{ 'notification.MARKALLREAD' | transloco }}
        </div>
      </div>
    </ng-container>
    <ng-template #disconnected
      ><div class="disconnected">
        {{ 'notification.DISCONNECTED' | transloco }}
      </div></ng-template
    >
  </mat-menu>
</ng-container>

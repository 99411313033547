import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  DashboardDetailsFacade,
  DashboardFacade,
} from '@backoffice-frontend/market/domain';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { SmaChartData } from '@backoffice-frontend/shared/ui-statistics';
import {
  ClosingValue,
  LineChartBaseChart,
  SmaGroup,
  SmaGroupWithIndex,
  SmaSettings,
  TimeSeriesFacade,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import {
  DevExtremeLineChartComponent,
  LineChartDataModel,
  LineChartModel,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { TranslocoModule } from '@ngneat/transloco';
import { TitleOptions } from 'chart.js';
import { EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fof-ma-chart',
  templateUrl: './ma-chart.component.html',
  imports: [CommonModule, TranslocoModule, DevExtremeLineChartComponent],
})
export class MAChartComponent extends LineChartBaseChart<SmaSettings> {
  @Input()
  public set identifier(value: TimeSeriesInput) {
    this.identifier$.next(value);
  }

  @Input()
  public set timeFrame(value: TimeFrame) {
    this.selectedTimeFrame$.next(value);
  }

  @Input()
  public set settings(value: SmaSettings) {
    this.chartSettings$.next(value);
  }

  @Input()
  public legend = false;

  public yAxisTitle: TitleOptions;

  public data$ = this.paramChange$.pipe(
    switchMap(
      ([value, settings, timeFrame]: [
        TimeSeriesInput,
        SmaSettings,
        TimeFrame
      ]) => {
        return this.timeSeriesFacade
          .getMAChartData$(value.identifier, settings, timeFrame)
          .pipe(indicate(this.isLoading$));
      }
    ),
    map((values: SmaGroupWithIndex) => {
      const array = new Array<any>();

      const closedData = {
        label: values.name,
        data: values.closingValues.map(
          (val: ClosingValue) =>
            ({
              date: new Date(val.tradeDate),
              value: val.value,
            } as LineChartDataModel)
        ),
      };

      array.push(closedData);

      values.groups.forEach((value: SmaGroup) => {
        const data = {
          label: 'MA' + value.name,
          yAxisTitle: values.unit,
          data: value.values.map(
            (val: SmaChartData) =>
              ({
                date: new Date(val.date),
                value: val.sma,
              } as LineChartDataModel)
          ),
        } as LineChartModel;

        array.push(data);
      });

      return { chart: array, table: values.table };
    })
  );

  details$ = EMPTY;

  constructor(
    private timeSeriesFacade: TimeSeriesFacade,
    public dashboardFacade: DashboardFacade,
    public marketDashboardDetailsDataService: DashboardDetailsFacade
  ) {
    super();
  }
}

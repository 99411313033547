import { CommonModule } from '@angular/common';
import {
  Component,
  forwardRef,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { MultiSelectAutocompleteModule } from '@clean-code/shared/components/ui-multiselect-autocomplete';
import { map } from 'rxjs/operators';
import { CompanyDropDown } from '../company';
import { CompanyDropDownService } from '../company-dropdown.service';

@Component({
  standalone: true,
  selector: 'bo-companies-dropdown',
  templateUrl: './companies-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ReactiveFormsModule, MultiSelectAutocompleteModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompaniesDropdownComponent),
      multi: true,
    },
  ],
})
export class CompaniesDropdownComponent extends ControlValueAccessorConnector {
  private companyService = inject(CompanyDropDownService);
  dataFunction = (search: string) =>
    this.companyService.getAllCompanies$(search).pipe(
      map((data: CompanyDropDown[]) => data.filter((x) => !x.disabled)),
      map((values: CompanyDropDown[]) =>
        values.map((value: CompanyDropDown) => {
          return {
            ...value,
            id: +value.id,
          };
        })
      )
    );
}

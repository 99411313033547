import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
  CompanyRelation,
  CompanyRelationTypeEnum,
  FofPublicFacade,
} from '@backoffice/contacts/domain';
import { AuthQuery } from '@clean-code/shared/auth/util-auth';
import { indicate } from '@clean-code/shared/common';
import { faBurn, faPlug } from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'cc-kam',
  templateUrl: './kam.component.html',
  styleUrls: ['./kam.component.scss'],
})
export class KamComponent implements OnInit, OnDestroy {
  @HostBinding('class.page-component') pageComponent = true;
  public companies: { key: number; value: string }[] = [];
  public selectedCompany: { key: number; value: string };

  private closeSubject = new Subject<void>();
  private kamData$ = new Subject<CompanyRelation[]>();

  // public kams$ = this.authQuery.userData$.pipe(
  //   filter((authUser) => !!authUser),
  //   switchMap((authUser) =>
  //     this.contactsFacade
  //       .getAllKeyAccountManagersForCompany$(authUser.companies[0].key)
  //       .pipe(indicate(this.isLoading$), first())
  //   )
  // );
  kams$ = this.kamData$.asObservable();

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public faBurn = faBurn;
  public faPlug = faPlug;

  public CompanyRelationTypeEnum = CompanyRelationTypeEnum;

  constructor(
    private contactsFacade: FofPublicFacade,
    private authQuery: AuthQuery
  ) {}

  ngOnInit() {
    this.authQuery.userData$
      .pipe(
        filter((authUser) => !!authUser),
        tap((authUser) => {
          this.companies = authUser.companies;
          this.selectedCompany = this.companies ? this.companies[0] : null;
          this.loadKams();
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }

  public companyChanged() {
    this.loadKams();
  }

  private loadKams() {
    if (!this.selectedCompany || !this.selectedCompany.key) {
      return;
    }

    this.contactsFacade
      .getAllKeyAccountManagersForCompany$(this.selectedCompany.key)
      .pipe(
        indicate(this.isLoading$),
        tap((kams) => this.kamData$.next(kams)),
        first()
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }
}

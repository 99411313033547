import { Component, Input } from '@angular/core';
import { FrontOfficeLimitReportPermissions } from '@clean-code/shared/frontoffice/util-permissions';
import {
  faCalculator,
  faChartLine,
  faFileAlt,
  faGraduationCap,
  faHome,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle, faEuroSign, faPlug } from '@fortawesome/pro-solid-svg-icons';
import { SideNavService } from '../../services/side-nav.service';

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss'],
})
export class SideNavMenuComponent {
  public faHome = faHome;
  public faChartLine = faChartLine;
  public faCircle = faCircle;
  public faPlug = faPlug;
  public faFileAlt = faFileAlt;

  public limitReportPermissions = FrontOfficeLimitReportPermissions;

  @Input()
  toggleMenu = true;

  public start = { name: 'side-nav.START', link: 'start', icon: faHome };

  public energy_market = {
    name: 'side-nav.ENERGY_MARKET',
    link: 'market/energy-market',
    icons: [faChartLine, faCircle, faPlug],
  };

  public market = {
    name: 'side-nav.FINANCIAL_MARKETS',
    link: 'market/market',
    icons: [faChartLine, faCircle, faEuroSign],
  };

  public spreads = {
    name: 'side-nav.SPREADS',
    link: 'spreads',
    icon: faCalculator,
  };

  public limitReports = {
    name: 'limit-reports.TITLE_PLURAL',
    link: 'limit-report',
    icon: faFileAlt,
  };

  public wiki = { name: 'side-nav.WIKI', link: 'wiki', icon: faGraduationCap };

  constructor(public service: SideNavService) {}

  public toggleUserMenu() {
    if (this.toggleMenu) {
      this.service.toggleUserMenu();
    }
  }
}

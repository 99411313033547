import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ENV_TOKEN, EnvConfig } from '@clean-code/shared/util-config';
import { ToastService } from '@clean-code/shared/util/util-toast';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private toastService: ToastService,
    @Inject(ENV_TOKEN) private env: EnvConfig
  ) // @Inject('ILoggingService') private loggerService: ILoggingService
  {}

  handleError(e: any) {
    this.handle(e, false);
  }

  private handle(e: any, rethrow: boolean) {
    const error = this.getError(e);

    if (
      !error ||
      error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >=
        0 ||
      error.message.indexOf('detectChanges') >= 0
    ) {
      console.error(error);
      return;
    }

    const chunkFailedMessage = /Loading chunk/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    // this.appInsightsService.trackException(e);
    this.toastService.showError(error.message);

    console.error(error);
    // this.loggerService.logException(error, 3);

    if (this.env.production) {
      Sentry.captureException(error);
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    if (rethrow) {
      throw error;
    }
  }

  private getError(error: any): Error {
    if (!error) {
      return null;
    }

    if (error.rejection) {
      return this.getError(error.rejection);
    } else if (error instanceof HttpErrorResponse) {
      return this.getError(
        this.getErrorFromHttpErrorResponse(error as HttpErrorResponse)
      );
    } else if (error instanceof Error) {
      return error;
    }
    return new Error(error);
  }

  private getErrorFromHttpErrorResponse(
    errorResponse: HttpErrorResponse
  ): Error {
    if (errorResponse.status === 0) {
      // backend offline
      return new Error('common.SERVER_OFFLINE');
    } else if (errorResponse.status === 401) {
      return new Error('common.NOT_AUTHORIZED');
    } else if (errorResponse.status === 403) {
      return new Error('common.NOT_ALLOWED');
    } else if (errorResponse.status === 400) {
      if (errorResponse.error && errorResponse.error.errors) {
        return new Error(errorResponse.error.errors[0]);
      } else if (errorResponse.error && errorResponse.error.error_description) {
        return new Error(
          'common.error.' + errorResponse.error.error_description
        );
      }
      return new Error('400 (Bad Request)');
    } else if (errorResponse.status === 900) {
      return null;
    } else {
      let errorText = errorResponse.message;
      if (!errorText) {
        errorText =
          errorResponse.statusText + ' (' + errorResponse.status + ')';
      }

      return new Error('Server error');
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Route, RouterModule } from '@angular/router';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { NormCubicMeterFormComponent } from './norm-cubic-meter-form.component';

const routes: Route[] = [
  {
    path: '',
    component: NormCubicMeterFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    RouterModule.forChild(routes),
    MatInputModule,
    NgSelectModule,

    FormContainerModule,
  ],
  declarations: [NormCubicMeterFormComponent],
  exports: [NormCubicMeterFormComponent],
})
export class NormCubicMeterFormModule {}

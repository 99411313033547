<div class="confirm-dialog">
  <h1 mat-dialog-title>
    <fa-icon *ngIf="icon" [style.color]="iconColor" [icon]="icon"></fa-icon>
    {{ title | transloco }}
  </h1>
  <div mat-dialog-content>{{ message | transloco }}</div>
  <div>
    <div mat-dialog-actions flexLayout="row">
      <button
        *ngIf="showCancelButton"
        visible=""
        type="button"
        mat-button
        (click)="dialogRef.close(false)"
      >
        {{ 'common.CANCEL' | transloco }}
      </button>
      <span fxFlex>
        <!--Space-->
      </span>
      <button type="button" mat-button (click)="dialogRef.close(true)">
        {{ 'common.OK' | transloco }}
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { Termin } from '../entities/termin';
import { TerminStore } from './termin.store';

@Injectable({ providedIn: 'root' })
export class TerminQuery extends AkitaSearchQueryEntity<Termin> {
  constructor(protected store: TerminStore) {
    super(store);
  }
}

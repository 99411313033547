import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  PatchFormGroupValuesDirective,
  PatchFormGroupWithEmitValuesDirective,
} from './patch-form-group-values/patch-form-group-values.directive';
@NgModule({
  imports: [
    CommonModule,
    PatchFormGroupValuesDirective,
    PatchFormGroupWithEmitValuesDirective,
  ],
  declarations: [
    // FormControlReadOnlyDirective,
    // DateInputConverterDirective,
  ],
  exports: [
    // FormControlReadOnlyDirective,
    PatchFormGroupValuesDirective,
    PatchFormGroupWithEmitValuesDirective,
    // DateInputConverterDirective,
  ],
})
export class FormDirectiveModule {}

import { CommonModule, KeyValue } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ID, indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MarketHknCustomersWidgetTypeConfig } from '../market-hkn-customers';
import { MarketHknCustomersService } from '../services/market-hkn-customers.service';
@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-customers-content',
  templateUrl: './market-hkn-customers-content.component.html',
  styleUrls: ['./market-hkn-customers-content.component.css'],
  imports: [CommonModule, TranslocoModule, ProgressBarComponent],
})
export class MarketHknCustomersContentComponent extends BaseWidgetTypeContentComponent<MarketHknCustomersWidgetTypeConfig> {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public data$: Observable<
    KeyValue<string, { volume: number; companyId: ID }>[]
  >;

  public constructor(injector: Injector) {
    super();

    const service = injector.get(MarketHknCustomersService);

    this.data$ = this.configObservable.pipe(
      filter((value: MarketHknCustomersWidgetTypeConfig) => !!value.filter),
      switchMap((value: MarketHknCustomersWidgetTypeConfig) =>
        service
          .dashboardOrderCo2HknVolume$(value.filter)
          .pipe(indicate(this.isLoading$))
      )
    );
  }
}

import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { HknIdentifier } from '../models/hkn-identifier';

const stateName = 'hknidentifier';

export interface HknIdentifierState extends BaseState<HknIdentifier> {}

function createInitialState(): HknIdentifierState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: stateName })
export class HknIdentifierStore extends BaseStore<HknIdentifier> {
  constructor() {
    super(createInitialState());
  }
}

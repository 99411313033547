import { ErrorResponse } from '@clean-code/shared/common';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export function checkError() {
  const stopSignal$ = new Subject<void>();

  return <T>(source: Observable<T>) => {
    return source.pipe(
      tap((response: any) => {
        response?.errors?.forEach((x: ErrorResponse) => {
          if (x.extensions.code === '400') {
            stopSignal$.next();
          }
        });
      }),
      takeUntil(stopSignal$)
    );
  };
}

import { Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { CompanyDropDown } from './company';

@Injectable({ providedIn: 'root' })
export class CompanyDropDownService {
  private url = this.configService.settings['graphql'];

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public getAllCompanies$(searchTerm: string): Observable<CompanyDropDown[]> {
    const query = `
    {
      allCompaniesForDropDown( where: {
        name: { contains: "${searchTerm}"}
        }) {
            id
            name
        }
    }
    `;

    return this.graphqlService.query<CompanyDropDown[]>(query, null, this.url);
  }
}

import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'bo-country-dropdown',
  standalone: true,
  templateUrl: './shared-bo-components-ui-country-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryDropdownComponent),
      multi: true,
    },
  ],
})
export class CountryDropdownComponent extends ControlValueAccessorConnector {
  public countries$ = this.enumService.getEnumType$('Country');

  constructor(private enumService: EnumService) {
    super();
  }
}

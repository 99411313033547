import { Component, Input } from '@angular/core';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { ActionButton } from '../action-button/action-button';

@Component({
  selector: 'cc-save-action-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['../action-button/action-button.scss'],
})
export class SaveButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'common.SAVE';

  @Input()
  public override icon = faSave;

  @Input()
  public override displayText = 'common.SAVE';
}

import { computed } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { withRouteParams } from '@clean-code/shared/util/util-state';
import { signalStore, withComputed, withState } from '@ngrx/signals';

type FormState = {
  id: ID;
  isLoading: boolean;
};

const initialState: FormState = {
  id: null,
  isLoading: false,
};

export const FormStore = signalStore(
  // withDevtools('LimitReportStore'),
  withState(initialState),
  withRouteParams({
    id: (param) => (isNaN(Number(param)) ? String(param) : Number(param)),
  }),
  withComputed(({ id }) => {
    return {
      isNew: computed(() => id + '' === 'new'),
    };
  })
);

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { HknType } from '../models/hkn-type';
import { HknTypeStore } from './hkn-type.store';

@Injectable({ providedIn: 'root' })
export class HknTypeQuery extends AkitaSearchQueryEntity<HknType> {
  constructor(protected store: HknTypeStore) {
    super(store);
  }
}

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { TerminLocalized } from './../entities/termin';
import { TerminWidgetStore } from './termin-widget.store';

@Injectable({ providedIn: 'root' })
export class TerminWidgetQuery extends AkitaSearchQueryEntity<TerminLocalized> {
  constructor(protected store: TerminWidgetStore) {
    super(store);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ForbiddenComponent } from './forbidden.component';

const routes: Route[] = [
  {
    path: '',
    component: ForbiddenComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), TranslocoModule],
  declarations: [ForbiddenComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'forbidden',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class ForbiddenModule {}

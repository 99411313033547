import { Component, Input } from '@angular/core';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { first } from 'rxjs/operators';
import { ActionButton } from '../action-button/action-button';

@Component({
  selector: 'cc-delete-action-button',
  templateUrl: '../action-button/action-button.html',
  styleUrls: ['../action-button/action-button.scss'],
  providers: [ConfirmService],
})
export class DeleteButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'common.DELETE';

  @Input()
  public override icon = faTrash;

  @Input()
  public override displayText = 'common.DELETE';

  @Input()
  customTitle: string;

  @Input()
  customMessage: string;

  constructor(private confirmService: ConfirmService) {
    super();
  }

  public override click(event?: any) {
    {
      this.confirmService
        .delete(this.customMessage, this.customTitle, this.icon)
        .pipe(first())
        .subscribe((result: boolean) => {
          if (result) {
            this.onClick.emit(event);
          }
        });
    }
  }
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { HknIdentifierQuery } from '../+state';

export const HKN_IDENTIFIER = new InjectionToken('HKN_IDENTIFIER', {
  providedIn: 'root',
  factory: () => {
    const hknIdentifierQuery = inject(HknIdentifierQuery);
    return new PaginatorPlugin(hknIdentifierQuery, { startWith: 0 });
  },
});

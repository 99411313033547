<mat-form-field class="search-input">
  <input
    matInput
    placeholder="{{ 'common.SEARCH' | transloco }}"
    [formControl]="formControl"
  />
  <button
    type="mat-icon"
    *ngIf="formControl.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="formControl.setValue(null)"
  >
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</mat-form-field>

import { CommonModule } from '@angular/common';
import { Component, inject, Renderer2, ViewChild } from '@angular/core';
import {
  CorrelationModel,
  CorrelationWidgetFacade,
} from '@backoffice-frontend/market/domain';
import { mapTimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { BaseChartWidget } from '@clean-code/shared/components/ui-dashboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import {
  DxPivotGridComponent,
  DxPivotGridModule,
} from 'devextreme-angular/ui/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { CorrelationWidgetTypeConfig } from '../models/widget-type-config';
import { CorrelationWidgetSettingsComponent } from '../widget-settings/widget-settings.component';

export enum CorrelationWidgetTypes {
  WidgetType = 'CorrelationWidget',
}
@Component({
  selector: 'correlation-widget',
  standalone: true,
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  imports: [
    CommonModule,
    DxChartModule,
    DxPivotGridModule,

    TranslocoModule,
    FontAwesomeModule,

    CorrelationWidgetSettingsComponent,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'correlationWidget',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class CorrelationWidgetComponent extends BaseChartWidget<CorrelationWidgetTypeConfig> {
  @ViewChild(DxPivotGridComponent, { static: false })
  pivotGrid: DxPivotGridComponent;

  public faTriangleExclamation = faTriangleExclamation;
  private renderer = inject(Renderer2);
  private translateService = inject(TranslocoService);
  private service = inject(CorrelationWidgetFacade);
  private translocoScope = inject(TRANSLOCO_SCOPE);

  //selectTranslate necessary or else the translations wouldn't be availabe on time
  public dataSource$ = combineLatest([
    this.translateService.selectTranslate('WEEKS', {}, this.translocoScope),
    this.config$,
  ]).pipe(
    map(([_, config]) => config), //remove translation from params
    filter((config: CorrelationWidgetTypeConfig) => !!config),
    mapTimeFrame(),
    switchMap((config: CorrelationWidgetTypeConfig) =>
      this.service.marketTableCorrelation$(
        config.dataSources.map((x) => x.settings.identifier),
        config.settings.timeFrame,
        config.settings.weeks,
        config.settings.months,
        config.settings.years
      )
    ),
    map((values: CorrelationModel[]) => {
      values = values.map((v) => {
        return {
          ...v,
          value: v.error ? v.error : v.value,
        };
      });
      const dataSourceDef = this.getDataSourceDef(values);

      return dataSourceDef;
    })
  );

  updateChart(): void {
    this.pivotGrid?.instance.repaint();
  }

  onCellPrepared(e: any) {
    if (e.area !== 'data') return;

    if (!isNaN(e.cell.value)) return;

    const tempDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(
      tempDiv,
      'title',
      this.translateService.translate<string>(
        'correlationWidget.DATA_NOT_SATISFIED'
      )
    );
    this.renderer.addClass(tempDiv, 'icon');

    const icon = `
    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="triangle-exclamation" class="svg-inline--fa fa-triangle-exclamation" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 512 512"><path fill="currentColor" 
    d="${this.faTriangleExclamation.icon[4]}">
    </path>
    </svg>`;

    tempDiv.innerHTML = icon;
    e.cellElement.appendChild(tempDiv);
  }

  private getDataSourceDef(store: unknown): PivotGridDataSource {
    return new PivotGridDataSource({
      store: store,
      fields: [
        {
          caption: 'Base',
          dataField: 'base',
          width: 150,
          area: 'row',
        },
        {
          dataField: 'comparison',
          area: 'column',
        },
        {
          dataField: 'type',
          area: 'column',
          // expanded: true,
          sortBy: 'none',
          customizeText: (options: any) => {
            return this.translateService.translate<string>(
              'correlationWidget.' + options.valueText
            );
          },
        },
        {
          caption: 'Total',
          dataType: 'number',
          // format: 'currency',
          dataField: 'value',
          area: 'data',
          summaryType: 'max',
          format: '##0.00##',
          // selector: function (data: any) {
          //   return data.value;
          // },
        },
      ],
    });
  }
}

import { Injectable } from '@angular/core';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private graphQlService: GraphqlService) {}

  sendContactMail$(input: Contact): Observable<boolean> {
    const mutation = `
      mutation ($input: ContactInput!){
        sendContactMail(input: $input)
        }`;

    const variables = {
      input,
    };

    return this.graphQlService
      .mutation<boolean>(mutation, variables, null, {
        ignoreNullCheckFilter: true,
      })
      .pipe(tap((result: boolean) => console.log(result)));
  }
}

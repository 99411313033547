import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private breakPoints = [
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];

  private watcher = this.breakpointObserver.observe(this.breakPoints).pipe(
    distinctUntilChanged(),
    debounceTime(100)
    // tap((value) => console.info(value))
  );

  private _lowerThanMedium = this.watcher.pipe(
    map(
      () =>
        this.breakpointObserver.isMatched(Breakpoints.Medium) ||
        this.breakpointObserver.isMatched(Breakpoints.Small) ||
        this.breakpointObserver.isMatched(Breakpoints.XSmall)
    )
  );

  private _lowerThanLarge = this.watcher.pipe(
    map(
      () =>
        this.breakpointObserver.isMatched(Breakpoints.Large) ||
        this.breakpointObserver.isMatched(Breakpoints.XLarge)
    )
  );

  activeBreakpoints: string[] = [];

  constructor(private breakpointObserver: BreakpointObserver) {}

  get isLowerThanMedium$(): Observable<boolean> {
    return this._lowerThanMedium.pipe(debounceTime(200));
  }

  get isLowerThanLarge$(): Observable<boolean> {
    return this._lowerThanLarge.pipe(debounceTime(200));
  }
}

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { HknIdentifier } from '../models/hkn-identifier';
import { HknIdentifierStore } from './hkn-identifier.store';

@Injectable({ providedIn: 'root' })
export class HknIdentifierQuery extends AkitaSearchQueryEntity<HknIdentifier> {
  constructor(protected store: HknIdentifierStore) {
    super(store);
  }
}

<div
  formContainerFormRow
  [formGroup]="formGroup"
>
  <ng-container
    *ngFor="let localization of localizations.controls"
    [formGroup]="localization"
  >
    <mat-form-field
      class="item"
    >
      <mat-label>{{
        (label | transloco) + ' ' + localization.get('cultureCode').value
        }}</mat-label>
      <input
        *ngIf="!longText"
        matInput
        formControlName="value"
      />
      <textarea
        *ngIf="longText"
        matInput
        placeholder="{{
          (label | transloco) + ' ' + localization.get('cultureCode').value
        }}"
        formControlName="value"
      ></textarea>
    </mat-form-field>
  </ng-container>
</div>

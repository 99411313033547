<mat-dialog-content class="h-full">
  <div
    *ngIf="selectActive$ | async; let active"
    class="w-full h-full bg-gray-100 border-b-2"
  >
    <div class="w-full flex items-center bg-primary-color h-8">
      <!-- Date -->

      <div class="w-1/3 text-white ml-2 font-light">
        {{ active.date | date: 'shortDate' }}
      </div>

      <!-- Title + move -->
      <div class="w-1/3 flex place-content-center items-center">
        <!-- Move left -->
        <div class="flex items-center cursor-pointer" (click)="previous()">
          <fa-icon
            class="text-white"
            size="2x"
            [icon]="faChevronLeft"
          ></fa-icon>
        </div>

        <!-- Title -->
        <div
          class="w-80 text-center text-white font-light text-xs md:text-sm lg:text-base xl:text-lg"
        >
          {{ active.title }}
        </div>

        <!-- Move right -->
        <div class="flex items-center cursor-pointer" (click)="next()">
          <fa-icon
            class="text-white"
            size="2x"
            [icon]="faChevronRight"
          ></fa-icon>
        </div>
      </div>

      <!-- Tools -->
      <div class="w-1/3 flex place-content-end h-full items-center">
        <!-- Zoom -->
        <div
          class="text-white h-full flex items-center bg-primary-color-600 w-14 place-content-center cursor-default"
        >
          {{ zoomPercentage }}%
        </div>
        <div class="ml-4 h-full">
          <div class="h-4 w-4 flex items-center">
            <button class="focus:outline-none" (click)="zoomIn()">
              <fa-icon class="text-white" [icon]="faChevronUp"></fa-icon>
            </button>
          </div>
          <div class="h-4 w-4 flex items-center">
            <button class="focus:outline-none" (click)="zoomOut()">
              <fa-icon class="text-white" [icon]="faChevronDown"></fa-icon>
            </button>
          </div>
        </div>

        <!-- Download -->
        <div class="ml-8 mr-8">
          <button (click)="download()">
            <fa-icon class="text-white" [icon]="faDownload"></fa-icon>
          </button>
        </div>

        <!-- Close -->
        <div
          mat-dialog-close
          class="cursor-pointer h-full w-8 flex items-center place-content-center"
        >
          <fa-icon size="2x" class="text-white" [icon]="faTimes"></fa-icon>
        </div>
      </div>
    </div>

    <!-- PDF viewer -->
    <pdf-viewer
      *ngIf="pdfSrc$ | async; let pdfSrc"
      class="w-full h-[calc(100%-40px)]"
      [src]="pdfSrc"
      [(page)]="pageNumber"
      [render-text]="true"
      [original-size]="true"
      [zoom]="pdfZoom"
    ></pdf-viewer>
  </div>
</mat-dialog-content>
<ng-template #loading
  ><cc-loading-indicator></cc-loading-indicator>
</ng-template>

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { OfferPower } from '../models/offer-power';
import { OfferPowerStore } from './offer-power.store';

@Injectable({ providedIn: 'root' })
export class OfferPowerQuery extends AkitaSearchQueryEntity<OfferPower> {
  constructor(protected store: OfferPowerStore) {
    super(store);
  }
}

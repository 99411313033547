import { Inject, Injectable } from '@angular/core';
import { AkitaSearchBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  ObjectHelper,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HknIdentifierStore } from '../+state/hkn-identifier.store';
import { HknIdentifier } from '../models/hkn-identifier';
import { HKN_IDENTIFIER } from './hkn-identifier-paginator';
@Injectable({
  providedIn: 'root',
})
export class HknIdentifierService
  extends AkitaSearchBaseService<HknIdentifier>
  implements EditSearchDataService<HknIdentifier>
{
  private url = this.configService.settings['api'] + 'orderpower/graphql';

  private entityFields = `
      id
      identifier
      supplierId
      supplier
      deliveryAreaId
      deliveryArea
      hknTypeId
      hknType
      year
    `;

  constructor(
    protected store: HknIdentifierStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    @Inject(HKN_IDENTIFIER) private paginatorRef: PaginatorPlugin<HknIdentifier>
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<HknIdentifier>> {
    const query = `
    query ($params: DataTableParametersType!){
      hknIdentifiers(params: $params){
          perPage
          currentPage
          total
          lastPage
          data {
            ${this.entityFields}
          }
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService.query<PaginationResponse<HknIdentifier>>(
      query,
      variables,
      this.url
    );
  }

  public search$(params: DataTableParameters): Observable<HknIdentifier[]> {
    const query = `
    query ($params: DataTableParametersType!){
      hknIdentifiersSearch(params: $params){

            ${this.entityFields}
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService.query<HknIdentifier[]>(
      query,
      variables,
      this.url
    );
  }

  public getById$(id: ID): Observable<HknIdentifier> {
    const query = `
    query($id: ID!) {
      hknIdentifier(id: $id) {
        ${this.entityFields}
      }
    }
    `;
    const variables = { id };
    return this.graphqlService
      .query<HknIdentifier>(query, variables, this.url)
      .pipe(tap((data) => this.store.upsert(data.id, data)));
  }

  public add$(hknIdentifier: HknIdentifier): Observable<ID> {
    const query = `
      mutation ($hknIdentifier: EnergyOrderHknIdentifierInsertType!)
      {
        insertHknIdentifier(hknIdentifier: $hknIdentifier) {
          ${this.entityFields}
        }
      }
    `;

    const clone = ObjectHelper.cloneObject(hknIdentifier);

    delete clone.id;

    const variables = { hknIdentifier: clone };

    return this.graphqlService.query<ID>(query, variables, this.url).pipe(
      tap((id: ID) => {
        this.store.upsert(id, hknIdentifier);
        this.paginatorRef.refreshCurrentPage();
      })
    );
  }

  public update$(hknIdentifier: HknIdentifier) {
    const query = `
      mutation ($hknIdentifier: EnergyOrderHknIdentifierUpdateType!)
      {
        updateHknIdentifier(hknIdentifier: $hknIdentifier) {
          ${this.entityFields}
        }
      }
    `;

    const clone = ObjectHelper.cloneObject(hknIdentifier);

    const variables = { hknIdentifier: clone };

    return this.graphqlService
      .query<HknIdentifier>(query, variables, this.url)
      .pipe(
        tap((newHknIdentifier) => {
          this.store.upsert(newHknIdentifier.id, newHknIdentifier);
          this.paginatorRef.refreshCurrentPage();
        })
      );
  }

  public delete$(id: ID) {
    const query = `
      mutation ($id: String!)
      {
        deleteHknIdentifier(id: $id)
      }
      `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url).pipe(
      tap((value) => {
        if (value) {
          this.store.remove(id);
          this.paginatorRef.refreshCurrentPage();
        }
      })
    );
  }
}

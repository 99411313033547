import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

// https://angular-academy.com/angular-jwt/
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // map((event: any) => {
      //   if (
      //     event instanceof HttpResponse &&
      //     event?.body?.errors.any(
      //       (x: any) => x.extensions.code === 'AUTH_NOT_AUTHENTICATED'
      //     )
      //   ) {
      //     event = event.clone({
      //       status: 401,
      //     });

      //     this.checkAuthStatus(event);
      //   }

      //   return event;
      // }),
      tap({
        next: (event: HttpEvent<any>) => {
          if (
            event instanceof HttpResponse &&
            event?.body?.errors?.any(
              (x: any) => x.extensions.code === 'AUTH_NOT_AUTHENTICATED'
            )
          ) {
            event = event.clone({
              status: 401,
            });
            this.checkAuthStatus(event);
          }
        },
      }),
      catchError((err) => {
        this.checkAuthStatus(err);

        return next.handle(request);
      })
    );
  }

  private checkAuthStatus(event: any) {
    if (event.status === 401 || event.status === 403) {
      // auto logout if 401 response returned from api

      this.router.navigate(['/forbidden'], { replaceUrl: true });
    }
  }
}

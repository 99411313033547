import {
  Component,
  HostBinding,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import {
  AuthQuery,
  AuthUser,
  IAuthService,
} from '@clean-code/shared/auth/util-auth';
import { faChevronDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cc-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent {
  @HostBinding('class') class = 'cc-user-menu';

  public langs$ = of(this.languageService.configuredLanguages).pipe(
    map(
      (
        l:
          | string[]
          | {
              id: string;
              label: string;
            }[]
      ) =>
        l.map(
          (x) =>
            x as {
              id: string;
              label: string;
            }
        )
    )
  );

  public faUser = faUser;
  public faChevronDown = faChevronDown;
  public fullName$ = this.authQuery.userData$.pipe(
    map((user: AuthUser) => user?.fullName)
  );

  showKam$ = this.authQuery.userData$.pipe(
    map((user: AuthUser) => user?.companies?.length > 0)
  );

  public showUserInfo = false;

  @Input()
  mobile = false;

  constructor(
    public languageService: LanguageService,
    private authQuery: AuthQuery,
    @Inject('IAuthService') private authService: IAuthService
  ) {}

  toggleUserInfo(): void {
    this.showUserInfo = !this.showUserInfo;
  }

  setLanguage(id: string | MatButtonToggleChange) {
    let lang: string;
    if (typeof id === 'string' || id instanceof String) {
      lang = id as string;
    } else {
      lang = id.value;
    }

    if (this.languageService.currentLocale !== lang) {
      this.languageService.setLanguage(lang);
    }
  }

  logOut() {
    this.authService.logout();
  }
}

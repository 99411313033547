import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  type OnDestroy,
  type OnInit,
  type QueryList,
  type TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatPaginator,
  MatPaginatorModule,
  type PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatColumnDef,
  MatTable,
  MatTableModule,
} from '@angular/material/table';
import type { PagingResponse } from '@clean-code/shared/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faListTree,
} from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { RxIf } from '@rx-angular/template/if';
import { Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { TableStateService } from '../services/table-state.service';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  standalone: true,
  selector: 'bo-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpanding', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  imports: [
    CommonModule,

    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,

    TranslocoModule,
    RxIf,
    FontAwesomeModule,
  ],
})
export class BoMatTableComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'bo-mat-table';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public isAllSelected = false;

  public closeSubject = new Subject<void>();
  public state$ = this.tableStateService.state$;
  public paginationState$ = this.tableStateService.paginationState$;
  private refreshTable = new Subject<void>();
  protected faChevronDown = faChevronDown;
  protected faChevronUp = faChevronUp;
  protected faListTree = faListTree;
  protected tableColumnsLength = 0;
  protected expandedElement: any;
  // protected columnsToDisplay: any[] = [];
  @Input() showPaginator = true;

  @Input() markDisabled = false;
  @Input() disabled = false;

  @Input() expandRowTemplateComponent: TemplateRef<any>;

  @Input()
  public set data(value: any) {
    if (value?.perPage && this.pageSize !== value.perPage) {
      this.pageSize = value.perPage;
    }

    // //search/sort does get the paginator out of sync as the state paginator is changed but not the mat-paginator
    // //also on re-navigating to the list the index is off
    // if (value?.currentPage !== this.paginator?.pageIndex) {
    //   this.paginator.pageIndex = value.currentPage;
    // }

    if (value?.items) {
      this.tableStateService.setData(value);
    } else if (value?.data) {
      const paging = {
        items: value.data,
        totalCount: value.total ?? value.totalCount,
        perPage: value.perPage,
      } as PagingResponse<any>;

      this.tableStateService.setData(paging);
    } else {
      const paging = { items: value ?? [] } as PagingResponse<any>;
      this.tableStateService.setData(paging);
    }
  }

  objHasChildren(index: any, row: any) {
    if (row.hasChildren) {
      return true;
    }
    return false;
  }

  public tableColumns$ = combineLatest([
    this.tableStateService.columnDef$,
    this.tableStateService.hiddenTableColumns$.pipe(
      startWith(new Array<string>())
    ),
    this.refreshTable.pipe(startWith(null)),
  ]).pipe(
    filter(
      ([columnDef, _h, _]: [MatColumnDef[], string[], any]) =>
        columnDef.length > 0 && !!this.table
    ),
    debounceTime(300),
    map(
      ([columnDef, hiddenTableColumns, _]: [MatColumnDef[], string[], any]) => {
        const tableColumns = new Array<string>();
        if (this.multi) {
          tableColumns.push('select');
        }

        if (this.isExpandable) {
          tableColumns.push('expand');
        }

        // biome-ignore lint/complexity/noForEach: <explanation>
        columnDef.forEach((columnDef: MatColumnDef) => {
          if (
            hiddenTableColumns.length === 0 ||
            hiddenTableColumns.indexOf(columnDef.name) === -1
          ) {
            tableColumns.push(columnDef.name);
            this.table.addColumnDef(columnDef);
          }
        });
        // tap((item) => {
        //   if (item !== 'expand') {
        //     this.columnsToDisplay.push(item);
        //   }
        // });
        this.tableColumnsLength = tableColumns.length;
        return tableColumns;
      }
    ),
    shareReplay(1)
  );

  @Input()
  public set hiddenTableColumns(value: string[]) {
    this.tableStateService.updateHiddenTableColumns(value);
  }

  @Input()
  multi = false;

  @Input() isExpandable = false;

  public pageSize = 50;

  private _table: MatTable<any>;
  @ViewChild(MatTable, { static: false })
  set table(table: MatTable<any>) {
    if (table) {
      this._table = table;
      this.refreshTable.next();
    }
  }

  get table() {
    return this._table;
  }

  @ContentChildren(MatColumnDef) set columnDefs(
    value: QueryList<MatColumnDef>
  ) {
    this.tableStateService.updateColumnDefColumns(value.toArray());
  }

  constructor(private tableStateService: TableStateService) {}

  ngOnInit(): void {
    this.tableStateService.loadDataFromRouting$
      .pipe(takeUntil(this.closeSubject))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
    this.tableStateService.reset();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  public select(item: any) {
    if (this.disabled) {
      return;
    }

    if (!this.multi) {
      //item is null e.g. on route back from preview
      if (item) {
        this.tableStateService.select(item);
      } else {
        this.tableStateService.clear(); //todo: toggle instead of clear?
      }
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected
      ? this.tableStateService.clear()
      : this.tableStateService
          .getData()
          .items.forEach((row: any) => this.tableStateService.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected ? 'select' : 'deselect'} all`;
    }
    return `${
      this.tableStateService.isSelected(row) ? 'deselect' : 'select'
    } row ${row.position + 1}`;
  }

  public isSelected(row: any) {
    return this.tableStateService.isSelected(row);
  }

  public toggle(value: any) {
    this.tableStateService.toggle(value);
  }

  public changePage(event: PageEvent) {
    this.tableStateService.setPaging(event.pageIndex);
  }
}

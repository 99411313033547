import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { NewsTranslated } from './../entities/news';
import { NewsWidgetStore } from './news-widget.store';

@Injectable({ providedIn: 'root' })
export class NewsWidgetQuery extends AkitaSearchQueryEntity<NewsTranslated> {
  constructor(protected store: NewsWidgetStore) {
    super(store);
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { MarketHknPositionsWidgetTypeConfig } from '../market-hkn-positions';
@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-positions-config',
  templateUrl: './market-hkn-positions-config.component.html',
  styleUrls: ['./market-hkn-positions-config.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
    ProgressBarComponent,
  ],
})
export class MarketHknPositionsConfigComponent extends BaseWidgetTypeConfigComponent<MarketHknPositionsWidgetTypeConfig> {
  form = new UntypedFormGroup({
    filter: new UntypedFormControl(null, [Validators.required]),
  });

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }
}

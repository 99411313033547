import { Inject, Injectable } from '@angular/core';
import { AkitaSearchBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { Area } from '@clean-code/backoffice/area/util-api';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginatorPlugin } from '@datorama/akita';
import { Observable } from 'rxjs';
import { HknTypeStore } from '../+state/hkn-type.store';
import { HknType } from '../models/hkn-type';
import { Supplier } from '../models/supplier';
import { HKN_TYPE } from './hkn-type-paginator';
@Injectable({
  providedIn: 'root',
})
export class HknTypeService
  extends AkitaSearchBaseService<HknType>
  implements EditSearchDataService<HknType>
{
  private url = this.configService.settings['api'] + 'surcharge/graphql';
  private orderUrl = this.configService.settings['api'] + 'orderpower/graphql';

  private entityFields = `
  id
  name
  deliveryAreas
  {
    id
    hknTypeId
    areaDeliveryAreaId
  }
  companySegments
  {
    id
    hknTypeId
    companySegmentId
  }
  suppliers
  {
    id
    hknTypeId
    supplierId
  }
  `;

  private readonly entityFieldsGetAll = `
    id
    name
    deliveryAreas {
        id
        hknTypeId
        areaDeliveryAreaId
        areaDeliveryArea {
            name
        }
    }
    companySegments {
        id
        hknTypeId
        companySegmentId
        companySegment {
            name
        }
    }
    suppliers {
        id
        hknTypeId
        supplierId
        supplier {
            name
        }
    }
    selectedDeliveryAreas
    selectedCompanySegments
    selectedSuppliers
  `;

  constructor(
    protected store: HknTypeStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    @Inject(HKN_TYPE) private paginatorRef: PaginatorPlugin<HknType>
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PagingResponse<HknType>> {
    return this.graphqlService.getAll<HknType>(
      params,
      'hknTypeGetAll',
      this.entityFieldsGetAll
    );
    // const query = `
    // query ($params: DataTableParametersType!){
    //   hknTypes(params: $params){
    //       perPage
    //       currentPage
    //       total
    //       lastPage
    //       data {
    //         ${this.entityFields}
    //         selectedDeliveryAreas
    //         selectedCompanySegments
    //         selectedSuppliers
    //       }
    //     }
    //   }
    // `;
    // const variables = { params: params };

    // return this.graphqlService.query<PaginationResponse<HknType>>(
    //   query,
    //   variables,
    //   this.url
    // );
  }

  public search$(params: DataTableParameters): Observable<HknType[]> {
    const query = `
    query ($params: DataTableParametersType!){
      hknTypesSearch(params: $params){

            ${this.entityFields}
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService.query<HknType[]>(query, variables, this.url);
  }

  public searchByContractId$(contractId: ID): Observable<HknType[]> {
    const query = `
    query($contractId: ID!) {
      hknTypesByContractId(contractId: $contractId) {
        id
        name
      }
    }
    `;
    const variables = { contractId };
    return this.graphqlService.query<HknType[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  public getById$(id: ID): Observable<HknType> {
    return this.graphqlService.getById<HknType>(
      id,
      'hknTypeGetById',
      this.entityFields
    );
    // const query = `
    // query($id: ID!) {
    //   hknType(id: $id) {
    //     ${this.entityFields}
    //   }
    // }
    // `;
    // const variables = { id };
    // return this.graphqlService
    //   .query<HknType>(query, variables, this.url, { redirectTo404: true })
    //   .pipe(tap((data) => this.store.upsert(data.id, data)));
  }

  public getSuppliersByHknTypeId$(hknTypeId: string): Observable<Supplier[]> {
    const query = `
    query($hknTypeId: ID!) {
      suppliersByHknTypeId(hknTypeId: $hknTypeId) {
        id
        name
      }
    }
    `;
    const variables = { hknTypeId };
    return this.graphqlService.query<Supplier[]>(query, variables, this.url);
  }

  public getDeliveryAreasByHknTypeId$(hknTypeId: string): Observable<Area[]> {
    const query = `
    query($hknTypeId: ID!) {
      deliveryAreasByHknTypeId(hknTypeId: $hknTypeId) {
        id
        name
      }
    }
    `;
    const variables = { hknTypeId };
    return this.graphqlService.query<Area[]>(query, variables, this.url);
  }

  public add$(hknType: HknType): Observable<boolean> {
    const input = {
      id: hknType.id,
      name: hknType.name,
      deliveryAreas: hknType.deliveryAreas,
      companySegments: hknType.companySegments,
      suppliers: hknType.suppliers,
    };

    const mutation = `
        mutation ($input: HknTypeAddUpdateInput!){
          addHknType(input: $input)
        }`;

    return this.graphqlService.query<boolean>(mutation, { input });

    // const query = `
    //   mutation ($hknType: HknTypeInsertType!)
    //   {
    //     insertHknType(hknType: $hknType) {
    //       ${this.entityFields}
    //     }
    //   }
    // `;

    // let clone = ObjectHelper.cloneObject(hknType);
    // // clone = this.transform(clone);

    // delete clone.id;

    // return this.graphqlService
    //   .query<ID>(query, { hknType: clone }, this.url)
    //   .pipe(
    //     tap((id: ID) => {
    //       hknType.id = id;
    //       this.upsertAndRefresh(hknType);
    //       this.paginatorRef.refreshCurrentPage();
    //     })
    //   );
  }

  public update$(hknType: HknType) {
    const input = {
      id: hknType.id,
      name: hknType.name,
      deliveryAreas: hknType.deliveryAreas,
      companySegments: hknType.companySegments,
      suppliers: hknType.suppliers,
    };

    const mutation = `
        mutation ($input: HknTypeAddUpdateInput!){
          updateHknType(input: $input)
        }`;

    return this.graphqlService.query<boolean>(mutation, { input });
    // const query = `
    //   mutation ($hknType: HknTypeUpdateType!)
    //   {
    //     updateHknType(hknType: $hknType) {
    //       ${this.entityFields}
    //     }
    //   }
    // `;

    // let clone = ObjectHelper.cloneObject(hknType);
    // // clone = this.transform(clone);

    // return this.graphqlService
    //   .query<HknType>(query, { hknType: clone }, this.url)
    //   .pipe(
    //     tap((newHknType) => {
    //       this.upsertAndRefresh(newHknType);
    //       this.paginatorRef.refreshCurrentPage();
    //     })
    //   );
  }

  public delete$(id: string) {
    const mutation = `
        mutation ($id: Int!){
          deleteHknType(id: $id)
          }`;

    return this.graphqlService.mutation<boolean>(mutation, { id });
    // const query = `
    //   mutation ($id: String!)
    //   {
    //     deleteHknType(id: $id)
    //   }
    //   `;
    // const variables = { id };

    // return this.graphqlService.query<boolean>(query, variables, this.url).pipe(
    //   tap((value) => {
    //     if (value) {
    //       this.store.remove(id);
    //       this.invalidateCache();
    //       this.paginatorRef.refreshCurrentPage();
    //     }
    //   })
    // );
  }
  // private transform(product: HknType): HknType {
  //   return {
  //     ...product,
  //     deliveryAreas: product.deliveryAreas.map((x: any) => {
  //       return {
  //         hknTypeId: product.id,
  //         areaDeliveryAreaId: x,
  //       } as HknTypeAreaDelivery;
  //     }),
  //     companySegments: product.companySegments.map((x: any) => {
  //       return {
  //         hknTypeId: product.id,
  //         companySegmentId: x,
  //       } as HknTypeCompanySegment;
  //     }),
  //     suppliers: product.suppliers.map((x: any) => {
  //       return {
  //         hknTypeId: product.id,
  //         supplierId: x,
  //       } as HknTypeSupplier;
  //     }),
  //   };
  // }

  public getAllPossibleHknTypesByContractId$(id: ID): Observable<HknType[]> {
    const query = `
    query($id: Int!) {
      allPossibleHknTypesByContractId(id: $id) {
        id
        name 
      }
    }
    `;
    const variables = { id };
    return this.graphqlService.query<HknType[]>(query, variables);
  }
}

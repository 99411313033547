import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { TimeSeriesIdentifierParserValue } from '../entities/time-series-analysis-identifier';

export interface TimeSeriesIdentifierParserValueState
  extends BaseState<TimeSeriesIdentifierParserValue> {}

function createInitialState(): TimeSeriesIdentifierParserValueState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'TimeSeriesIdentifierParserValue' })
export class TimeSeriesIdentifierParserValueStore extends BaseStore<TimeSeriesIdentifierParserValue> {
  constructor() {
    super(createInitialState());
  }
}

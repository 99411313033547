import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { AnalysisLocalized } from './../entities/analysis';
import { AnalysisWidgetStore } from './analysis-widget.store';

@Injectable({ providedIn: 'root' })
export class AnalysisWidgetQuery extends AkitaSearchQueryEntity<AnalysisLocalized> {
  constructor(protected store: AnalysisWidgetStore) {
    super(store);
  }
}

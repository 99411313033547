import { Inject, Injectable } from '@angular/core';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { AkitaSearchEditBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { EnumValue } from '@clean-code/shared/bo/util-enum';
import {
  DataTableParameters,
  ID,
  ObjectHelper,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { COMPANY_PAGINATOR } from '../+state/company-paginator';
import { CompanyQuery } from '../+state/company.query';
import { CompanyStore } from '../+state/company.store';
import { Company, CompanyCreate, CompanyPreview } from '../models/company';
import { Person } from '../models/person';

@Injectable({ providedIn: 'root' })
export class CompanyService
  extends AkitaSearchEditBaseService<Company>
  implements EditSearchDataService<Company>
{
  // private legacyUrl = this.configService.settings['legacy_api'] + 'Ng';
  private url = this.configService.settings['api'] + 'contacts/graphql';

  constructor(
    protected store: CompanyStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private companyQuery: CompanyQuery,
    private enumService: EnumService,
    // private httpClient: HttpClient,
    @Inject(COMPANY_PAGINATOR) private paginatorRef: PaginatorPlugin<Company>
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<Company>> {
    const query = `
      query ($params: DataTableParametersType!){
        companies(params: $params) {
          perPage
          currentPage
          total
          lastPage
          data {
            id
            name
            segment {
              name
            }
            disabled
            personId
            superId
            partnerIdPower
            partnerIdGas
            keyAccountManagersPower
            keyAccountManagersGas
            hasChildren
          }
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService
      .query<PaginationResponse<Company>>(query, variables, this.url)
      .pipe(
        map((value) => {
          this.store.upsertMany(value.data);
          return value;
        })
      );
  }

  public getAllCompanies$(): Observable<Company[]> {
    const query = `
      query {
        all_companies {
          id
          name
          segmentId
          segment {
            name
          }
          disabled
          personId
          superId
          partnerIdPower
          partnerIdGas
        }
      }
    `;

    return this.graphqlService.query<Company[]>(query, null, this.url);
  }

  public getAllCompaniesForDropDown$(): Observable<Company[]> {
    const query = `
      query {
        allCompaniesForDropDown (order: [{ name: ASC }]) {
          id
          name
          disabled
        }
      }
    `;

    return this.graphqlService.query<Company[]>(query, null);
  }

  public getCompanyByEnergySourceUser$(): Observable<Company[]> {
    const query = `
      query {
        companyByEnergySourceUser {
          id
          name
          segmentId
          segment {
            name
          }
        }
      }
    `;

    return this.graphqlService.query<Company[]>(query, null);
  }

  public getAllPowerKeyAccountManager$(
    searchString: string
  ): Observable<Person[]> {
    const query = `
    query($searchString: String)  {
      powerKeyaccountManager(searchString: $searchString) {
        id,
        fullName
      }
    }
  `;

    const variables = { searchString: searchString };
    return this.graphqlService.query<Person[]>(query, variables);
  }

  public getAllGasKeyAccountManager$(
    searchString: string
  ): Observable<Person[]> {
    const query = `
    query($searchString: String) {
      gasKeyaccountManager(searchString: $searchString) {
        id,
        fullName
      }
    }
  `;

    const variables = { searchString: searchString };
    return this.graphqlService.query<Person[]>(query, variables);
  }

  public getAllCompanyParentsWithSuperId$(
    companyId: ID
  ): Observable<Company[]> {
    const query = `
      query($id: ID!) {
        company_parents_with_super_id(id: $id) {
          id,
          name
        }
      }
    `;

    const variables = { id: companyId };
    return this.graphqlService.query<Company[]>(query, variables, this.url);
  }

  public getParentId$(companyId: ID): Observable<Company> {
    const query = `
      query($id: ID!) {
        company_parent_id(id: $id) {
          id
        }
      }
    `;

    const variables = { id: companyId };
    return this.graphqlService.query<Company>(query, variables, this.url);
  }

  public getCompanySubsidiaries$(
    companyId: ID,
    searchTerm: string
  ): Observable<Company[]> {
    const query = `
      query($id: ID!, $searchTerm: String) {
        company_subsidiaries(id: $id, searchTerm: $searchTerm) {
          id
          name
          segment
          {
            name
          }
          segmentId
          disabled
          language
          customerType
          personId
          superId
          partnerIdPower
          partnerIdGas
          keyAccountManagersPower
          keyAccountManagersGas
          markedBySubsidiarySearch
        }
      }
    `;

    const variables = { id: companyId, searchTerm: searchTerm };
    return this.graphqlService.query<Company[]>(query, variables, this.url);
  }

  public hasSubsidiaries$(companyId: ID): Observable<boolean> {
    const query = `
      query($id: ID!) {
        has_subsidiaries(id: $id)
      }
    `;

    const variables = { id: companyId };
    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public getById$(id: ID): Observable<Company> {
    const query = `
      query($id: ID!) {
        company(id: $id) {
          id
          name
          segmentId
          segment{
            name
          }
          disabled
          language
          customerType
          keyAccountManagerPowerIds
          keyAccountManagerGasIds
          personId
          superId
          partnerIdPower
          partnerIdGas
          hasChildren
          parentId
        }
      }
    `;
    const variables = { id: id };

    return this.graphqlService
      .query<Company>(query, variables, this.url, { redirectTo404: true })
      .pipe(
        tap((company) => {
          this.store.upsert(id, company);
        })
      );
  }

  public getPreviewById$(id: ID): Observable<CompanyPreview> {
    const query = `
      query($id: String!) {
        company_preview(id: $id) {
          id
          name
          segment {
            name
          },
          disabled
          language
          customerType
          personId
          superId
          partnerIdPower
          partnerIdGas
        }
      }
    `;
    const variables = { id: id };

    return this.graphqlService.query<CompanyPreview>(
      query,
      variables,
      this.url,
      { redirectTo404: true }
    );
  }

  public add$(company: Company): Observable<Company> {
    const query = `
      mutation ($company: CustomerInputType!) {
        createCompany(company: $company) {
          customerType
          disabled
          id
          keyAccountManagerPowerIds
          keyAccountManagerGasIds
          language
          name
          segmentId
          personId
          superId
          partnerIdPower
          partnerIdGas
        }
      }
    `;

    const companyCreate: CompanyCreate =
      ObjectHelper.cloneObject<CompanyCreate>(company);
    delete companyCreate['id'];

    const variables = { company: companyCreate };

    return this.graphqlService.query<Company>(query, variables, this.url).pipe(
      tap((newCompany: Company) => {
        this.upsertAndRefresh(newCompany);
        this.paginatorRef.refreshCurrentPage();

        // TODO: do not remove this!!! (needed in future)
        // const legacyCompany = this.getLegacyCompany(newCompany);
        // this.httpClient.post<LegacyCompany>(`${this.legacyUrl}/AddCustomer`, legacyCompany).subscribe();
      })
    );
  }

  public update$(company: Company): Observable<Company> {
    const query = `
      mutation ($company: CustomerUpdateInputType!) {
        updateCompany(company: $company) {
            customerType
            disabled
            id
            keyAccountManagerPowerIds
            keyAccountManagerGasIds
            language,
            name,
            segmentId,
            personId,
            superId,
            partnerIdPower,
            partnerIdGas
        }
      }
    `;
    const variables = { company: company };

    return this.graphqlService.query<Company>(query, variables, this.url).pipe(
      tap((newCompany) => {
        this.upsertAndRefresh(newCompany);
        this.paginatorRef.refreshCurrentPage();

        // TODO: do not remove this!!! (needed in future)
        // const legacyCompany = this.getLegacyCompany(newCompany);
        // this.httpClient.put<LegacyCompany>(`${this.legacyUrl}/UpdateCustomer`, legacyCompany).subscribe();
      })
    );
  }

  // TODO: do not remove this!!! (needed in future)
  // private getLegacyCompany(newCompany: Company): LegacyCompany {
  //   return {
  //     id: newCompany.id,
  //     segmentId: newCompany.segmentId,
  //     deactivated: newCompany.disabled,
  //     designation: newCompany.name,
  //     keyAccountManagerId: newCompany.keyAccountManagerPowerId,
  //     language: newCompany.language,
  //     keyAccountManagerEmail: newCompany.keyAccountManagerEmail
  //   } as LegacyCompany;
  // }

  public getCompanyRelations$(): Observable<EnumValue[]> {
    return this.enumService.getEnumType$('CustomerType');
  }

  public setActive(companyId: string) {
    if (companyId) {
      this.store.setActive(companyId);
    } else {
      const id = this.companyQuery.getActiveId();
      this.store.removeActive(id);
    }
  }

  public get selectActive$(): Observable<Company> {
    return this.companyQuery.selectActive().pipe(
      distinctUntilChanged(
        (prev, curr) => !!prev && !!curr && prev.id === curr.id
      ),
      debounceTime(100)
    );
  }

  delete$(_id?: ID): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public allCompaniesWithOfferPowerContractsByTimeSeriesIdentifierId$(
    customerPoolId: ID
  ): Observable<Company[]> {
    const query = `
      query($customerPoolId: Int!) {
        allCompaniesWithOfferPowerContractsByCustomerPoolId 
            (order: [{ name: ASC }], customerPoolId: $customerPoolId) {
          id
          name
          disabled
        }
      }
    `;

    const variables = { customerPoolId: +customerPoolId };

    return this.graphqlService.query<Company[]>(query, variables);
  }

  public allCompaniesWithOfferGasContractsByTimeSeriesIdentifierId$(
    customerPoolId: ID
  ): Observable<Company[]> {
    const query = `
    query($customerPoolId: Int!) {
      allCompaniesWithOfferGasContractsByCustomerPoolId 
          (order: [{ name: ASC }], customerPoolId: $customerPoolId) {
        id
        name
        disabled
      }
    }
  `;

    const variables = { customerPoolId: +customerPoolId };

    return this.graphqlService.query<Company[]>(query, variables);
  }
}

import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public print(
    name: string,
    printContainer: ElementRef,
    titleHtml?: string
  ): void {
    const tempTitle = document.title;
    document.title = name;

    let w = { ...window };
    const head = w.document.querySelector('head');

    let doc = document.implementation.createHTMLDocument('print');
    doc.head.innerHTML = head.innerHTML;
    var cssElement = document.getElementsByTagName('link')[0].cloneNode();

    doc.head.appendChild(cssElement);

    doc.body = document.createElement('body');
    doc.body.innerHTML = printContainer.nativeElement.innerHTML;
    doc.body.classList.add('mx-4');

    // create PDF logo line
    const PDFLogoLine = document.createElement('div');
    PDFLogoLine.classList.add(
      'flex',
      'flex-row',
      'justify-between',
      'items-center',
      'p-1'
    );
    let PDFLogoLineLogo = '';
    if (titleHtml) {
      PDFLogoLineLogo += titleHtml;
    }

    PDFLogoLineLogo += `<div></div>`;
    PDFLogoLineLogo += `<h2 class="text-3xl text-gray-50 font-bold"></h2>`;
    PDFLogoLineLogo += `<img alt="ENERGIE STEIERMARK" class="w-20 h-20 block" src="assets/img/e_steiermark_logo.png"/>`;
    PDFLogoLine.innerHTML = PDFLogoLineLogo;
    doc.body.prepend(PDFLogoLine);

    var mywindow = window.open('', 'PRINT', '');

    cssElement.addEventListener('load', function () {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    });

    mywindow.document.write(doc.documentElement.outerHTML);

    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 1000);
    document.title = tempTitle;
  }
}

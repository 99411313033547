import { Directive, HostBinding, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseEntity,
  DataTableParameters,
  FilterParam,
  ID,
  indicate,
  MediaQueryService,
  ObjectHelper,
  PagingResponse,
  SortBy,
} from '@clean-code/shared/common';
import { TableStateService } from '@clean-code/shared/components/ui-mat-table';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { PaginationResponse } from '@datorama/akita';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { doPaging } from './rxjs-operator/doPaging';
@Directive()
export abstract class DataTableBaseComponent<T extends BaseEntity>
  implements OnDestroy
{
  @HostBinding('class.dataTableList') dataTableList = true;

  public selectedItems = new Array<T>();
  public selected: T;
  public sortType = SortType;
  public selectionType = SelectionType;

  public idKeyName = 'id';
  public id$: Observable<ID>;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  protected navigateUrl: string;
  protected preview = true;

  search$ = new Subject<string>();

  protected toastService: ToastService;
  protected router: Router;
  protected mediaQueryService: MediaQueryService;
  protected activatedRoute: ActivatedRoute;
  protected tableStateService: TableStateService;

  protected set defaultSort(value: SortBy[]) {
    this.tableStateService.setSort(value);

    this.updateSort();
  }

  protected set defaultSort2(value: SortBy[]) {
    this.tableStateService.setSort2(value);
    this.updateSort();
  }

  protected set addFilterParams(value: FilterParam[]) {
    this.tableStateService.upsertFilters(value);
  }

  protected resetFilterParams() {
    this.tableStateService.resetFilters();
  }

  protected closeSubject = new Subject<void>();
  isMobile: boolean;

  public paging$: Observable<PaginationResponse<T> | PagingResponse<T> | T[]>;

  constructor(protected searchService: EditSearchDataService<T>) {
    this.toastService = inject(ToastService);
    this.router = inject(Router);
    this.mediaQueryService = inject(MediaQueryService);
    this.activatedRoute = inject(ActivatedRoute);
    this.tableStateService = inject(TableStateService);

    this.tableStateService.singleSelect$
      .pipe(
        tap((selected: any) => {
          this.onSelect(selected);
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.mediaQueryService.isLowerThanMedium$
      .pipe(
        tap((isMobile) => (this.isMobile = isMobile)),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.id$ = this.tableStateService.selectedId$;
    this.updateSort();

    this.paging$ = doPaging<T>(
      this.tableStateService.sort$,
      this.tableStateService.filters$,
      this.tableStateService.pageIndex$,
      this.tableStateService.searchTerm$,
      this.tableStateService.refreshTrigger$,
      (params: DataTableParameters) =>
        this.getAll(params).pipe(
          debounceTime(300),
          indicate(this.isLoading$),
          takeUntil(this.closeSubject)
        )
    ).pipe(debounceTime(300));
  }

  protected getAll(
    params: DataTableParameters
  ): Observable<DataServiceReturn<T>> {
    return this.searchService.getAll$(params);
  }

  ngOnDestroy(): void {
    this.tableStateService.resetSelection();
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  public onSelect(selectedParam: any) {
    if (selectedParam) {
      this.selected = selectedParam;
      if (this.preview) {
        this.navigatePreview(selectedParam);
      } else if (this.isMobile) {
        this.router.navigate([this.navigateUrl, selectedParam.id]);
      }
    }
  }

  private navigatePreview(selected: any) {
    if (selected) {
      if (this.preview && this.navigateUrl) {
        this.router.navigate([this.navigateUrl, 'preview', selected.id]);
      } else if (this.preview && !this.navigateUrl) {
        this.router.navigate(['preview', selected.id], {
          relativeTo: this.activatedRoute,
        });
      }
    }
  }

  public onSort(event: any) {
    if (event.sorts) {
      if (event.sorts.length > 0) {
        const sorts = ObjectHelper.cloneObject(event.sorts) as any[]; //type SortBy[]
        sorts.forEach(
          (sortEntry) => (sortEntry.dir = sortEntry.dir.toLocaleUpperCase())
        );
        this.tableStateService.setSort(sorts);
      } else {
        this.tableStateService.setDefaultSort();
      }
    } else {
      if (event.active && event.direction) {
        const sort = {
          prop: event.active,
          dir: event.direction.toLocaleUpperCase(),
        } as SortBy;
        this.tableStateService.setSort([sort]);
      } else {
        this.tableStateService.setDefaultSort();
      }
    }
  }

  public add() {
    if (this.preview && this.navigateUrl) {
      this.router.navigate([this.navigateUrl, 'preview', 'new']);
    } else if (this.navigateUrl) {
      this.router.navigate([this.navigateUrl, 'new']);
    } else if (this.preview && !this.navigateUrl) {
      this.router.navigate(['preview', 'new'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['new'], { relativeTo: this.activatedRoute });
    }
  }

  public edit(id?: ID) {
    id = id ?? this.selected.id;
    this.router.navigate([this.navigateUrl, id]);
  }

  public delete() {
    this.searchService
      .delete$(this.selected.id)
      .pipe(indicate(this.isLoading$), takeUntil(this.closeSubject))
      .subscribe(() => {
        this.selected = undefined;
        this.toastService.showSuccess('common.SUCCESSFULLY_DELETED');
        this.tableStateService?.refresh();
        if (this.navigateUrl) {
          this.router.navigate([this.navigateUrl]);
        } else {
          this.router.navigate(['./'], { relativeTo: this.activatedRoute });
        }
      });
  }

  private updateSort(): void {
    // this.tableStateService.setSort();
    // this.sort$.next(
    //   this.paginatorRef.metadata.get('sortBy') || this._defaultSort
    // );
  }

  setPage(event: any) {
    this.tableStateService.setPaging(event.pageIndex);
  }
}

import { CommonModule, KeyValue } from '@angular/common';
import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { ID, indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MarketSegmentsContractVolumeContentService } from '../services/market-segments-contract-volume.service';
import { MarketSegmentsContractVolumeWidgetTypeConfig } from './../market-segments-contract-volume';
@Component({
  standalone: true,
  selector: 'market-segments-contract-volume-content',
  templateUrl: './market-segments-contract-volume-content.component.html',
  styleUrls: ['./market-segments-contract-volume-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,

    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
    ProgressBarComponent,
  ],
})
export class MarketSegmentsContractVolumeContentComponent extends BaseWidgetTypeContentComponent<MarketSegmentsContractVolumeWidgetTypeConfig> {
  public data$: Observable<
    KeyValue<string, { volume: number; closed: number; companyId: ID }>[]
  >;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public constructor(injector: Injector) {
    super();

    const service = injector.get(MarketSegmentsContractVolumeContentService);

    this.data$ = this.configObservable.pipe(
      filter((value: MarketSegmentsContractVolumeWidgetTypeConfig) => !!value),
      switchMap((value: MarketSegmentsContractVolumeWidgetTypeConfig) =>
        service
          .dashboardSegmentContractVolume$(value.filter)
          .pipe(indicate(this.isLoading$))
      )
    );
  }
}

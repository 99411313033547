import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { CompanyQuery } from './company.query';

export const COMPANY_PAGINATOR = new InjectionToken('COMPANY_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const companyQuery = inject(CompanyQuery);
    return new PaginatorPlugin(companyQuery, { startWith: 0 });
  },
});

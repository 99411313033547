<cc-form-container [isLoading]="isLoading$ | async">

  <cc-save-action-button form-buttons (onClick)="submit()" class="button-align"></cc-save-action-button>

  <form
    [formGroup]="formGroup"
    form-content
    [patchFormGroupWithEmitValues]="value$ | async"
  >
    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{ 'hkn-identifier.YEAR' | transloco }}</mat-label>
        <input
          formControlName="year"
          matInput
          type="number"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'hkn-identifier.IDENTIFIER' | transloco }}</mat-label>
        <input
          formControlName="identifier"
          matInput
        />
      </mat-form-field>
    </div>
    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{ 'hkn-identifier.HKN_TYPE' | transloco }}</mat-label>
        <mat-select formControlName="hknTypeId">
          <mat-option
            *ngFor="let item of hknTypes$ | async"
            value="{{ item.id }}"
          >{{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{ 'hkn-identifier.SUPPLIER' | transloco }}</mat-label>
        <mat-select formControlName="supplierId">
          <mat-option
            *ngFor="let item of suppliers$ | async"
            value="{{ item.id }}"
          >{{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'hkn-identifier.DELIVERY_AREA' | transloco }}</mat-label>
        <mat-select formControlName="deliveryAreaId">
          <mat-option
            *ngFor="let item of deliveryAreas$ | async"
            value="{{ item.id }}"
          >{{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</cc-form-container>

<page-container>
  <ng-container title>{{ 'team.TEAM' | transloco }}</ng-container>
  <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      <div
        class="w-full bg-white dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-center"
      >
        <div>
          <img
            class="object-center object-cover h-auto w-full"
            src="assets/img/team/bauersabine.jpg"
            alt="DI Sabine Bauer"
          />
        </div>
        <div class="text-center h-full py-8 sm:py-6">
          <p class="text-xl lg:text-lg xl:text-xl font-bold">DI Sabine Bauer</p>
          <p class="text-base font-normal">
            {{ 'team.focus.POWERMARKET' | transloco }},
            {{ 'team.focus.EMISSIONS' | transloco }}
          </p>
        </div>
      </div>
      <div
        class="w-full bg-white dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-center"
      >
        <div>
          <img
            class="object-center object-cover h-auto w-full"
            src="assets/img/team/bulzluciandaniel.jpg"
            alt="Ing. Lucian-Daniel Bulz"
          />
        </div>
        <div class="text-center h-full py-8 sm:py-6">
          <p class="text-xl lg:text-lg xl:text-xl font-bold">
            Ing. Lucian-Daniel Bulz
          </p>
          <p class="text-base font-normal">
            {{ 'team.focus.TECHNICALSUPPORT' | transloco }}
          </p>
        </div>
      </div>
      <div
        class="w-full bg-white dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-center"
      >
        <div>
          <img
            class="object-center object-cover h-auto w-full"
            src="assets/img/team/goerkestefan.jpg"
            alt="Mag. Stefan Goerke, CIIA"
          />
        </div>
        <div class="text-center h-full py-8 sm:py-6">
          <p class="text-xl lg:text-lg xl:text-xl font-bold">
            Mag. Stefan Goerke, CIIA
          </p>
          <p class="text-base font-normal">
            {{ 'team.focus.POWERMARKET' | transloco }},
            {{ 'team.focus.EURO_DOLLAR' | transloco }},
            {{ 'team.focus.GLOBALECONOMY' | transloco }}
          </p>
        </div>
      </div>
      <div
        class="w-full bg-white dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-center"
      >
        <div>
          <img
            class="object-center object-cover h-auto w-full"
            src="assets/img/team/sattingerroland.jpg"
            alt="DI(FH) Roland Sattinger, MBA"
          />
        </div>
        <div class="text-center h-full py-8 sm:py-6">
          <p class="text-xl lg:text-lg xl:text-xl font-bold">
            DI(FH) Roland Sattinger, MBA
          </p>
          <p class="text-base font-normal">
            {{ 'team.focus.OIL' | transloco }},
            {{ 'team.focus.GAS' | transloco }},
            {{ 'team.focus.LNG' | transloco }},
            {{ 'team.focus.ASIAN' | transloco }}
            {{ 'team.focus.GEOPOLITICS' | transloco }}
          </p>
        </div>
      </div>
      <div
        class="w-full bg-white dark:bg-layout-dark-sidenav text-layout-page-container-text dark:text-layout-dark-page-container-text rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-center"
      >
        <div>
          <img
            class="object-center object-cover h-auto w-full"
            src="assets/img/team/schrottnermartin.jpg"
            alt="Mag. Martin Schrottner"
          />
        </div>
        <div class="text-center h-full py-8 sm:py-6">
          <p class="text-xl lg:text-lg xl:text-xl font-bold">
            Mag. Martin Schrottner
          </p>
          <p class="text-base font-normal">
            {{ 'team.focus.COAL' | transloco }},
            {{ 'team.focus.EURO_DOLLAR' | transloco }},
            {{ 'team.focus.GLOBALECONOMY' | transloco }}
          </p>
        </div>
      </div>
    </div>
  </section>
</page-container>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import {
  TerminLocalized,
  TerminWidgetData,
  TerminWidgetDay,
  TerminWidgetFacade,
  TerminWidgetMonth,
  TerminWidgetQuery,
  TerminWidgetStore,
} from '@backoffice-frontend/news/domain';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TerminWidgetDialogComponent } from './components/termin-widget-dialog/termin-widget-dialog.component';

@Component({
  selector: 'news-termin-widget',
  templateUrl: './termin-widget.component.html',
  styleUrls: ['./termin-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TerminWidgetComponent {
  @HostBinding('class') class = 'news-termin-widget';

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public months: TerminWidgetMonth[];
  public events: TerminLocalized[];
  public selectedMonth: TerminWidgetMonth;
  public selectedMonthIndex: number;
  public selectedDayItem: TerminWidgetDay;

  constructor(
    private languageService: LanguageService,
    private widgetFacade: TerminWidgetFacade,
    private store: TerminWidgetStore,
    public query: TerminWidgetQuery,
    private confirmService: ConfirmService
  ) {
    this.languageService.languageChangesWithDefault$
      .pipe(
        switchMap(() =>
          this.widgetFacade.getAll$().pipe(indicate(this.isLoading$))
        ),
        tap((values: TerminWidgetData) => {
          this.events = values.events;
          this.months = values.months;

          this.selectedMonthIndex = 0;
          this.selectedMonth = values.months[this.selectedMonthIndex];

          this.store.reset();
          this.store.upsertMany(values.events);
        })
      )
      .subscribe();
  }

  public select(value: TerminLocalized) {
    this.store.setActive(value.id);
    this.confirmService
      .open(value.location, '', TerminWidgetDialogComponent, null)
      .subscribe();
  }

  public openPreviousMonth() {
    this.selectedMonthIndex--;
    this.selectedMonth = this.months[this.selectedMonthIndex];
    this.selectedDayItem = null;
  }

  public openNextMonth() {
    this.selectedMonthIndex++;
    this.selectedMonth = this.months[this.selectedMonthIndex];
    this.selectedDayItem = null;
  }

  public dayItemClick(dayItem: TerminWidgetDay) {
    if (this.selectedDayItem && this.selectedDayItem.date === dayItem.date) {
      this.selectedDayItem = null;
      return;
    }

    this.selectedDayItem = dayItem;
  }
}

import { Injectable } from '@angular/core';
import { cache } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { lastValueFrom, Observable, ReplaySubject, tap } from 'rxjs';
import { ApplicationTenant } from './application-tenant';

const withAsync = async (fn: any) => {
  try {
    const response = await fn();
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  public readonly entityFields = `
  tenant
`;
  private tenant$ = new ReplaySubject<Map<string, ApplicationTenant>>(1);
  constructor(private graphqlService: GraphqlService) {}

  getAll$(): Observable<ApplicationTenant[]> {
    const query = `
      query {
        tenantGetAll {
          tenant
        }
      }`;

    return this.graphqlService.query<ApplicationTenant[]>(query, null).pipe(
      tap((values: ApplicationTenant[]) => {
        const map = new Map<string, ApplicationTenant>();

        values.forEach((value: ApplicationTenant) =>
          map.set(value.tenant, value)
        );
        // this._availableTenants = map;
        this.tenant$.next(map);
      }),
      cache()
    );
  }

  public validateTenant(key: string): void {
    lastValueFrom(this.tenant$).then((availableTenants) => {
      const tenant = availableTenants.get(key);

      if (!tenant) {
        throw new Error('Tenant ' + key + ' not supported');
      }
    });
  }
}

<div [formGroup]="formGroup" class="flex flex-col">
  <bo-energy-source-dropdown
    *ngIf="showEnergySource"
    [required]="true"
    formControlName="energySource"
  ></bo-energy-source-dropdown>

  <area-dropdown
    type="market"
    [energySource]="energySource$ | async"
    placeholder="{{ 'time-series-identifier.MARKET_AREA' | transloco }}"
    formControlName="marketAreaId"
    [useIntAsId]="true"
    [clearable]="true"
  ></area-dropdown>

  <area-dropdown
    type="delivery"
    [energySource]="energySource$ | async"
    placeholder="{{ 'load-profile.fields.DELIVERY_AREA' | transloco }}"
    formControlName="deliveryAreaId"
    [useIntAsId]="true"
    [clearable]="true"
  ></area-dropdown>

  <bo-kam-dropdown
    formControlName="kamIds"
    [energySource]="energySource$ | async"
  ></bo-kam-dropdown>

  <mat-form-field>
    <mat-label>{{ 'company.segment.NAME' | transloco }}</mat-label>

    <mat-select formControlName="segmentId">
      <mat-option [value]="null" selected>{{
        'common.NO_SELECTION' | transloco
      }}</mat-option>
      <mat-option
        *ngFor="let option of segments$ | async"
        [value]="option.id"
        >{{ option.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'master-data.YEARS' | transloco }}</mat-label>
    <mat-select formControlName="years" multiple>
      <mat-option *ngFor="let year of years" [value]="year">{{
        year
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <bo-companies-dropdown formControlName="companyIds"></bo-companies-dropdown>
  <!-- <input
    type="text"
    class="date-range"
    ngxDaterangepickerMd
    formControlName="selectedRange"
    [alwaysShowCalendars]="true"
    drops="up"
    opens="right"
    [showDropdowns]="true"
    [locale]="locale"
    [showClearButton]="true"
    placeholder="{{ 'dashboard.CREATE_DATE_RANGE' | transloco }}"
  /> -->

  <ui-date-range formGroupName="selectedRange"></ui-date-range>
</div>

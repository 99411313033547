import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    const base = this.http.get(`/assets/i18n/${lang}.json`);

    // if (scope) {
    //   return base;
    // }

    return forkJoin([
      base,
      this.http.get(`/assets/i18n/${lang}.vendor.json`),
    ]).pipe(
      map(([translation, vendor]) => {
        return { ...translation, ...vendor };
      })
    );
  }
}

import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MarketCustomerAnalysisWidgetTypeConfig } from '../market-customer-analysis-widget.models';
import { MarketCustomerAnalysisWidgetService } from '../services/market-customer-analysis-widget.service';
import { IMarketCustomerAnalysisWidgetContent } from './market-customer-analysis-widget-content.models';

@Component({
  standalone: true,
  selector: 'dashboard-market-customer-analysis-widget-content',
  templateUrl: './market-customer-analysis-widget-content.component.html',
  styleUrls: ['./market-customer-analysis-widget-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,

    ReactiveFormsModule,
    TranslocoModule,

    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
    ProgressBarComponent,
  ],
})
export class MarketCustomerAnalysisWidgetContentComponent extends BaseWidgetTypeContentComponent<MarketCustomerAnalysisWidgetTypeConfig> {
  @HostBinding('class') class =
    'dashboard-market-customer-analysis-widget-content';
  public data$: Observable<IMarketCustomerAnalysisWidgetContent>;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public constructor(injector: Injector) {
    super();

    const service = injector.get(MarketCustomerAnalysisWidgetService);

    this.data$ = this.configObservable.pipe(
      filter(
        (value: MarketCustomerAnalysisWidgetTypeConfig) => !!value?.companyId
      ),
      switchMap((value: MarketCustomerAnalysisWidgetTypeConfig) =>
        service
          .dashboardCustomerOverviewById$(value)
          .pipe(indicate(this.isLoading$))
      )
    );
  }
}

<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<div *ngIf="selectedMonth">
  <div class="relative grid grid-cols-1 gap-x-14 md:grid-cols-1">
    <button
      *ngIf="selectedMonthIndex > 0"
      type="button"
      class="absolute -top-1 -left-0 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
      (click)="openPreviousMonth()"
    >
      <span class="sr-only"></span>
      <svg
        class="h-10 w-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <button
      *ngIf="selectedMonthIndex < 11"
      type="button"
      class="absolute -top-1 -right-0 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
      (click)="openNextMonth()"
    >
      <span class="sr-only"></span>
      <svg
        class="h-10 w-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <section class="text-center p-2">
      <h2 class="title">
        {{ 'info.termin.months.' + selectedMonth.month | transloco }}
      </h2>
      <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>{{ 'info.termin.short-weekday.MONDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.TUESDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.WEDNESDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.THURSDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.FRIDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.SATURDAY' | transloco }}</div>
        <div>{{ 'info.termin.short-weekday.SUNDAY' | transloco }}</div>
      </div>
      <div
        class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
      >
        <ng-container *ngFor="let dayItem of selectedMonth.days; let i = index">
          <!-- First week of visible month in calendar -->
          <button
            *ngIf="dayItem.dayOfWeek === 1 && dayItem.isInFirstWeek"
            type="button"
            [ngClass]="
              dayItem.day > 10
                ? 'relative rounded-tl-lg bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10'
                : 'relative rounded-tl-lg bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
            "
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>

          <button
            *ngIf="
              dayItem.dayOfWeek >= 2 &&
              dayItem.dayOfWeek <= 6 &&
              dayItem.isInFirstWeek
            "
            type="button"
            [ngClass]="
              dayItem.day > 10
                ? 'relative bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10'
                : 'relative bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
            "
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>
          <button
            *ngIf="dayItem.dayOfWeek === 0 && dayItem.isInFirstWeek"
            type="button"
            class="relative rounded-tr-lg bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10"
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>

          <!-- In-Between-weeks of visible month in calendar -->
          <button
            *ngIf="!dayItem.isInFirstWeek && !dayItem.isInLastWeek"
            type="button"
            class="relative bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10"
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>

          <!-- Last-Between-weeks of visible month in calendar -->
          <button
            *ngIf="dayItem.dayOfWeek === 1 && dayItem.isInLastWeek"
            type="button"
            [ngClass]="
              dayItem.day < 10
                ? 'relative rounded-bl-lg bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10'
                : 'relative rounded-bl-lg bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
            "
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>

          <button
            *ngIf="
              dayItem.dayOfWeek >= 2 &&
              dayItem.dayOfWeek <= 6 &&
              dayItem.isInLastWeek
            "
            type="button"
            [ngClass]="
              dayItem.day < 10
                ? 'relative bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10'
                : 'relative bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
            "
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>

          <button
            *ngIf="dayItem.dayOfWeek === 0 && dayItem.isInLastWeek"
            type="button"
            [ngClass]="
              dayItem.day < 10
                ? 'relative rounded-br-lg bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10'
                : 'relative rounded-br-lg bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
            "
            (click)="dayItemClick(dayItem)"
          >
            <ng-container
              *ngTemplateOutlet="
                calendarCellTemplate;
                context: { dayItem: dayItem }
              "
            >
            </ng-container>
          </button>
        </ng-container>
      </div>
    </section>
  </div>
  <section *ngIf="selectedDayItem" class="mt-12 p-2">
    <h2 class="title">
      {{ selectedDayItem.date | date : 'shortDate' }}
    </h2>
    <ol class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
      <ng-container *ngIf="selectedDayItem.events">
        <ng-container *ngFor="let event of selectedDayItem.events">
          <li
            class="py-4 sm:flex text-layout-page-container-text dark:text-layout-dark-page-container-text"
          >
            <p *ngIf="event.internal" class="mt-2 flex-auto font-light sm:mt-0">
              {{ event.description + ' (' + event.location + ') - ' }}
              {{ 'info.termin.INTERNAL_DATE' | transloco }}
            </p>
            <p
              *ngIf="!event.internal"
              class="mt-2 flex-auto font-light sm:mt-0"
            >
              {{ event.description + ' (' + event.location + ')' }}
            </p>
            <ng-container
              *ngIf="event.start.toString() === event.end.toString()"
            >
              <p class="flex-none font-light sm:ml-6">
                {{ event.start | date : 'shortDate' }}
              </p>
            </ng-container>
            <ng-container
              *ngIf="event.start.toString() !== event.end.toString()"
            >
              <p class="flex-none font-light sm:ml-6">
                {{ event.start | date : 'shortDate' }} -
                {{ event.end | date : 'shortDate' }}
              </p>
            </ng-container>
          </li>
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="!selectedDayItem.events || selectedDayItem.events.length <= 0"
      >
        <li
          class="py-4 sm:flex text-layout-page-container-text dark:text-layout-dark-page-container-text"
        >
          <p class="mt-2 flex-auto font-light sm:mt-0">
            {{ 'info.termin.NO_EVENT_AVAILABLE' | transloco }}
          </p>
        </li>
      </ng-container>
    </ol>
  </section>
  <section class="mt-12 p-2">
    <h2 class="title">
      {{ 'info.termin.UPCOMING_EVENTS' | transloco }}
    </h2>
    <ol class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
      <ng-container *ngFor="let event of events">
        <li
          class="py-4 sm:flex text-layout-page-container-text dark:text-layout-dark-page-container-text"
        >
          <p *ngIf="event.internal" class="mt-2 flex-auto font-light sm:mt-0">
            {{ event.description + ' (' + event.location + ') - ' }}
            {{ 'info.termin.INTERNAL_DATE' | transloco }}
          </p>
          <p *ngIf="!event.internal" class="mt-2 flex-auto font-light sm:mt-0">
            {{ event.description + ' (' + event.location + ')' }}
          </p>
          <ng-container *ngIf="event.start.toString() === event.end.toString()">
            <p class="flex-none font-light sm:ml-6">
              {{ event.start | date : 'shortDate' }}
            </p>
          </ng-container>
          <ng-container *ngIf="event.start.toString() !== event.end.toString()">
            <p class="flex-none font-light sm:ml-6">
              {{ event.start | date : 'shortDate' }} -
              {{ event.end | date : 'shortDate' }}
            </p>
          </ng-container>
        </li>
      </ng-container>
    </ol>
  </section>
</div>

<ng-template #calendarCellTemplate let-dayItem="dayItem">
  <div class="flex felx-row">
    <div
      class="mx-auto flex flex-column h-7 w-3 items-center justify-center rounded-full"
    ></div>
    <div
      class="mx-auto flex flex-column h-7 w-7 items-center justify-center rounded-full"
      [ngClass]="[
        dayItem.isToday ? 'bg-primary-color-500 font-semibold text-white' : '',
        selectedDayItem && selectedDayItem.date === dayItem.date
          ? 'border-2 border-secondary-color-800'
          : ''
      ]"
    >
      {{ dayItem.day }}
    </div>
    <div
      class="mx-auto flex-column h-7 w-3 items-center justify-center rounded-full"
    >
      <span
        *ngIf="dayItem.isEvent"
        class="flex h-1.5 w-1.5 rounded-full bg-gray-400"
      ></span>
      <span
        *ngIf="dayItem.isInternalEvent"
        class="flex h-1.5 w-1.5 rounded-full bg-primary-color-400"
      ></span>
    </div>
  </div>
</ng-template>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  TimeSeriesInput,
  TimeSeriesMarketWidgetInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { BaseDialog } from '@clean-code/shared/components/ui-confirm';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { MarketDetailsComponent } from '@clean-code/time-series-identifier/ui-details';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
@Component({
  standalone: true,
  templateUrl: './market-overview-widget-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatDialogModule,

    FontAwesomeModule,

    ProgressBarComponent,
    MarketDetailsComponent,
  ],
})
export class MarketOverviewWidgetDialogComponent extends BaseDialog {
  public faTimes = faTimes;
  public datasource: TimeSeriesInput;
  public longName: string;
  constructor(
    dialogRef: MatDialogRef<BaseDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: TimeSeriesMarketWidgetInput
  ) {
    super(dialogRef);

    this.datasource = data.timeSeriesInput;
    this.longName = data.longName;
  }
}

import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface NavigationState {
  key: string;
  value: string;
}

export function createInitialState(): NavigationState {
  return {
    key: '',
    value: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'navigation' })
export class NavigationStore extends Store<NavigationState> {
  constructor() {
    super(createInitialState());
  }
}

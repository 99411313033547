import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'cc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class ProgressBarComponent {
  @HostBinding('class') class = 'cc-progress-bar';
  @Input()
  isLoading = false;
}

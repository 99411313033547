import { Injectable } from '@angular/core';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Dayjs } from 'dayjs';
import { SpotTimeSeriesData } from '../models/spot-time-series-data';
import { SpotType } from '../models/spot-type';

@Injectable({
  providedIn: 'root',
})
export class SettingSpotDataService {
  constructor(private graphqlService: GraphqlService) {}

  getSpotTimeSeriesDatas(
    spotConfigurationId: number,
    spotType: SpotType,
    date?: Dayjs
  ) {
    const query = `
      query ($spotConfigurationId: Int!, $spotType: SpotType!, $date: Date) {
        spotTimeSeriesDatas(spotConfigurationId: $spotConfigurationId, spotType: $spotType, date: $date) {
        identifier
        timeStamp
        unit
        value
        }
      }
    `;

    const variables = { spotConfigurationId, spotType, date };
    return this.graphqlService.query<SpotTimeSeriesData[]>(query, variables);
  }
}

<div
  class="border-b border-gray-200 mb-2 text-xs flex items-center justify-between"
>
  <div class="flex-1 min-w-0">
    <h1 class="text-sm sm:text-lg font-medium text-primary-color truncate">
      {{ (selectActive$ | async)?.name }}
    </h1>
  </div>
  <div class="mt-4 flex sm:mt-0 sm:ml-4">
    <fa-icon
      size="2x"
      [icon]="faTimes"
      class="cursor-pointer"
      (click)="previous()"
      mat-dialog-close
    ></fa-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography h-full">
  <div
    *ngIf="(selectActive$ | async)?.imageUrl; let imageUrl"
    class="news-logo-wrapper"
  >
    <img src="{{ imageUrl }}" />
  </div>
  <div
    class="h-full"
    [innerHtml]="(selectActive$ | async)?.content | safeHtml"
  ></div>
</mat-dialog-content>

<div
  class="flex h-8 mt-2 mx-auto place-content-center items-center rounded-full w-56 relative"
>
  <div
    (click)="previous()"
    class="flex h-6 items-center left-0 place-content-center w-6 cursor-pointer"
  >
    <fa-icon [icon]="faChevronLeft"></fa-icon>
  </div>
  <div class="flex w-28 bg-primary-color rounded-full place-content-center">
    <div class="mr-1 text-white font-light">
      {{ activeIndex }}
    </div>
    <div class="text-white font-light">
      {{ 'info.news.ITEMOF' | transloco }} {{ lastIndex }}
    </div>
  </div>

  <div
    (click)="next()"
    class="flex h-6 items-center right-0 place-content-center w-6 cursor-pointer"
  >
    <fa-icon [icon]="faChevronRight"></fa-icon>
  </div>
</div>

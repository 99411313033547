import { Directive, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilterParam } from '@clean-code/shared/common';
import { TableStateService } from '@clean-code/shared/components/ui-mat-table';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseTableFilterLegacy implements OnInit, OnDestroy {
  public searchControl = new UntypedFormControl();
  public filterControl: UntypedFormGroup;
  public faTimes = faTimes;

  @Input() public dateRangeSearchTerm = false;

  @Input()
  public searchTermProperties = ['name'];
  protected closeSubject = new Subject<void>();
  protected service = inject(TableStateService);

  ngOnInit() {
    this.searchControl.patchValue(this.service.searchTerm);

    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.closeSubject))
      .subscribe((term) => {
        this.service.updateSearchTerm(term);
      });

    if (this.filterControl) {
      this.filterControl.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          takeUntil(this.closeSubject)
        )
        .subscribe((value: Array<FilterParam>) => this.updateFilter(value));
    }

    this.updateFilterParams(this.filterControl.value);
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  protected updateFilter(filter: any) {
    this.updateFilterParams(filter);
    this.service.updateSearchTerm(this.searchControl.value);
    this.service.refresh();
  }

  private updateFilterParams(filter: any) {
    const currentParams = Object.keys(filter).map(
      (value: string) =>
        ({ key: value, value: filter[value]?.toString() } as FilterParam)
    );

    this.service.upsertFilters(
      currentParams.filter(
        (x: FilterParam) => x.value !== null && x.value !== undefined
      )
    );
  }
}

<style>
  .splash-container {
    z-index: 99999;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .splash-loader:before {
    content: '';
    border: 1px solid darkgray;
    border-radius: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
  .splash-loader .splash-loaderBar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #82c728;
    width: 0;
    animation: borealisBar 1s linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    10% {
      left: 0%;
      right: 75%;
      width: 25%;
    }
    90% {
      right: 0%;
      left: 75%;
      width: 25%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }
</style>
<div *ngIf="(showSplash$ | async) === true" class="splash-container w-64 h-60">
  <img class="splash-logo" src="assets/img/logo_estmk.svg" />
  <div
    class="splash-loader mt-4 rounded-lg border-solid border-transparent border-4 relative p-1"
  >
    <div class="splash-loaderBar"></div>
  </div>
</div>
<router-outlet></router-outlet>

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { EnergySourceDropdownComponent } from '@clean-code/shared/bo/components/ui-energy-source-dropdown';
import { EnumService } from '@clean-code/shared/bo/util-enum';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { CountryDropdownComponent } from 'shared/bo/components/ui-country-dropdown';
import { SettingSpotConfiguration } from '../../models/setting-spot-configuration';
import { SettingSpotConfigurationService } from './../../services/setting-spot-configuration.service';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'backoffice-frontend-setting-spot-configuration-details',
  templateUrl: './setting-spot-configuration-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CanDeactivateForm,
      useExisting: forwardRef(() => SettingSpotConfigurationDetailsComponent),
    },
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatTooltipModule,
    MatCheckboxModule,
    PageContainerModule,
    FormContainerModule,
    ActionButtonModule,
    PipesModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgSelectModule,
    EnergySourceDropdownComponent,
    VarDirective,
    CountryDropdownComponent,
  ],
})
export class SettingSpotConfigurationDetailsComponent extends CanDeactivateForm<SettingSpotConfiguration> {
  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    country: new UntypedFormControl(null, [Validators.required]),
    energySource: new UntypedFormControl(null, [Validators.required]),
    stockMarket: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private enumService: EnumService,
    settingSpotConfigurationService: SettingSpotConfigurationService
  ) {
    super(settingSpotConfigurationService);
  }
}

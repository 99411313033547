<div class="dashboard-add-component">
  <!-- <div class="dialog-title">
    <div mat-dialog-title>{{ 'DASHBOARDPANEL.DIALOG-TITLE.ADD' | transloco }}</div>
  </div>   -->

  <div class="h-full" mat-dialog-content>
    <mat-horizontal-stepper class="h-full" [linear]="false" #stepper>
      <mat-step [stepControl]="editForm">
        <ng-template matStepLabel>{{
          'DASHBOARDPANEL.DIALOG-TITLE.CREATE' | transloco
        }}</ng-template>

        <ng-template matStepContent>
          <dashboard-dashboard-edit
            #editChild
            [dashboard]="dashboard"
          ></dashboard-dashboard-edit>
        </ng-template>
      </mat-step>
      <mat-step [stepControl]="referenceForm">
        <ng-template matStepLabel>{{
          'DASHBOARDPANEL.DIALOG-TITLE.REFERENCE' | transloco
        }}</ng-template>

        <ng-template matStepContent>
          <dashboard-dashboard-reference
            #referenceChild
            (callback)="onAddedResult($event)"
          >
          </dashboard-dashboard-reference>
        </ng-template>
      </mat-step>
    </mat-horizontal-stepper>
  </div>

  <div mat-dialog-actions class="flex flex-row justify-end">
    <cc-cancel-action-button (onClick)="close()"></cc-cancel-action-button>
    <cc-save-action-button (onClick)="save()"></cc-save-action-button>
  </div>
</div>

import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { translocoProviders } from '@backoffice-frontend/shared/util-transloco';
import { AppModule } from './app.module';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(AppModule),
    provideHttpClient(),
    // provideServiceWorker('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
    // provideRouter(
    //   appRoutes,
    //   withComponentInputBinding(),
    //   withRouterConfig({ paramsInheritanceStrategy: 'always' })
    // ),
    translocoProviders,
  ],
};

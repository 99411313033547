import { CommonModule } from '@angular/common';
import { Component, HostBinding, Inject, Input } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NotificationCenterComponent } from '@clean-code/notifications/feature-notification-center';
import {
  AuthQuery,
  AuthUser,
  IAuthService,
} from '@clean-code/shared/auth/util-auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-user-menu',
  templateUrl: './user-menu.component.html',

  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonToggleModule,

    FontAwesomeModule,
    TranslocoModule,
    NotificationCenterComponent,
  ],
  // encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent {
  @HostBinding('class') class = 'cc-user-menu';

  public faUser = faUser;
  public faChevronDown = faChevronDown;
  public fullName$ = this.authQuery.userData$.pipe(
    map((user: AuthUser) => user?.fullName)
  );

  showKam$ = this.authQuery.userData$.pipe(
    map((user: AuthUser) => user?.companies?.length > 0)
  );

  public showUserInfo = false;

  @Input()
  mobile = false;

  constructor(
    private authQuery: AuthQuery,
    @Inject('IAuthService') private authService: IAuthService
  ) {}

  toggleUserInfo(): void {
    this.showUserInfo = !this.showUserInfo;
  }

  logOut() {
    this.authService.logout();
  }
}

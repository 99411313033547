import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  TimeSeriesAnalysisIdentifier,
  TimeSeriesAnalysisIdentifierFacade,
  TimeSeriesIdentifierInput,
  TimeSeriesIdentifierInputForm,
} from '@backoffice-frontend/time-series-identifier/domain';
import { ID, ObjectHelper } from '@clean-code/shared/common';
import { FormGroupAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { PaginationResponse } from '@datorama/akita';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@rx-angular/template/let';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  tap,
} from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'time-series-dynamic-selection',
  templateUrl: './time-series-dynamic-selection.component.html',
  styleUrls: ['./time-series-dynamic-selection.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    MatAutocompleteModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatSlideToggleModule,

    LetDirective,

    TranslocoModule,
  ],
})
export class TimeSeriesDynamicSelectionComponent
  extends FormGroupAccessorConnector<TimeSeriesIdentifierInputForm>
  implements OnInit
{
  public selectionControl = new UntypedFormControl();
  public selected$ = new BehaviorSubject<TimeSeriesAnalysisIdentifier | null>(
    null
  );

  private identifierList = new Array<TimeSeriesAnalysisIdentifier>();
  public timeSeriesAnalysisIdentifiers$ = this.timeSeriesFacade
    .getAllForSelection$({ perPage: 999, page: 0 })
    .pipe(
      tap((values: PaginationResponse<TimeSeriesAnalysisIdentifier>) => {
        this.identifierList = values.data;
        this.setFormControl(this.formGroup.value);

        const item = this.findItem(this.formGroup?.value?.identifierId);
        this.selected$.next(item);
      })
    );

  public filtered$ = combineLatest([
    this.selectionControl.valueChanges.pipe(
      startWith(<string>null),
      debounceTime(200)
    ),
    this.timeSeriesAnalysisIdentifiers$,
  ]).pipe(
    map(
      ([searchTerm, items]: [
        string,
        PaginationResponse<TimeSeriesAnalysisIdentifier>
      ]) => this.filter(searchTerm, items.data)
    )
  );

  public isContinuously$: Observable<boolean>;
  public selection$: Observable<TimeSeriesAnalysisIdentifier>;

  constructor(private timeSeriesFacade: TimeSeriesAnalysisIdentifierFacade) {
    super();
    this.selectionControl.setValidators([Validators.required]);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.formGroup.get('adjustment').addValidators([adjustmentValidator]);
    this.setIsContinuously();
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    const item = this.findItem(event.option.value.id);

    //do NOT use onlySelf here or formGroup will not validate and therefore will not get the change!
    this.formGroup.patchValue({
      identifierId: item.id,
    });

    this.selected$.next(item);
  }

  private findItem(id: ID): TimeSeriesAnalysisIdentifier {
    if (!id) {
      return null;
    }

    return this.identifierList.find(
      (x: TimeSeriesAnalysisIdentifier) => x.id === id
    );
  }

  public displayFn(value: TimeSeriesAnalysisIdentifier): string {
    return value && value.id ? value.identifier + ' | ' + value.name : '';
  }

  private filter(
    searchTerm: string | unknown,
    items: TimeSeriesAnalysisIdentifier[]
  ): TimeSeriesAnalysisIdentifier[] {
    if (ObjectHelper.isObject(searchTerm)) {
      return items;
    }

    const filterValue = searchTerm?.toString().toLowerCase();

    if (!filterValue) {
      //if input is cleared patch null
      //but attention -> startwith(null)
      if (this.selectionControl.dirty) {
        this.formGroup.patchValue({ identifierId: null });
      }
      return items;
    }

    return items.filter(
      (option: TimeSeriesAnalysisIdentifier) =>
        option.identifier.toLowerCase().indexOf(filterValue) > -1 ||
        option.name?.toLowerCase()?.indexOf(filterValue) > -1
    );
  }

  private setFormControl(selected: TimeSeriesIdentifierInput) {
    const localized = this.identifierList.find(
      (x) => x.id === selected.identifierId
    );

    if (localized) {
      this.selectionControl.patchValue(localized);
    }
  }

  private setIsContinuously() {
    const adjustmentControl = this.formGroup.get('adjustment');
    const continuouslyControl = this.formGroup.get('continuously');

    const adjustment$ = adjustmentControl.valueChanges.pipe(
      startWith(adjustmentControl.value),
      map((value: string) => value === '#'),
      distinctUntilChanged()
    );

    const continuously$ = continuouslyControl.valueChanges.pipe(
      startWith(continuouslyControl.value),
      distinctUntilChanged()
    );

    this.isContinuously$ = combineLatest([adjustment$, continuously$]).pipe(
      map(([adjustment, continuously]) => adjustment || continuously),
      tap((value: boolean) => {
        if (value) {
          this.formGroup.get('adjustment').setValue(0);
          this.formGroup.get('continuously').setValue(true);
        }
      })
    );
  }
}

export function adjustmentValidator(control: AbstractControl) {
  if (control.value === '#') {
    return null;
  }

  const number = +control.value;

  if (number >= -2 && number <= 7) {
    return null;
  }

  return { adjustmentValid: false };
}

import {
  BaseEntity,
  DataTableParameters,
  FilterParam,
  ObjectHelper,
  SortBy,
} from '@clean-code/shared/common';
import {
  combineLatest,
  debounceTime,
  from,
  map,
  Observable,
  startWith,
  switchMap,
} from 'rxjs';

export function doPaging<T extends BaseEntity>(
  sort$: Observable<SortBy[]>,
  filters$: Observable<FilterParam[]>,
  pageIndex$: Observable<number>,
  search$: Observable<string>,
  refreshTrigger$: Observable<boolean>,
  requestFn: (params: DataTableParameters) => Observable<any>
): Observable<any> {
  return combineLatest([
    sort$,
    filters$,
    pageIndex$,
    search$.pipe(startWith(null)),
    refreshTrigger$.pipe(startWith(true)),
  ]).pipe(
    debounceTime(200),
    switchMap(
      ([sortBy, filters, pageIndex, searchTerm, _refreshTrigger]: [
        SortBy[],
        FilterParam[],
        number,
        string,
        boolean
      ]) => {
        const req = () =>
          requestFn({ page: pageIndex, sortBy, filters, searchTerm });
        return from(req());
      }
    ),
    map((response) => {
      const resp = ObjectHelper.cloneObject(response);
      return resp;
    })
  );
}

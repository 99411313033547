<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<table
  mat-table
  [dataSource]="data$ | async"
  class="table-layout bg-transparent"
>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'info.analysis.TITLE' | transloco }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      class="!text-primary-color !dark:text-primary-color w-full"
    >
      {{ element.title }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'common.DATE' | transloco }}
    </th>
    <td mat-cell *matCellDef="let element" class="text-xs italic">
      {{ element.date | translocoDate: { dateStyle: 'medium' } }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    class="hover:bg-gray-300 cursor-pointer"
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="select(row)"
  ></tr>
</table>

<div class="flex flex-col w-full h-full">
  <cc-progress-bar *ngIf="isLoading()"></cc-progress-bar>
  <div
    class="flex flex-row justify-between"
    *ngIf="dashboard(); let dashboard; else: empty"
  >
    <div class="pl-2 pt-2 title">
      {{ dashboard.name.localizations[0].value }}
    </div>
    <div page-buttons class="flex flex-row" *ngIf="allowedEdit()">
      <ng-container *ngVar="editMode(); let editMode">
        <ng-container *ngVar="hasUserPermission(); let hasUserPermission">
          <cc-edit-action-button
            *ngIf="!editMode && hasUserPermission"
            (onClick)="edit()"
          ></cc-edit-action-button>
          <cc-action-button
            [icon]="faPlus"
            displayText="DASHBOARDPANEL.ADD_WIDGET"
            title="{{
              (!hasUserPermission
                ? 'DASHBOARDPANEL.DIALOG-TITLE.NOWIDGETADD'
                : 'DASHBOARDWIDGET.DIALOG-TITLE.ADD'
              ) | transloco : { user: dashboard.creator.caption }
            }}"
            [disabled]="!hasUserPermission"
            (onClick)="addWidget()"
            *ngIf="editMode"
          >
          </cc-action-button>
          <cc-action-button
            [icon]="faShare"
            displayText="DASHBOARDPANEL.REFERENZ_WIDGET"
            title="{{
              (!hasUserPermission
                ? 'DASHBOARDPANEL.DIALOG-TITLE.NOWIDGETADD'
                : 'DASHBOARDWIDGET.DIALOG-TITLE.REFERENCE'
              ) | transloco : { user: dashboard.creator.caption }
            }}"
            [disabled]="!hasUserPermission"
            (onClick)="referenceWidget()"
            *ngIf="editMode"
          >
          </cc-action-button>
          <cc-save-action-button
            *ngIf="editMode"
            (onClick)="edit()"
          ></cc-save-action-button>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div style="height: 100%">
    <dashboard-dashboard-panel-widgets></dashboard-dashboard-panel-widgets>
  </div>
</div>
<ng-template #empty>
  <!-- {{ 'DASHBOARDPANEL.SERVER-ERROR.DASHBOARD-NOT-VISIBLE' | transloco }} -->
  <cc-loading-indicator></cc-loading-indicator>
</ng-template>

import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AnalysisLocalized,
  AnalysisWidgetFacade,
  AnalysisWidgetQuery,
  AnalysisWidgetStore,
} from '@backoffice-frontend/news/domain';
import { HttpResponseHelper } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faDownload,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject, from, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  templateUrl: './analysis-widget-dialog.component.html',
  styleUrls: ['./analysis-widget-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AnalysisWidgetDialogComponent {
  @HostBinding('class') class = 'analysis-widget-dialog';

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public displayedColumns = ['id', 'name', 'fileName'];

  public faChevronRight = faChevronRight;
  public faChevronLeft = faChevronLeft;
  public faChevronUp = faChevronUp;
  public faChevronDown = faChevronDown;
  public faTimes = faTimes;
  public faDownload = faDownload;

  //TODO: PageNumber?
  public pageNumber = 1;
  public activeIndex: number;
  public lastIndex: number;

  public pdfZoom = 1;
  public zoomPercentage = 100;

  public selectActive$ = this.query.selectActive();

  public pdfSrc$ = this.selectActive$.pipe(
    tap((_) => this.isLoading$.next(true)),
    filter((value: AnalysisLocalized) => !!value?.files[0]?.id),
    switchMap((value: AnalysisLocalized) => {
      const url = `${this.configService.settings['api']}analysis/download/${value.files[0].id}`;
      this.isLoading$.next(true);

      return this.downloadFile$(url).pipe(
        switchMap((res: any) => from(res.arrayBuffer())),
        catchError((_) => {
          this.isLoading$.next(false);
          return of(null);
        }),
        finalize(() => this.isLoading$.next(false))
      );
    })
  );

  zoomIn() {
    if (this.zoomPercentage < 300) {
      this.pdfZoom = this.pdfZoom + 0.1;
      this.zoomPercentage = this.zoomPercentage + 10;
    }
  }

  zoomOut() {
    if (this.zoomPercentage > 10) {
      this.pdfZoom = this.pdfZoom - 0.1;
      this.zoomPercentage = this.zoomPercentage - 10;
    }
  }

  // public openPdf(id: ID) {
  //   const url = `${this.configService.settings['api']}analysis/download/${id}`;
  //   this.pdfSrc = null;
  //   this.downloadFile$(url).subscribe(async (res: any) => {
  //     this.pdfSrc = await res.arrayBuffer(); // pdfSrc can be Blob or Uint8Array
  //     // this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
  //     this.cd.detectChanges();
  //   });
  // }

  private downloadFile$(url: string): any {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map((res) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fileId: number },
    private query: AnalysisWidgetQuery,
    public store: AnalysisWidgetStore,
    private configService: ConfigService,
    private httpClient: HttpClient, // private cd: ChangeDetectorRef
    private analysisWidgetFacade: AnalysisWidgetFacade
  ) {
    this.lastIndex = query.getAll().length;

    this.setActiveIndex();
  }

  public previous() {
    this.store.setActive({ prev: true });

    this.setActiveIndex();
  }

  public next() {
    this.store.setActive({ next: true });

    this.setActiveIndex();
  }

  public download() {
    const id = this.query.getActive().files[0]?.id;
    if (!id) {
      return null;
    }

    return this.analysisWidgetFacade
      .downloadPdf$(id)
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          const fileName = HttpResponseHelper.getHttpResponseFileName(response);
          const url = window.URL.createObjectURL(response.body);
          const anchor = document.createElement('a');
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }),
        first()
      )
      .subscribe();
  }

  private setActiveIndex() {
    const activeId = this.query.getActiveId();

    this.activeIndex =
      this.query.getAll().findIndex((value: AnalysisLocalized) => {
        return value.id === activeId;
      }) + 1;
  }
}

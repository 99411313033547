import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { Analysis } from '../entities/analysis';

export interface AnalysisState extends BaseState<Analysis> {}

function createInitialState(): AnalysisState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Analysis' })
export class AnalysisStore extends BaseStore<Analysis> {
  constructor() {
    super(createInitialState());
  }
}

import { Injectable } from '@angular/core';

import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs';
import { CanDeactivateComponent } from './components/can-deactivate.component';

@Injectable()
export class CanDeactivateGuard
  
{
  constructor(private confirmService: ConfirmService) {}

  canDeactivate(
    component: CanDeactivateComponent
  ): Observable<boolean> | boolean {
    if (!component.canDeactivate()) {
      return this.confirmService.confirm(
        'common.confirm.UNSAVED_CHANGES_TITLE',
        'common.confirm.UNSAVED_CHANGES_TEXT',
        true,
        faExclamationCircle,
        '#ffb74d'
      );
    }
    return true;
  }
}

<cc-form-container>
  <ng-container form-buttons>
    <cc-save-action-button (click)="submit()"></cc-save-action-button>
    <!-- Energysource with Id=1 and Id=2 are always Power and Gas (in each database), this should not be deleted -->
    <cc-delete-action-button
      [disabled]="
        (isNew$ | async) ||
        (!(isNew$ | async) &&
          ((currentId$ | async) == 1 || (currentId$ | async) == 2))
      "
      (onClick)="delete()"
    >
    </cc-delete-action-button>
  </ng-container>
  <form
    [formGroup]="formGroup"
    [patchFormGroupValues]="value$ | async"
    form-content
    class="p-3"
  >
    <cc-input-translation
      [label]="'setting.energy-source.fields.NAME'"
      [required]="true"
      formControlName="name"
    ></cc-input-translation>

    <div formContainerFormRow>
      <bo-energy-source-dropdown
        class="w-full"
        formControlName="kind"
      ></bo-energy-source-dropdown>
    </div>

    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{
          'setting.energy-source.fields.COSTING_DAYS_VALID' | transloco
        }}</mat-label>
        <input matInput type="number" formControlName="costingDaysValid" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{
          'setting.energy-source.fields.CRM_OFFER_DAYS_VALID' | transloco
        }}</mat-label>
        <input matInput type="number" formControlName="crmOfferDaysValid" />
      </mat-form-field>
    </div>

    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{
          'setting.energy-source.fields.AMOUNT_LIMIT_IN_GWH' | transloco
        }}</mat-label>
        <input matInput type="number" formControlName="amountLimitInGwh" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{
          'setting.energy-source.fields.BAND_ORDERS_LOWER_LIMIT' | transloco
        }}</mat-label>
        <input matInput type="number" formControlName="bandOrdersLowerLimit" />
      </mat-form-field>
    </div>

    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{
          'setting.energy-source.fields.FORECAST_HOLIDAY_TOLERANCE_IN_PERCENT'
            | transloco
        }}</mat-label>
        <input
          matInput
          type="number"
          formControlName="forecastHolidayToleranceInPercent"
        />
      </mat-form-field>
    </div>
  </form>
</cc-form-container>

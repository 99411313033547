import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphqlErrorInterceptor {
  constructor(
    private translateService: TranslocoService,
    private toastService: ToastService,
    private router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: any) => {
        if (
          event instanceof HttpResponse &&
          event.body &&
          event.body.data &&
          !event.body.errors &&
          request.headers.has('X-404-OnNull')
        ) {
          //is the first property null?
          if (!event.body.data[Object.keys(event.body.data)[0]]) {
            this.router.navigate(['/notfound'], { replaceUrl: true });
            return EMPTY;
          } else if (request.headers.has('X-404-OnNull-Property')) {
            const check404Property = request.headers.get(
              'X-404-OnNull-Property'
            );

            if (
              !event.body.data[Object.keys(event.body.data)[0]][
                check404Property
              ] ||
              !event.body.data[Object.keys(event.body.data)[0]][
                check404Property
              ].length
            ) {
              this.router.navigate(['/notfound'], { replaceUrl: true });
              return EMPTY;
            }
          }
        }

        if (
          event instanceof HttpResponse &&
          !!event.body &&
          !!event.body.errors &&
          Array.isArray(event.body.errors) &&
          event.body.errors.length > 0
        ) {
          //is this an auth issue?
          //HotChocolate specific!
          if (
            event.body.errors.some(
              (error: any) => error.extensions?.code === 'AUTH_NOT_AUTHORIZED'
            )
          ) {
            this.router.navigate(['/forbidden'], { replaceUrl: true });
            return EMPTY;
          }

          this.buildErrorMessage(event.body.errors);
        }
        return event;
      }),
      catchError((error: any) => {
        if (error?.error?.errors) {
          this.buildErrorMessage(error.error.errors);
        } else {
          throw error;
        }

        return EMPTY;
      })
    );
  }

  private buildErrorMessage(errors: any[]) {
    let warning = '';

    errors
      .filter((e: any) => !!e.extensions)
      .map((x: any) => {
        return { message: x.message, extensions: x.extensions };
      })
      .forEach((errorObject: any) => {
        if (!errorObject || !errorObject.message) {
          return;
        }

        if (!warning) {
          warning = '<ul>';
        }

        //TODO: Enhance for more generic use!
        if (errorObject.extensions && errorObject.extensions.uniqueValue) {
          warning +=
            '<li>' +
            this.translateService.translate(errorObject.message, {
              uniqueValue: errorObject.extensions.uniqueValue,
            }) +
            '</li>';
        } else {
          warning +=
            '<li>' +
            this.translateService.translate(errorObject.message) +
            '</li>';
        }
      });

    warning += '</ul>';

    if (warning.length > 5) {
      this.toastService.showWarning(warning);
    }
  }
}

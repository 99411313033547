<div class="bg-white flex items-center">
  <div
    class="h-screen w-screen container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700"
  >
    <div class="max-w-md pb-5">
      <div class="text-5xl font-dark font-bold">404</div>
      <p class="text-2xl md:text-3xl font-light leading-normal">
        {{ '404.message' | transloco }}
      </p>
      <p class="mb-8">{{ '404.support' | transloco }}</p>

      <button
        onclick="history.back()"
        class="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-primary-color active:bg-primary-color hover:bg-primary-color-600"
      >
        {{ '404.back' | transloco }}
      </button>
    </div>
    <div class="max-w-lg">
      <img class="w-52" src="assets/img/logo_estmk.svg" />
    </div>
  </div>
</div>

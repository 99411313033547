import { Directive, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableStateService } from '@clean-code/shared/components/ui-mat-table';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseTableFilter implements OnInit, OnDestroy {
  @Input() searchTermProperties: string[] = [];

  protected service = inject(TableStateService);

  protected closeSubject$ = new Subject<void>();

  protected filterControl: UntypedFormGroup;
  public searchControl = new UntypedFormControl(null);

  abstract setFilterKeyConditions(): FilterKeyConditionType[];

  private initialLoad = true;

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.closeSubject$))
      .subscribe(() => {
        this.setSearch();
      });
  }
  ngOnInit(): void {
    if (this.filterControl) {
      this.filterControl.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          takeUntil(this.closeSubject$)
        )
        .subscribe(() => {
          this.applyFilters(this.setFilterKeyConditions());
        });
    }
  }

  protected applyFilters(conditions: { key: string; conditions: string }[]) {
    for (const condition of conditions) {
      if (condition.conditions) {
        this.service.upsertFilters([condition]);
      } else if (!condition.conditions) {
        this.service.removeFilter(condition.key);
      }
    }
    this.service.setPaging(0);
  }

  setSearch() {
    if (this.searchControl.value) {
      const filters = this.searchTermProperties.map((key) => ({
        key,
        value: `"${this.searchControl.value}"`,
      }));

      this.service.upsertFilters([
        {
          filters,
        },
      ]);
    } else if (!this.searchControl.value && !this.initialLoad) {
      this.searchTermProperties.forEach((key) => {
        this.service.removeFilter(key);
      });
    }
    this.service.setPaging(0);
    this.initialLoad = false;
  }

  ngOnDestroy(): void {
    this.closeSubject$.next();
    this.closeSubject$.complete();
  }
}

export interface FilterKeyConditionType {
  key: string;
  conditions: string;
}

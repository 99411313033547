<div class="dashboard-reference-add" *ngVar="isLoading$ | async; let isLoading">
  <cc-loading-indicator *ngIf="isLoading"></cc-loading-indicator>

  <div class="h-full" *ngIf="(dashboards$ | async)?.length > 0; else empty">
    <dashboard-panel-preview
      *ngFor="let dashboard of dashboards$ | async"
      [dashboard]="dashboard"
      (click)="toggleSelection(dashboard)"
      [ngClass]="{ selected: isSelected(dashboard) }"
    >
    </dashboard-panel-preview>
  </div>

  <div class="footer" *ngIf="showFooter">
    <button mat-stroked-button (click)="cancel()">
      {{ 'common.CANCEL' | transloco }}
    </button>
  </div>
</div>

<ng-template #empty>
  <div class="dashboard-preview-content">
    <li class="empty">
      <a>
        <span class="name">{{ 'common.EMPTY' | transloco }}</span>
      </a>
    </li>
  </div>
</ng-template>

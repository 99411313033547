<mat-form-field class="search-input">
  <mat-label>{{
    'costing.offer.fields.TIME_FRAMES_RANGE' | transloco
  }}</mat-label>
  <mat-date-range-input [formGroup]="formGroup" [rangePicker]="picker">
    <input matStartDate [readonly]="true" formControlName="startDate" />
    <input matEndDate [readonly]="true" formControlName="endDate" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker-toggle matSuffix (click)="clearStartDate()">
    <fa-icon matDatepickerToggleIcon [icon]="faTimes"></fa-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{
      'Ungültiges Start Datum' | transloco
    }}</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{
      'Ungültiges End Datum' | transloco
    }}</mat-error> -->
</mat-form-field>

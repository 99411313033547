import dayjs from 'dayjs';
import { map, Observable } from 'rxjs';
import { IndividualTimeFrame, TimeFrameStrict } from '../models/time-frame';

export function mapTimeFrame() {
  return <T>(source: Observable<any>) => {
    return source.pipe(
      map((config: any) => {
        if (config.settings.startDate) {
          config.settings.timeFrame = {
            start: dayjs(config.settings.startDate),
            end: dayjs().add(1, 'day'),
          } as IndividualTimeFrame;
        }

        config.settings.timeFrame = config.settings
          .timeFrame as TimeFrameStrict;

        return config as T;
      })
    );
  };
}

import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'fof-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavItemComponent {
  @HostBinding('class') class = 'fof-side-nav-item';

  @Input()
  item: {
    name: string;
    link: string;
    icon?: IconDefinition;
    icons?: IconDefinition[];
  };
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { DashboardFacade } from '@clean-code/shared/dashboard/dashboard/domain';
import {
  DashboardWidgetAddDialogComponent,
  DashboardWidgetReferenceDialogComponent,
} from '@clean-code/shared/dashboard/widget/ui-widget-dialog';
import { faPlus, faShare } from '@fortawesome/pro-light-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { DashboardPanelWidgetsComponent } from '../dashboard-panel-widgets/dashboard-panel-widgets.component';

@Component({
  standalone: true,
  selector: 'dashboard-dashboard-panel-component',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ActionButtonModule,
    VarDirective,
    DashboardPanelWidgetsComponent,
  ],
})
export class DashboardPanelComponent implements OnDestroy {
  @HostBinding('class.page-component') pageComponent = true;

  public faPlus = faPlus;
  public faShare = faShare;

  public editMode = this.dashboardFacade.editMode;
  public allowedEdit = this.dashboardFacade.allowedEdit$;
  public dashboard = this.dashboardFacade.active;
  public hasUserPermission = this.dashboardFacade.hasUserPermission;
  public isLoading = this.dashboardFacade.isLoading;

  private closeSubject = new Subject<void>();

  constructor(
    private dashboardFacade: DashboardFacade,
    protected activatedRoute: ActivatedRoute,
    private confirmService: ConfirmService
  ) {
    const route = inject(ActivatedRoute);
    route.params.pipe(takeUntil(this.closeSubject)).subscribe((params) => {
      this.dashboardFacade.getById$(+params.id);
    });
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  public addWidget() {
    if (!this.dashboardFacade.hasUserPermission()) {
      return;
    }

    this.confirmService
      .open(
        '',
        '',
        DashboardWidgetAddDialogComponent,
        { dashboardId: this.dashboard().id, tenant: this.dashboard().tenant },
        {
          width: '800px',
          height: '634px',
        }
      )
      .subscribe((result) => {
        if (result) {
          const widgetWithDashboardId = {
            ...result,
            dashboardId: this.dashboard().id,
          } as IDashboardWidgetDetailDto;

          this.dashboardFacade.addWidget(widgetWithDashboardId);
        }
      });
  }

  public referenceWidget() {
    if (!this.dashboardFacade.hasUserPermission()) {
      return;
    }

    const inputWidget = {
      dashboardId: this.dashboard().id,
    } as IDashboardWidgetDetailDto;
    this.confirmService
      .open('', '', DashboardWidgetReferenceDialogComponent, inputWidget, {
        width: '800px',
        height: '634px',
      })
      .subscribe((result) => {
        if (result) {
          const widgetWithDashboardId = {
            ...result,
            dashboardId: this.dashboard().id,
          } as IDashboardWidgetDetailDto;
          this.dashboardFacade.addWidget(widgetWithDashboardId);
        }
      });
  }

  public edit() {
    this.dashboardFacade.toggleEdit();
  }
}

import { Inject, Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private translateService: TranslocoService,
    @Inject(Injector) private injector: Injector
  ) {}

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  public showSuccess(message: string = null) {
    this.toastrService.success(
      message === null ? '' : this.translateService.translate(message),
      this.translateService.translate('common.SUCCESSFULLY_EXECUTED')
    );
  }

  public showError(message: string) {
    this.toastrService.error(
      message === 'common.SERVER_OFFLINE' && !this.hasTranslation(message)
        ? 'Server nicht erreichbar'
        : this.translateService.translate(message),
      !this.hasTranslation('common.ERROR')
        ? 'Error'
        : this.translateService.translate('common.ERROR'),
      {
        closeButton: true,
        disableTimeOut: true,
        onActivateTick: true,
        enableHtml: true,
      }
    );
  }

  public showWarning(message: string) {
    this.toastrService.warning(
      this.translateService.translate(message),
      this.translateService.translate('common.WARNING'),
      {
        closeButton: true,
        disableTimeOut: true,
        onActivateTick: true,
        enableHtml: true,
      }
    );
  }

  public showInfo(message: string) {
    this.toastrService.info(
      this.translateService.translate(message),
      this.translateService.translate('common.INFO')
    );
  }

  private hasTranslation(key: string): boolean {
    const translation = this.translateService.translate(key);
    return translation !== key && translation !== '';
  }
}

<gridster [options]="options">
  <gridster-item [item]="item" *ngFor="let item of widgets">
    <bo-widget class="bo-widget" [headerTemplate]="headerTemplate">
      <ng-container widget-header>
        {{ item.title | transloco }}
      </ng-container>

      <ng-template
        [ngComponentOutlet]="castToAnyType(item)"
        [ndcDynamicInputs]="item.inputs"
      ></ng-template>
    </bo-widget>
  </gridster-item>
</gridster>

import { Injectable } from '@angular/core';
import { DataTableParameters } from '@clean-code/shared/common';
import { TimeSeriesAnalysisIdentifierDataService } from '../infrastructure/time-series-analysis-identifier.data.service';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesAnalysisIdentifierFacade {
  constructor(private service: TimeSeriesAnalysisIdentifierDataService) {}

  public getAll$(params: DataTableParameters) {
    return this.service.getAll$(params);
  }

  getAllForSelection$(params: DataTableParameters) {
    return this.service.getAllForSelection$(params);
  }
}

import { Component, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EnumService,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { WidgetTypeConfig } from '@backoffice-frontend/time-series-identifier/domain';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { atLeastOne } from '@clean-code/shared/util/util-form';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Dayjs } from 'dayjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs';
@Component({
  templateUrl: './chart-config.component.html',
  styleUrls: ['./chart-config.component.scss'],
})
export class ChartConfigComponent extends BaseWidgetTypeConfigComponent<WidgetTypeConfig> {
  override form = new FormGroup(
    {
      settings: new FormGroup({
        timeFrame: new FormControl<TimeFrame>('M_3'),
        startDate: new FormControl<Dayjs>(null),
        showInPercentage: new FormControl(false),
      }),
      dataSources: new FormArray([]),
    },
    {
      validators: atLeastOne(Validators.required, [
        'settings.timeFrame',
        'settings.startDate',
      ]),
    }
  );

  public calculationType$ = this.form
    .get('settings.showInPercentage')
    .valueChanges.pipe(
      startWith(false),
      map((value: boolean) => (value ? 'RELATIVE' : 'ABSOLUTE'))
    );

  private enumService = inject(EnumService);
  public timeFrames$ = this.enumService.getEnumType$('TimeSeriesTimeFrame');
  public faTimes = faTimes;

  constructor() {
    super();

    this.form
      .get('settings.startDate')
      .valueChanges.pipe(
        filter((value: Dayjs) => !!value),
        tap(() => this.form.get('settings.timeFrame').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.form
      .get('settings.timeFrame')
      .valueChanges.pipe(
        filter((value: TimeFrame) => !!value),
        tap(() => this.form.get('settings.startDate').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }

  public get dataSources(): FormArray {
    return this.form.get('dataSources') as FormArray;
  }

  clearStartDate(event: any) {
    event.stopPropagation();
    this.form.get('settings.startDate').reset();
  }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierChartWidgetFacade {
  // getTimeSeriesChartData$(
  //   symbols: string[]
  // ): Observable<TimeSeriesIdentifierLocalized[]> {
  //   return this.service.getTimeSeriesChartData$(symbols);
  // }
  // getById$(symbols: string[]): Observable<TimeSeriesIdentifierLocalized[]> {
  //   return this.service.timeSeriesLocalized$(symbols);
  // }
}

import { Injectable } from '@angular/core';

import { AkitaFormBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { NormCubicMeterStore } from '../+state/norm-cubic-meter.store';
import { NormCubicMeter } from '../models/norm-cubic-meter';
import { NormCubicMeterService } from '../services/norm-cubic-meter.service';

@Injectable({ providedIn: 'root' })
export class NormCubicMeterFormFacade extends AkitaFormBaseService<NormCubicMeter> {
  constructor(
    store: NormCubicMeterStore,
    protected service: NormCubicMeterService
  ) {
    super(store, service);
  }

  getAll$(
    _params: DataTableParameters
  ): Observable<PaginationResponse<NormCubicMeter>> {
    throw new Error('Method not implemented.');
  }
}

import { MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export class BaseDialog {
  public title: string;
  public message: string;
  public data: any;
  public showCancelButton = true;
  public icon: IconDefinition;
  public iconColor: string;

  constructor(public dialogRef: MatDialogRef<BaseDialog>) {}
}

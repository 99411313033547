<cc-form-container [isLoading]="isLoading$ | async">
  <ng-container form-buttons>
    <cc-save-action-button (onClick)="submit()" class="button-align">
    </cc-save-action-button>
  </ng-container>

  <form
    [formGroup]="formGroup"
    form-content
    [patchFormGroupValues]="value$ | async"
  >
    <div formContainerFormRow>
      <mat-form-field class="w-full">
        <mat-label>{{
          'settings.norm-cubic-meter.fields.YEAR' | transloco
        }}</mat-label>
        <input formControlName="year" matInput type="number" />
      </mat-form-field>
    </div>
    <div formContainerFormRow>
      <mat-form-field class="w-full">
        <mat-label>{{
          'settings.norm-cubic-meter.fields.FACTOR' | transloco
        }}</mat-label>
        <input type="number" formControlName="factor" matInput />
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="(isNew$ | async) === false">
      <div formContainerFormRow>
        <mat-form-field class="w-full">
          <mat-label>{{
            'settings.norm-cubic-meter.fields.UPDATED_DATE' | transloco
          }}</mat-label>
          <input formControlName="updatedDate" matInput />
        </mat-form-field>
      </div>
      <div formContainerFormRow>
        <mat-form-field class="w-full">
          <mat-label>{{
            'settings.norm-cubic-meter.fields.UPDATED_BY' | transloco
          }}</mat-label>
          <input formControlName="updatedBy" matInput />
        </mat-form-field>
      </div>
    </div>
  </form>
</cc-form-container>

import { Injectable } from '@angular/core';
import { AkitaFormBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TimeSeriesAnalysisIdentifierStore } from '../+state/time-series-analysis-identifier.store';
import { Parser } from '../entities/parser';
import { TimeSeriesAnalysisIdentifier } from '../entities/time-series-analysis-identifier';
import { TimeSeriesIdentifierCategory } from '../entities/time-series-identifier-category';
import { TimeSeriesAnalysisIdentifierDataService } from '../infrastructure/time-series-analysis-identifier.data.service';
import { TimeSeriesIdentifierCategoryDataService } from '../infrastructure/time-series-identifier-category.data.service';
import { TimeSeriesDataService } from '../infrastructure/time-series.data.service';

@Injectable({ providedIn: 'root' })
export class TimeSeriesAnalysisIdentifierFormFacade extends AkitaFormBaseService<TimeSeriesAnalysisIdentifier> {
  constructor(
    store: TimeSeriesAnalysisIdentifierStore,
    protected override service: TimeSeriesAnalysisIdentifierDataService,
    protected categoryService: TimeSeriesIdentifierCategoryDataService,
    private identifierDataService: TimeSeriesDataService
  ) {
    super(store, service);
  }

  getAll$(
    _params: DataTableParameters
  ): Observable<PaginationResponse<TimeSeriesAnalysisIdentifier>> {
    throw new Error('Method not implemented.');
  }

  getTimeSeriesIdentifierCategoriesLocalized$(): Observable<
    TimeSeriesIdentifierCategory[]
  > {
    return this.categoryService.getTimeSeriesIdentifierCategoriesLocalized$();
  }

  getParsers$(): Observable<Parser[]> {
    return this.service.getParsers$();
  }

  getTimeSeriesIdentifierDataByCategory$(categoryId: ID) {
    return this.identifierDataService.timeSeriesIdentifierDataByCategory$(
      categoryId
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { CustomersContractVolumeWidgetTypeConfig } from '../customer-contract-volume';
@Component({
  standalone: true,
  templateUrl: './customer-contract-volume-config.component.html',
  styleUrls: ['./customer-contract-volume-config.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent,
  ],
})
export class CustomersContractVolumeConfigComponent extends BaseWidgetTypeConfigComponent<CustomersContractVolumeWidgetTypeConfig> {
  form = new UntypedFormGroup({
    filter: new UntypedFormControl(null, [Validators.required]),
  });

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { ID, indicate } from '@clean-code/shared/common';
import { BaseFormControlChild } from '@clean-code/shared/components/ui-form-controls';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { ItemData } from './multi-select-item-data';

@Component({
  selector: 'cc-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectAutocompleteComponent),
      multi: true,
    },
  ],
  styleUrls: ['./multi-select-autocomplete.component.scss'],
})
export class MultiSelectAutocompleteComponent
  extends BaseFormControlChild
  implements AfterViewInit
{
  @Input() placeholder = 'common.SELECT';
  @Input() valueBinding: string;
  @Input() lableBinding: string;

  @Input() displayWith: (value: any) => string;
  @Input({ required: true }) searchDataFunction: (
    searchTerm: string
  ) => Observable<any[]>;

  //@Input() formControlName: string;

  loading$ = new BehaviorSubject<boolean>(false);

  //data$ = new Observable<any[]>();

  @Input() data = new Array<any>();

  //rawData: Array<ItemData> = [];
  selectData: Array<ItemData> = [];

  @Input() key = '';

  selectControl = new UntypedFormControl();
  public searchFormControl = new FormControl();

  data$ = this.searchFormControl.valueChanges.pipe(
    debounceTime(300),
    startWith(''),
    switchMap((search: string) =>
      this.searchDataFunction(search).pipe(
        map((data) => this.mapToArray(data)),
        indicate(this.loading$),
        catchError(() => of([])) // empty list on error
        //delay(2000), // delay to simulate network latency
      )
    )
  );

  public mapToArray(data: Array<any>) {
    let dataArr = new Array<ItemData>();
    if (data) {
      dataArr = data.map((x) => {
        return {
          id: x[this.valueBinding],
          item: x,
          selected: false,
        } as ItemData;
      });

      if (this.formControl.value) {
        const selected = dataArr.filter((item: ItemData) => {
          return (
            this.formControl.value?.indexOf(item.item[this.valueBinding]) != -1
          );
        });

        selected.forEach((item: ItemData) => this.toggleSelection(item));
      }
      this.selectControl.setValue('');
    }
    return dataArr;
  }

  ngAfterViewInit(): void {
    super.ngOnInit();
  }

  // filteredData = this.selectControl.valueChanges.pipe(
  //   debounceTime(300),
  //   startWith<string>(''),
  //   indicate(this.loading$),
  //   map((value) => (typeof value === 'string' ? value : this.filterString)),
  //   map((filter) => this.filter(filter))
  // );

  // filterString = '';
  // faTimes = faTimes;

  // filter = (filter: string): Array<ItemData> => {
  //   this.filterString = filter;
  //   if (filter.length > 0) {
  //     return this.rawData.filter((option) => {
  //       return (
  //         option.item[this.lableBinding]
  //           .toLowerCase()
  //           .indexOf(filter.toLowerCase()) >= 0
  //       );
  //     });
  //   } else {
  //     return this.rawData.slice();
  //   }
  // };
  // displayFn = (): string => '';

  optionClicked = (event: Event, data: ItemData): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection = (data: ItemData): void => {
    data.selected = !data.selected;
    if (data.selected === true) {
      if (
        this.selectData.some(
          (
            value //!ObjectHelper.compareObject(value.item.id, data.item.id)
          ) => JSON.stringify(value.item.id) === JSON.stringify(data.item.id)
        )
      ) {
        console.log(data.item.id);
        return; // check if it already exists
      }
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex((value) => value.item === data.item);
      this.selectData.splice(i, 1);
    }
    this.selectControl.setValue(this.selectData);
    this.emitAdjustedData();
  };

  emitAdjustedData = (): void => {
    const results: Array<ID> = [];
    this.selectData.forEach((data: ItemData) => {
      results.push(data.item[this.valueBinding]);
    });
    this.formControl.setValue(results);
  };

  removeChip = (data: ItemData): void => {
    this.toggleSelection(data);
  };

  // writeValue(value: any) {
  //   if (value) {
  //     this.formControl.setValue(value, { onlySelf: true, emitEvent: false });
  //   } else {
  //     this.formControl.reset();
  //   }
  // }
}

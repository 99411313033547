import { Injectable } from '@angular/core';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { Termin } from '../entities/termin';

@Injectable({ providedIn: 'root' })
export class TerminDataService implements EditSearchDataService<Termin> {
  public readonly entityFields = `
        id
        description
        {
            id
            localizations
            {
                localizationSetId
                id
                value
                cultureCode
            }
        }
        location
        {
            id
            localizations
            {
                localizationSetId
                id
                value
                cultureCode
            }
        }
        start
        end
        internal
        display
        createdDate
        createdBy
        updatedDate
        updatedBy
    `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(params: DataTableParameters): Observable<PagingResponse<Termin>> {
    return this.graphqlService.getAll(
      params,
      'terminGetAll',
      this.entityFields
    );
  }

  getById$(id: ID): Observable<Termin> {
    return this.graphqlService.getById(id, 'terminGetById', this.entityFields);
  }

  add$(input: Termin): Observable<ID> {
    const mutation = `
            mutation ($input: TerminInput!){
                terminAddMenu(input: $input)
            }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  update$(input: Termin): Observable<Termin> {
    const mutation = `
            mutation ($input: TerminInput!){
                terminUpdateMenu(input: $input)
            }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<Termin>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
            mutation ($id: Int!) {
                terminDelete(id: $id)
            }`;

    return this.graphqlService.mutation<boolean>(mutation, { id });
  }
}

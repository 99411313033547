<div class="dashboard-reference-dialog w-full h-full">
  <div class="flex flex-row flex-nowrap items-center">
    <h2 mat-dialog-title class="dialog-title">
      {{ 'dashboard.reference.dialog.title' | transloco }}
    </h2>
    <div class="flex-auto"></div>

    <fa-icon
      class="icon-close"
      size="2x"
      [icon]="faTimes"
      mat-dialog-close
      (click)="dialogRef.close()"
    ></fa-icon>
  </div>

  <div mat-dialog-content class="px-3">
    <dashboard-dashboard-reference
      (callback)="onSave($event)"
      [showFooter]="false"
    ></dashboard-dashboard-reference>
  </div>
</div>

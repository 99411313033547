<div searchRow>
  <mat-form-field class="search-input">
    <input
      matInput
      placeholder="{{ 'common.SEARCH' | transloco }}"
      [formControl]="searchControl"
    />
    <button
      type="button"
      *ngIf="searchControl.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="searchControl.setValue(null)"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </mat-form-field>
  <mat-form-field class="search-input">
    <mat-label>{{
      'costing.offer.fields.TIME_FRAMES_RANGE' | transloco
    }}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate [readonly]="true" formControlName="start" />
      <input matEndDate [readonly]="true" formControlName="end" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{
      'Ungültiges Start Datum' | transloco
    }}</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{
      'Ungültiges End Datum' | transloco
    }}</mat-error> -->
  </mat-form-field>
</div>

<ng-container *rxIf="state$ | async; let state">
  <table
    mat-table
    [dataSource]="state?.data.items"
    [trackBy]="trackByFn"
    [multiTemplateDataRows]="isExpandable"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="!state.isEmpty && isAllSelected"
          [indeterminate]="!state.isEmpty && !isAllSelected"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? toggle(row) : null"
          [checked]="isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef [width]="20">
        <fa-icon [icon]="faListTree" class="fa-icon"> </fa-icon>
      </th>
      <td mat-cell *matCellDef="let row" [width]="20">
        <button
          mat-icon-button
          (click)="
            expandedElement = expandedElement === row ? null : row;
            $event.stopPropagation()
          "
          class="h-8 w-8 rounded-full hover:bg-gray-300"
          *ngIf="row.hasChildren"
        >
          <fa-icon
            [icon]="expandedElement !== row ? faChevronDown : faChevronUp"
            class="fa-spacer"
          >
          </fa-icon>
        </button>
      </td>
    </ng-container>

    <!-- <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container> -->

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="tableColumnsLength"
      >
        <div
          class="example-element-detail"
          [@detailExpanding]="
            element === expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div
            class="example-element-description"
            *ngIf="element === expandedElement"
          >
            <!-- {{ element.description }} -->
            <span class="example-element-description-attribution">
              <ng-container
                *ngTemplateOutlet="
                  expandRowTemplateComponent;
                  context: { $implicit: element }
                "
              ></ng-container>
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-content></ng-content>
    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns$ | async; sticky: true"
    ></tr>
    <tr
      mat-row
      class="entity-row"
      [ngClass]="{ selected: state.selected?.selectedId === row.id }"
      *matRowDef="let row; columns: tableColumns$ | async"
      [ngStyle]="{
        background: markDisabled && row.disabled ? 'lightsalmon' : null
      }"
      (click)="select(row)"
    ></tr>

    <ng-container *ngIf="isExpandable">
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']; when: objHasChildren"
        class="example-detail-row"
      ></tr>
    </ng-container>
  </table>

  <div
    class="no-data bg-white dark:bg-layout-dark-page-container text-layout-page-container-text dark:text-layout-dark-page-container-text"
    *ngIf="!state.data?.items || state.data?.items.length <= 0"
  >
    {{ 'common.NO_DATA' | transloco }}
  </div>

  <ng-container *rxIf="paginationState$; let paginationState">
    <mat-paginator
      *ngIf="paginationState.totalCount > paginationState.pageSize"
      class="dark:bg-layout-dark-page-container"
      (page)="changePage($event)"
      [length]="paginationState.totalCount"
      [pageSize]="paginationState.pageSize"
      [pageIndex]="paginationState.pageIndex"
    ></mat-paginator>
  </ng-container>
</ng-container>

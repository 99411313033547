import { Injectable } from '@angular/core';

import { AkitaFormBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { HknIdentifierStore } from '../+state/hkn-identifier.store';
import { HknIdentifier } from '../models/hkn-identifier';
import { HknIdentifierService } from '../services/hkn-identifier.service';

@Injectable({ providedIn: 'root' })
export class HknIdentifierFormFacade extends AkitaFormBaseService<HknIdentifier> {
  constructor(
    store: HknIdentifierStore,
    protected service: HknIdentifierService
  ) {
    super(store, service);
  }

  getAll$(
    _params: DataTableParameters
  ): Observable<PaginationResponse<HknIdentifier>> {
    throw new Error('Method not implemented.');
  }
}

<cc-form-container
  [ngClass]="{ 'preview-container': preview }"
  [isLoading]="isLoading$ | async"
>
  <ng-container form-buttons *ngVar="id$ | async; let id">
    <cc-save-action-button (onClick)="submit()" class="button-align">
    </cc-save-action-button>
    <cc-delete-action-button [disabled]="id === 'new'" (onClick)="delete(id)">
    </cc-delete-action-button>
  </ng-container>

  <form
    [formGroup]="formGroup"
    [patchFormGroupValues]="value$ | async"
    form-content
  >
    <!-- <cc-save-action-button
      class="button-align"
      (onClick)="submit()"
    ></cc-save-action-button> -->
    <div class="form-row">
      <div class="form-group col-xl-6">
        <mat-form-field>
          <input
            matInput
            placeholder="{{
              'spot-configuration.spot-configuration-identifier.fields.IDENTIFIER'
                | transloco
            }}"
            formControlName="identifier"
          />
        </mat-form-field>
      </div>
      <div class="form-group col-xl-6">
        <mat-form-field>
          <input
            matInput
            placeholder="{{
              'spot-configuration.spot-configuration-identifier.fields.HOUR'
                | transloco
            }}"
            formControlName="hour"
            type="number"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</cc-form-container>

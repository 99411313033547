import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { TimeSeriesIdentifierParserValue } from './../entities/time-series-analysis-identifier';
import { TimeSeriesIdentifierParserValueStore } from './time-series-identifier-parser-value.store';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierParserValueQuery extends AkitaSearchQueryEntity<TimeSeriesIdentifierParserValue> {
  constructor(store: TimeSeriesIdentifierParserValueStore) {
    super(store);
  }
}

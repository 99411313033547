import { Directive, Input } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[patchFormGroupValues]',
})
export class PatchFormGroupValuesDirective {
  @Input() formGroup: any;
  @Input()
  set patchFormGroupValues(val: any) {
    if (!val) return;
    this.formGroup.patchValue(val, { emitEvent: false });
  }
}

@Directive({
  standalone: true,

  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[patchFormGroupWithEmitValues]',
})
export class PatchFormGroupWithEmitValuesDirective {
  @Input() formGroup: any;
  @Input()
  set patchFormGroupWithEmitValues(val: any) {
    if (!val) return;
    this.formGroup.patchValue(val, { emitEvent: true });
  }
}

import { Injectable } from '@angular/core';
import { AkitaSearchBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { IBaseService } from '@clean-code/shared/util/util-component-services';
import { PaginationResponse } from '@datorama/akita';
import { EMPTY, Observable } from 'rxjs';
import { CompanyQuery } from '../+state/company.query';
import { CompanyStore } from '../+state/company.store';
import { CompanyService } from '../infrastructure/company.service';
import { Company, CompanyPreview } from '../models/company';

@Injectable({ providedIn: 'root' })
export class CompanyPublicFacade
  extends AkitaSearchBaseService<Company>
  implements IBaseService<Company>
{
  constructor(
    private service: CompanyService,
    private query: CompanyQuery,
    store: CompanyStore
  ) {
    super(store);
  }

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<Company>> {
    return this.service.getAll$(params);
  }

  public getById$(id: ID): Observable<Company> {
    return this.service.getById$(id);
  }

  public getAllCompanies$(): Observable<Company[]> {
    return this.service.getAllCompanies$();
  }

  public getAllCompaniesForDropDown$(): Observable<Company[]> {
    return this.service.getAllCompaniesForDropDown$();
  }

  public allCompaniesWithOfferPowerContractsByTimeSeriesIdentifierId$(
    customerPoolId: ID
  ): Observable<Company[]> {
    return this.service.allCompaniesWithOfferPowerContractsByTimeSeriesIdentifierId$(
      customerPoolId
    );
  }

  public allCompaniesWithOfferGasContractsByTimeSeriesIdentifierId$(
    customerPoolId: ID
  ): Observable<Company[]> {
    return this.service.allCompaniesWithOfferGasContractsByTimeSeriesIdentifierId$(
      customerPoolId
    );
  }

  public getActiveId(): ID {
    return this.query.getActiveId();
  }

  public getActive(): Company {
    return this.query.getActive();
  }

  public hasActive(): boolean {
    return this.query.hasActive();
  }

  selectActive(): Observable<Company> {
    return this.query.selectActive();
  }
  getCompanyByEnergySourceUser$() {
    return this.service.getCompanyByEnergySourceUser$();
  }

  getAllCompanyParentsWithSuperId$(id: ID): Observable<Company[]> {
    return this.service.getAllCompanyParentsWithSuperId$(id);
  }
  getCompanyRelations$() {
    return this.service.getCompanyRelations$();
  }
  getAllGasKeyAccountManager$(searchTerm?: string) {
    return this.service.getAllGasKeyAccountManager$(searchTerm);
  }
  getAllPowerKeyAccountManager$(searchTerm?: string) {
    return this.service.getAllPowerKeyAccountManager$(searchTerm);
  }
  getPreviewById$(id: ID): Observable<CompanyPreview> {
    return this.service.getPreviewById$(id);
  }

  add$() {
    return EMPTY;
  }
  update$() {
    return EMPTY;
  }
  delete$() {
    return EMPTY;
  }
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { NewsQuery } from '../+state/news.query';

export const NEWS_PAGINATOR = new InjectionToken('NEWS_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const query = inject(NewsQuery);
    return new PaginatorPlugin(query, { startWith: 0 });
  },
});

import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { HknType } from '../models/hkn-type';

const stateName = 'hkntype';

export interface HknTypeState extends BaseState<HknType> {}

function createInitialState(): HknTypeState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: stateName })
export class HknTypeStore extends BaseStore<HknType> {
  constructor() {
    super(createInitialState());
  }
}

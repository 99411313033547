import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthQuery } from '@clean-code/shared/auth/util-auth';
import { ID, IEntityPositionDto } from '@clean-code/shared/common';
import { IDashboardWidgetDetailDto, WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { ENV_TOKEN } from '@clean-code/shared/util-config';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import {
  addEntities,
  addEntity,
  removeAllEntities,
  removeEntity,
  updateEntity,
  withEntities
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, pipe, switchMap, tap } from 'rxjs';
import { DashboardDataService } from '../infrastructure/dashboard-data.service';
import {
  IDashboardDetailDto,
  IDashboardListDto,
  IDashboardPanelPreviewDto,
  IDashboardReferenceDto
} from '../models/dashboard.models';

type DashboardState = {
  editMode: boolean;
  allowedEdit: boolean;
  active: IDashboardDetailDto;
  // widgets: IDashboardWidgetDetailDto[];
  isLoading: boolean;
  selectedWidget: {
    widget: IDashboardWidgetDetailDto;
  };

  editing: IDashboardListDto;
};

const initialState: DashboardState = {
  editMode: false,
  allowedEdit: false,
  active: null,
  isLoading: false,
  // widgets: [],
  selectedWidget: null,
  editing: null
};

export const DashboardStore = signalStore(
  { providedIn: 'root' },
  withEntities({
    entity: type<IDashboardListDto>()
  }),
  withEntities({
    entity: type<IDashboardWidgetDetailDto>(),
    collection: 'widgets'
  }),
  withState(() => {
    const env = inject(ENV_TOKEN);
    initialState.allowedEdit = env.allowEditDashboard ?? false;
    return initialState;
  }),
  withComputed(({ active, widgetsEntities }) => {
    const auth = inject(AuthQuery);

    const widgetTypeProvider = inject(WidgetTypeProvider);
    return {
      hasUserPermission: computed(() => {
        return active().creator.id === auth.userDataSignal().id;
      }),

      mappedWidgets: computed(() => {
        return widgetsEntities().map((x) => {
          const provider = widgetTypeProvider.getProvider(x.type as string);
          const minItemRows = provider?.minItemRows ?? 0;
          const minItemCols = provider?.minItemCols ?? 0;

          return {
            ...x,
            cols: x.cols >= minItemCols ? x.cols : minItemCols,
            rows: x.rows >= minItemRows ? x.rows : minItemRows,
            minItemRows: minItemRows,
            minItemCols: minItemCols
          } as IDashboardWidgetDetailDto;
        });
      }),
      tenant: computed(() => active().tenant)
    };
  }),
  withMethods((store) => {
    const service = inject(DashboardDataService);
    const router = inject(Router);
    const toastService = inject(ToastService);

    // const queryParam = injectParams('id');
    // const id = queryParam();

    return {
      getById: rxMethod<ID>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((id) =>
            service.getById$(id).pipe(
              tapResponse({
                next: (entity: IDashboardDetailDto) => {
                  patchState(store, { active: entity });
                  patchState(
                    store,
                    removeAllEntities({ collection: 'widgets' })
                  );
                  patchState(
                    store,
                    addEntities(entity.widgets, {
                      collection: 'widgets',
                      idKey: 'id'
                    })
                  );
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false })
              })
            )
          )
        )
      ),
      update: rxMethod<IDashboardDetailDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service.update$(entity).pipe(
              tapResponse({
                next: (active: IDashboardDetailDto) => {
                  if (store.active().id === active.id) {
                    patchState(store, { active });
                  }
                  toastService.showSuccess();
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false })
              })
            )
          )
        )
      ),
      getAssigned: rxMethod<void>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap(() =>
            service.getAssigned$().pipe(
              tapResponse({
                next: (entities: IDashboardListDto[]) => {
                  patchState(
                    store,
                    addEntities(entities, {
                      idKey: 'id'
                    })
                  );
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false })
              })
            )
          )
        )
      ),
      remove(id: ID) {
        patchState(store, removeEntity(id));
      },

      add: rxMethod<IDashboardListDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service.add$(entity).pipe(
              tapResponse({
                next: (active: IDashboardListDto) => {
                  patchState(
                    store,
                    addEntity(active, {
                      idKey: 'id'
                    })
                  );

                  router.navigate(['/dashboard', active.id]);
                },
                error: console.error,
                finalize: () => patchState(store, { isLoading: false })
              })
            )
          )
        )
      ),
      addReference: rxMethod<IDashboardPanelPreviewDto>(
        pipe(
          debounceTime(300),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((entity) =>
            service
              .reference$({
                id: entity.id
              } as IDashboardReferenceDto)
              .pipe(
                tapResponse({
                  next: (active: IDashboardListDto) => {
                    patchState(
                      store,
                      addEntity(active, {
                        idKey: 'id'
                      })
                    );

                    router.navigate(['/dashboard', active.id]);
                  },
                  error: console.error,
                  finalize: () => patchState(store, { isLoading: false })
                })
              )
          )
        )
      ),
      async updateEditMode(editMode: boolean) {
        patchState(store, { editMode });
      },

      addWidget(widget: IDashboardWidgetDetailDto) {
        patchState(
          store,
          addEntity(widget, {
            collection: 'widgets',
            idKey: 'id'
          })
        );
      },

      removeWidget(widget: IDashboardWidgetDetailDto) {
        patchState(
          store,
          removeEntity(widget.id, {
            collection: 'widgets'
          })
        );
      },

      updateActive(active: IDashboardDetailDto) {
        patchState(store, { active });
        patchState(
          store,
          addEntities(active.widgets, {
            collection: 'widgets',
            idKey: 'id'
          })
        );
      },

      async updateWidget(active: IDashboardWidgetDetailDto) {
        patchState(
          store,
          updateEntity(
            {
              id: active.id,
              changes: active
            },
            {
              collection: 'widgets'
            }
          )
        );
      },

      async updateWidgetPosition(id: ID, widget: IEntityPositionDto) {
        patchState(
          store,
          updateEntity(
            {
              id: id,
              changes: {
                x: widget.x,
                y: widget.y,
                cols: widget.cols,
                rows: widget.rows
              }
            },
            {
              collection: 'widgets'
            }
          )
        );
      }
    };
  }),
  withHooks({
    onInit(store) {
      store.getAssigned();
    }
  })
);

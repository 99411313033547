import { Injectable } from '@angular/core';
// import { ID, PaginationResponse } from '@datorama/akita';
import { ID, PagingResponse } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { AreaService } from '../infrastructure/area.service';
import { DeliveryAreaService } from '../infrastructure/delivery-area.service';
import { Area, ShortArea } from '../models/area';

@Injectable({ providedIn: 'root' })
export class AreasPublicFacade {
  constructor(
    private areaService: AreaService,
    private deliveryAreaService: DeliveryAreaService
  ) {}

  getAllAreas$(areaType: string) {
    return this.areaService.getAllAreas$(areaType);
  }
  getAllShortAreas$(): Observable<PagingResponse<ShortArea>> {
    return this.areaService.getAllShortMarketAreas$();
  }
  getByEnergySource$(
    areaType: string,
    energySource: string
  ): Observable<Area[]> {
    return this.areaService.getByEnergySource$(areaType, energySource);
  }

  getAllMarketAreasByEnergySource$(energySource: string): Observable<Area[]> {
    return this.areaService.getAllMarketAreasByEnergySource$(energySource);
  }

  getAreaById$(areaType: string, id: string) {
    return this.areaService.getAreaById$(areaType, id);
  }
  getAllDeliveryAreasByEnergySource$(
    energySource: string
  ): Observable<PagingResponse<Area>> {
    return this.areaService.getAllDeliveryAreasByEnergySource$(energySource);
  }
  getAreasByEnergySource$(energySource: string) {
    return this.deliveryAreaService.getAreasByEnergySource$(energySource);
  }

  isMarketAreaInDeliveryArea$(
    marketAreaId: ID,
    deliveryAreaId: ID
  ): Observable<boolean> {
    return this.areaService.isMarketAreaInDeliveryArea$(
      marketAreaId,
      deliveryAreaId
    );
  }
}

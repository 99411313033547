import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { NotificationCenterComponent } from '@clean-code/notifications/feature-notification-center';
import { DashboardMenuComponent } from '@clean-code/shared/dashboard/dashboard/feature-dashboard-menu';
import { UserLimitUiViolationNotificationModule } from '@clean-code/user-limit/ui-violation-notification';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SideNavItemComponent } from './components/side-nav-item/side-nav-item.component';
import { SideNavMenuComponent } from './components/side-nav-menu/side-nav-menu.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { TailwindLayoutComponent } from './layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatMenuModule,

    FontAwesomeModule,
    TranslocoModule,
    NgxPermissionsModule,

    DashboardMenuComponent,

    //notification center
    NotificationCenterComponent,

    //notification items
    UserLimitUiViolationNotificationModule,
  ],
  declarations: [
    TailwindLayoutComponent,
    TopNavComponent,
    SideNavComponent,
    SideNavItemComponent,
    UserMenuComponent,
    SideNavMenuComponent,
  ],
})
export class TailwindLayoutModule {}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '@clean-code/shared/common';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  imports: [RouterModule, CommonModule, TranslocoModule, ProgressBarModule],
})
export class PageContainerComponent {
  public pageTitle$ = this.service.pageTitle$;

  constructor(public service: NavigationService) {}
}

import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

@Injectable({
  providedIn: 'root',
})
export class DateParserService {
  private static ISO_8601_FULL =
    /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

  public static getJsonDateReviver(): (
    name: unknown,
    value: unknown
  ) => unknown {
    return (_, value) => {
      //check for full iso_8601 compliance
      if (typeof value === 'string' && this.ISO_8601_FULL.test(value)) {
        return dayjs(value).toDate();
      }
      //check year, month, date only
      if (
        typeof value === 'string' &&
        dayjs(value, 'YYYY-MM-DD', true).isValid()
      ) {
        return dayjs(value).toDate();
      }
      return value;
    };
  }
}

import { Component, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthQuery } from '@clean-code/shared/auth/util-auth';
import { indicate } from '@clean-code/shared/common';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Contact } from '../models/contact';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'fof-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnDestroy {
  public form: UntypedFormGroup;
  public personId: UntypedFormControl = new UntypedFormControl('');
  public name: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  public email: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  public message: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(256),
  ]);
  public honeypot: UntypedFormControl = new UntypedFormControl(''); // we will use this to prevent spam
  public submitted = false; // show and hide the success message
  public isLoading = false; // disable the submit button if we're loading
  public responseMessage: string; // the response message to show to the user

  public isLoading$ = new BehaviorSubject<boolean>(false);
  private closeSubject = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private toastService: ToastService,
    authQuery: AuthQuery
  ) {
    this.form = this.formBuilder.group({
      personId: this.personId,
      name: this.name,
      email: this.email,
      message: this.message,
      honeypot: this.honeypot,
    });

    authQuery.userData$
      .pipe(
        filter((userData) => !!userData),
        takeUntil(this.closeSubject)
      )
      .subscribe((user) => {
        this.personId.setValue(user.id);
        this.name.setValue(user.fullName);
        this.email.setValue(user.email);
      });
  }

  public onSubmit() {
    if (this.form.status == 'VALID' && this.honeypot.value == '') {
      this.form.disable(); // disable the form if it's valid to disable multiple submissions
      const contact: Contact = {
        personId: this.form.get('personId').value,
        name: this.form.get('name').value,
        email: this.form.get('email').value,
        message: this.form.get('message').value,
      };
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits

      this.contactService
        .sendContactMail$(contact)
        .pipe(
          indicate(this.isLoading$),
          tap((result) => {
            if (result) {
              this.responseMessage = 'contact.SENDMESSAGE_SUCCESS';
              this.message.setValue('');
              this.toastService.showSuccess(this.responseMessage);
            } else {
              this.responseMessage = 'contact.SENDMESSAGE_ERROR';
              this.toastService.showError(this.responseMessage);
            }
          }),
          catchError((_: boolean) => {
            this.responseMessage = 'contact.SENDMESSAGE_ERROR';
            this.toastService.showError(this.responseMessage);

            return of(false);
          }),
          finalize(() => {
            this.submitted = true; // show the response message
            this.form.enable(); // re enable the form after a success
            this.isLoading = false; // re enable the submit button
          }),
          first()
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }
}

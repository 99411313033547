export type ContactsPermissionValues =
  | 'contacts.company.manage'
  | 'contacts.company.view'
  | 'contacts.power.user'
  | 'contacts.gas.user'
  | 'contacts.person.manage'
  | 'contacts.person.view'
  | 'contacts.person.show_only_own'
  | 'setting.admin'
  | 'setting.power_user'
  | 'setting.gas_user'
  | 'keyaccountmanager.power-receive-mail'
  | 'keyaccountmanager.gas-receive-mail'
  | 'customer-users.menue'
  | 'customer-users.manage'
  | 'customer-users.unlock'
  | 'can_see_data_history';

export class ContactsPermissions {
  public static readonly companyManagePermission: ContactsPermissionValues =
    'contacts.company.manage';
  public static readonly companyViewPermission: ContactsPermissionValues =
    'contacts.company.view';
  public static readonly powerUser: ContactsPermissionValues =
    'contacts.power.user';
  public static readonly gasUser: ContactsPermissionValues =
    'contacts.gas.user';
  public static readonly personManagePermission: ContactsPermissionValues =
    'contacts.person.manage';
  public static readonly personViewPermission: ContactsPermissionValues =
    'contacts.person.view';
  public static readonly showOnlyOwned: ContactsPermissionValues =
    'contacts.person.show_only_own';
  public static readonly settingAdmin: ContactsPermissionValues =
    'setting.admin';
  public static readonly settingPowerUser: ContactsPermissionValues =
    'setting.power_user';
  public static readonly settingGasUser: ContactsPermissionValues =
    'setting.gas_user';

  public static readonly keyAccountManagerPowerReceiveMail: ContactsPermissionValues =
    'keyaccountmanager.power-receive-mail';
  public static readonly keyAccountManagerGasReceiveMail: ContactsPermissionValues =
    'keyaccountmanager.gas-receive-mail';

  public static readonly customerUserMenue: ContactsPermissionValues =
    'customer-users.menue';
  public static readonly customerUserManage: ContactsPermissionValues =
    'customer-users.manage';
  public static readonly customerUserUnlock: ContactsPermissionValues =
    'customer-users.unlock';

  public static readonly canSeeDataHistory: ContactsPermissionValues =
    'can_see_data_history';

  public static companySideNavPermisssions = [
    ContactsPermissions.companyManagePermission,
    ContactsPermissions.companyViewPermission,
  ];

  public static personSideNavPermisssions = [
    ContactsPermissions.personManagePermission,
    ContactsPermissions.personViewPermission,
  ];

  public static customerUserSideNavPermisssions = [
    ContactsPermissions.customerUserMenue,
  ];
}

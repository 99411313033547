import { enableProdMode } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { Chart } from 'chart.js';
import { SentryHelper } from 'shared/util/util-sentry-logging';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

Chart.defaults.font.family = "'VAGRounded_Light', sans-serif";

if (environment.production) {
  SentryHelper.init({
    dsn: 'https://ea5e6041c8984053afca5bfcb6fb200a@o362612.ingest.sentry.io/5682157',
    environment: environment.sentryEnv,
    networkDetailAllowUrls: [
      environment.api,
      environment.api + '/userProfile/me',
      environment.graphql,
    ],
    ignoreErrors: ['/ResizeObserver loop/'],
    tracePropagationTargets: [
      'localhost',
      environment.api,
      environment.graphql,
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}

(window as any).CC = {
  version: environment.version,
};

// platformBrowserDynamic()
//   .bootstrapModule(AppModule, {
//     defaultEncapsulation: ViewEncapsulation.None,
//   })
//   .catch((err) => console.error(err));

bootstrapApplication(AppComponent, appConfig)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    (window as any)['ngRef']?.destroy();

    (window as any)['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));

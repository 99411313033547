import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';

export class SplashScreenState {
  show = true;
}
@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  private state = inject(RxState<SplashScreenState>);

  public showSplash$ = this.state.select('hide');

  public hideState() {
    this.state.set({ hide: false });
  }
}

import { Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import {
  TradingCalendarHoliday,
  TradingCalendarHolidayAddInput,
} from '../entities/trading-calendar-holiday';

import { TradingCalendarHolidayDataService } from '../infrastructure/trading-calendar-holiday.data.service';

@Injectable({ providedIn: 'root' })
export class TradingCalendarHolidayListFacade
  implements EditSearchDataService<TradingCalendarHoliday>
{
  constructor(
    private tradingCalendarHolidayDataService: TradingCalendarHolidayDataService
  ) {}

  public getById$(id: ID): Observable<TradingCalendarHoliday> {
    return this.tradingCalendarHolidayDataService.getById$(id);
  }

  public getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<TradingCalendarHoliday>> {
    return this.tradingCalendarHolidayDataService.getAll$(params);
  }

  public add$(input: TradingCalendarHolidayAddInput): Observable<ID> {
    return this.tradingCalendarHolidayDataService.add$(input);
  }

  public update$(input: TradingCalendarHolidayAddInput): Observable<ID> {
    return this.tradingCalendarHolidayDataService.update$(input);
  }

  public delete$(id: ID): Observable<boolean> {
    return this.tradingCalendarHolidayDataService.delete$(id);
  }

  public addDefaultHolidays$(
    from: Dayjs,
    to: Dayjs,
    tradingCalendarId: ID
  ): Observable<boolean> {
    return this.tradingCalendarHolidayDataService.addDefaultHolidays$(
      from,
      to,
      tradingCalendarId
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SettingSpotDataService, SpotTimeSeriesData, SpotType } from '@backoffice-frontend/setting-lib';
import { ChartHelper } from '@backoffice-frontend/shared/components/ui-chart-js';
import { indicate } from '@clean-code/shared/common';
import {
  DevExtremeChartContainerComponent,
  DevExtremeLineChartComponent,
  LineChartModel
} from '@clean-code/shared/components/ui-devextreme-charts';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TitleOptions } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, filter, map, startWith, switchMap } from 'rxjs';
import { dateJsProviders, UtilDateModule } from '@clean-code/shared/util/util-date';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fof-spot-chart',
  templateUrl: './spot-chart.component.html',
  styleUrls: ['./spot-chart.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatProgressBarModule,
    MatTooltipModule,

    TranslocoModule,
    FontAwesomeModule,

    UtilDateModule,
    DevExtremeLineChartComponent,
    DevExtremeChartContainerComponent
  ],
  providers: [
    dateJsProviders()
  ]
})
export class SpotChartComponent {
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  isLoading$ = new BehaviorSubject<boolean>(true);
  yAxisTitle: TitleOptions;

  dateControl = new FormControl<Dayjs | null>(null);

  maxSpotDate: Dayjs | null = dayjs();

  @Input() spotConfigurationId: number;
  @Input() spotType: SpotType;

  @Input()
  public legend = false;

  spotLabel: string = null;
  spotValue = 0;

  //unfortunately this fires twice as setting the max value will trigger valueChanges :(
  //no fix from angular-material team since 2018
  //the filter used below fixes this behavior
  public data$ = this.dateControl.valueChanges.pipe(
    startWith(null),
    filter((value) => value !== this.maxSpotDate), //to counter valueChanges because of max date
    switchMap((date) =>
      this.spotDataService
        .getSpotTimeSeriesDatas(this.spotConfigurationId, this.spotType, date)
        .pipe(indicate(this.isLoading$))
    ),
    map((values: SpotTimeSeriesData[]) => {
      const array = new Array<any>();

      if (values && values.length > 0) {
        this.yAxisTitle = ChartHelper.createYAxisChartTitle(values[0].unit);

        //set value from backend if it differs (e.g. when backend is called without a date)
        const returnedDate = dayjs(values[0].timeStamp).startOf('day');
        if (returnedDate.diff(this.dateControl.value?.startOf('day')) !== 0) {
          this.dateControl.setValue(returnedDate, { emitEvent: false });

          //also set new max value
          this.maxSpotDate = returnedDate;
        }
      }

      //as the last entry is 24 but the values in the db do not use 23-24 but 00:00 we have to add one day to the last entry
      //otherwise the chart will place it at the beginning but will connect the dot from the end
      const twentyFour = values.find((x) => x.timeStamp.getHours() === 0);
      if (twentyFour) {
        twentyFour.timeStamp = dayjs(twentyFour.timeStamp)
          .add(1, 'day')
          .toDate();
      }

      this.spotLabel =
        'Spot ' +
        this.translocoService.translate(
          'market-dashboard.widgets.settings.SPOTTYPE_' + this.spotType
        ); // +
      //' - ';

      // const data = values.map((val) => ({
      //   x: val.timeStamp,
      //   y: val.value,
      // }));

      const data = {
        label: this.spotLabel,
        data: values.map((val: SpotTimeSeriesData) => ({
          date: new Date(val.timeStamp),
          value: val.value
        })),
        yAxisTitle: this.yAxisTitle.text
      } as LineChartModel;

      this.spotValue =
        values.length === 0
          ? 0
          : values.map((val) => val.value).reduce((a, b) => a + b, 0) /
          values.length;

      //line-chart
      // array.push({
      //   label:
      //     this.spotLabel +
      //     this.translocoService.translate('market-dashboard-details.LINE'),
      //   line: {
      //     spanGaps: false, //eventually if off-peak with gaps is used (needs null value in data to produce gap!)
      //   },
      //   data: data,
      //   type: 'line',
      //   xAxisID: 'line-x',
      //   yAxisID: 'y',
      // });

      //bar chart
      // array.push({
      //   label: this.spotLabel, //+ this.translocoService.translate('market-dashboard-details.BAR'),
      //   data: data,
      //   type: 'bar',
      //   xAxisID: 'bar-x',
      //   yAxisID: 'y',
      //   opacity: 0.3,
      // });

      array.push(data);
      return array;
    })
  );

  constructor(
    private spotDataService: SettingSpotDataService,
    private deviceService: DeviceDetectorService,
    private translocoService: TranslocoService
  ) {
  }

  public get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  setDate(days: number) {
    const date = this.dateControl.getRawValue().add(days, 'day');
    if (date.startOf('day') <= this.maxSpotDate) {
      this.dateControl.setValue(date);
    }
  }
}

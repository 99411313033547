import { HttpResponse } from '@angular/common/http';

export class HttpResponseHelper {
  public static getHttpResponseFileName(response: HttpResponse<any>): string {
    const contentDisposition = response.headers
      .get('content-disposition')
      .split(';');

    const fileNameDisposition = contentDisposition.find(
      (s) => s.indexOf('filename=') > -1
    );

    let fileName: string;

    if (fileNameDisposition) {
      fileName = fileNameDisposition
        .replace('filename=', '')
        .replace('"', '')
        .replace('"', '')
        .replace('.', ' ')
        .trim();
    } else {
      throw Error('content-disposition not found');
    }

    return fileName;
  }
}

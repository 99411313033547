import { Dayjs } from 'dayjs';

// redundant DataTableParameters because cycle dependency
export enum LogicalFilterOperator {
  and = 'and',
  or = 'or',
}

export enum FilterOperator {
  gt = 'gt',
  gte = 'gte',
  eq = 'eq',
  neq = 'neq',
  in = 'in',
  nin = 'nin',
  ngt = 'ngt',
  lt = 'lt',
  nlt = 'nlt',
  lte = 'lte',
  nlte = 'nlte',
  ncontains = 'ncontains',
  startsWith = 'startsWith',
  nstartsWith = 'nstartsWith',
  endsWith = 'endsWith',
  nendsWith = 'nendsWith',
  contains = 'contains',
}
export interface DataTableParameters {
  page?: number;
  sortBy?: SortBy[];
  perPage?: number;
  searchTerm?: string;
  filters?: FilterParam[];
}

export interface FilterParam {
  key?: string;
  value?: string | number | Dayjs;
  logicOperator?: LogicalFilterOperator;
  operator?: FilterOperator;

  filters?: FilterParam[];
  conditions?: string;
}

export interface SortBy {
  dir: 'ASC' | 'DESC';
  prop: string;
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { SurchargeQuery } from '../+state/surcharge.query';

export const SURCHARGE_PAGINATOR = new InjectionToken('SURCHARGE_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const surchargeQuery = inject(SurchargeQuery);
    return new PaginatorPlugin(surchargeQuery, { startWith: 0 });
  },
});

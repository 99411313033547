import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel,
  UntypedFormControl,
} from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs';

// https://betterprogramming.pub/angular-with-controlvalueaccessor-and-without-less-boilerplate-da27f0a16639
@Directive()
export abstract class BaseFormControlChild
  implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit
{
  public formControl!: FormControl;

  onChange: any = () => ({} as any);
  onTouched: any = () => {
    this.formControl.markAsTouched();
  };

  onValidationChange: any = () => ({} as any);
  protected isDisabled$ = new BehaviorSubject<boolean>(false);

  private cd = inject(ChangeDetectorRef);

  protected readonly destroy = new Subject<void>();
  private injector = inject(Injector);

  public ngOnInit(): void {
    this.setComponentControl();

    this.isDisabled$
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap((value: boolean) => this.setDisabled(value)),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  get value(): any {
    return this.formControl?.value;
  }

  set value(value: any) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.onTouched();
    this.onValidationChange();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    // if (value) {
    //   this.formControl.patchValue(value, { onlySelf: true, emitEvent: false });
    // } else {
    //   this.formControl.reset();
    // }
    this.onChange(value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    return c.valid;
  }

  registerOnValidatorChange?(fn: any): void {
    this.onValidationChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  protected setDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  private setComponentControl(): void {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case NgModel: {
          const { control, update } = formControl as NgModel;

          this.formControl = control;

          this.formControl.valueChanges
            .pipe(
              tap((value: any) => update.emit(value)),
              takeUntil(this.destroy)
            )
            .subscribe();
          break;
        }
        case FormControlName: {
          this.formControl = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        }
        default: {
          this.formControl = (formControl as FormControlDirective)
            .form as FormControl;
          break;
        }
      }
    } catch (error) {
      this.formControl = new FormControl();
    }
  }
}

import { Component, Input } from '@angular/core';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { ActionButton } from '../action-button/action-button';

@Component({
  selector: 'cc-edit-action-button',
  templateUrl: '../action-button/action-button.html',
  styleUrls: ['../action-button/action-button.scss'],
})
export class EditButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'common.EDIT';

  @Input()
  public override icon = faEdit;

  @Input()
  public override displayText = 'common.EDIT';
}

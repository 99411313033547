import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

export class ObjectHelper {
  static isArray(role: string[] | string) {
    return Array.isArray(role);
  }
  public static cloneObject<T>(object: T): T {
    return cloneDeep(object);
  }

  public static compareObject(v1: any, v2: any): boolean {
    return isEqual(v1, v2);
  }

  public static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number' || !isNaN(+value);
  }

  public static isDate(value: any): boolean {
    return value instanceof Date || dayjs.isDayjs(value);
  }

  public static isString(value: any): boolean {
    return value instanceof String;
  }

  public static isObject(value: any): boolean {
    return value instanceof Object;
  }

  //https://github.com/dsherret/ts-nameof/issues/121
  public static nameof<TObject>(obj: TObject, key: keyof TObject): string;
  public static nameof<TObject>(key: keyof TObject): string;
  public static nameof(key1: any, key2?: any): any {
    return key2 ?? key1;
  }
}

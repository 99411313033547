import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { ConfirmModule } from '@clean-code/shared/components/ui-confirm';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TerminWidgetDialogComponent } from './components/termin-widget-dialog/termin-widget-dialog.component';
import { TerminWidgetComponent } from './termin-widget.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    PipesModule,
    TranslocoModule,
    TranslocoLocaleModule,
    FontAwesomeModule,

    ConfirmModule,
    FormContainerModule,
  ],
  declarations: [TerminWidgetComponent, TerminWidgetDialogComponent],
  exports: [TerminWidgetComponent],
})
export class NewsFeatureTerminWidgetModule {}

import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TimeSeriesIdentifierInput } from '../models/timeseries-input';

// TODO: https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28

export class TimeSeriesIdentifierInputForm extends UntypedFormGroup {
  constructor(
    readonly model?: TimeSeriesIdentifierInput,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    super(
      fb.group({
        identifierId: [null, [Validators.required]],
        identifier: [null],
        adjustment: [0, [Validators.required]],
        sortOrder: [null], //also used to find old additional settings
        calculationType: ['ABSOLUTE', [Validators.required]],
        ignoreGaps: [false],
        continuously: [false],
      }).controls,
      validatorOrOpts
    );
    if (model) {
      if (model.calculationType) {
        model.calculationType = 'ABSOLUTE';
      }
      this.patchValue(model);
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { EnvConfig, ENV_TOKEN } from '../models/config.model';

@Injectable()
export class ConfigService {
  constructor(@Inject(ENV_TOKEN) private env: EnvConfig) {}

  public get settings() {
    return this.env;
  }

  public getByKey(key: string) {
    var config = this.env as any;
    return config[key];
  }
}

import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TailwindThemeConfig } from './tailwind-theme-config';
import { TailwindThemeService } from './tailwind-theme.service';

// @dynamic
export function initTailwindThemeConfig(
  tailwindThemeSvc: TailwindThemeService
) {
  const func = () => tailwindThemeSvc.loadConfig();
  return func;
}
@NgModule({
  imports: [CommonModule],
  providers: [
    TailwindThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initTailwindThemeConfig,
      deps: [TailwindThemeService],
      multi: true,
    },
  ],
})
export class TailwindThemeModule {
  static forRoot(
    config?: TailwindThemeConfig
  ): ModuleWithProviders<TailwindThemeModule> {
    return {
      ngModule: TailwindThemeModule,
      providers: [
        {
          provide: TailwindThemeConfig,
          useValue: config,
        },
        TailwindThemeService,
      ],
    };
  }
}

import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { MarketColumnDetail } from './../models/market-dashboard';
import {
  IMarketColumnDetailState,
  MarketColumnDetailStore,
} from './details-column.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'position',
  sortByOrder: Order.ASC,
})
export class MarketColumnDetailQuery extends QueryEntity<
  IMarketColumnDetailState,
  MarketColumnDetail
> {
  public columns$ = (id: ID) =>
    this.selectAll({
      filterBy: (entity) => entity.columnId === id,
    });

  public columnCount$ = (id: ID) => this.selectCount((x) => x.columnId === id);

  constructor(protected store: MarketColumnDetailStore) {
    super(store);
  }
}

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  EnumService,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { DirectivesModule } from '@backoffice-frontend/shared/directives/common';
import {
  TimeSeriesDynamicSelectionGroupComponent,
  WidgetTypeConfig,
} from '@backoffice-frontend/time-series-identifier/api';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { FormDirectiveModule } from '@clean-code/shared/directives/ui-form';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { atLeastOne } from '@clean-code/shared/util/util-form';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { Dayjs } from 'dayjs';
import { filter, takeUntil, tap } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './export-setting.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,

    TranslocoModule,

    UtilDateModule,
    DirectivesModule,
    TimeSeriesDynamicSelectionGroupComponent,
    FormDirectiveModule,
  ],
})
export class ExportTableSettingComponent extends BaseWidgetTypeConfigComponent<WidgetTypeConfig> {
  private enumService = inject(EnumService);

  public timeFrames$ = this.enumService.getEnumType$('TimeSeriesTimeFrame');
  public faTimes = faTimes;

  override form = new FormGroup(
    {
      settings: new FormGroup({
        timeFrame: new FormControl<TimeFrame>('M_3'),
        startDate: new FormControl<Dayjs>(null),
        showInPercentage: new FormControl(false),
      }),
      dataSources: new FormArray([]),
    },
    {
      validators: atLeastOne(Validators.required, [
        'settings.timeFrame',
        'settings.startDate',
      ]),
    }
  );

  constructor() {
    super();

    this.form
      .get('settings.startDate')
      .valueChanges.pipe(
        filter((value: Dayjs) => !!value),
        tap(() => this.form.get('settings.timeFrame').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.form
      .get('settings.timeFrame')
      .valueChanges.pipe(
        filter((value: TimeFrame) => !!value),
        tap(() => this.form.get('settings.startDate').reset()),
        takeUntil(this.closeSubject)
      )
      .subscribe();
  }
}

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { Company } from '../models/company';
import { CompanyStore } from './company.store';

export const companyFormName = 'companyForm';

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends AkitaSearchQueryEntity<Company> {
  constructor(protected store: CompanyStore) {
    super(store);
  }
}

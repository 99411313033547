<page-container>
  <mat-progress-bar
    *ngIf="isLoading$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
  <ng-container title>E-Steiermark Business GmbH</ng-container>
  <p>
    Adresse<br />E-Steiermark Business GmbH<br />Leonhardg&uuml;rtel 10<br />A-8010
    Graz
  </p>
  <p>
    Telefon: <a href="tel:+4331690000">+43 (0) 316 9000-0</a><br />Fax: +43 (0)
    316 9000-22 909<br />E-Mail:
    <a href="mailto:business@e-steiermark.com">business@e-steiermark.com</a
    ><br />Internet:
    <a href="https://www.e-steiermark.com" target="_blank"
      >www.e-steiermark.com</a
    >
  </p>
  <p class="text-primary-color font-bold text-lg">
    {{ 'contact.CONTACT' | transloco }}
  </p>
  <form
    class="contact"
    [formGroup]="form"
    method="post"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field>
      <mat-label>{{ 'contact.NAME' | transloco }}</mat-label>
      <input matInput [formControl]="name" required />
      <mat-error *ngIf="name.invalid">{{
        'contact.NAME_HINT' | transloco
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'contact.EMAIL' | transloco }}</mat-label>
      <input
        matInput
        [formControl]="email"
        placeholder="email@example.com"
        required
      />
      <mat-error *ngIf="email.invalid">{{
        'contact.EMAIL_HINT' | transloco
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'contact.MESSAGE' | transloco }}</mat-label>
      <textarea
        class="h-52 border-transparent focus:border-transparent focus:ring-0"
        matInput
        [formControl]="message"
        maxlength="512"
        placeholder="{{ 'contact.MESSAGE_PLACEHOLDER' | transloco }}"
        required
      ></textarea>
      <mat-hint align="end"> {{ message.value?.length || 0 }}/512</mat-hint>
      <mat-error *ngIf="message.invalid">{{
        'contact.MESSAGE_HINT' | transloco
      }}</mat-error>
    </mat-form-field>
    <button
      [disabled]="isLoading"
      class="submit hover:text-primary-color"
      type="submit"
    >
      {{ 'contact.SUBMIT' | transloco }}
    </button>
    <input [formControl]="honeypot" class="hidden" type="text" />
    <div [ngClass]="!submitted ? 'hidden' : 'block'" class="center-text">
      <span>{{ responseMessage | transloco }}</span>
    </div>
  </form>
</page-container>

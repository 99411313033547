import { Observable, Subject } from 'rxjs';

export abstract class CanDeactivateComponent {
  protected closeSubject = new Subject<void>();

  abstract canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;

  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //     if (!this.canDeactivate()) {
  //         $event.returnValue = true;
  //     }
  // }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmService } from './confirm.service';
import { ConfirmDeleteComponent } from './dialogs/confirm-delete/confirm-delete.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MatDialogModule,
    MatButtonModule,

    FontAwesomeModule,
  ],
  declarations: [
    ConfirmDeleteComponent,
    ConfirmDialogComponent,
    // AddNewDialogComponent,
  ],
  exports: [
    ConfirmDeleteComponent,
    ConfirmDialogComponent,
    MatDialogModule,
    MatButtonModule,
    // AddNewDialogComponent,
  ],
  providers: [ConfirmService],
})
export class ConfirmModule {}

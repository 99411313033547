import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formContainerFormRow]',
})
export class FormRowDirective {
  @HostBinding('class') elementClass =
    'formContainerFormRow flex flex-col md:flex-row space-x-0 md:space-x-2';
}

<div
  class="widget-type-content-component w-full h-full"
  class="{{ widget().type }}"
>
  <ng-container
    *ngTemplateOutlet="
      contentCmponent ? widget_template : widget_not_exist;
      context: { type: { component: contentCmponent, inputs: inputs() } }
    "
  >
  </ng-container>
</div>

<ng-template #widget_template let-type="type">
  <div class="header-template">
    <ng-container
      *ngIf="headerTemplate"
      [ngTemplateOutlet]="headerTemplate"
    ></ng-container>
  </div>

  <ndc-dynamic
    #component
    class="w-full h-full"
    [ndcDynamicComponent]="type.component"
    [ndcDynamicInputs]="type.inputs"
  >
  </ndc-dynamic>
</ng-template>

<ng-template #widget_not_exist let-type="type">
  <div class="flex flex-col text-center">
    {{ 'common.NOT_FOUND' | transloco }}
  </div></ng-template
>

import { TimeSeriesAnalysisIdentifierDataLocalized } from '../models/time-series-analysis-identifier-data';

export function timeSeriesCompare(
  timeSeries1: TimeSeriesAnalysisIdentifierDataLocalized,
  timeSeries2: TimeSeriesAnalysisIdentifierDataLocalized
): boolean {
  return (
    timeSeries1?.identifier === timeSeries2?.identifier &&
    timeSeries1?.timeStamp?.getTime() === timeSeries2?.timeStamp?.getTime()
  );
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { MultiSelectAutocompleteComponent } from './multi-select-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatSelectModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatCheckboxModule,
    MatInputModule,

    TranslocoModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
  ],
  declarations: [MultiSelectAutocompleteComponent],
  exports: [MultiSelectAutocompleteComponent],
})
export class MultiSelectAutocompleteModule {}

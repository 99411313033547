import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

dayjs.extend(customParseFormat);

export class DateHttpInterceptor implements HttpInterceptor {
  // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            this.convertToDate(body);
          }
        },
        //TODO: this necessary?
        // error: (err: any) => {
        //         if (err instanceof HttpErrorResponse) {
        //             if (err.status === 401) {
        //             }
        //         }
        // },
      })
    );
  }

  convertToDate(body: unknown) {
    if (typeof body === 'object' && body !== null) {
      const casted = body as { [key: string]: unknown };
      for (const key of Object.keys(casted)) {
        const value = casted[key];
        if (this.isIso8601(value)) {
          casted[key] = dayjs(value).toDate();
        } else if (typeof value === 'object' && value !== null) {
          this.convertToDate(value);
        }
      }
    }

    return body;
  }

  isIso8601(value: unknown): value is string {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return (
        this.iso8601.test(value) || dayjs(value, 'YYYY-MM-DD', true).isValid()
      );
    }
    return false;
  }
}

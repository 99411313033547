<fof-side-nav-item
  [item]="start"
  (click)="toggleUserMenu()"
></fof-side-nav-item>

<span dashboard-menu class="dashboard-menu"></span>

<fof-side-nav-item
  [item]="energy_market"
  (click)="toggleUserMenu()"
></fof-side-nav-item>

<fof-side-nav-item
  [item]="market"
  (click)="toggleUserMenu()"
></fof-side-nav-item>

<fof-side-nav-item
  [item]="spreads"
  (click)="toggleUserMenu()"
></fof-side-nav-item>

<fof-side-nav-item
  *ngxPermissionsOnly="limitReportPermissions.sideNavPermission"
  [item]="limitReports"
  (click)="toggleUserMenu()"
></fof-side-nav-item>

<fof-side-nav-item [item]="wiki" (click)="toggleUserMenu()"></fof-side-nav-item>

<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-white">
  <body class="h-full">
  ```
-->
<div class="min-h-full">
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <fof-side-nav> </fof-side-nav>
  <!-- Main column -->
  <div class="xl:pl-64 flex flex-col min-h-full h-screen">
    <!-- Search header -->
    <div
      class="fixed w-full top-0 z-10 shrink-0 flex h-16 bg-white border-b border-gray-200 xl:hidden print:hidden"
    >
      <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. -->
      <button
        (click)="toggleUserMenu()"
        type="button"
        class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-color-500 xl:hidden"
      >
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-1 -->
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      </button>
      <div class="flex-1 flex justify-between px-4 sm:px-6 xl:px-8">
        <div class="flex-1 flex">
          <img
            src="assets/img/logo_estmk.svg"
            alt="Energie Steiermark"
            class="h-16 p-1 w-auto"
          />
        </div>
        <div class="flex items-center">
          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <cc-user-menu [mobile]="true"></cc-user-menu>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
          </div>
        </div>
      </div>
    </div>
    <main
      class="flex-1 h-full xl:h-screen pt-16 xl:pt-0 print:pt-0 print:!overflow-visible"
    >
      <router-outlet></router-outlet>
    </main>
    <footer class="bg-white print:hidden !hidden">
      <div class="flex items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-400 py-1">
            <a
              href="https://e-steiermark.com/"
              class="text-primary-color hover:text-primary-color-600"
              target="_blank"
            >
              E-Steiermark</a
            >,
            <span class="px-1">Leonhardgürtel 10, A-8010 Graz,</span>
            &copy; <span>{{ year }},</span>
            <a
              class="text-primary-color hover:text-primary-color-600"
              routerLink="/imprint"
            >
              {{ 'fof.IMPRINT' | transloco }}</a
            >,
            <a
              class="text-primary-color hover:text-primary-color-600"
              routerLink="/disclaimer"
            >
              {{ 'fof.DISCLAIMER' | transloco }} </a
            >,
            <a
              class="text-primary-color hover:text-primary-color-600"
              routerLink="/contact"
            >
              {{ 'fof.CONTACT' | transloco }}
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>

import { Injectable, inject } from '@angular/core';
import { AuthQuery } from '@clean-code/shared/auth/util-auth';
import { ArrayHelper } from '@clean-code/shared/common';

@Injectable({ providedIn: 'root' })
export class DashboardValidateService {
  public canAddWidgetOrChangeTenant(
    tenant: Array<string>,
    dashboardTenant: Array<string>
  ): boolean {
    return ArrayHelper.anyElement<string>(dashboardTenant, tenant);
  }

  private authQuery = inject(AuthQuery);
  public hasPermission(permissions: string[]): boolean {
    // check if permissions is empty
    if (permissions.length === 0) {
      return true;
    }

    const userPermissions = this.authQuery.permissionsSignal();

    // check if any of permissions is in userPermissions
    return ArrayHelper.anyElement<string>(permissions, userPermissions);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Route, RouterModule } from '@angular/router';
import { EnergySourceService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { EnergySourceDropdownComponent } from '@clean-code/shared/bo/components/ui-energy-source-dropdown';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { InputTranslationModule } from '@clean-code/shared/components/ui-translation';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { EnergySourceDetailsComponent } from './energy-source-details.component';

const routes: Route[] = [
  {
    path: '',
    component: EnergySourceDetailsComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,

    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,

    TranslocoModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    PageContainerModule,
    FormContainerModule,
    ActionButtonModule,
    PipesModule,
    EnergySourceDropdownComponent,
    InputTranslationModule,
  ],
  declarations: [EnergySourceDetailsComponent],
  exports: [EnergySourceDetailsComponent],
  providers: [EnergySourceService],
})
export class EnergySourceDetailsModule {}

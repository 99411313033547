import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { CompanyRelation } from '../models/company-relation';

export interface CompanyRelationState
  extends EntityState<CompanyRelation>,
    ActiveState {
  searchTerm: string;
}

function createInitialState(): CompanyRelationState {
  return {
    active: null,
    searchTerm: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company_relation' })
export class CompanyRelationStore extends EntityStore<CompanyRelationState> {
  constructor() {
    super(createInitialState());
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  NewsTranslated,
  NewsType,
  NewsWidgetQuery,
  NewsWidgetStore,
  WidgetFacade,
} from '@backoffice-frontend/news/domain';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NewsWidgetDialogComponent } from './components/news-widget-dialog/news-widget-dialog.component';

@Component({
  selector: 'news-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NewsWidgetComponent {
  @HostBinding('class') class = 'news-widget';

  public isLoading$ = new BehaviorSubject<boolean>(false);

  @Input()
  type: NewsType = 'ALL';

  public data$ = this.languageService.languageChangesWithDefault$.pipe(
    switchMap(() =>
      //this was first done via observables as privates and forkjoin, but this lead to multi-loading, strange huh?
      this.widgetFacade.getNews$(this.type, {}).pipe(indicate(this.isLoading$))
    ),
    tap((values: NewsTranslated[]) => {
      this.newsWidgetStore.reset();
      if (values) {
        this.newsWidgetStore.upsertMany(values);
      }
    })
  );

  displayedColumns: string[] = ['icon', 'name', 'date'];
  constructor(
    private widgetFacade: WidgetFacade,
    private languageService: LanguageService,
    private newsWidgetStore: NewsWidgetStore,
    public query: NewsWidgetQuery,
    private confirmService: ConfirmService,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  public select(news: NewsTranslated) {
    this.newsWidgetStore.setActive(news.id);

    if (this.deviceDetectorService.isMobile()) {
      this.confirmService
        .open(news.name, '', NewsWidgetDialogComponent, null, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: '100vw',
          height: '100vh',
        })
        .subscribe();
    } else {
      this.confirmService
        .open(news.name, '', NewsWidgetDialogComponent, null, {
          width: '50%',
        })
        .subscribe();
    }
  }
}

import { Injectable } from '@angular/core';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { ID } from '@clean-code/shared/common';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { TimeSeriesDataService } from '../infrastructure/time-series.data.service';
import { AroonSummary } from '../models/aroon-summary';
import { BollingerBandSummary } from '../models/bollinger-with-index';
import { AroonSettings } from '../models/settings/aroon-settings';
import { BollingerSettings } from '../models/settings/bollinger-settings';
import { SmaSettings } from '../models/settings/sma-settings';
import { SmaGroupWithIndex } from '../models/sma-group-with-index';
import { TimeSeriesAnalysisIdentifierDataLocalized } from '../models/time-series-analysis-identifier-data';
import { ChartTimeSeries, TableTimeSeries } from '../models/time-series-widget';
import {
  DeltaType,
  TimeSeriesIdentifierInput,
} from '../models/timeseries-input';

@Injectable({ providedIn: 'root' })
export class TimeSeriesFacade {
  constructor(private timeSeriesDataService: TimeSeriesDataService) {}

  public getTimeSeriesChartData$(
    ids: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame
  ): Observable<ChartTimeSeries[]> {
    return this.timeSeriesDataService.getTimeSeriesChartData$(ids, timeFrame);
  }

  public chartComparisonTimeSeries$(
    ids: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame
  ) {
    return this.timeSeriesDataService.chartComparisonTimeSeries$(
      ids,
      timeFrame
    );
  }

  public getTimeSeriesTableData$(
    ids: TimeSeriesIdentifierInput[],
    deltaType: DeltaType = 'DAY',
    showIntraDay: boolean
  ): Observable<TableTimeSeries[]> {
    return this.timeSeriesDataService.getTimeSeriesTableData$(
      ids,
      deltaType,
      showIntraDay
    );
  }

  public getAroonChartData$(
    id: TimeSeriesIdentifierInput,
    settings: AroonSettings,
    timeFrame: TimeFrame
  ): Observable<AroonSummary> {
    return this.timeSeriesDataService.getAroonChartData$(
      id,
      settings,
      timeFrame
    );
  }

  public getMAChartData$(
    id: TimeSeriesIdentifierInput,
    settings: SmaSettings,
    timeFrame: TimeFrame
  ): Observable<SmaGroupWithIndex> {
    return this.timeSeriesDataService.getMAChartData$(id, settings, timeFrame);
  }

  getBollingerBandData$(
    identifier: TimeSeriesIdentifierInput,
    settings: BollingerSettings,
    timeFrame: TimeFrame
  ): Observable<BollingerBandSummary> {
    return this.timeSeriesDataService.getBollingerBandData$(
      identifier,
      settings,
      timeFrame
    );
  }

  timeSeriesIdentifierDataByEnergySource$(
    energySourceId: ID
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.timeSeriesDataService.timeSeriesIdentifierDataByEnergySource$(
      energySourceId
    );
  }

  timeSeriesIdentifierDataByMarketArea$(
    areaId: ID,
    from: Dayjs = null,
    to: Dayjs = null
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.timeSeriesDataService.timeSeriesIdentifierDataByMarketArea$(
      areaId,
      from,
      to
    );
  }
}

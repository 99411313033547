<div *ngIf="!componentRef" style="border: 1px solid red">
  MISSING DEFINITION FOR '{{ notification.type }}'
</div>
<div
  class="flex flex-row items-center place-content-start notification notification-content"
  [ngClass]="{ read: notification.isRead }"
  mat-ripple
>
  <div (click)="notificationClicked($event)" class="flex flex-col">
    <ng-template notificationsNotificationHost></ng-template>
  </div>
  <!-- <span fxFlex></span> -->
  <div
    *ngIf="componentRef && !notification.isRead"
    class="flex flex-col small-icon-style"
  >
    <fa-icon
      [icon]="faEnvelopeOpen"
      (click)="markMessageAsRead($event)"
      title="{{ 'notification.MARKREAD' | transloco }}"
    ></fa-icon>
  </div>
</div>
<div class="divider"></div>

import { Color, TailwindTheme } from './tailwind-theme.interface';
export function computeColorPalette(hex: string): Color[] {
  const tinycolor = require('tinycolor2');
  return [
    getColorObject(tinycolor(hex).lighten(45), '50'),
    getColorObject(tinycolor(hex).lighten(40), '100'),
    getColorObject(tinycolor(hex).lighten(30), '200'),
    getColorObject(tinycolor(hex).lighten(20), '300'),
    getColorObject(tinycolor(hex).lighten(10), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(10), '600'),
    getColorObject(tinycolor(hex).darken(20), '700'),
    getColorObject(tinycolor(hex).darken(30), '800'),
    getColorObject(tinycolor(hex).darken(40), '900'),

    getColorObject(tinycolor(hex).lighten(20), '1'),
    getColorObject(tinycolor(hex).lighten(10), '2'),
    getColorObject(tinycolor(hex), '3'),
    getColorObject(tinycolor(hex).darken(10), '4'),

    getColorObject(tinycolor(hex).lighten(20), '5'),
    getColorObject(tinycolor(hex).lighten(10), '6'),
    getColorObject(tinycolor(hex), '7'),
    getColorObject(tinycolor(hex).darken(10), '8'),
  ];
}
export function getColorObject(value: any, name: string): Color {
  const tinycolor = require('tinycolor2');

  const c = tinycolor(value);
  return {
    name,
    hex: c.toHexString(),
    isDarkContrast: c.isLight(),
  };
}
export function updateThemeVariables(theme: TailwindTheme, document: Document) {
  for (const [name, color] of Object.entries(theme)) {
    if (name.indexOf('chart') <= -1) {
      const palette = computeColorPalette(color);
      for (const variant of palette) {
        document.documentElement.style.setProperty(
          `--${name}-${variant.name}`,
          variant.hex
        );
      }
    } else {
      document.documentElement.style.setProperty(`--${name}`, color);
    }
  }
}

export function updateThemeVariablesList(
  theme: TailwindTheme
): Map<string, string> {
  const colors = new Map<string, string>();
  for (const [name, color] of Object.entries(theme)) {
    if (name.indexOf('chart') <= -1) {
      const palette = computeColorPalette(color);
      for (const variant of palette) {
        colors.set(`${name}-${variant.name}`, variant.hex);
      }
    } else {
      colors.set(`${name}`, color);
    }
  }

  return colors;
}

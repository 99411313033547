import { Directive, OnInit, inject } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Directive()
//  implements OnInit
export abstract class FormGroupAccessorConnector<TForm extends AbstractControl>
  implements OnInit
{
  public formGroup: TForm;
  protected controlContainer = inject(ControlContainer);

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as TForm;
  }
}

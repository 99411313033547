<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="flex flex-row print:m-3">
  <ng-content select="[form-title]" class="form-title"> </ng-content>
  <span class="flex flex-grow">
    <!--Space-->
  </span>
  <ng-content select="[form-buttons]"> </ng-content>
</div>
<div class="p-2 content overflow-y-hidden print:m-3">
  <ng-content> </ng-content>
</div>
<div class="overlay" *ngIf="isLoading"></div>

<a
  [routerLink]="[item.link]"
  routerLinkActive
  #rla="routerLinkActive"
  class="group flex items-center px-2 py-2 rounded-md"
  [ngClass]="
    rla.isActive
      ? 'bg-primary-color text-white'
      : 'text-layout-text-navigation hover:text-gray-900 hover:bg-primary-color-50'
  "
>
  <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
  <fa-icon
    *ngIf="item.icon"
    [icon]="item.icon"
    size="2x"
    class="fa-fw mr-3 w-8"
    [ngClass]="
      rla.isActive
        ? 'text-white'
        : 'text-layout-text-navigation group-hover:text-gray-500'
    "
  ></fa-icon>
  <fa-stack *ngIf="!item.icon && !!item.icons" class="fa-fw mr-3 w-8">
    <fa-icon [icon]="item.icons[0]" stackItemSize="2x"></fa-icon>
    <fa-icon
      [icon]="item.icons[1]"
      stackItemSize="2x"
      [ngClass]="rla.isActive ? 'text-primary-color' : 'text-white'"
      transform="shrink-7 down-5 right-5"
    ></fa-icon>
    <fa-icon
      [icon]="item.icons[2]"
      stackItemSize="2x"
      transform="shrink-8 down-5 right-5"
    ></fa-icon>
  </fa-stack>
  {{ item.name | transloco }}
</a>

type PermissionValues =
  | 'forecast.view'
  | 'forecast.edit'
  | 'forecast.editbuttonscalesonly'
  | 'forecast.create'
  | 'forecast.copy'
  | 'forecast.delete'
  | 'forecast.admin'
  | 'forecast.creationmail'
  | 'forecast.import'
  | 'forecast-settings.edit'
  | 'forecast.power'
  | 'forecast.gas'
  | 'forecast.formrange'
  | 'forecast.formrestrictioncheck'
  | 'forecast.formscaleactualloadprofile'
  | 'forecast.formstandardizedloadprofile'
  | 'forecast.formscaleyears'
  | 'forecast.formignorezerovalues'
  | 'forecast.formcalculationstartend'
  | 'forecast.formforwardprojection'
  | 'forecast.formforwardprojectionweekly'
  | 'forecast.formforwardprojectionholiday';

export class ForecastPermissions {
  public static readonly forecastEdit: PermissionValues = 'forecast.edit';
  public static readonly forecastCopy: PermissionValues = 'forecast.copy';
  public static readonly forecastEditButtonScalesOnly: PermissionValues =
    'forecast.editbuttonscalesonly';
  public static readonly forecastCreate: PermissionValues = 'forecast.create';
  public static readonly forecastDelete: PermissionValues = 'forecast.delete';
  public static readonly forecastView: PermissionValues = 'forecast.view';
  public static readonly forecastAdmin: PermissionValues = 'forecast.admin';
  public static readonly forecastCreationMail: PermissionValues =
    'forecast.creationmail';
  public static readonly forecastImport: PermissionValues = 'forecast.import';
  public static readonly editForecastSettings: PermissionValues =
    'forecast-settings.edit';
  public static readonly formIgnoreZeroValues: PermissionValues =
    'forecast.formignorezerovalues';
  public static readonly formCalculationStartEnd: PermissionValues =
    'forecast.formcalculationstartend';
  public static readonly formForwardProjection: PermissionValues =
    'forecast.formforwardprojection';
  public static readonly formForwardProjectionWeekly: PermissionValues =
    'forecast.formforwardprojectionweekly';
  public static readonly formForwardProjectionHoliday: PermissionValues =
    'forecast.formforwardprojectionholiday';

  public static readonly power: PermissionValues = 'forecast.power';
  public static readonly gas: PermissionValues = 'forecast.gas';

  public static readonly forecastFormRange: PermissionValues =
    'forecast.formrange';
  public static readonly forecastFormRestrictionCheck: PermissionValues =
    'forecast.formrestrictioncheck';
  public static readonly forecastFormScaleActualLoadProfile: PermissionValues =
    'forecast.formscaleactualloadprofile';
  public static readonly forecastFormStandardizedLoadProfile: PermissionValues =
    'forecast.formstandardizedloadprofile';
  public static readonly forecastFormScaleYears: PermissionValues =
    'forecast.formscaleyears';

  public static readonly forecastRootSideNavPermisssion = [
    ForecastPermissions.forecastEdit,
    ForecastPermissions.forecastCreate,
    ForecastPermissions.forecastView,
    ForecastPermissions.forecastAdmin,
  ].concat([ForecastPermissions.editForecastSettings]);

  public static readonly forecastTabPermisssion = [
    ForecastPermissions.forecastEdit,
    ForecastPermissions.forecastCreate,
    ForecastPermissions.forecastView,
    ForecastPermissions.forecastAdmin,
  ];
  public static readonly forecastSideNavPermisssion = [
    ForecastPermissions.forecastEdit,
    ForecastPermissions.forecastCreate,
    ForecastPermissions.forecastView,
    ForecastPermissions.forecastAdmin,
  ];
}

import { Component, Input } from '@angular/core';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { ActionButton } from '../action-button/action-button';

@Component({
  selector: 'cc-cancel-action-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['../action-button/action-button.scss'],
})
export class CancelButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'common.CANCEL';

  @Input()
  public override icon = faXmark;

  @Input()
  public override displayText = 'common.CANCEL';
}

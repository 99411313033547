import { LoadProfilePermissionValues } from './load-profile-permisssions';

export class StandardizedLoadProfilePermissions {
  public static readonly edit: LoadProfilePermissionValues =
    'standardized-load-profile.edit';
  public static readonly create: LoadProfilePermissionValues =
    'standardized-load-profile.create';
  public static readonly delete: LoadProfilePermissionValues =
    'standardized-load-profile.delete';

  public static readonly standardizedLoadProfileTabPermisssion = [
    StandardizedLoadProfilePermissions.edit,
    StandardizedLoadProfilePermissions.create,
    StandardizedLoadProfilePermissions.delete,
  ];
}

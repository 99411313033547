import { Inject, Injectable } from '@angular/core';
import { AkitaSearchEditBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters, ObjectHelper } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NormCubicMeterStore } from '../+state/norm-cubic-meter.store';
import { NormCubicMeter } from '../models/norm-cubic-meter';
import { NORM_CUBIC_METER } from './norm-cubic-meter-paginator';

@Injectable({
  providedIn: 'root',
})
export class NormCubicMeterService extends AkitaSearchEditBaseService<NormCubicMeter> {
  private url = this.configService.settings['api'] + 'loadprofile/graphql';

  private entityFields = `
      id
      year
      factor
      updatedDate
      updatedBy
    `;

  constructor(
    protected store: NormCubicMeterStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    @Inject(NORM_CUBIC_METER)
    private paginatorRef: PaginatorPlugin<NormCubicMeter>
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<NormCubicMeter>> {
    const query = `
    query ($params: DataTableParametersType!){
      loadProfileNormCubicMeterSettings(params: $params){
          perPage
          currentPage
          total
          lastPage
          data {
            ${this.entityFields}
          }
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService.query<PaginationResponse<NormCubicMeter>>(
      query,
      variables,
      this.url
    );
  }

  public getById$(id: string): Observable<NormCubicMeter> {
    const query = `
    query($id: ID!) {
      loadProfileNormCubicMeterSetting(id: $id) {
        ${this.entityFields}
      }
    }
    `;
    const variables = { id };
    return this.graphqlService
      .query<NormCubicMeter>(query, variables, this.url)
      .pipe(tap((data) => this.store.upsert(data.id, data)));
  }

  public add$(loadProfileNormCubicMeterSetting: NormCubicMeter) {
    const query = `
      mutation ($loadProfileNormCubicMeterSetting: LoadProfileNormCubicMeterSettingInsertType!)
      {
        insertLoadProfileNormCubicMeterSetting(loadProfileNormCubicMeterSetting: $loadProfileNormCubicMeterSetting) {
          ${this.entityFields}
        }
      }
    `;

    loadProfileNormCubicMeterSetting = ObjectHelper.cloneObject(
      loadProfileNormCubicMeterSetting
    );

    delete loadProfileNormCubicMeterSetting.id;

    const variables = { loadProfileNormCubicMeterSetting };

    return this.graphqlService
      .query<NormCubicMeter>(query, variables, this.url)
      .pipe(
        tap((newHknIdentifier) => {
          this.upsertAndRefresh(newHknIdentifier);
          this.paginatorRef.refreshCurrentPage();
        })
      );
  }

  public update$(loadProfileNormCubicMeterSetting: NormCubicMeter) {
    const query = `
      mutation ($loadProfileNormCubicMeterSetting: LoadProfileNormCubicMeterSettingUpdateType!)
      {
        updateLoadProfileNormCubicMeterSetting(loadProfileNormCubicMeterSetting: $loadProfileNormCubicMeterSetting) {
          ${this.entityFields}
        }
      }
    `;

    loadProfileNormCubicMeterSetting = ObjectHelper.cloneObject(
      loadProfileNormCubicMeterSetting
    );

    const variables = { loadProfileNormCubicMeterSetting };

    return this.graphqlService
      .query<NormCubicMeter>(query, variables, this.url)
      .pipe(
        tap((newHknIdentifier) => {
          this.upsertAndRefresh(newHknIdentifier);
          this.paginatorRef.refreshCurrentPage();
        })
      );
  }

  public delete$(id: string) {
    const query = `
      mutation ($id: ID!)
      {
        deleteLoadProfileNormCubicMeterSetting(id: $id)
      }
      `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url).pipe(
      tap((value) => {
        if (value) {
          this.store.remove(id);
          this.invalidateCache();
          this.paginatorRef.refreshCurrentPage();
        }
      })
    );
  }
}

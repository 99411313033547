import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  public openMenu$ = new BehaviorSubject<boolean>(false);
  public mobileMenuToggled$ = new BehaviorSubject<boolean>(false);

  public toggleUserMenu() {
    this.openMenu$.next(!this.openMenu$.value);
    this.mobileMenuToggled$.next(this.openMenu$.value);
  }
}

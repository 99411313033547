import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'cc-spreads',
  templateUrl: './spreads.component.html',
  styleUrls: ['./spreads.component.scss'],
})
export class SpreadsComponent {
  @HostBinding('class.page-component') pageComponent = true;
}

import { PagingResponse } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function mapGraphqlPaging(currentPage: number, perPage: number) {
  return <T>(source: Observable<PagingResponse<T>>) => {
    return source.pipe(
      map((x) => {
        const lastPage = x.pageInfo.hasNextPage ? currentPage + 1 : currentPage;
        return {
          data: x.items,
          currentPage,
          perPage,
          lastPage,
          total: x.totalCount,
        } as PaginationResponse<T>;
      })
    );
  };
}

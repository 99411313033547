import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import {
  DashboardFacade,
  IDashboardListDto,
} from '@clean-code/shared/dashboard/dashboard/domain';
import { TranslocoModule } from '@ngneat/transloco';
import { DashboardEditComponent } from '../dashboard-edit/dashboard-edit.component';

@Component({
  standalone: true,
  templateUrl: './dashboard-edit-dialog.component.html',
  styleUrls: ['./dashboard-edit-dialog.component.css'],
  imports: [
    CommonModule,
    TranslocoModule,
    DashboardEditComponent,
    ActionButtonModule,
  ],
  // providers: [DashboardFacade, DashboardStore],
})
export class DashboardEditDialogComponent {
  @ViewChild('editChild', { static: false })
  public editChild: DashboardEditComponent;
  private facade = inject(DashboardFacade);

  public constructor(
    public dialogRef: MatDialogRef<DashboardEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dashboard: IDashboardListDto
  ) {}

  public save() {
    this.facade.update$(this.editChild.form.value);
    this.dialogRef.close();
  }

  public close() {
    this.dialogRef.close();
  }
}

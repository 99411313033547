import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { OfferPower } from '../models/offer-power';

const stateName = 'offer_power';

export interface OfferPowerState extends BaseState<OfferPower> {}

function createInitialState(): OfferPowerState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: stateName })
export class OfferPowerStore extends BaseStore<OfferPower> {
  constructor() {
    super(createInitialState());
  }
}

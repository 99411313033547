import { BaseEntity, ID } from '@clean-code/shared/common';
import { IFormBaseService } from '@clean-code/shared/util/util-component-services';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseStore } from '../../base-state/search-base.state';
import { AkitaSearchEditBaseService } from '../data/edit-search.service';

export abstract class AkitaFormBaseService<T extends BaseEntity>
  extends AkitaSearchEditBaseService<T>
  implements IFormBaseService<T>
{
  constructor(
    protected store: BaseStore<T>,
    protected service: EditSearchDataService<T>
  ) {
    super(store);
  }

  public getById$(id: ID): Observable<T> {
    return this.service.getById$(id).pipe(
      tap((entity: T) => {
        this.store.upsert(entity.id, entity);
        // id can be either int or string, use entity.id to always have the correct type
        this.store.setActive(entity.id);
      })
    );
  }

  public add$(entity: T): Observable<T | ID | boolean> {
    return this.service.add$(entity).pipe(
      tap((_) => {
        this.store.upsert(entity.id, entity);
      })
    );
  }

  public update$(entity: T): Partial<Observable<T | ID | boolean>> {
    return this.service.update$(entity).pipe(
      tap((_) => {
        this.store.update(entity.id, entity);
      })
    );
  }

  public delete$(id?: ID): any {
    this.service.delete$(id).pipe(
      tap((_) => {
        this.store.remove(id);
      })
    );
  }

  //TODO:
  // public selectActive(): Observable<T> {
  //   return this.query.selectActive();
  // }
  // public selectLoading(): Observable<boolean> {
  //   return this.query.selectLoading();
  // }
}

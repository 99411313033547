import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActionRowDirective } from 'shared/directives/ui-layout';
import { BoMatTableComponent } from './mat-table/mat-table.component';
import { TableStateService } from './services/table-state.service';
import { DataTableContainerComponent } from './table-container/table-container.component';
import { TablePreviewComponent } from './table-preview/table-preview.component';

@NgModule({
  imports: [
    MatTableModule,
    MatSortModule,

    TablePreviewComponent,
    BoMatTableComponent,
    DataTableContainerComponent,
    ActionRowDirective,
  ],
  declarations: [],
  exports: [
    MatTableModule,
    MatSortModule,

    BoMatTableComponent,
    DataTableContainerComponent,
    TablePreviewComponent,
    ActionRowDirective,
  ],
  providers: [TableStateService],
})
export class UiMatTableModule {}

// @NgModule({
//   imports: [
//     CommonModule,
//     RouterModule,

//     MatButtonModule,
//     MatSidenavModule,
//     MatTableModule,
//     MatSortModule,
//     MatPaginatorModule,
//     MatCheckboxModule,
//     MatProgressBarModule,
//     MatMenuModule,
//     TranslocoModule,
//     FontAwesomeModule,
//     ActionButtonModule,
//   ],
//   providers: [
//     {
//       provide: MatPaginatorIntl,
//       useClass: MatPaginationIntlService,
//     },
//   ],
//   declarations: [
//     BoMatTableComponent, DataTableContainerComponent,
//     DataTableContainerComponent,
//     TablePreviewComponent,
//   ],
//   exports: [
//     BoMatTableComponent, DataTableContainerComponent,
//     MatTableModule,
//     MatSortModule,
//     MatPaginatorModule,
//     FontAwesomeModule,
//     DataTableContainerComponent,
//     TranslocoModule,
//     TablePreviewComponent,
//     ActionButtonModule,
//   ],
// })

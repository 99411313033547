import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PageContainerComponent } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { TranslocoModule } from '@ngneat/transloco';
import { ImprintComponent } from './imprint.component';

const routes: Route[] = [
  {
    path: '',
    component: ImprintComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    PageContainerComponent,
  ],
  declarations: [ImprintComponent],
})
export class ImprintModule {}

<div
  class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
>
  <div class="max-w-max mx-auto">
    <main class="sm:flex">
      <div class="sm:ml-6">
        <div class="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1
            class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
          >
            {{ 'forbidden.TITLE' | transloco }}
          </h1>
          <p class="mt-1 text-base text-gray-500">
            {{ 'forbidden.MESSAGE' | transloco }}
          </p>
        </div>
        <div
          class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"
        >
          <a
            href="#"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-primary-color-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            [routerLink]="['/']"
          >
            {{ 'forbidden.LOGIN' | transloco }}
          </a>
          <!-- <a
            href="#"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary-color-700 bg-primary-color-100 hover:bg-primary-color-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ 'forbidden.SUPPORT' | transloco }}
          </a> -->
        </div>
      </div>
    </main>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { Dayjs } from 'dayjs';
import { debounceTime, filter, startWith, takeUntil, tap } from 'rxjs';
import { SearchRowDirective } from 'shared/directives/ui-layout';
import { BaseTableFilter, FilterKeyConditionType } from '../base-table-filter';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'search-date-range-table-filter',
  templateUrl: './search-date-range-table-filter.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,

    TranslocoModule,
    FontAwesomeModule,
    UtilDateModule,
    SearchRowDirective,
  ],
})
export class SearchDateRangeTableFilterComponent
  extends BaseTableFilter
  implements OnInit
{
  faTimes = faTimes;

  @Input() public set defaultStart(start: Dayjs) {
    this.range.controls.start.setValue(start);
  }

  @Input() public set defaultEnd(end: Dayjs) {
    this.range.controls.end.setValue(end);
  }

  range = new FormGroup({
    start: new FormControl<Dayjs | null>(null, [Validators.required]),
    end: new FormControl<Dayjs | null>(null, [Validators.required]),
  });

  private startCondition = () => {
    return {
      key: 'start',
      conditions: `{ date: { gte: "${this.range.get('start').value}" } }`,
    } as FilterKeyConditionType;
  };

  private endCondition = () => {
    return {
      key: 'end',
      conditions: `{ date: { lte: "${this.range.get('end').value}" } }`,
    } as FilterKeyConditionType;
  };

  setFilterKeyConditions(): FilterKeyConditionType[] {
    return [this.startCondition(), this.endCondition()];
  }

  ngOnInit(): void {
    this.range.valueChanges
      .pipe(
        startWith(this.range.value),
        debounceTime(300),
        filter(
          () =>
            this.range.valid &&
            this.range.get('start').value !== null &&
            this.range.get('end').value !== null
        ),
        tap(() => {
          this.applyFilters(this.setFilterKeyConditions());
        }),
        takeUntil(this.closeSubject$)
      )
      .subscribe();
  }
}

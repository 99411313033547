import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity, ID, indicate } from '@clean-code/shared/common';
import { TableStateService } from '@clean-code/shared/components/ui-mat-table';
import { IFormBaseService } from '@clean-code/shared/util/util-component-services';
import { FormUiStateService } from '@clean-code/shared/util/util-state';
import { ToastService } from '@clean-code/shared/util/util-toast';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FormStore } from './can-deactivate.store';
import { CanDeactivateComponent } from './components/can-deactivate.component';

@Directive()
export abstract class CanDeactivateFormBase
  extends CanDeactivateComponent
  implements AfterViewInit, OnDestroy
{
  protected formStore = inject(FormStore, { optional: true });
  public id = this.formStore?.id;
  public isNew = this.formStore?.isNew;

  public hasEditPermissions: Observable<boolean>;
  public idKeyName = 'id';
  public id$: Observable<ID>;
  public isNew$: Observable<boolean>;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public abstract formGroup: UntypedFormGroup;

  protected toastService: ToastService;
  protected activatedRoute = inject(ActivatedRoute);
  protected router: Router;
  protected readOnly$ = new BehaviorSubject<boolean>(false);
  protected searchQuery: any;

  protected resetValue = {};
  protected formStateService?: FormUiStateService;
  protected tableStateService: TableStateService;
  protected sendRawData = false;

  private cd = inject(ChangeDetectorRef);

  private _formGroupDirective: FormGroupDirective;
  private formGroupDirectiveSubscribe$ = new BehaviorSubject(null);
  @ViewChild(FormGroupDirective)
  set formGroupDirective(value: FormGroupDirective) {
    if (value) {
      this._formGroupDirective = value;
      this.formGroupDirectiveSubscribe$.next(Date.now());
    }
  }

  public get formGroupDirective() {
    return this._formGroupDirective;
  }

  @ViewChild('formActions')
  public set formActions(value: TemplateRef<any>) {
    if (this.formStateService) {
      this.formStateService.formActions = value;
    }
  }

  public preview$ = new BehaviorSubject<boolean>(false);
  @Input()
  public set preview(preview: boolean) {
    this.preview$.next(preview);
  }

  constructor() {
    super();

    this.toastService = inject(ToastService);
    this.activatedRoute = inject(ActivatedRoute);
    this.router = inject(Router);
    this.tableStateService = inject(TableStateService, {
      optional: true,
    });

    if (!this.tableStateService) {
      console.info('user-info', 'Table State Service is not injected');
    }

    this.formStateService = inject(FormUiStateService, {
      optional: true,
    });

    this.id$ = this.activatedRoute.paramMap.pipe(
      map((params) =>
        isNaN(+params.get(this.idKeyName))
          ? params.get(this.idKeyName)
          : +params.get(this.idKeyName)
      )
    );

    this.isNew$ = this.id$.pipe(
      map((id) => {
        return !!id && id === 'new';
      })
    );

    this.isNew$
      .pipe(
        tap((_) => this.resetForm()),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.preview$
      .pipe(
        filter((value: boolean) => !!value),
        tap((value: boolean) => {
          if (value) {
            this.formGroup.disable();
          } else {
            this.formGroup.enable();
          }
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    if (this.hasEditPermissions) {
      this.hasEditPermissions
        .pipe(
          tap((value: boolean) => {
            if (!value) {
              this.formGroup.disable();
            }
          }),
          takeUntil(this.closeSubject)
        )
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    this.formGroupDirectiveSubscribe$
      .pipe(
        debounceTime(300),
        filter(() => !!this._formGroupDirective),
        switchMap(() =>
          this._formGroupDirective.ngSubmit.pipe(
            debounceTime(300),
            tap(() => {
              this.formGroup.markAsTouched();
              if (!this.formGroup.valid) {
                this.toastService.showError('common.error.FORM_NOT_VALID');
              }
            }),
            //filter(([_isnew, _event]) => this.formGroup.valid),
            filter((_event) => {
              return this.formGroup.valid;
            }),
            switchMap((_) => this.isNew$.pipe(first())),
            tap((isNew) => {
              this.trigger(isNew);
              // this.formGroup.markAsPristine();
            })
          )
        )
      )
      .subscribe();

    this.cd.detectChanges();
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty;
  }

  public ngOnDestroy(): void {
    // this.tableStateService.resetSelection();
    this.formGroup.reset({});
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  public submit(event?: any) {
    this.formGroupDirective.ngSubmit.emit(event);
    this.formGroup.markAllAsTouched();
  }

  public trigger(isNew: boolean) {
    if (!isNew) {
      this.update();
    } else {
      this.add();
    }
  }

  protected resetForm() {
    this.formGroup?.reset(this.resetValue);
  }

  protected abstract add(): any;
  protected abstract update(): any;
}

export abstract class CanDeactivateForm<T extends BaseEntity>
  extends CanDeactivateFormBase
  implements AfterViewInit
{
  public refreshTrigger$ = new Subject<void>();

  public value$ = combineLatest([
    this.activatedRoute.paramMap,
    this.refreshTrigger$.pipe(startWith(null)),
  ]).pipe(
    map(([param]) => param),
    map((params) =>
      isNaN(+params.get(this.idKeyName))
        ? params.get(this.idKeyName)
        : +params.get(this.idKeyName)
    ),
    filter((id: ID) => !!id),
    filter((id: ID) => id !== 'new'),
    switchMap((id: ID) =>
      this.service.getById$(id).pipe(indicate(this.isLoading$))
    ),
    tap((value: T) => {
      this.tableStateService?.select(value);
    })
  );

  public valueFromResolver$ = this.activatedRoute.data.pipe(
    map((data) => data.entity),
    tap((value: T) => {
      this.tableStateService?.select(value);
    })
  );

  constructor(protected service: IFormBaseService<T>) {
    super();
  }

  protected add(): void {
    this.service
      .add$(this.formValue)
      .pipe(takeUntil(this.closeSubject))
      .subscribe((value: T | ID | boolean) => {
        this.toastService.showSuccess();
        this.tableStateService?.refresh();
        const id = ((value as BaseEntity)?.id ?? (value as ID)).toString();
        this.navigate(id);
      });
  }

  protected update(): void {
    this.service
      .update$(this.formValue)
      .pipe(takeUntil(this.closeSubject))
      .subscribe(() => {
        this.toastService.showSuccess();
        this.tableStateService?.refresh();
      });
  }

  protected delete(id: ID) {
    this.service
      .delete$(id)
      .pipe(indicate(this.isLoading$), takeUntil(this.closeSubject))
      .subscribe(() => {
        this.toastService.showSuccess('common.SUCCESSFULLY_DELETED');
        this.tableStateService?.refresh();
        this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
        });
      });
  }

  protected get formValue() {
    return this.sendRawData
      ? this.formGroup.getRawValue()
      : this.formGroup.value;
  }

  protected navigate(id?: ID) {
    this.router.navigate(['../', id], {
      relativeTo: this.activatedRoute,
    });
  }
}

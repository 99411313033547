import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { CompanyRelation } from '../models/company-relation';

@Injectable({
  providedIn: 'root',
})
export class FofCompanyService {
  constructor(private graphqlService: GraphqlService) {}

  public getAllKeyAccountManagersForCompany$(
    companyId: ID
  ): Observable<CompanyRelation[]> {
    const query = `
  query($id: Int!) {
    allKeyAccountManagersByCompanyId(id: $id) {
      person {
        id
        preTitle
        postTitle
        firstName
        lastName
        phoneNumber
        email
        publicMail
        fullName
      }
      company {
        id
        name
      }
      type
    }
  }
`;
    const variables = { id: companyId };
    return this.graphqlService.query<CompanyRelation[]>(query, variables);
  }
}

import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import {
  CalculationType,
  DataSourceInput,
  TimeSeriesIdentifierInputForm,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { ID, moveItemInFormArray } from '@clean-code/shared/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { TooltipModule } from '@clean-code/shared/components/ui-tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  faGripLines,
  faQuestionCircle,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';
import { TimeSeriesDynamicSelectionComponent } from '../time-series-dynamic-selection.component';

@Component({
  standalone: true,
  selector: 'time-series-dynamic-selection-group',
  templateUrl: './time-series-dynamic-selection-group.component.html',
  styleUrls: ['./time-series-dynamic-selection-group.component.scss'],
  imports: [
    CommonModule,

    ReactiveFormsModule,
    DragDropModule,
    FontAwesomeModule,

    TooltipModule,
    ActionButtonModule,
    TimeSeriesDynamicSelectionComponent,
  ],
})
export class TimeSeriesDynamicSelectionGroupComponent {
  public faGripLines = faGripLines;
  public faTrashAlt = faTrashAlt;
  public faQuestionCircle = faQuestionCircle;

  @Input()
  public formGroup: FormGroup;

  @Input()
  public formCtrlName = 'dataSources';

  private _calculationType?: CalculationType;
  @Input()
  public set calculationType(value: CalculationType) {
    this._calculationType = value;

    this.dataSources.controls.forEach((control) => {
      this.setCalculationType(control, value);
    });
  }

  @Input()
  public set dataSource(values: Array<{ id: ID; settings: TimeSeriesInput }>) {
    this.dataSources.clear();
    values?.forEach((value, index) => {
      this.dataSources.push(this.createDataSourceForm(index, value));
    });
  }

  @Input()
  public caption: string;

  //TODO: load TimeSeriesAnalysisIdentifier only once?
  constructor(private fb: FormBuilder) {
    console.debug('TimeSeriesDynamicSelectionGroupComponent');
  }

  public get dataSources() {
    return this.formGroup.get(this.formCtrlName) as FormArray;
  }

  public get dataSourcesShort() {
    return (
      (this.formGroup.get(this.formCtrlName) as FormArray)
        ?.value as DataSourceInput[]
    ).map((values: DataSourceInput) => {
      return {
        sortOrder: values.settings?.identifier?.sortOrder,
        identifierId: values.settings?.identifier?.identifierId,
      };
    });
  }

  public addDataSource() {
    this.dataSources.push(
      this.createDataSourceForm(this.dataSources.length, {
        settings: {
          identifier: {
            adjustment: 0,
            calculationType: 'ABSOLUTE',
          },
        },
      } as { id: ID; settings: TimeSeriesInput })
    );

    if (this._calculationType) {
      this.calculationType = this._calculationType;
    }
  }

  public removeDataSource(index: number) {
    this.dataSources.removeAt(index);
  }

  public createDataSourceForm(
    index: number,
    value?: { id: ID; settings: TimeSeriesInput }
    // widgetId?: ID
  ) {
    if (!value.settings.identifier.sortOrder) {
      value.settings.identifier.sortOrder = index;
    }

    const form = new UntypedFormGroup({
      //   id: new FormControl(),
      //   widgetId: new FormControl(widgetId),
      settings: new UntypedFormGroup({
        identifier: new TimeSeriesIdentifierInputForm(
          value.settings.identifier
        ),
      }),
    });

    if (value) {
      form.patchValue(value);
    }

    this.setCalculationType(form, this._calculationType);

    return form;
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInFormArray(
      this.dataSources,
      event.previousIndex,
      event.currentIndex
    );

    // recalculate sort order
    (this.dataSources?.controls as Array<UntypedFormGroup>).forEach(
      (value: UntypedFormGroup, index) => {
        value.get('settings.identifier.sortOrder').setValue(index);
      }
    );
  }

  private setCalculationType(control: AbstractControl, value: CalculationType) {
    if (!value) {
      return;
    }

    control.get('settings.identifier.calculationType').setValue(value);

    control
      .get('settings.identifier.calculationType')
      .disable({ onlySelf: true });
  }
}

import { Injectable } from '@angular/core';
import {
  AkitaSearchBaseService,
  mapGraphqlPaging,
} from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  ObjectHelper,
  PagingResponse,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse } from '@datorama/akita';
import { Observable, map, tap } from 'rxjs';
import { DeliveryAreaStore } from '../+state/delivery-area.store';
import { Area, ShortArea } from '../models/area';
@Injectable({
  providedIn: 'root',
})
export class DeliveryAreaService extends AkitaSearchBaseService<Area> {
  private url = this.configService.settings['api'] + 'area/graphql';

  constructor(
    protected store: DeliveryAreaStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<Area>> {
    const par = this.graphqlService.mapDataTableParameters(params);
    const where = this.graphqlService.mapDataTableFilterParameters2(
      params.filters
    );

    const order = this.graphqlService.mapDataTableSortParametersTemp(
      params.sortBy
    );

    const query = `
                query {
                  deliveryAreaGetAll(
                      ${par}
                      ${order}
                      ${where}
                    ){
                      items {
                        id
                        name
                        homeMarketAreaId
                        homeMarketArea {
                            name
                        }
                        longName
                        country
                        energySource
                        disabled
                        timeZoneId
                        timeRangeFrom
                        timeRangeTo
                      }
                      pageInfo{
                          hasNextPage
                          hasPreviousPage
                      }
                      totalCount
                    }
                }`;

    return this.graphqlService.query<PagingResponse<Area>>(query).pipe(
      mapGraphqlPaging(params.page, params.perPage),
      tap((value: PaginationResponse<Area>) =>
        this.store.upsertMany(value.data)
      )
    );

    // const query = `
    //     query {
    //         delivery_areas{
    //         id
    //         name
    //         homeMarketAreaId
    //         homeMarketArea
    //         {
    //           name
    //         }
    //         longName
    //         country
    //         energySource
    //         disabled
    //         timeZoneId
    //         timeRangeFrom
    //         timeRangeTo
    //         }
    //     }
    // `;

    // return this.graphqlService.query<Area[]>(query, null, this.url);
  }

  public getAreasByEnergySource$(energySource: string): Observable<Area[]> {
    const query = `
          query ($energySource:EnergySourceEnum!) {
            deliveryAreasByEnergySource (energySource:$energySource) {
            id
            name
            homeMarketAreaId
            homeMarketArea
            {
              name
            }
            longName
            country
            energySource
            disabled
            timeZoneId
            timeRangeFrom
            timeRangeTo
            }
        }
    `;

    const variables = { energySource: energySource };
    return this.graphqlService.query<Area[]>(query, variables, this.url);
  }

  public getAllShortAreas$(): Observable<ShortArea[]> {
    const query = `
        query {
            delivery_areas{
            id
            name
            homeMarketAreaId
            }
        }
    `;

    return this.graphqlService.query<ShortArea[]>(query, null, this.url);
  }

  public getById$(id: string): Observable<Area> {
    const where = `where: { id: { eq: ${id} }}`;
    const query = `
        query
        {
          deliveryAreaGetById(${where}) {
            id
            homeMarketAreaId
            name
            longName
            country
            energySource
            disabled
            timeZoneId
            timeRangeFrom
            timeRangeTo
            }
        }
    `;

    return this.graphqlService.singleQuery<Area>(query).pipe(
      /*
        Without map operator, the 'Heimmarktzone' field remains empty in the preview, 
        even if the 'homeMarketAreaId' is not null 
      */
      map((x) => {
        {
          x.homeMarketAreaId = x.homeMarketAreaId?.toString();
        }
        return x;
      })
    );
  }

  public add$(area: Area): Observable<Area> {
    const query = `
        mutation($area: DeliveryAreaInsertType!){
            insert_delivery_area(area: $area){
              id
              name
              longName
            }
          }
    `;

    const clone = ObjectHelper.cloneObject(area);
    delete clone.id;

    const variables = { area: clone };

    return this.graphqlService.query<Area>(query, variables, this.url);
  }

  public update$(area: Area): Observable<Area> {
    const query = `
        mutation($area: DeliveryAreaUpdateType!){
            update_delivery_area(area: $area){
              id
              name
              longName
            }
          }
    `;
    const variables = { area: area };

    return this.graphqlService.query<Area>(query, variables, this.url);
  }

  public delete$(id: ID): Observable<boolean> {
    const query = `
        mutation($id:String!) {
            delete_delivery_area(id: $id)
        }
    `;
    const variables = { id: id };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }
}

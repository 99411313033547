import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataTableParameters,
  HttpResponseHelper,
  ID,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import {
  DataServiceReturn,
  EditSearchDataService,
} from '@clean-code/shared/util/util-data-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SettingSpotConfiguration } from '../models/setting-spot-configuration';
@Injectable({
  providedIn: 'root',
})
export class SettingSpotConfigurationService
  implements EditSearchDataService<SettingSpotConfiguration>
{
  private url = this.configService.settings['api'] + 'setting/graphql';
  private uploadUrl = this.configService.settings['api'] + 'setting/';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}
  getAll$(
    params?: DataTableParameters
  ): Observable<DataServiceReturn<SettingSpotConfiguration>> {
    throw new Error('Method not implemented.');
  }

  // public getAllSettingSpotConfigurationsPower$(): Observable<
  //   SettingSpotConfiguration[]
  // > {
  //   return this.getAllSettingSpotConfigurations$('POWER');
  // }

  // public getAllSettingSpotConfigurationsGas$(): Observable<
  //   SettingSpotConfiguration[]
  // > {
  //   return this.getAllSettingSpotConfigurations$('GAS');
  // }

  public getAllSettingSpotConfigurations$(
    energySource: 'POWER' | 'GAS' = null
  ): Observable<SettingSpotConfiguration[]> {
    const where = `(where: {
      energySource: { eq: $energySource}
    })`;

    const query = `
        query {
            settingSpotConfigurations
              ${energySource ? where : ''}
            {
                id
                country
                energySource
                stockMarket
            }
        }
    `;

    const variables = {
      energySource,
    };

    return this.graphqlService.query<SettingSpotConfiguration[]>(
      query,
      energySource ? variables : null,
      this.url
    );
  }

  public getById$(id: ID): Observable<SettingSpotConfiguration> {
    const query = `
        query ($id:ID!)
        {
            settingSpotConfiguration(id:$id) {
                id
                country
                energySource
                stockMarket
            }
        }
    `;

    const variables = { id };
    return this.graphqlService.query<SettingSpotConfiguration>(
      query,
      variables,
      this.url
    );
  }

  public add$(
    settingSpotConfiguration: SettingSpotConfiguration
  ): Observable<SettingSpotConfiguration> {
    const query = `
        mutation($settingSpotConfiguration: SettingSpotConfigurationInsertType!){
            insertSettingSpotConfiguration(settingSpotConfiguration: $settingSpotConfiguration){
              id
              country
              energySource
              stockMarket
            }
          }
    `;
    const variables = { settingSpotConfiguration };

    return this.graphqlService.query<SettingSpotConfiguration>(
      query,
      variables,
      this.url
    );
  }

  public update$(
    settingSpotConfiguration: SettingSpotConfiguration
  ): Observable<SettingSpotConfiguration> {
    const query = `
        mutation($settingSpotConfiguration: SettingSpotConfigurationUpdateType!){
            updateSettingSpotConfiguration(settingSpotConfiguration: $settingSpotConfiguration){
              id
              country
              energySource
              stockMarket
            }
          }
    `;
    const variables = { settingSpotConfiguration };

    return this.graphqlService.query<SettingSpotConfiguration>(
      query,
      variables,
      this.url
    );
  }

  public delete$(id: ID): Observable<boolean> {
    const query = `
        mutation($id:ID!) {
            deleteSettingSpotConfiguration(id: $id)
        }
    `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public exportSpotConfgiurationResultToExcel$(id: ID): Observable<boolean> {
    const headers = new HttpHeaders({
      'Access-Control-Expose-Headers': 'Content-Disposition',
    });

    return this.httpClient
      .get(this.uploadUrl + '' + id + '/export', {
        responseType: 'blob' as 'json',
        observe: 'response',
        headers: headers,
      })
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          const fileName = HttpResponseHelper.getHttpResponseFileName(response);
          const url = window.URL.createObjectURL(response.body);
          const anchor = document.createElement('a');
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }),
        map(() => true),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}

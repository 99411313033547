import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ID } from '@clean-code/shared/common';
import { TimeSeriesIdentifierInput } from '../models/timeseries-input';

// TODO: https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28

export interface TimeSeriesIdentifierDataForm {
  identifierId: FormControl<ID>;
  identifier: FormControl<string>;
  timeStamp: FormControl<Date>;
  adjustment: FormControl<number>;
  sortOrder: FormControl<number>;
}

export class TimeSeriesIdentifierDataInputForm extends FormGroup<TimeSeriesIdentifierDataForm> {
  constructor(
    readonly model?: TimeSeriesIdentifierInput,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        identifierId: [null, [Validators.required]],
        identifier: [null, [Validators.required]],
        timeStamp: [null],
        adjustment: [0, [Validators.required]],
        sortOrder: [null], //also used to find old additional settings
      }).controls,
      validatorOrOpts
    );
    //this.patchValue(model);
  }
}

import { Component, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HknTypeService } from '@backoffice/costing/util-api';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { HknIdentifierFormFacade } from '../../application/hkn-identifier-form.facade';
import { HknIdentifier } from '../../models/hkn-identifier';

@Component({
  selector: 'backoffice-frontend-hkn-identifier-form',
  templateUrl: './hkn-identifier-form.component.html',
})
export class HknIdentifierFormComponent
  extends CanDeactivateForm<HknIdentifier>
  implements OnDestroy
{
  public closeSubject = new Subject<void>();

  public navigationUrl = '/settings/hkn-identifier';

  public hknTypes$ = this.hknTypeService.search$({});

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    identifier: new UntypedFormControl(null, [Validators.required]),
    year: new UntypedFormControl(null, [Validators.required]),
    hknTypeId: new UntypedFormControl(null, [Validators.required]),
    supplierId: new UntypedFormControl(null, [Validators.required]),
    deliveryAreaId: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private hknTypeService: HknTypeService,
    hknIdentifierService: HknIdentifierFormFacade
  ) {
    super(hknIdentifierService);
  }

  public suppliers$ = this.formGroup.controls.hknTypeId.valueChanges.pipe(
    filter((hknTypeId) => !!hknTypeId),
    switchMap((hknTypeId) => {
      return this.hknTypeService.getSuppliersByHknTypeId$(hknTypeId);
    }),
    takeUntil(this.closeSubject)
  );

  public deliveryAreas$ = this.formGroup.controls.hknTypeId.valueChanges.pipe(
    filter((hknTypeId) => !!hknTypeId),
    switchMap((hknTypeId) => {
      return this.hknTypeService.getDeliveryAreasByHknTypeId$(hknTypeId);
    }),
    takeUntil(this.closeSubject)
  );

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}

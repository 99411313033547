<gridster #gridster [options]="options" *ngIf="widgets(); let widgets">
  <gridster-item [item]="item" *ngFor="let item of widgets; trackBy: trackByFn">
    <dashboard-dashboard-panel-widget
      [widget]="item"
    ></dashboard-dashboard-panel-widget>
  </gridster-item>
</gridster>

<!-- <div class="empty" *ngIf="!widgets?.length && !hasWritePermissions()">
  {{ 'DASHBOARDPANEL.NOWIDGETS.NOTCREATOR' | transloco }}
</div>

<div class="empty" *ngIf="!widgets?.length && hasWritePermissions()">
  {{ 'DASHBOARDPANEL.NOWIDGETS.CREATOR' | transloco }}
</div>
-->

import { Component, Input } from '@angular/core';
import { ActionButton } from './action-button';

@Component({
  selector: 'cc-action-button',
  templateUrl: './action-button.html',
  styleUrls: ['./action-button.scss'],
})
export class ActionButtonComponent extends ActionButton {
  @Input()
  public override tooltip = 'data.action';

  @Input()
  public override displayText = 'data.action';
}

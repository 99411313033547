type PermissionValues =
  | 'customer-pool.power.admin'
  | 'customer-pool.power.pool-manager'
  | 'customer-pool.power.email-notification-all-signals'
  | 'customer-pool.power.push-notification-all-signals'
  | 'customer-pool.gas.admin'
  | 'customer-pool.gas.pool-manager'
  | 'customer-pool.gas.email-notification-all-signals'
  | 'customer-pool.gas.push-notification-all-signals';

export class CustomerPoolPermissions {
  public static readonly customerPoolPowerAdmin: PermissionValues =
    'customer-pool.power.admin';

  public static readonly customerPoolPowerPoolManager: PermissionValues =
    'customer-pool.power.pool-manager';

  public static readonly customerPoolPowerEmailNotificationAllSignals: PermissionValues =
    'customer-pool.power.email-notification-all-signals';

  public static readonly customerPoolPowerPushNotificationAllSignals: PermissionValues =
    'customer-pool.power.push-notification-all-signals';

  public static readonly customerPoolGasAdmin: PermissionValues =
    'customer-pool.gas.admin';

  public static readonly customerPoolGasPoolManager: PermissionValues =
    'customer-pool.gas.pool-manager';

  public static readonly customerPoolGasEmailNotificationAllSignals: PermissionValues =
    'customer-pool.gas.email-notification-all-signals';

  public static readonly customerPoolGasPushNotificationAllSignals: PermissionValues =
    'customer-pool.gas.push-notification-all-signals';

  public static readonly sideNavPermission = [
    CustomerPoolPermissions.customerPoolPowerAdmin,
    CustomerPoolPermissions.customerPoolPowerPoolManager,
    CustomerPoolPermissions.customerPoolGasAdmin,
    CustomerPoolPermissions.customerPoolGasPoolManager,
  ];

  public static readonly tabPermission = [
    CustomerPoolPermissions.customerPoolPowerAdmin,
    CustomerPoolPermissions.customerPoolPowerPoolManager,
    CustomerPoolPermissions.customerPoolGasAdmin,
    CustomerPoolPermissions.customerPoolGasPoolManager,
  ];
}

import { FrontOfficeLimitReportPermissions } from '@clean-code/shared/frontoffice/util-permissions';
import {
  faCalculator,
  faChartLine,
  faCircle,
  faEuroSign,
  faFileAlt,
  faGraduationCap,
  faHome,
  faPlug,
} from '@fortawesome/pro-solid-svg-icons';

export const navigationItems = [
  { name: 'side-nav.START', link: 'start', icon: faHome },
  {
    name: 'side-nav.ENERGY_MARKET',
    link: 'market/energy-market',
    icons: [faChartLine, faCircle, faPlug],
  },
  {
    name: 'side-nav.FINANCIAL_MARKETS',
    link: 'market/market',
    icons: [faChartLine, faCircle, faEuroSign],
  },
  {
    name: 'side-nav.SPREADS',
    link: 'spreads',
    icon: faCalculator,
  },
  {
    name: 'limit-reports.TITLE_PLURAL',
    link: 'limit-report',
    icon: faFileAlt,
    permission: [
      FrontOfficeLimitReportPermissions.limitReportOtherViewer,
      FrontOfficeLimitReportPermissions.limitReportGasViewer,
      FrontOfficeLimitReportPermissions.limitReportPowerViewer,
    ],
  },
  { name: 'side-nav.WIKI', link: 'wiki', icon: faGraduationCap },
];

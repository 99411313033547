<page-container>
  <ng-container title
    >E-Steiermark Business GmbH – {{ 'fof.IMPRINT' | transloco }}</ng-container
  >
  <p>
    Adresse<br />E-Steiermark Business GmbH<br />Leonhardg&uuml;rtel 10<br />A-8010
    Graz
  </p>
  <p>
    Telefon: <a href="tel:+4331690000">+43 (0) 316 9000-0</a><br />Fax: +43 (0)
    316 9000-22 909<br />E-Mail:
    <a href="mailto:business@e-steiermark.com">business@e-steiermark.com</a
    ><br />Internet:
    <a href="https://www.e-steiermark.com" target="_blank"
      >www.e-steiermark.com</a
    >
  </p>
  <p>Rechtsform: Gesellschaft mit beschr&auml;nkter Haftung</p>
  <p>Sitz: Graz</p>
  <p>
    FN 386795 t (Landes- als Firmenbuchgericht Graz)<br />UID ATU 67490878<br />DVR
    4009116
  </p>
  <p>
    Berufsrechtliche Vorschriften:<br />Elektrizit&auml;tswirtschafts- und
    Organisationsgesetz, Stmk. Elektrizit&auml;tswirtschafts- und
    Organisationsgesetz; Gaswirtschaftsgesetz, Gewerbeordnung
  </p>
  <p>
    Die angef&uuml;hrten gesetzlichen Grundlagen sind &uuml;ber das
    Rechtsinformationssystem des Bundeskanzleramts online unter
    <a href="https://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a>
    verf&uuml;gbar.
  </p>
  <p>Berufsverband:<br />&Ouml;sterreichs Energie, Wirtschaftskammer</p>
  <p>
    Unternehmensgegenstand:<br />Abschluss von Energieliefervertr&auml;gen mit
    Businesskunden und Handel mit Energie- und Umweltprodukten
  </p>
  <p>
    Aufsichtsbeh&ouml;rde:<br />E-Control GmbH, Amt der Stmk. Landesregierung
  </p>
</page-container>

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { News } from '../entities/news';
import { NewsStore } from './news.store';
@Injectable({ providedIn: 'root' })
export class NewsQuery extends AkitaSearchQueryEntity<News> {
  constructor(protected store: NewsStore) {
    super(store);
  }
}

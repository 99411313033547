import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[global-tooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('global-tooltip') text?: string;
  private overlayRef?: OverlayRef;

  private closeSubject = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    this.hide();
    this.closeSubject.next();
    this.closeSubject.complete();
  }

  @HostListener('mouseover')
  show() {
    if (this.overlayRef && this.text) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(TooltipComponent)
      );
      tooltipRef.instance.text = this.text;
    } else {
      throw new Error('overlayRef or text does not exist');
    }
  }

  @HostListener('mouseout')
  hide() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}

import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig, StoreConfigOptions } from '@datorama/akita';
import { AnalysisLocalized } from './../entities/analysis';

export interface AnalysisWidgetState extends BaseState<AnalysisLocalized> {}

function createInitialState(): AnalysisWidgetState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Analysis-widget' })
export class AnalysisWidgetStore extends BaseStore<AnalysisLocalized> {
  constructor() {
    super(createInitialState(), {
      resettable: true,
    } as StoreConfigOptions);
  }
}

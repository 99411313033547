import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { NotFoundComponent } from './not-found.component';

const routes: Route[] = [
  {
    path: '',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), TranslocoModule],
  declarations: [NotFoundComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'bo-404',
        alias: '404',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class NotFoundModule {}

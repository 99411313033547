import { Injectable } from '@angular/core';
import { AuthStore, IAuthService } from '@clean-code/shared/auth/util-auth';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OAuthAuthService implements IAuthService {
  constructor(
    private authService: OAuthService,
    private authStore: AuthStore
  ) {}

  login() {
    from(this.authService.tryLoginCodeFlow()).subscribe();
  }

  logout(): void {
    this.authStore.reset();
    this.authService.logOut();
  }

  getAccessToken$(): Observable<string> {
    return of(this.authService.getAccessToken());
  }

  async getAccessToken(): Promise<string> {
    return this.authService.getAccessToken();
  }

  hasValidIdToken(): boolean {
    return this.authService.hasValidIdToken();
  }
  hasValidAccessToken(): boolean {
    return this.authService.hasValidAccessToken();
  }
}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
<table
  class="table-layout min-w-full divide-y divide-gray-200"
  *ngIf="data$ | async; let data"
>
  <thead>
    <tr>
      <th scope="col">
        {{ 'master-data.COMPANY' | transloco }}
      </th>
      <th>
        {{ 'master-data.VOLUME' | transloco }}
      </th>
    </tr>
  </thead>
  <tbody class="overflow-y-scroll">
    <!-- Odd row -->
    <tr
      class="border-b"
      *ngFor="let element of data"
      global-tooltip="{{ (element.value.volume | number: '.2-2') + ' GWh' }}"
    >
      <td
        class="whitespace-nowrap text-sm font-medium text-primary-color hover:text-black"
        *ngIf="config?.filter.energySource; let energySource"
        [routerLink]="[
          '/company-' +
            energySource.toLowerCase() +
            '/' +
            element.value.companyId +
            '/' +
            energySource.toLowerCase() +
            '/orders/hkn/orders'
        ]"
      >
        {{ element.key }}
      </td>
      <td class="whitespace-nowrap text-sm text-gray-500">
        {{ element.value.volume | number: '1.0-0' }} GWh
      </td>
    </tr>
  </tbody>
</table>

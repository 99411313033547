<!-- Page title & actions -->
<mat-progress-bar
  class="progress"
  *ngIf="isLoading"
  mode="indeterminate"
></mat-progress-bar>
<div class="progress-bar-spacer" *ngIf="!isLoading"></div>

<div
  *ngIf="showTitle"
  class="sm:flex sm:items-center sm:justify-between flex-row space-x-2 h-12 py-3 border-b print:mx-3"
>
  <fa-icon
    style="margin-bottom: 0.5rem"
    *ngIf="!!(pageIcon$ | async) && showTitle"
    [class]="(pageIcon$ | async).class"
    [icon]="(pageIcon$ | async).icon"
  >
  </fa-icon>
  <div class="min-w-0 flex-1">
    <h1
      #title
      class="font-bold leading-8 text-gray-900 dark:text-primary-color text-3xl truncate"
    >
      <ng-content select="[title]"></ng-content>
    </h1>
    <h1
      class="font-bold leading-8 text-gray-900 dark:text-primary-color text-3xl truncate"
      *ngIf="
        title?.childNodes?.length === 0 && (pageTitle$ | async) as titleState
      "
    >
      {{ titleState.key | transloco : { value: titleState.value } }}
    </h1>
  </div>
  <div class="mt-4 flex sm:mt-0 sm:ml-4">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>

<ng-content></ng-content>

import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { Analysis } from '../entities/analysis';
import { AnalysisStore } from './analysis.store';

@Injectable({ providedIn: 'root' })
export class AnalysisQuery extends AkitaSearchQueryEntity<Analysis> {
  constructor(protected store: AnalysisStore) {
    super(store);
  }
}

import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { OAuthModuleConfig } from './auth.config';
import { AuthConfigService } from './authconfig.service';
import { OAuthAuthService } from './services/oauth-auth.service';
// export function init_app(authConfigService: AuthConfigService) {
//   return () => authConfigService.initAuth();
// }

export function storageFactory() {
  //return new StateAuthStorage(authStore, authQuery);
  return localStorage;
}

// function createDefaultLogger() {
//   return console;
// }

// function createDefaultStorage() {
//   return typeof sessionStorage !== 'undefined'
//     ? sessionStorage
//     : new MemoryStorage();
// }

@NgModule({
  imports: [HttpClientModule],
})
export class OAuthAuthModule {
  static forRoot(
    config: OAuthModuleConfig
  ): ModuleWithProviders<OAuthAuthModule> {
    return {
      ngModule: OAuthAuthModule,
      providers: [
        // AuthConfigService,
        { provide: OAuthModuleConfig, useValue: config },
        { provide: 'IAuthService', useClass: OAuthAuthService },
        { provide: AuthConfig, useValue: config },
        AuthConfigService,
        // { provide: OAuthStorage, useFactory: storageFactory },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: init_app,
        //   deps: [AuthConfigService],
        //   multi: true,
        // },
      ],
    };
  }
}

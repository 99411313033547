import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NoPermissionLayoutComponent } from './no-permission-layout.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [NoPermissionLayoutComponent],
  exports: [NoPermissionLayoutComponent],
})
export class NoPermissionLayoutModule {}

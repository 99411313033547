<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<div
  *ngIf="widget(); let widget"
  [formGroup]="form"
  [patchFormGroupValues]="widget"
>
  <div class="form-row">
    <div class="form-group col-lg-12 flex flex-col sm:flex-row">
      <cc-input-translation
        [label]="'DASHBOARDWIDGET.PROPERTIES.NAME'"
        [required]="true"
        [containerClasses]="'name-container'"
        formControlName="name"
      ></cc-input-translation>
      <cc-color-picker formControlName="color"></cc-color-picker>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-lg-12">
      <cc-input-translation
        [label]="'DASHBOARDWIDGET.PROPERTIES.DESCRIPTION'"
        [containerClasses]="'description-container'"
        [longText]="true"
        formControlName="description"
      ></cc-input-translation>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-lg-12">
      <mat-form-field class="w-full">
        <mat-label>{{
          'DASHBOARDWIDGET.PROPERTIES.TYPE' | transloco
        }}</mat-label>
        <mat-select formControlName="type" *ngIf="!widget.id">
          <!--[sortComparator]="sortComparator" -->
          <mat-option *ngFor="let type of type$ | async" [value]="type">
            {{ 'DASHBOARDWIDGET.TYPES.' + type | transloco }}
          </mat-option>
        </mat-select>

        <input
          matInput
          *ngIf="widget.id"
          readonly="true"
          value="{{ 'DASHBOARDWIDGET.TYPES.' + widget.type | transloco }}"
        />

        <!-- <input
          type="text"
          placeholder="{{ 'DASHBOARDWIDGET.PROPERTIES.TYPE' | transloco }}"
          aria-label="text"
          formControlName="type"
          matInput
          [matAutocomplete]="auto"
          class="w-full"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filtered$ | async"
            [value]="option.key"
          >
            {{ option.value }}
          </mat-option>
        </mat-autocomplete> -->
      </mat-form-field>

      <div *ngIf="type.errors?.['forbiddenWidget']" class="text-red-600">
        {{
          'DASHBOARDWIDGET.PROPERTIES.TYPE_NOT_ALLOWED'
            | transloco
              : {
                  value: type.errors?.['forbiddenWidget'].value
                }
        }}
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-lg-12">
      <mat-checkbox #shared formControlName="shared"
        >{{ 'DASHBOARDWIDGET.PROPERTIES.SHARED' | transloco }}
      </mat-checkbox>
    </div>
  </div>

  <div class="form-row" [hidden]="!shared.checked">
    <div class="form-group col-lg-12">
      <cc-visible-to formControlName="visibleTo"></cc-visible-to>
    </div>
  </div>
</div>

import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthUser } from '../models/auth-user';

export interface AuthState {
  user: AuthUser;
  authValues: KeyValue<string, string>[];
  accessToken: string;
}

export function createInitialState(): AuthState {
  return {
    user: null,
    authValues: new Array<KeyValue<string, string>>(),
    accessToken: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}

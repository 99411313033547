import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { NormCubicMeter } from '../models/norm-cubic-meter';
import { NormCubicMeterStore } from './norm-cubic-meter.store';

@Injectable({ providedIn: 'root' })
export class NormCubicMeterQuery extends AkitaSearchQueryEntity<NormCubicMeter> {
  constructor(protected store: NormCubicMeterStore) {
    super(store);
  }
}

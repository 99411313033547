type PermissionValues =
  | 'users.view'
  | 'users.manage'
  | 'roles.view'
  | 'roles.manage'
  | 'roles.assign';

export enum SystemRoles {
  KAM = 'Vertrieb KAM',
}

export enum SystemClaims {
  KAM_POWER = 'keyaccountmanagerpower',
  KAM_GAS = 'keyaccountmanagergas',
  ENERGY_SOURCE_POWER = 'energysourcepower',
  ENERGY_SOURCE_GAS = 'energysourcegas',
}

export class AvailablePermission {
  // public static readonly viewUsersPermission: PermissionValues = 'users.view';
  public static readonly manageUsersPermission: PermissionValues =
    'users.manage';

  // public static readonly viewRolesPermission: PermissionValues = 'roles.view';
  public static readonly manageRolesPermission: PermissionValues =
    'roles.manage';
  // public static readonly assignRolesPermission: PermissionValues = 'roles.assign';
}

import { Component } from '@angular/core';

@Component({
  selector: 'cc-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  public year: number = new Date().getFullYear();
}

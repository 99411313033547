import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // get new headers and include old ones
    const langHeader = this.getLanguageHeaders(req.headers, req);
    // Clone the request to add the new header.
    const lanReq = req.clone({ headers: langHeader });
    // Pass on the cloned request instead of the original request.
    return next.handle(lanReq);
  }

  // TODO: split interceptor for language header?
  private getLanguageHeaders(
    existingHeaders: HttpHeaders,
    _req: HttpRequest<any>
  ): HttpHeaders {
    const language = this.languageService.currentLocale;

    const headers = existingHeaders.append('Accept-Language', language);

    return headers;
  }
}

<mat-progress-bar
  *ngIf="(isLoading$ | async) || (isTableLoading$ | async)"
  mode="indeterminate"
></mat-progress-bar>

<div class="flex flex-col lg:flex-row space-x-4">
  <cc-line-chart
    [data]="data$ | async"
    class="w-full lg:w-3/4"
    [legend]="legend"
    (pointClickEvent)="pointClickEvent($event)"
  ></cc-line-chart>
  <div
    class="flex flex-col space-y-4 w-full lg:w-1/4"
    *ngVar="identifier$ | async; let identifier"
  >
    <ng-container
      *ngIf="
        identifier?.summaryType !== 'COMPACT' && details$ | async;
        let details
      "
    >
      <h4 *ngIf="details.deliveryDay" class="text-center text-gray-500 text-xl">
        {{ 'market-dashboard-details.DELIVERY_DAY' | transloco }}
      </h4>
      <table class="table-fixed">
        <tbody>
          <tr *ngIf="details.priceDetails.intradayPrice">
            <td class="w-56">
              {{ 'market-dashboard-details.INTRA_DAY_PRICE' | transloco }}
              <div class="text-xs font-bold">
                ({{ details.priceDetails.intradayDateTime | date : 'short' }})
              </div>
            </td>
            <td class="w-28">
              {{ details.priceDetails.intradayPrice | number : '.2-2' }}
            </td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr *ngIf="details.priceDetails.lastClosingPrice">
            <td>
              {{
                (!hideClosingText
                  ? 'market-dashboard-details.CLOSING_PRICE'
                  : 'market-dashboard-details.CLOSING_VALUE'
                ) | transloco
              }}
              <div class="text-xs font-bold">
                ({{ details.priceDetails.lastClosingDate | date }})
              </div>
            </td>
            <td>
              {{ details.priceDetails.lastClosingPrice | number : '.2-2' }}
            </td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr *ngIf="details.priceDetails.intradayChange">
            <td>
              {{ 'market-dashboard-details.INTRA_DAY_CHANGES' | transloco }}
            </td>
            <td>
              {{ details.priceDetails.intradayChange * 100 | number : '.2-2' }}
            </td>
            <td>%</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.YEAR_HIGH' | transloco }}
            </td>
            <td>{{ details.priceDetails.yearHigh | number : '.2-2' }}</td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.YEAR_LOW' | transloco }}
            </td>
            <td>{{ details.priceDetails.yearLow | number : '.2-2' }}</td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr>
            <td>
              {{
                'market-dashboard-details.CHANGE_DATE'
                  | transloco
                    : {
                        value:
                          (details.priceDetails.firstDayOfYear | date : 'short')
                      }
              }}
            </td>
            <td>
              {{
                details.priceDetails.firstDayOfYearDiff * 100 | number : '.2-2'
              }}
            </td>
            <td>%</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.ONE_YEAR_HIGH' | transloco }}
            </td>
            <td>{{ details.priceDetails.oneYearHigh | number : '.2-2' }}</td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.ONE_YEAR_LOW' | transloco }}
            </td>
            <td>{{ details.priceDetails.oneYearLow | number : '.2-2' }}</td>
            <td>{{ details.priceDetails.unit }}</td>
          </tr>
          <tr>
            <td>
              {{
                'market-dashboard-details.CHANGE_DATE'
                  | transloco
                    : {
                        value:
                          (details.priceDetails.oneYearChangeDate
                          | date : 'short')
                      }
              }}
            </td>
            <td>
              {{ details.priceDetails.oneYearChange * 100 | number : '.2-2' }}
            </td>
            <td>%</td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="details.volumeDetails && details.volumeDetails.previewDayVolume"
      >
        <h4 class="text-center">
          {{ 'market-dashboard-details.VOLUME_TITLE' | transloco }}
        </h4>
        <table class="table-fixed">
          <tbody>
            <tr>
              <td class="w-56">
                {{ 'market-dashboard-details.PREVIEW_DAY_VOLUME' | transloco }}
              </td>
              <td class="w-28">
                {{ details.volumeDetails.previewDayVolume | number : '.2-2' }}
              </td>
              <td>GWh</td>
            </tr>
            <tr>
              <td>
                {{ 'market-dashboard-details.DIFF' | transloco }}
              </td>
              <td>{{ details.volumeDetails.diff * 100 | number : '.2-2' }}</td>
              <td>%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <!-- compact summary -->
    <ng-container
      *ngIf="
        identifier?.summaryType === 'COMPACT' && details$ | async;
        let detail
      "
    >
      <h4 *ngIf="detail.deliveryDay" class="text-center text-gray-500 text-xl">
        {{ 'market-dashboard-details.DELIVERY_DAY' | transloco }}
      </h4>
      <table>
        <tbody>
          <tr>
            <th colspan="3"></th>
          </tr>
          <tr>
            <td class="w-56">
              {{ 'market-dashboard-details.ACTUAL' | transloco }}
              <div class="text-xs font-bold">
                ({{ detail.currentValueDate | date : 'shortDate' }})
              </div>
            </td>
            <td>
              {{ detail.currentValue | number : '.2-3' }}
            </td>
            <td>
              {{ detail.unit }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.PREVIOUS' | transloco }}
              <div class="text-xs">
                ({{ detail.previousValueDate | date : 'shortDate' }})
              </div>
            </td>
            <td>
              {{ detail.previousValue | number : '.2-3' }}
            </td>
            <td>
              {{ detail.unit }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.DIFF' | transloco }}
            </td>
            <td>
              {{
                (calculationType$ | async) === 'RELATIVE'
                  ? (detail.differenceRelative * 100 | number : '.2-2')
                  : (detail.differenceAbsolute | number : '.2-3')
              }}
            </td>
            <td>
              {{
                (calculationType$ | async) === 'RELATIVE' ? '%' : detail.unit
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AkitaSearchBaseService } from '@backoffice-frontend/shared/ui-akita-state';
import { OfferPermissions } from '@backoffice/shared/bo/util-permissions';
import { Area } from '@clean-code/backoffice/area/util-api';
import {
  DataTableParameters,
  ID,
  ObjectHelper,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, combineLatest, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OfferGasStore } from '../+state/offer-gas.store';
import { Offer } from '../models/offer';
import { OfferCrmLookupOpportunity } from '../models/offer-crm-lookup-opportunity';
import { OfferGas } from '../models/offer-gas';
import { OfferGasContract } from '../models/offer-gas-contract';
import { OfferGasPricingOverview } from '../models/offer-gas-pricing-overview';
import { OfferTradingRequest } from '../models/offer-trading-request';
import { OFFER_GAS_PAGINATOR } from './offer-gas-paginator';
import { IOfferService } from './offer.service';
@Injectable({
  providedIn: 'root',
})
export class OfferGasService
  extends AkitaSearchBaseService<OfferGas>
  implements IOfferService
{
  private url = this.configService.settings['api'] + 'offer/graphql';
  private orderUrl = this.configService.settings['api'] + 'ordergas/graphql';

  public readonly entityFields = `
    id
    marketPriceRiskDays
    areaDeliveryAreaId
    areaDeliveryArea
    areaMarketAreaId
    areaMarketArea
    companyId
    company
    lastComment
    forecastId
    forecast
    forwardPriceCurveVersionId
    forwardPriceCurveVersion
    grossMargin
    name
    segmentId
    segment
    supplierId
    supplier
    exclusivity
    dailyStructure
    minFactor
    maxFactor
    balancingVariant
    offerId
    timeFrames
    {
      start
      end
      timeFrame
    }
    costingType
    createdBy
    createdDate
  `;

  constructor(
    protected store: OfferGasStore,
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    @Inject(OFFER_GAS_PAGINATOR)
    private paginatorRef: PaginatorPlugin<OfferGas>,
    protected permissionService: NgxPermissionsService,
    public activatedRoute: ActivatedRoute
  ) {
    super(store);
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<OfferGas>> {
    const query = `
    query ($params: DataTableParametersType!){
      offersGas(params: $params){
          perPage
          currentPage
          total
          lastPage
          data {
            ${this.entityFields}
            canDelete
          }
        }
      }
    `;
    const variables = { params: params };

    return this.graphqlService.query<PaginationResponse<OfferGas>>(
      query,
      variables,
      this.url
    );
  }

  public getById$(id: ID): Observable<OfferGas> {
    const query = `
    query($id: String!) {
      offerGas(id: $id) {
         ${this.entityFields}
      }
    }
    `;
    const variables = { id };
    return this.graphqlService
      .query<OfferGas>(query, variables, this.url, { redirectTo404: true })
      .pipe(
        tap((data) => {
          this.store.upsert(data.id, data);
          this.store.setActive(data.id);
          this.invalidateCache();
        })
      );
  }

  public add$(offerGas: OfferGas) {
    const query = `
      mutation ($offerGas: OfferGasInsertType!)
      {
        insertOfferGas(offerGas: $offerGas) {
          ${this.entityFields}
        }
      }
    `;

    offerGas.timeFrames = offerGas.timeFrames.filter((x) => x !== null);
    offerGas = ObjectHelper.cloneObject(offerGas);

    delete offerGas.id;

    const variables = { offerGas };

    return this.graphqlService.query<OfferGas>(query, variables, this.url).pipe(
      tap((newOfferGas) => {
        this.upsertAndRefresh(newOfferGas);
        this.paginatorRef.refreshCurrentPage();
      })
    );
  }

  public delete$(id: ID) {
    const query = `
      mutation ($id: String!)
      {
        deleteOfferGas(id: $id)
      }
      `;
    const variables = { id };

    return this.graphqlService.query<boolean>(query, variables, this.url).pipe(
      tap((value) => {
        if (value) {
          this.store.remove(id);
          this.invalidateCache();
          this.paginatorRef.refreshCurrentPage();
        }
      })
    );
  }

  getVersions$(offerId: ID): Observable<Offer[]> {
    const query = `
    query($offerId: String!) {
      offerGasVersions(offerId: $offerId) {
        id
        offerId
        versionId
        costingType
        contractProductId
        contractMarketAreaId
        updatedDate
        deliveryRange
        company
        companyId
        forecast
        forecastId
        name
        supplierId
        supplier
        areaDeliveryAreaId
        areaDeliveryArea
        createdDate
        createdBy
        areaMarketArea
      }
    }
    `;
    const variables = { offerId };
    return this.graphqlService.query<Offer[]>(query, variables, this.url);
  }

  marketAreaByOffer$(id: ID): Observable<Area[]> {
    const query = `
    query($id: String!) {
      offerGasMarketAreas(id: $id) {
        id
        name
        country
      }
    }
    `;
    const variables = { id };
    return this.graphqlService.query<Area[]>(query, variables, this.url);
  }

  public searchContract$(
    params: DataTableParameters
  ): Observable<OfferGasContract[]> {
    const query = `
    query ($params: DataTableParametersType!){
      offerContractSearch(params: $params){
          id
          company
          product
          areaMarketArea
          amount
          fixedPrice
          fixedSurcharge
          name
        }
      }
    `;
    const variables = { params };

    return this.graphqlService.query<OfferGasContract[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  getTradingRequest$(
    id: string,
    offerPricingIds: ID[]
  ): Observable<OfferTradingRequest> {
    const query = `
    query ($id: ID!, $offerPricingIds: [ID]!) {
      gasTradingRequest(id: $id, offerPricingIds: $offerPricingIds) {
        forecast
        marketArea
        customer
        keyAccountManagers
        subject
        isProductionEnvironment
        environmentType
        timeFrameData
        {
          name
          start
          end
          monthlyData
        }
      }
    }
  `;
    const variables = { id, offerPricingIds };
    return this.graphqlService.query<OfferTradingRequest>(
      query,
      variables,
      this.url
    );
  }

  sendTradeRequest$(
    template: string,
    offerPricingIds: ID[]
  ): Observable<boolean> {
    const query = `
      mutation ($template: String!, $offerPricingIds: [ID]!)
      {
        sendTradeRequest(template: $template, offerPricingIds: $offerPricingIds)
      }
    `;

    const variables = { template, offerPricingIds };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  public getOverviewModel$(id: string): Observable<OfferGasPricingOverview> {
    const query = `
    query ($id: ID!) {
      offerGasOverview(id: $id) {
        pricingHeaders
        combinations {
          marketArea
          product
          pricings
        }
      }
    }
    `;
    const variables = { id };
    return this.graphqlService.query<OfferGasPricingOverview>(
      query,
      variables,
      this.url
    );
  }

  public toOffer$(offerId: ID): Observable<boolean> {
    const query = `
      mutation ($offerId: String!)
      {
        offerGasToOffer(offerId: $offerId)
      }
    `;

    const variables = { offerId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public toCrmOffer$(offerId: ID): Observable<OfferCrmLookupOpportunity> {
    throw new Error('Method not implemented. OfferId: ' + offerId);
  }

  public sendOfferToCrm$(
    offerId: ID,
    areaId: ID,
    productId: ID,
    opportunityNumber: string,
    opportunityPartnerNumber: string,
    deliveryPeriodFrom: string,
    deliveryPeriodTill: string
  ): Observable<boolean> {
    throw new Error(
      'Method not implemented. OfferId: ' +
        offerId +
        ', AreaId:' +
        areaId +
        ', ProductId:' +
        productId +
        ', OpportunityNumber: ' +
        opportunityNumber +
        ', PartnerNumber: ' +
        opportunityPartnerNumber +
        ', deliveryPeriodFrom: ' +
        deliveryPeriodFrom +
        ', deliveryPeriodTill: ' +
        deliveryPeriodTill
    );
  }

  public toContract$(offerId: ID, offerPricingIds: ID[]): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!, $offerPricingIds: [ID]!)
      {
        offerGasToContract(offerId: $offerId, offerPricingIds: $offerPricingIds)
      }
    `;

    const variables = { offerId, offerPricingIds };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  public downgradeToOffer$(
    offerId: ID,
    marketAreaId: ID,
    productId: ID
  ): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!, $marketAreaId: ID!, $productId: ID!)
      {
        offerGasDowngradeContractToOffer(offerId: $offerId, marketAreaId: $marketAreaId, productId: $productId)
      }
    `;

    const variables = { offerId, marketAreaId, productId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public downgradeToCosting$(offerId: ID): Observable<boolean> {
    const query = `
      mutation ($offerId: ID!)
      {
        offerGasDowngradeOfferToCosting(offerId: $offerId)
      }
    `;

    const variables = { offerId };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  public hasDowngradePermission$(): Observable<boolean> {
    return from(
      this.permissionService.hasPermission(
        OfferPermissions.offerGasVersionAdmin
      )
    );
  }

  public offerContractPrefilled(
    pricingIds: ID[]
  ): Observable<OfferGasContract[]> {
    const query = `
    query($pricingIds: [ID]!) {
      offerContractsPrefilled(pricingIds: $pricingIds)
      {
        amount
        grossMargin
        fixedPrice
        fixedSurcharge
        companyId
        offerOfferId
        areaMarketAreaId
        areaMarketArea
        productId
        product
        products {
          position
          productId
          name
        }
        pricingId
        timeFrame {
          start
          end
          timeFrame
        }
        upperLimit
        lowerLimit
        name
        enableIndexFormula
      }
    }
    `;
    const variables = { pricingIds };
    return this.graphqlService.query<OfferGasContract[]>(
      query,
      variables,
      this.orderUrl
    );
  }

  public insertOfferContracts$(
    contracts: OfferGasContract[]
  ): Observable<boolean> {
    const query = `
      mutation ($contracts: [OfferGasContractInsertType]!)
      {
        insertOfferContracts(contracts: $contracts)
      }
    `;

    contracts = ObjectHelper.cloneObject(contracts);
    contracts.forEach((contract: OfferGasContract) => {
      delete contract.isOpen;
      delete contract.id;
      if (!contract.fixedSurcharge && contract.fixedPrice) {
        contract.fixedSurcharge = 0;
      }
      if (contract.products?.length > 0) {
        contract.products.forEach((x) => {
          delete x.product;
          delete x.offerGasContractId;
        });
      }
    });

    const variables = { contracts };

    return this.graphqlService.query<boolean>(query, variables, this.orderUrl);
  }

  public createContractFormGroup(contract: OfferGasContract): UntypedFormGroup {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(contract.id),
      amount: new UntypedFormControl(contract.amount, [Validators.required]),
      grossMargin: new UntypedFormControl(+contract.grossMargin.toFixed(2)),
      fixedPrice: new UntypedFormControl(contract.fixedPrice),
      fixedSurcharge: new UntypedFormControl(
        +contract.fixedSurcharge.toFixed(2),
        [Validators.required]
      ),
      companyId: new UntypedFormControl(contract.companyId, [
        Validators.required,
      ]),
      offerOfferId: new UntypedFormControl(contract.offerOfferId, [
        Validators.required,
      ]),
      areaMarketAreaId: new UntypedFormControl(contract.areaMarketAreaId, [
        Validators.required,
      ]),
      productId: new UntypedFormControl(contract.productId, [
        Validators.required,
      ]),
      product: new UntypedFormControl(contract.product, [Validators.required]),
      products: new UntypedFormControl(contract.products),
      pricingId: new UntypedFormControl(contract.pricingId, [
        Validators.required,
      ]),
      timeFrame: new UntypedFormControl(contract.timeFrame, [
        Validators.required,
      ]),
      areaMarketArea: new UntypedFormControl(contract.areaMarketArea, [
        Validators.required,
      ]),
      isOpen: new UntypedFormControl(null, [Validators.required]),
      upperLimit: new UntypedFormControl(contract.upperLimit, [
        Validators.required,
      ]),
      lowerLimit: new UntypedFormControl(contract.lowerLimit, [
        Validators.required,
      ]),
      name: new UntypedFormControl(contract.name, [Validators.required]),
    });

    form.controls.product.disable();
    form.controls.areaMarketArea.disable();

    if (!contract.enableIndexFormula) {
      form.controls.fixedSurcharge.disable({ onlySelf: true });
      form.controls.grossMargin.disable({ onlySelf: true });

      form.controls.fixedPrice.setValidators(Validators.required);
      form.controls.fixedPrice.markAsTouched();
    }

    return form;
  }

  public editViewMode$(): Observable<boolean> {
    return combineLatest([
      from(this.permissionService.hasPermission(OfferPermissions.gasEdit)),
      this.activatedRoute.paramMap.pipe(
        map((params) => params.get('mode')),
        map((id) => !!id && id === 'readOnly')
      ),
    ]).pipe(map(([edit, readOnly]) => edit && !readOnly));
  }

  getOfferPreview(id: ID): Observable<Offer> {
    const query = `query {
      offerGasById(id: ${id})
      {
        id
        name
        offerId
        versionId
        company
        {
          name
        }
      }
    }`;

    return this.graphqlService.query<Offer>(query);
  }

  offerVersionsById(id: ID): Observable<Offer[]> {
    const query = `
    query($id: Int!){
      offerGasVersionsById(id: $id) {
        id
        name
        company
        {
          name
        }
      }
    }
    `;

    const variables = { id };

    return this.graphqlService.query<Offer[]>(query, variables);
  }
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Parser } from '../entities/parser';
import {
  TimeSeriesAnalysisIdentifier,
  TimeSeriesAnalysisIdentifierAddInput,
} from '../entities/time-series-analysis-identifier';

@Injectable({ providedIn: 'root' })
export class TimeSeriesAnalysisIdentifierDataService
  implements EditSearchDataService<TimeSeriesAnalysisIdentifier>
{
  public readonly entityFields = `
    id
    name
    identifier
    areaMarketAreaId
    isActive
    createdBy
    updatedBy
    createdDate
    updatedDate
    unit

    typeId
    categoryId
    parserType
    parserId
    deliveryDay
    spotConfigurationId
    spotType
    deliveryDayType
  `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<TimeSeriesAnalysisIdentifier>> {
    const par = this.graphqlService.mapDataTableParameters(params);
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);

    let condition = `{and: [{ identifier: { ncontains: "<" } }, { identifier: { ncontains: "[" } }]}`;

    if (params.searchTerm) {
      condition += `{or: [{ identifier: { contains: "${params.searchTerm}" } }, { name: { contains: "${params.searchTerm}" } }]}`;
    }

    const where = this.graphqlService.mapDataTableFilterParameters(
      params.filters,
      condition
    );

    const query = `
    query{
      timeSeriesAnalysisIdentifierAll(
        ${par}
        ${order}
        ${where}
        ){
            totalCount
            items
            {
                ${this.entityFields}
                areaMarketArea{
                  name
                }
            }
            pageInfo{
                hasNextPage
                hasPreviousPage
            }
        }
    }`;

    return this.graphqlService
      .query<PagingResponse<TimeSeriesAnalysisIdentifier>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getAllForSelection$(params: DataTableParameters) {
    const query = `
    query{
      timeSeriesAnalysisIdentifierAll(take: 300){
            totalCount
            items
            {
              id
              name
              identifier
              hasContinuouslyOperator
            }
            pageInfo{
                hasNextPage
                hasPreviousPage
            }
        }
    }`;

    return this.graphqlService
      .query<PagingResponse<TimeSeriesAnalysisIdentifier>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getById$(id: ID): Observable<TimeSeriesAnalysisIdentifier> {
    const query = `
    query($id: Int!) {
      timeSeriesAnalysisIdentifierById(id: $id) {
            ${this.entityFields}
            frontOfficeNameId
            frontOfficeName
            {
              id
              localizations
              {
                  localizationSetId
                  id
                  value
                  cultureCode
              }
            }
            longNameId
            longName
            {
              id
              localizations
              {
                  localizationSetId
                  id
                  value
                  cultureCode
              }
            }
        }
    }`;

    return this.graphqlService.query<TimeSeriesAnalysisIdentifier>(
      query,
      {
        id,
      },
      null,
      { redirectTo404: true }
    );
  }

  update$(
    input: TimeSeriesAnalysisIdentifierAddInput
  ): Observable<TimeSeriesAnalysisIdentifier> {
    const mutation = `
      mutation ($input: TimeSeriesAnalysisIdentifierAddInput!){
        timeSeriesAnalysisIdentifierUpdate(input: $input)
        }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<TimeSeriesAnalysisIdentifier>(
      mutation,
      variables
    );
  }

  add$(input: TimeSeriesAnalysisIdentifierAddInput): Observable<ID> {
    const mutation = `
      mutation ($input: TimeSeriesAnalysisIdentifierAddInput!){
        timeSeriesAnalysisIdentifierAdd(input: $input)
        }`;

    const variables = {
      input,
    };

    return this.graphqlService.mutation<ID>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
      mutation ($id: Int!){
          timeSeriesAnalysisIdentifierDelete(id: $id)
        }`;

    return this.graphqlService.mutation<any>(mutation, { id }, null, {
      ignoreNullCheckFilter: true,
    });
  }

  public getParsers$(): Observable<Parser[]> {
    const query = `
    query {
      timeSeriesParsers {
            id
            name
        }
    }`;

    return this.graphqlService.query<Parser[]>(query);
  }

  public parserPreview$(id: ID): Observable<any> {
    const query = `
    query($id: Int!) {
      parserPreview(id: $id) {
            identifierId
            identifier
            frontOfficeName
            longName
        }
    }`;

    return this.graphqlService.query<any>(query, { id });
  }
}

<div class="dashboard-widget">
  <div
    class="widget-header flex flex-row items-center px-1 h-6 hover:h-6"
    [ngClass]="editMode() ? 'cursor-move z-50' : 'z-30'"
    [ngStyle]="{ 'border-top': '5px solid ' + widget.color }"
  >
    <button
      mat-icon-button
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
      *ngIf="editMode()"
    >
      <fa-icon [icon]="faEllipsisVertical">more_vert</fa-icon>
    </button>

    <mat-menu #menu="matMenu" class="widget-menu">
      <button
        mat-menu-item
        (click)="editWidget($event); $event.stopPropagation()"
        *ngIf="hasUserPermission()"
      >
        <fa-icon [icon]="faEdit"></fa-icon>
        <span>{{ 'common.EDIT' | transloco }}</span>
      </button>

      <button
        mat-menu-item
        (click)="removeWidget($event); $event.stopPropagation()"
      >
        <fa-icon [icon]="faTimes"></fa-icon>
        <span>{{ 'common.REMOVE' | transloco }}</span>
      </button>

      <button
        mat-menu-item
        (click)="deleteWidget($event); $event.stopPropagation()"
        *ngIf="hasUserPermission()"
      >
        <fa-icon [icon]="faTimes"></fa-icon>
        <span>{{ 'common.DELETE' | transloco }}</span>
      </button>
    </mat-menu>

    <span class="name title pl-2">{{ title() }}</span>

    <div class="flex flex-grow"></div>
    <button
      class="settings mr-2"
      mat-icon-button
      (click)="openSettings($event)"
      *ngIf="hasUserPermission() && editMode()"
    >
      <fa-icon [icon]="faGear"></fa-icon>
    </button>
  </div>

  <!-- widget dynamic part-->
  <div class="widget-body select-none">
    <dashboard-widget-type-content></dashboard-widget-type-content>
  </div>
</div>

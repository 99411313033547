import { Injectable } from '@angular/core';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { TimeSeriesAnalysisIdentifierDataLocalized } from '@backoffice-frontend/time-series-identifier/domain';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpreadStateService {
  public filter$ = new BehaviorSubject<
    TimeSeriesAnalysisIdentifierDataLocalized[]
  >([]);
  public timeFrame$ = new BehaviorSubject<TimeFrame>('M_3');
}

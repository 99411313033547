<div class="chip-list-wrapper">
  <mat-chip-listbox #chipList>
    <ng-container *ngFor="let select of selectData">
      <mat-chip-option (click)="removeChip(select)">
        {{ select.item[lableBinding] }}
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</div>

<mat-form-field class="w-full">
  <input
    matInput
    type="text"
    placeholder="{{ placeholder | transloco }}"
    [matAutocomplete]="auto"
    [formControl]="searchFormControl"
  />
  <mat-progress-spinner
    *ngIf="loading$ | async"
    mode="indeterminate"
    diameter="20"
  ></mat-progress-spinner>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
  <mat-option *ngFor="let data of data$ | async">
    <div (click)="optionClicked($event, data)">
      <mat-checkbox
        [checked]="data.selected"
        (change)="toggleSelection(data)"
        (click)="$event.stopPropagation()"
      >
        {{ data.item[lableBinding] }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

import { BaseEntity, ID, LocalizationSet } from '@clean-code/shared/common';
export interface TimeSeriesAnalysisIdentifier extends BaseEntity {
  name: string;
  identifier: string;
  areaMarketAreaId: number;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  createdDate: string;
  updatedDate: string;
  unit: string;
  typeId: number;
  categoryId: number;
  parserType: string;
  parserId: number;
  deliveryDay: boolean;
  spotConfigurationId: number;
  frontOfficeNameId: number;
  frontOfficeName: LocalizationSet;
  longNameId: number;
  longName: LocalizationSet;
  hasContinuouslyOperator: boolean;
}

export interface TimeSeriesAnalysisIdentifierAddInput extends BaseEntity {
  name: string;
}

export interface TimeSeriesIdentifierParser extends BaseEntity {}

export interface TimeSeriesIdentifierParserValue extends BaseEntity {
  id: ID;
  name: string;
  positionRighthand: number;
  value: number;
  type: string;
  parserId: ID;
}

export enum ParserValueType {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { TimeSeriesIdentifierParserValueQuery } from '../+state/time-series-identifier-parser-value.query';

export const TIME_SERIES_IDENTIFIER_PARSER_VALUE_PAGINATOR = new InjectionToken(
  'TIME_SERIES_IDENTIFIER_PARSER_VALUE_PAGINATOR',
  {
    providedIn: 'root',
    factory: () => {
      const query = inject(TimeSeriesIdentifierParserValueQuery);
      return new PaginatorPlugin(query, { startWith: 0 });
    },
  }
);

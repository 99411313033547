import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { of } from 'rxjs';

@Component({
  standalone: true,
  selector: 'cc-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatProgressBarModule,

    FontAwesomeModule,
  ],
})
export class DataTableContainerComponent {
  @HostBinding('class') class = 'cc-table-container';

  public isMobile$ = of(false); // TODO!! fix mobile with tailwind -> lib migration

  @Input()
  public isLoading = false;

  @Input()
  public showActionButtons = true;

  public faBars = faBars;
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import { DataTableParameters, PagingResponse } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { ID, PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TimeSeriesIdentifierCategory } from '../entities/time-series-identifier-category';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierCategoryDataService
  implements EditSearchDataService<TimeSeriesIdentifierCategory>
{
  public readonly entityFields = `
    id
    name
    localizedNameId
    localizedName {
        id
        localizations { 
            id
            value
        }
    }
  `;

  constructor(protected graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<TimeSeriesIdentifierCategory>> {
    let par = this.graphqlService.mapDataTableParameters(params);
    par = par + ',' + params.filters[0].key + ':' + params.filters[0].value;
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);
    const filter = this.graphqlService.mapDataTableFilterParameters(
      params.filters
    );

    const query = `
    query{
        timeSeriesIdentifierCategoryGetAll(
        ${par}
        ${order}
        ${filter}
        ){
            totalCount
            items
            {
                ${this.entityFields}
            }
            pageInfo{
                hasNextPage
                hasPreviousPage
            }
        }
    }`;

    return this.graphqlService
      .query<PagingResponse<TimeSeriesIdentifierCategory>>(query)
      .pipe(
        mapGraphqlPaging(params.page, params.perPage)
        //tap((value) => this.store.upsertMany(value.data))
      );
  }

  getById$(id: ID): Observable<TimeSeriesIdentifierCategory> {
    console.log(id);
    throw new Error('Method not implemented.');
  }

  add$(
    entity: TimeSeriesIdentifierCategory
  ): Observable<TimeSeriesIdentifierCategory | ID> {
    console.log(entity);
    throw new Error('Method not implemented.');
  }

  update$(
    entity: TimeSeriesIdentifierCategory
  ): Observable<TimeSeriesIdentifierCategory | ID> {
    console.log(entity);
    throw new Error('Method not implemented.');
  }

  delete$(id: ID): Observable<boolean> {
    console.log(id);
    throw new Error('Method not implemented.');
  }

  getTimeSeriesIdentifierCategoriesLocalized$(): Observable<
    TimeSeriesIdentifierCategory[]
  > {
    const query = `
    query categoriesLocalized {
        timeSeriesIdentifierCategoryGetAllLocalized (
          order: {name: ASC}
        ) {
          id
          name
        }
      }
    `;

    return this.graphqlService.query<TimeSeriesIdentifierCategory[]>(query);
  }
}

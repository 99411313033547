import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { EnumValue } from '@clean-code/shared/bo/util-enum';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { TranslocoModule } from '@ngneat/transloco';
import { of } from 'rxjs';

@Component({
  standalone: true,
  selector: 'bo-energy-source-dropdown',
  templateUrl: './energy-source-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnergySourceDropdownComponent),
      multi: true,
    },
  ],
})
export class EnergySourceDropdownComponent extends ControlValueAccessorConnector {
  public energySources$ = this.enumService.getEnergySources$();

  @Input()
  public set energySources(value: EnumValue[]) {
    this.energySources$ = of(value);
  }

  @Input()
  public includeEmpty = false;

  constructor(private enumService: EnumService) {
    super();
  }
}

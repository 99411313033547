<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select [formControl]="formControl">
    <mat-option [value]="null" selected *ngIf="clearable">{{
      'common.NO_SELECTION' | transloco
    }}</mat-option>
    <mat-option
      *ngFor="let item of marketAreas$ | async"
      [value]="item.id"
      [disabled]="item.disabled"
    >
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DEV_ENV_TOKEN } from '@clean-code/shared/common';
import { EnvConfig, ENV_TOKEN } from './models/config.model';
import { ConfigService } from './services/config.service';

@NgModule({
  providers: [ConfigService],
  declarations: [],
})
export class ConfigModule {
  static forRoot(env: EnvConfig): ModuleWithProviders<RootConfigModule> {
    return {
      ngModule: RootConfigModule,
      providers: [
        { provide: ENV_TOKEN, useValue: env },
        { provide: DEV_ENV_TOKEN, useValue: env.env === 'dev' },
        ConfigService,
      ],
    };
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [CommonModule],
})
export class RootConfigModule {}

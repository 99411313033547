import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig, StoreConfigOptions } from '@datorama/akita';
import { NewsTranslated } from './../entities/news';

export interface NewsWidgetState extends BaseState<NewsTranslated> {}

function createInitialState(): NewsWidgetState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'news-widget' })
export class NewsWidgetStore extends BaseStore<NewsTranslated> {
  constructor() {
    super(createInitialState(), {
      resettable: true,
    } as StoreConfigOptions);
  }
}

import { Company } from './company';
import { Person } from './person';

export interface CompanyRelation {
  id: string;
  person: Person;
  company: Company;
  type: CompanyRelationTypeEnum;
}
export interface ContactsCompanyRelation extends CompanyRelation {
  subsidiaryId: string;
}

export interface CreateCompanyRelation {
  personId: string;
  companyId: string;
  type: string;
}

export enum CompanyRelationTypeEnum {
  CustomerUser = 'CUSTOMER_USER',
  CompanySubsidiary = 'COMPANY_SUBSIDIARY',
  KeyAccountManagerPower = 'KEY_ACCOUNT_MANAGER_POWER',
  KeyAccountManagerGas = 'KEY_ACCOUNT_MANAGER_GAS',
}

export interface CompanyPerson {
  id: string;

  fullName: string;
  firstName: string;
  lastName: string;
  email: string;

  isLockedOut: boolean;
  isEnabled: boolean;

  companies: Company[];
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { SafeFile } from './safe-html.pipe';
import { SafePipe } from './safe.pipe';
import { YesNoPipe } from './yes-no.pipe';
@NgModule({
  imports: [CommonModule, NgPipesModule, YesNoPipe],
  declarations: [SafeFile, SafePipe],
  exports: [SafeFile, SafePipe, NgPipesModule, YesNoPipe],
})
export class PipesModule {}

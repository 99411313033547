import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TimeSeriesIdentifierParserValueStore } from '../+state/time-series-identifier-parser-value.store';
import { TimeSeriesIdentifierParserValue } from '../entities/time-series-analysis-identifier';

@Injectable({ providedIn: 'root' })
export class TimeSeriesIdentifierParserValueDataService
  implements EditSearchDataService<TimeSeriesIdentifierParserValue>
{
  public readonly entityFields = `
    id
    name
    positionRighthand
    value
    type
    parserId
  `;

  constructor(
    private graphqlService: GraphqlService,
    private store: TimeSeriesIdentifierParserValueStore
  ) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<TimeSeriesIdentifierParserValue>> {
    let par = this.graphqlService.mapDataTableParameters(params);
    par = par + ',' + params.filters[0].key + ':' + params.filters[0].value;
    const order = this.graphqlService.mapDataTableSortParameters(params.sortBy);

    let where = this.graphqlService.mapDataTableFilterParameters(
      params.filters
    );

    // if (where) {
    //   where = 'where:' + where;
    // }
    const query = `
    query{
      timeSeriesAnalysisIdentifierParserValueByParserId(
        ${par}
        ${order}
        ${where}
        ){
            totalCount
            items
            {
                ${this.entityFields}
            }
            pageInfo{
                hasNextPage
                hasPreviousPage
            }
        }
    }`;

    return this.graphqlService
      .query<PagingResponse<TimeSeriesIdentifierParserValue>>(query)
      .pipe(
        mapGraphqlPaging(params.page, params.perPage),
        tap((value) => this.store.upsertMany(value.data))
      );
  }

  getById$(id: ID): Observable<TimeSeriesIdentifierParserValue> {
    const query = `
    query($id: Int!) {
      timeSeriesAnalysisIdentifierParserValueById(id: $id) {
            ${this.entityFields}
        }
    }`;

    return this.graphqlService.query<TimeSeriesIdentifierParserValue>(
      query,
      {
        id,
      },
      null,
      { redirectTo404: true }
    );
  }

  update$(
    input: TimeSeriesIdentifierParserValue
  ): Observable<TimeSeriesIdentifierParserValue> {
    const mutation = `
      mutation ($input: TimeSeriesAnalysisIdentifierParserValueAddInput!){
        timeSeriesIdentifierParserValueUpdate(input: $input)
        }`;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<TimeSeriesIdentifierParserValue>(mutation, variables)
      .pipe(
        tap((value: TimeSeriesIdentifierParserValue) =>
          this.store.upsert(value.id, value)
        )
      );
  }

  add$(input: TimeSeriesIdentifierParserValue): Observable<ID> {
    const mutation = `
      mutation ($input: TimeSeriesAnalysisIdentifierParserValueAddInput!){
        timeSeriesIdentifierParserValueAdd(input: $input)
        }`;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<ID>(mutation, variables)
      .pipe(tap((id: ID) => this.store.upsert(id, input)));
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
      mutation ($id: Int!){
          timeSeriesIdentifierParserValueDelete(id: $id)
        }`;

    return this.graphqlService
      .mutation<any>(mutation, { id })
      .pipe(tap((_) => this.store.remove(id)));
  }
}

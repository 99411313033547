import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { MarketWidget } from '../models/market-dashboard';
import { IMarketWidgetState, MarketWidgetStore } from './widget.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'position',
  sortByOrder: Order.ASC,
})
export class MarketWidgetQuery extends QueryEntity<
  IMarketWidgetState,
  MarketWidget
> {
  public widgets$ = (groupId: ID) =>
    this.selectAll({
      filterBy: (entity) => entity.groupId === groupId,
    });

  public widgetsCount = (groupId: ID) =>
    this.getCount((x) => x.groupId === groupId);

  constructor(protected store: MarketWidgetStore) {
    super(store);
  }
}

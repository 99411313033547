<mat-drawer-container
  class="preview-container"
  *rxLet="open$ | async; let open"
>
  <!-- Drawer -->
  <mat-drawer
    class="preview-drawer flex flex-row overflow-auto"
    [style.width]="open ? width : '0px'"
    [mode]="mode$ | async"
    [opened]="open"
    position="end"
    [disableClose]="true"
    #matDrawer
  >
    <div class="flex flex-row w-full space-x-1 p-1">
      <button
        *ngIf="showBack$ | async"
        class="btn btn-outline-secondary"
        (click)="back()"
      >
        <fa-icon class="z-50" [icon]="faAngleLeft"></fa-icon>
      </button>

      <button class="btn btn-outline-secondary" (click)="close()">
        <fa-icon [icon]="faTimes" class="close"></fa-icon>
      </button>
      <div class="flex flex-grow"></div>
      <div class="flex space-x-1">
        <ng-content select="[actions]"></ng-content>

        <ng-container
          *ngIf="stateService?.formActions"
          [ngTemplateOutlet]="stateService.formActions"
        ></ng-container>
      </div>
    </div>

    <div class="flex flex-col h-full overflow-y-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>

  <mat-drawer-content
    class="flex flex-col overflow-hidden"
    [style.margin-right]="open ? width : '0px'"
  >
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>

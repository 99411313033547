type PermissionValues =
  | 'monitoring.access'
  | 'monitoring.limit-report.power-signal-mail'
  | 'monitoring.limit-report.gas-signal-mail'
  | 'monitoring.limit-report.other-signal-mail'
  | 'monitoring.customer-pool.power-signal-mail'
  | 'monitoring.customer-pool.power-spread-signal-mail'
  | 'monitoring.customer-pool.gas-signal-mail';

export class MonitoringPermissions {
  public static readonly healthCheckAccess: PermissionValues =
    'monitoring.access';
  public static readonly limitReportPowerSignalMail: PermissionValues =
    'monitoring.limit-report.power-signal-mail';
  public static readonly limitReportGasSignalMail: PermissionValues =
    'monitoring.limit-report.gas-signal-mail';
  public static readonly limitReportOtherSignalMail: PermissionValues =
    'monitoring.limit-report.other-signal-mail';
  public static readonly customerPoolPowerSignalMail: PermissionValues =
    'monitoring.customer-pool.power-signal-mail';
  public static readonly customerPoolPowerSpreadSignalMail: PermissionValues =
    'monitoring.customer-pool.power-spread-signal-mail';
  public static readonly customerPoolGasSignalMail: PermissionValues =
    'monitoring.customer-pool.gas-signal-mail';

  public static readonly sideNavPermission = [
    MonitoringPermissions.healthCheckAccess,
  ];
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { DashboardFacade } from '@clean-code/shared/dashboard/dashboard/domain';
import { DashboardWidgetFacade, WidgetStore } from '@clean-code/shared/dashboard/widget/domain';
import { WidgetTypeConfigDialogComponent } from '@clean-code/shared/dashboard/widget/ui-widget';
import { DashboardWidgetEditDialogComponent } from '@clean-code/shared/dashboard/widget/ui-widget-dialog';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faEllipsisVertical, faGear, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { WidgetTypeContentComponent } from '../widget-type-content/widget-type-content.component';

@Component({
  standalone: true,
  selector: 'dashboard-dashboard-panel-widget',
  templateUrl: './dashboard-panel-widget.component.html',
  styleUrls: ['./dashboard-panel-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatMenuModule,

    FontAwesomeModule,
    TranslocoModule,

    WidgetTypeContentComponent,
    VarDirective
  ],

  providers: [WidgetStore, DashboardWidgetFacade]
})
export class DashboardPanelWidgetComponent {
  @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;
  faTimes = faTimes;
  faEllipsisVertical = faEllipsisVertical;
  faGear = faGear;
  faEdit = faEdit;
  private widgetFacade = inject(DashboardWidgetFacade);
  public widgetSignal = this.widgetFacade.inputs;
  public hasUserPermission = this.widgetFacade.hasUserPermission;
  public title = this.widgetFacade.title;
  private dashboardFacade = inject(DashboardFacade);
  public editMode = this.dashboardFacade.editMode;
  private injector = inject(Injector);
  private toastrService = inject(ToastService);
  private confirmService = inject(ConfirmService);

  get widget(): IDashboardWidgetDetailDto {
    return this.widgetFacade.widget();
  }

  @Input() set widget(value: IDashboardWidgetDetailDto) {
    if (!this.widget) {
      this.widgetFacade.loadWidget(value);
      this.widgetFacade.updateTitle(value.name?.localizations[0].value);
    }
  }

  public openSettings($event: Event) {
    if (!this.hasUserPermission()) {
      $event.stopPropagation();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.confirmService
      .open('', '', WidgetTypeConfigDialogComponent, this.widget, {
        width: '800px',
        height: '80vh',
        injector: this.injector
      })
      .subscribe();
  }

  public editWidget($event: Event) {
    if (!this.hasUserPermission()) {
      $event.stopPropagation();
      return;
    }

    this.confirmService
      .open('', '', DashboardWidgetEditDialogComponent, this.widget, {
        width: '800px',
        height: '634px',
        injector: this.injector
      })
      .subscribe((data: IDashboardWidgetDetailDto) => {
        if (data) {
          this.widget = data;
        }
      });

    this.menuTrigger.closeMenu();
  }

  public removeWidget(_$event: Event) {
    this.confirmService
      .confirm(
        'DASHBOARDWIDGET.DIALOG-TITLE.REMOVE.TITLE',
        'DASHBOARDWIDGET.DIALOG-TITLE.REMOVE.BODY'
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.widgetFacade
            .remove$(this.widget)
            .subscribe((innerResult: boolean) => {
              if (innerResult) {
                this.dashboardFacade.removeWidget(this.widget);
                this.toastrService.showSuccess();
              }
            });
        }
      });
    this.menuTrigger.closeMenu();
  }

  public deleteWidget($event: Event) {
    if (!this.hasUserPermission()) {
      $event.stopPropagation();
      return;
    }

    this.confirmService
      .confirm(
        'DASHBOARDWIDGET.DIALOG-TITLE.DELETE.TITLE',
        'DASHBOARDWIDGET.DIALOG-TITLE.DELETE.BODY'
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.widgetFacade
            .delete$(this.widget.id)
            .subscribe((innerResult: boolean) => {
              if (innerResult) {
                this.dashboardFacade.removeWidget(this.widget);
                this.toastrService.showSuccess();
              }
            });
        }
      });
    this.menuTrigger.closeMenu();
  }
}

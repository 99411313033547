<a
  class="flex flex-row text-base items-center"
  [ngClass]="
    rla.isActive
      ? 'text-primary-color'
      : 'text-layout-sidenav-text dark:text-layout-dark-sidenav-text hover:text-gray-500 dark:hover:text-white'
  "
  [routerLink]="[item.link]"
  routerLinkActive
  #rla="routerLinkActive"
>
  <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
  <div class="w-7 h-6">
    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
    <fa-stack class="w-5 h-6" *ngIf="!item.icon && !!item.icons">
      <fa-icon [icon]="item.icons[0]" stackItemSize="1x"></fa-icon>
      <fa-icon
        [icon]="item.icons[1]"
        transform="shrink-7 down-5 right-5"
        [ngClass]="
          rla.isActive
            ? 'text-primary-color'
            : 'text-white dark:text-layout-dark-sidenav-text'
        "
        stackItemSize="1x"
      ></fa-icon>
      <fa-icon
        [icon]="item.icons[2]"
        class="text-layout-sidenav-text dark:text-white"
        transform="shrink-8 down-5 right-5"
        stackItemSize="1x"
      ></fa-icon>
    </fa-stack>
  </div>
  {{ item.name | transloco }}
</a>

// @dynamic
export class StringHelper {
  public static toUppercaseUnderscoreCase(s: string): string {
    return s
      .replace(/\.?([A-Z])/g, function (_x, y) {
        return '_' + y;
      })
      .replace(/^_/, '')
      .toUpperCase();
  }

  public static toLowercaseUnderscoreCase(s: string): string {
    return s
      .replace(/\.?([A-Z])/g, function (_x, y) {
        return '_' + y.toLowerCase();
      })
      .replace(/^_/, '');
  }

  public static enumFromStringValue<T>(
    enm: { [s: string]: T },
    value: string
  ): T | undefined {
    return (Object.values(enm) as unknown as string[]).includes(value)
      ? (value as unknown as T)
      : undefined;
  }
}

import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { NewsTranslated } from '../entities/news';
import { FofNewsDataService } from '../infrastructure/fof-news.data.service.service';

@Injectable({ providedIn: 'root' })
export class ReadonlyFormFacade {
  constructor(private service: FofNewsDataService) {}

  public getById$(id: ID): Observable<NewsTranslated> {
    return this.service.getById$(id);
  }
}

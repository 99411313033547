import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { faUserTie } from '@fortawesome/pro-light-svg-icons';
import { SideNavService } from './services/side-nav.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TailwindLayoutComponent {
  @HostBinding('class') class = 'cc-layout';

  public year: number = new Date().getFullYear();
  public faUserTie = faUserTie;

  constructor(public service: SideNavService) {}

  public toggleUserMenu() {
    this.service.toggleUserMenu();
  }
}

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { NewsTranslated } from '../entities/news';

@Injectable({
  providedIn: 'root',
})
export class FofNewsDataService {
  public readonly entityFields = `
    id
    position
    endDate
    name
    subName
    content
    createdDate
    type
  `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<NewsTranslated>> {
    const query = `
          query{
              newsGetAll{
                  items
                  {
                      ${this.entityFields}
                  }
                  pageInfo{
                      hasNextPage
                      hasPreviousPage
                   }
              }
          }`;

    return this.graphqlService
      .query<PagingResponse<NewsTranslated>>(query)
      .pipe(mapGraphqlPaging(params.page, params.perPage));
  }

  getAllApa$(): Observable<NewsTranslated[]> {
    const query = `
          query{
            newsGetAllApa{
                id
                position
                name
                subName
                content
                createdDate
                type
                imageUrl
              }
          }`;

    return this.graphqlService.query<NewsTranslated[]>(query, null, null, {
      ignoreNullCheckFilter: true,
    });
  }

  getById$(id: ID): Observable<NewsTranslated> {
    const query = `
      query($id: Int!) {
          newsGetById(id: $id) {
              ${this.entityFields}
          }
      }`;

    return this.graphqlService.query<NewsTranslated>(query, { id });
  }
}

<div
  *ngIf="!isMenu"
  class="flex flex-row"
  matTooltip="{{ (disabledTooltip ? '' : tooltip) | transloco }}"
>
  <button
    type="submit"
    mat-button
    color="warn"
    class="menu-btn"
    (click)="click($event)"
    [disabled]="isDisabled"
  >
    <div class="flex flex-row space-x-1">
      <fa-icon *ngIf="!!icon" [icon]="icon"></fa-icon>
      <div #wrapper class="flex flex-row">
        <!-- need a wrapper to get child's number -->
        <ng-content> </ng-content>
      </div>
      <span *ngIf="wrapper.childNodes.length === 0 && showText">{{
        displayText | transloco
      }}</span>
    </div>
  </button>
</div>
<button
  type="submit"
  *ngIf="isMenu"
  mat-menu-item
  color="warn"
  class="menu-btn"
  (click)="click($event)"
  [disabled]="isDisabled"
>
  <div class="flex flex-row space-x-1">
    <fa-icon *ngIf="!!icon" [icon]="icon"></fa-icon>
    <span>{{ displayText | transloco }}</span>
  </div>
</button>

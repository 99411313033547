import { Injectable } from '@angular/core';
import { AkitaSearchQueryEntity } from '@backoffice-frontend/shared/ui-akita-state';
import { TimeSeriesAnalysisIdentifier } from '../entities/time-series-analysis-identifier';
import { TimeSeriesAnalysisIdentifierStore } from './time-series-analysis-identifier.store';

@Injectable({ providedIn: 'root' })
export class TimeSeriesAnalysisIdentifierQuery extends AkitaSearchQueryEntity<TimeSeriesAnalysisIdentifier> {
  constructor(store: TimeSeriesAnalysisIdentifierStore) {
    super(store);
  }
}

type PermissionValues =
  | 'frontoffice.limitreports.power'
  | 'frontoffice.limitreports.power.passive-strategy-market-price-int'
  | 'frontoffice.limitreports.power.passive-strategy-market-price-ext'
  | 'frontoffice.limitreports.gas'
  | 'frontoffice.limitreports.gas.passive-strategy-market-price-int'
  | 'frontoffice.limitreports.gas.passive-strategy-market-price-ext'
  | 'frontoffice.limitreports.other'
  | 'frontoffice.limitreports.other.passive-strategy-market-price-int'
  | 'frontoffice.limitreports.other.passive-strategy-market-price-ext';

export class FrontOfficeLimitReportPermissions {
  public static readonly limitReportPowerViewer: PermissionValues =
    'frontoffice.limitreports.power';
  public static readonly limitReportGasViewer: PermissionValues =
    'frontoffice.limitreports.gas';
  public static readonly limitReportOtherViewer: PermissionValues =
    'frontoffice.limitreports.other';

  public static readonly limitReportPowerPassiveStrategyMarketPriceInt: PermissionValues =
    'frontoffice.limitreports.power.passive-strategy-market-price-int';
  public static readonly limitReportGasPassiveStrategyMarketPriceInt: PermissionValues =
    'frontoffice.limitreports.gas.passive-strategy-market-price-int';
  public static readonly limitReportOtherPassiveStrategyMarketPriceInt: PermissionValues =
    'frontoffice.limitreports.other.passive-strategy-market-price-int';

  public static readonly limitReportPowerPassiveStrategyMarketPriceExt: PermissionValues =
    'frontoffice.limitreports.power.passive-strategy-market-price-ext';
  public static readonly limitReportGasPassiveStrategyMarketPriceExt: PermissionValues =
    'frontoffice.limitreports.gas.passive-strategy-market-price-ext';
  public static readonly limitReportOtherPassiveStrategyMarketPriceExt: PermissionValues =
    'frontoffice.limitreports.other.passive-strategy-market-price-ext';

  public static readonly sideNavPermission = [
    FrontOfficeLimitReportPermissions.limitReportPowerViewer,
    FrontOfficeLimitReportPermissions.limitReportGasViewer,
    FrontOfficeLimitReportPermissions.limitReportOtherViewer,
  ];
}

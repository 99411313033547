import { Component, ViewEncapsulation } from '@angular/core';
import { AnalysisWidgetComponent } from '@backoffice-frontend/news/feature-analysis-widget';
import { TerminWidgetComponent } from '@backoffice-frontend/news/feature-termin-widget';
import { NewsWidgetComponent } from '@backoffice-frontend/news/feature-widget';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { GridsterConfig } from 'angular-gridster2';

@Component({
  templateUrl: './info.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class InfoComponent {
  options: GridsterConfig = {
    maxRows: 2,
    maxCols: 3,
    minCols: 3,
    minRows: 2,
  };

  widgets = [
    {
      cols: 1,
      rows: 2,
      y: 0,
      x: 0,
      title: 'start-dashboard.title.NEWS',
      type: NewsWidgetComponent,
    } as IDashboardWidgetDetailDto,
    {
      cols: 1,
      rows: 2,
      y: 1,
      x: 0,
      title: 'info.termin.PLURAL',
      type: TerminWidgetComponent,
    } as IDashboardWidgetDetailDto,
    {
      cols: 1,
      rows: 2,
      y: 2,
      x: 0,
      title: 'info.analysis.PLURAL',
      type: AnalysisWidgetComponent,
    } as IDashboardWidgetDetailDto,

    // { cols: 1, rows: 1, y: 2, x: 0, title: '', component: UserWidgetComponent },
    // { cols: 1, rows: 3, y: 0, x: 1, title: 'dashboard.title.contracts', component: ContractWidgetComponent },
    // { cols: 1, rows: 3, y: 0, x: 2, title: 'dashboard.title.energyOrder', component: EnergyOrderWidgetComponent },
  ];
}

import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavService } from '../services/side-nav.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  imports: [RouterModule, SideNavComponent, UserMenuComponent],
})
export class LayoutComponent {
  constructor(public service: SideNavService) {}

  public toggleUserMenu() {
    this.service.toggleUserMenu();
  }
}

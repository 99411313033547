import { CommonModule } from '@angular/common';
import { Component, inject, Input, ViewChild } from '@angular/core';
import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { ForModule } from '@rx-angular/template/for';
import { LetModule } from '@rx-angular/template/let';
import { DxChartComponent, DxChartModule } from 'devextreme-angular/ui/chart';
import { MultiChartModel } from '../models/multi-chart.model';
import { MultiChartChartState } from '../multichart/multichart.component';
import { simpleTooltip } from '../tooltips/simple-tooltip';
import { transformChartData } from '../utils/chart-data-helper';

@Component({
  standalone: true,
  selector: 'cc-multi-panel-chart',
  templateUrl: './multi-panel-chart.component.html',
  styleUrls: ['./multi-panel-chart.component.scss'],
  imports: [CommonModule, DxChartModule, LetModule, ForModule],
  providers: [RxState<MultiChartChartState>],
})
export class MultiPanelChartComponent extends RxState<MultiChartChartState> {
  @ViewChild(DxChartComponent, { static: false })
  public chart: DxChartComponent;

  public state$ = this.select().pipe(
    selectSlice(['series', 'data', 'visualRange', 'showPane'])
  );

  public yAxisTitle$ = this.select('yAxisTitle');
  public simpleTooltip = simpleTooltip;
  private tailwindThemeService = inject(TailwindThemeService);

  @Input()
  public set showPane(value: {
    showBottomPane: boolean;
    showTopPane: boolean;
  }) {
    if (value) {
      const showPane = {
        showBottomPane: value.showBottomPane,
        showTopPane: value.showTopPane,
      };
      this.set({ showPane });
    }
  }

  @Input()
  public set data(value: MultiChartModel) {
    if (value) {
      const chartData = transformChartData(value, this.tailwindThemeService);
      this.set({
        series: chartData.series,
        data: chartData.result,
        yAxisTitle: chartData.yAxisTitle,
        visualRange: chartData.visualRange,
      });
    }
  }
  @Input()
  aggregationInterval = 'day';

  constructor() {
    super();
    const showPane = {
      showBottomPane: false,
      showTopPane: true,
    };
    this.set({ showPane });
  }

  public render() {
    this.chart?.instance.render();
  }
}

<mat-form-field>
  <mat-label>{{
    'spot-configuration.spot-configuration-identifier.fields.ENERGY_SOURCE'
      | transloco
  }}</mat-label>
  <mat-select [formControl]="formControl">
    <mat-option *ngIf="includeEmpty" [value]="null" selected>{{
      'common.NO_SELECTION' | transloco
    }}</mat-option>
    <mat-option *ngFor="let item of energySources$ | async" [value]="item.name">
      {{ 'bo.common.energysources.' + item.name | transloco }}
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->

<ng-template #legalTemplate>
  <div class="relative m-2">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
    <div class="relative flex justify-start">
      <span class="pr-2 bg-secondary-color text-sm text-gray-500">
        {{ 'fof.LEGAL' | transloco }}
      </span>
    </div>
  </div>

  <div class="flex flex-col" (click)="toggleUserMenu()">
    <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
      <a routerLink="/imprint">
        {{ 'fof.IMPRINT' | transloco }}
      </a>
    </div>

    <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
      <a routerLink="/disclaimer">
        {{ 'fof.DISCLAIMER' | transloco }}
      </a>
    </div>

    <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
      <a routerLink="/contact">
        {{ 'fof.CONTACT' | transloco }}
      </a>
    </div>
    <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
      <a routerLink="/export-table">
        {{ 'fof.export-table' | transloco }}
      </a>
    </div>

    <!-- <div class="flex flex-grow"></div> -->
  </div>
</ng-template>

<ng-template #legacyTemplate>
  <ng-container *ngIf="(hasFrontOfficeIntPermission$ | async) === true">
    <div class="relative m-2">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-start">
        <span class="pr-2 bg-secondary-color text-sm text-gray-500">
          {{ 'fof.APP_LINKS' | transloco }}
        </span>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
        <a href="https://marktappa01.esn.at" target="_blank">{{
          'fof.LEGACY_APP' | transloco
        }}</a>
      </div>

      <!-- <div class="flex flex-grow"></div> -->
    </div>
  </ng-container>
</ng-template>

<div
  class="fixed inset-0 flex z-40 xl:hidden print:hidden"
  role="dialog"
  aria-modal="true"
  *ngIf="service.openMenu$ | async"
>
  <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
  <div class="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>
  <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
  <div
    class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
    @animateSlide
  >
    <div class="absolute top-0 right-0 -mr-12 pt-2">
      <button
        type="button"
        class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        (click)="toggleUserMenu()"
      >
        <span class="sr-only">Close sidebar</span>
        <!-- Heroicon name: x -->
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <div class="mt-5 flex-1 flex flex-col overflow-y-auto">
      <!-- Logo Hamburger Menu -->
      <div class="flex flex-col items-center shrink-0 px-4 pb-2">
        <img
          class="h-28 w-auto"
          src="assets/img/logo_estmk.svg"
          alt="Energie Steiermark"
        />
      </div>

      <nav class="px-2 flex-grow">
        <div class="space-y-1">
          <app-side-nav-menu></app-side-nav-menu>

          <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:text-gray-900 hover:bg-gray-50" -->
        </div>
      </nav>
      <ng-template [ngTemplateOutlet]="legalTemplate"></ng-template>
      <ng-template [ngTemplateOutlet]="legacyTemplate"></ng-template>

      <!-- <div class="text-center px-3 mt-6 text-sm text-gray-500">
        &copy;{{ year }}
        <span class="text-primary-color">
          <a href="https://e-steiermark.com/" target="_blank">E-Steiermark</a>
        </span>
      </div>
      <span class="text-center px-3 text-sm text-gray-500"
        >Leonhardgürtel 10, A-8010 Graz</span
      > -->
    </div>
  </div>
  <div class="shrink-0 w-14" aria-hidden="true">
    <!-- Dummy element to force sidebar to shrink to fit close icon -->
  </div>
</div>

<!-- Static sidebar for desktop -->
<div
  class="hidden xl:flex xl:flex-col xl:w-64 xl:fixed xl:inset-y-0 xl:shrink-0 xl:border-r xl:border-gray-200 xl:pb-4 bg-side-nav h-full"
>
  <div
    class="flex flex-col h-full min-h-full w-64 border-r border-gray-200 pt-5 pb-4"
  >
    <!-- Logo -->
    <div class="flex flex-col items-center shrink-0 px-6">
      <img
        class="h-28 w-auto"
        src="assets/img/logo_estmk.svg"
        alt="Energie Steiermark"
      />
      <div
        class="text-layout-sidenav-text dark:text-layout-dark-sidenav-text font-bold"
      >
        {{ env.name }}
      </div>
    </div>

    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="h-0 flex-1 flex flex-col overflow-y-auto">
      <!-- Navigation -->
      <nav class="px-3 mt-2 mb-6">
        <cc-user-menu class="border-b border-gray-200"></cc-user-menu>
        <div class="w-full border-t border-gray-200 mb-1"></div>
        <div class="space-y-1">
          <!-- Current: "bg-gray-200 text-gray-900", Default: "text-gray-700 hover:text-gray-900 hover:bg-gray-50" -->
          <app-side-nav-menu [toggleMenu]="false"></app-side-nav-menu>
        </div>
      </nav>

      <ng-template [ngTemplateOutlet]="legalTemplate"></ng-template>
      <ng-template [ngTemplateOutlet]="legacyTemplate"></ng-template>
    </div>

    <!-- <div class="text-center px-3 mt-6 text-sm text-gray-500">
      &copy;{{ year }}
      <span class="text-primary-color">
        <a href="https://e-steiermark.com/" target="_blank">E-Steiermark</a>
      </span>
    </div>
    <span class="text-center px-3 text-sm text-gray-500"
      >Leonhardgürtel 10, A-8010 Graz</span
    > -->
  </div>
</div>

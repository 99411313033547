<mat-progress-bar
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="flex flex-col lg:flex-row lg:h-56 space-x-4"
  *ngIf="data$ | async; let data"
>
  <cc-line-chart
    [data]="data.chart"
    class="w-full lg:w-3/4"
    [yAxisTitle]="yAxisTitle"
    [legend]="legend"
  ></cc-line-chart>
  <div class="w-full lg:w-1/4">
    <div class="text-center">
      {{ 'market-dashboard-details.MA_TITLE' | transloco }}
    </div>
    <table class="table-fixed">
      <tbody>
        <tr *ngFor="let item of data.table; let i = index">
          <td class="w-56" *ngIf="i === 0">
            {{ 'market-dashboard-details.CLOSING_PRICE' | transloco }}
          </td>
          <td class="w-56" *ngIf="i > 0">
            {{
              'market-dashboard-details.MA_NAME'
                | transloco: { value: item.name }
            }}
          </td>
          <td class="w-28">{{ item.lastValue | number: '.2-2' }}</td>
          <td>{{ item.unit }}</td>
        </tr>
      </tbody>
    </table>
    <ul class="list-disc !m-5">
      <li class="break-words">
        {{ 'market-dashboard-details.MA_20_TIP' | transloco }}
      </li>
      <li class="break-words">
        {{ 'market-dashboard-details.MA_200_TIP' | transloco }}
      </li>
    </ul>
  </div>
</div>

import { Injectable } from '@angular/core';
import { mapGraphqlPaging } from '@backoffice-frontend/shared/ui-akita-state';
import {
  DataTableParameters,
  ID,
  PagingResponse,
} from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { PaginationResponse } from '@datorama/akita';
import { Observable, map, tap } from 'rxjs';
import { DeliveryAreaStore } from '../+state/delivery-area.store';
import { TradingCalendar } from '../entities/trading-calendar';
import { Area, AreaType, ShortArea } from '../models/area';
@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private url = this.configService.settings['api'] + 'area/graphql';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private store: DeliveryAreaStore
  ) {}

  private readonly entityFields = `
    id
    name
    longName
    country
    energySource
    disabled
    timeZoneId
    timeRangeFrom
    timeRangeTo
  `;

  public getAllAreas$(areaType: string): Observable<Area[]> {
    const query = `
        query {
            ${areaType}_areas{
              ${this.entityFields}
            }
        }
    `;

    return this.graphqlService.query<Area[]>(query, null, this.url);
  }

  getAllShortMarketAreas$(): Observable<PagingResponse<ShortArea>> {
    const query = `
        query {
          marketAreaGetAll {
            items {
              ${this.entityFields}
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
    `;

    return this.graphqlService.query<PagingResponse<ShortArea>>(query);
  }

  public getAllMarketAreas$(
    params: DataTableParameters
  ): Observable<PaginationResponse<Area>> {
    const par = this.graphqlService.mapDataTableParameters(params);
    const where = this.graphqlService.mapDataTableFilterParameters2(
      params.filters
    );

    const order = this.graphqlService.mapDataTableSortParametersTemp(
      params.sortBy
    );

    const query = `
                query {
                  marketAreaGetAll(
                      ${par}
                      ${order}
                      ${where}
                    ){
                      items
                      {
                        ${this.entityFields}
                      }
                      pageInfo{
                          hasNextPage
                          hasPreviousPage
                      }
                      totalCount
                    }
                }`;

    return this.graphqlService.query<PagingResponse<Area>>(query).pipe(
      mapGraphqlPaging(params.page, params.perPage),
      tap((value: PaginationResponse<Area>) =>
        this.store.upsertMany(value.data)
      )
    );
  }

  public getByEnergySource$(
    areaType: string,
    energySource: string
  ): Observable<Area[]> {
    const query = `
      query ($energySource: EnergySourceEnum!){
            ${areaType}AreasByEnergySource (energySource:$energySource) {
            id
            name
            longName
            country
            energySource
            disabled
            timeZoneId
            timeRangeFrom
            timeRangeTo
            ${areaType === 'delivery' ? 'homeMarketAreaId' : ''}
            }
        }
    `;

    const variables = { energySource };

    return this.graphqlService.query<Area[]>(query, variables);
  }

  public getAllMarketAreasByEnergySource$(
    energySource: string
  ): Observable<Area[]> {
    const query = `
        query ($energySource:EnergySourceEnum!){
          marketAreasByEnergySource (energySource:$energySource) {
            id
            name
            longName
            country
            energySource
            disabled
            timeZoneId
            timeRangeFrom
            timeRangeTo
            }
        }
    `;

    const variables = { energySource: energySource };

    return this.graphqlService.query<Area[]>(query, variables);
  }

  public getAllDeliveryAreasByEnergySource$(
    energySource: string
  ): Observable<PagingResponse<Area>> {
    const where = `where: { energySource: { eq: ${energySource} }}`;

    const query = `
                query {
                  deliveryAreaGetAll(
                      ${where}
                    ){
                      items
                      {
                        ${this.entityFields}
                      }
                      pageInfo{
                          hasNextPage
                          hasPreviousPage
                      }
                      totalCount
                    }
                }`;

    return this.graphqlService.query<PagingResponse<Area>>(query);
  }

  public isMarketAreaInDeliveryArea$(
    marketAreaId: ID,
    deliveryAreaId: ID
  ): Observable<boolean> {
    const query = `
    query ($marketAreaId:Int!, $deliveryAreaId:Int!){
      marketAreaInDeliveryArea(marketAreaId:$marketAreaId, deliveryAreaId:$deliveryAreaId) 
    }
  `;

    const variables = {
      marketAreaId: +marketAreaId,
      deliveryAreaId: +deliveryAreaId,
    };

    return this.graphqlService.query<boolean>(query, variables);
  }

  public getAllShortAreas$(areaType: string): Observable<ShortArea[]> {
    const query = `
        query {
            ${areaType}_areas{
            id
            name
            }
        }
    `;

    return this.graphqlService.query<ShortArea[]>(query, null, this.url);
  }

  public getAreaById$(areaType: string, id: string): Observable<Area> {
    const where = `where: { id: { eq: ${id} }}`;
    const queryName =
      areaType === 'market' ? 'marketAreaGetById' : 'deliveryAreaGetById';

    const ef = `
      id
      name
      longName
      country
      energySource
      disabled
      timeZoneId
      timeRangeFrom
      timeRangeTo
      ${areaType === 'market' ? 'tradingCalendarId' : 'homeMarketAreaId'}
    `;

    const query = `
    query {
      ${queryName}(${where}) {
        ${ef}
      }
    }
  `;

    return this.graphqlService.singleQuery<Area>(query).pipe(
      /*
        Without map operator, the 'Handelskalender' field remains empty in the preview, 
        even if the 'tradingCalendarId' is not null 
      */
      map((x) => {
        if (areaType === 'market') {
          x.tradingCalendarId = x.tradingCalendarId?.toString();
        }
        return x;
      })
    );
  }

  public getAllTradingCalendars$(): Observable<TradingCalendar[]> {
    const query = `
        query
        {
          getAllTradingCalendars 
          {
            id
            name
          }
        }`;

    return this.graphqlService.query<TradingCalendar[]>(query, null, this.url);
  }

  insertArea$(areaType: AreaType, area: Area): Observable<boolean> {
    const input = {
      name: area.name,
      longName: area.longName,
      country: area.country,
      energySource: area.energySource,
      disabled: area.disabled,
      timeRangeFrom: area.timeRangeFrom,
      timeRangeTo: area.timeRangeTo,
      ...(areaType === 'Market'
        ? { tradingCalendarId: +area.tradingCalendarId }
        : { homeMarketAreaId: +area.homeMarketAreaId }),
    };

    const mutation = `
        mutation ($input: Add${areaType}AreaInput!){
          add${areaType}Area(input: $input)
        }`;

    return this.graphqlService.query<boolean>(mutation, { input });
  }

  updateArea$(areaType: AreaType, area: Area): Observable<boolean> {
    const input = {
      id: +area.id,
      name: area.name,
      longName: area.longName,
      country: area.country,
      energySource: area.energySource,
      disabled: area.disabled,
      timeRangeFrom: area.timeRangeFrom,
      timeRangeTo: area.timeRangeTo,
      ...(areaType === 'Market'
        ? { tradingCalendarId: +area.tradingCalendarId }
        : { homeMarketAreaId: +area.homeMarketAreaId }),
    };

    const mutation = `
        mutation ($input: Update${areaType}AreaInput!){
          update${areaType}Area(input: $input)
        }`;

    return this.graphqlService.query<boolean>(mutation, { input });
  }

  public deleteArea$(areaType: string, id: ID): Observable<boolean> {
    const query = `
        mutation($id:String!) {
            delete_${areaType}_area(id: $id)
        }
    `;
    const variables = { id: id };

    return this.graphqlService.query<boolean>(query, variables, this.url);
  }

  delete$(areaType: AreaType, id: ID): Observable<boolean> {
    const mutation = `
        mutation ($id: Int!){
          delete${areaType}Area(id: $id)
          }`;

    return this.graphqlService.mutation<boolean>(mutation, { id });
  }

  public deliveryAreaByHomeMarket$(marketAreaId: ID): Observable<ShortArea[]> {
    const query = `
        query($marketAreaId: Int!) {
          deliveryAreaByHomeMarket(marketAreaId: $marketAreaId)
          {
            id
            name
          }
        }
    `;

    return this.graphqlService.query<ShortArea[]>(query, { marketAreaId });
  }
}

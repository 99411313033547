import { LOCALE_ID, Provider } from '@angular/core';
import { LanguageService } from '../language.service';

export class LocaleId extends String {
  constructor(private languageService: LanguageService) {
    super();
  }

  override toString(): string {
    return 'de-DE'; //this.languageService.currentLocale;
  }

  override valueOf(): string {
    return this.toString();
  }
}

//https://github.com/armanozak/angular-dynamic-locale <- source for implementation
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [LanguageService],
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Route, RouterModule } from '@angular/router';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { PageContainerComponent } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { KamComponent } from './components/kam.component';

const routes: Route[] = [
  {
    path: '',
    component: KamComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatFormFieldModule,
    MatSelectModule,

    TranslocoModule,
    FontAwesomeModule,

    ProgressBarModule,
    PageContainerComponent,
  ],
  declarations: [KamComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'fof-kam',
        alias: 'kam',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class KamModule {}

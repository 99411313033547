import isEqual from 'lodash.isequal';
// @ts-ignore
Array.prototype.isEmpty = function () {
  return this.length === 0;
};

// @ts-ignore
Array.prototype.any = function (func) {
  return this.some(
    func ||
      function (x) {
        return x;
      }
  );
};

export class ArrayHelper {
  public static intersection<T>(array1: Array<T>, array2: Array<T>): Array<T> {
    return array1.filter((element) => array2.indexOf(element) !== -1);
  }

  public static except<T>(array1: Array<T>, array2: Array<T>): Array<T> {
    return array1.filter((element) => array2.indexOf(element) === -1);
  }

  public static equal<T>(array1: Array<T>, array2: Array<T>): boolean {
    if (!array1) {
      array1 = [];
    }

    if (!array2) {
      array2 = [];
    }

    array1 = [...array1].sort();
    array2 = [...array2].sort();

    return isEqual(array1, array2);
  }

  public static anyElement<T>(array1: Array<T>, array2: Array<T>): boolean {
    if (!array1) {
      return false;
    }
    return array1.some((r) => array2.includes(r));
  }

  public static distinct(array: any[], propertyName: string): any[] {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item[propertyName])) {
        map.set(item[propertyName], true); // set any value to Map
        result.push(item);
      }
    }
    return result;
  }

  static findElementByLatestDate<T>(array: any[], dateField: string): T {
    if (!Array.isArray(array) || array.length === 0) {
      return null;
    }

    return array.reduce((latest, element) => {
      let elementDate = new Date(element[dateField]).getTime();
      let latestDate = latest ? new Date(latest[dateField]).getTime() : 0;
      return elementDate > latestDate ? element : latest;
    }, null);
  }
}

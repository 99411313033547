import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  templateUrl: './confirm-delete.component.html',
})
export class ConfirmDeleteComponent extends ConfirmDialogComponent {
  public faDeleteIcon = faTrashAlt;

  public customMessage: string;
  public customTitle: string;

  constructor(public override dialogRef: MatDialogRef<ConfirmDeleteComponent>) {
    super(dialogRef);
  }
}

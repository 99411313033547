import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseTranslationComponent } from '../base-translation.component';

@Component({
  selector: 'cc-input-translation',
  templateUrl: './input-translation.component.html',
  styleUrls: ['./input-translation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTranslationComponent),
      multi: true,
    },
    // {
    //   provide: NG_VALIDATORS,
    //   useExisting: forwardRef(() => InputTranslationComponent),
    //   multi: true,
    // },
  ],
})
export class InputTranslationComponent extends BaseTranslationComponent {}

import { Injectable, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Query } from '@datorama/akita';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  public userData$ = this.select((state) => state.user);

  public accessToken$ = this.select((state) => state.accessToken);
  public userDataSignal = toSignal(this.userData$);
  public permissionsSignal = computed(() => this.userDataSignal().permissions);

  constructor(protected store: AuthStore) {
    super(store);
  }
}

<div class="widget-type-config-component" class="{{ type() }}">
  <div *ngIf="component">
    <ndc-dynamic
      #dynamic
      [ndcDynamicComponent]="component"
      [ndcDynamicInputs]="inputs"
      [ndcDynamicOutputs]="outputs"
      (ndcDynamicCreated)="componentCreated($event)"
    >
    </ndc-dynamic>
  </div>

  <div *ngIf="isLoading">
    <cc-loading-indicator></cc-loading-indicator>
  </div>

  <div *ngIf="!component">
    <span class="unknown">{{
      'DASHBOARDWIDGET.MESSAGES.TYPE.UNKNOWN' | transloco
    }}</span>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'katex',
})

//https://stackblitz.com/edit/angular-katex?file=src%2Fapp%2Fmarkdown.pipe.ts
export class KatexPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const md = require('markdown-it')({
      html: true,
      linkify: true,
      typographer: true,
    });
    // how to use math rendere here so i can display maths equations

    let output = '';
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const katex = require('katex');

      output = katex.renderToString(value, {
        throwOnError: false,
      });
    } catch (e: any) {
      output = ("Error in LaTeX '" + value + "': " + e.message)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
    }
    return md.render(output);
  }
}

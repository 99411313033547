import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { TimeSeriesIdentifierParser } from '../entities/time-series-analysis-identifier';

export interface TimeSeriesIdentifierParserState
  extends BaseState<TimeSeriesIdentifierParser> {}

function createInitialState(): TimeSeriesIdentifierParserState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'TimeSeriesIdentifierParser' })
export class TimeSeriesIdentifierParserStore extends BaseStore<TimeSeriesIdentifierParser> {
  constructor() {
    super(createInitialState());
  }
}

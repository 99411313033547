import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import {
  AnalysisLocalized,
  AnalysisWidgetFacade,
  AnalysisWidgetStore,
} from '@backoffice-frontend/news/domain';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AnalysisWidgetDialogComponent } from './analysis-widget-dialog/analysis-widget-dialog.component';

@Component({
  selector: 'news-analysis-widget',
  templateUrl: './analysis-widget.component.html',
  styleUrls: ['./analysis-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AnalysisWidgetComponent {
  @HostBinding('class') class = 'news-analysis-widget';
  public displayedColumns = ['title', 'date'];

  public isLoading$ = new BehaviorSubject<boolean>(false);

  data$ = this.languageService.languageChangesWithDefault$.pipe(
    switchMap(() =>
      this.widgetFacade.getAll$({}).pipe(indicate(this.isLoading$))
    ),
    tap((values: AnalysisLocalized[]) => {
      this.store.reset();
      this.store.upsertMany(values);
    })
  );

  constructor(
    private widgetFacade: AnalysisWidgetFacade,
    private store: AnalysisWidgetStore,
    private confirmService: ConfirmService,
    private deviceDetectorService: DeviceDetectorService,
    private languageService: LanguageService
  ) {}

  public select(value: AnalysisLocalized) {
    this.store.setActive(value.id);
    this.confirmService
      .open('', '', AnalysisWidgetDialogComponent, null, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.deviceDetectorService.isMobile() ? '100%' : '90%',
        width: this.deviceDetectorService.isMobile() ? '100%' : '90%',
      })
      .subscribe();
  }
}

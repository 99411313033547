import { CommonModule } from '@angular/common';
import { Component, inject, Injector, Input, ViewChild } from '@angular/core';
import {
  ExportTableDto,
  ExportTableFacade,
} from '@backoffice-frontend/market/domain';
import { mapTimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { WidgetTypeConfig } from '@backoffice-frontend/time-series-identifier/api';
import { indicate } from '@clean-code/shared/common';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import {
  TranslocoModule,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';

import {
  DxPivotGridComponent,
  DxPivotGridModule,
} from 'devextreme-angular/ui/pivot-grid';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import groupBy from 'lodash.groupby';
import _map from 'lodash.map';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  switchMap,
} from 'rxjs';

dayjs.extend(utc);
@Component({
  standalone: true,
  selector: 'export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.scss'],
  imports: [
    CommonModule,
    DxPivotGridModule,
    TranslocoModule,
    ProgressBarModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'export-table',
        alias: 'export-table',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        ),
      },
    },
  ],
})
export class ExportTableComponent {
  @ViewChild(DxPivotGridComponent, { static: false })
  public pivotGrid: DxPivotGridComponent;

  public params$ = new BehaviorSubject<WidgetTypeConfig | null>(null);

  @Input()
  public set params(value: WidgetTypeConfig) {
    this.params$.next(value);
  }

  public dataSource$: Observable<any>;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private injector: Injector, private service: ExportTableFacade) {
    const translocoScope = inject(TRANSLOCO_SCOPE);

    const dataSourceDef: any = {
      fields: [
        {
          dataField: 'areaMarketArea',
          area: 'column',
          expanded: true,
        },
        {
          dataField: 'frontOfficeName',
          area: 'column',
          selector: function (data: ExportTableDto) {
            return data.frontOfficeName + ' [' + data.unit + ']';
          },
        },
        {
          dataField: 'value',
          dataType: 'number',
          area: 'data',
          summaryType: 'avg',
          format: { type: 'fixedPoint', precision: 2 },
        },
        {
          dataField: 'tradeDate',
          sortOrder: 'desc',
          dataType: 'date',
          area: 'row',
          selector: function (data: ExportTableDto) {
            return data.tradeDate;
          },
          groupName: 'Date',
        },
        { groupName: 'Date', groupInterval: 'year', groupIndex: 0 },
        {
          groupName: 'Date',
          groupInterval: 'month',
          groupIndex: 1,
          // format: 'dd.MM.yyyy',
          // selector: function (data: any) {
          //   const month = new Date(data.tradeDate).getMonth();
          //   const year = new Date(data.tradeDate).getMonth();
          //   const day = new Date(data.tradeDate).getDay();

          //   return new Date(year, month, day);
          // },
        },
        {
          groupName: 'Date',
          groupInterval: 'day',
          groupIndex: 2,
          format: 'dd.MM.yyyy',
          selector: function (data: ExportTableDto) {
            return data.tradeDate;
          },
        },
      ],
    };

    this.dataSource$ = combineLatest([
      this.params$,
      injector
        .get(TranslocoService)
        .selectTranslate('BASE', {}, translocoScope),
    ]).pipe(
      map(([params, _]) => params),
      filter((x) => !!x),
      mapTimeFrame(),
      switchMap((value: WidgetTypeConfig) => {
        return this.service
          .getExportTableData$(
            value.dataSources.map((x) => x.settings.identifier),
            value.settings.timeFrame
          )
          .pipe(
            map((values: ExportTableDto[]) => {
              const source = { ...dataSourceDef };
              source.store = values;
              return source;
            }),
            indicate(this.isLoading$)
          );
      })
    );
  }

  async onExporting() {
    const workbook = new (await import('exceljs')).Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    exportPivotGrid({
      component: this.pivotGrid.instance,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `MarketExport_${new Date().toISOString()}.xlsx`
        );
      });
    });
    // e.cancel = true;

    // pivot.showRowTotals = true;
  }

  async exportData() {
    const workbook = new (await import('exceljs')).Workbook();

    const dataSource =
      this.pivotGrid.instance.getDataSource() as unknown as any;

    const data = dataSource.store()._dataSource._items;
    const groups = groupBy(data, 'longName');

    Object.keys(groups).forEach((name: string, _index: number) => {
      // eslint-disable-next-line no-useless-escape
      const ws = workbook.addWorksheet(name.replace(/['*''?':\\\/\[\]]/gm, ''));
      ws.spliceRows(1, 0, ...new Array(3));
      const array = _map(groups[name]);
      ws.columns = this.createHeader(name, array[0].unit);
      array.forEach((element: ExportTableDto) => {
        const date = dayjs(
          dayjs(element.tradeDate).utcOffset(0, true)
        ).toDate();
        ws.addRow([date, element.value]);
      });
    });

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `MarketExport_${new Date().toISOString()}.xlsx`
      );
    });
  }

  onCellPrepared(e: any) {
    if (e.cell.type === 'GT') {
      e.cell.text = 'Ø ' + e.cell.text;
    }
  }

  private createHeader(longName: string, unit: string) {
    const headers = [
      {
        header: longName,
        key: 'tradeDate',
        width: 15,
        style: { numFmt: 'dd.mm.yyyy' },
      },
      {
        header: unit,
        key: 'value',
        width: 15,
      },
    ];

    return headers;
  }
}

<div class="flex flex-row space-x-2 w-full" [formGroup]="formGroup">
  <mat-form-field class="!w-full">
    <input
      type="text"
      placeholder="{{ 'time-series-identifier.PLURAL' | transloco }}"
      aria-label="symbols"
      [formControl]="selectionControl"
      matInput
      [matAutocomplete]="auto"
      class="w-full"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="optionSelected($event)"
    >
      <mat-option *ngFor="let option of filtered$ | async" [value]="option">
        {{ option.identifier }} | {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <ng-container *ngIf="(selected$ | async)?.hasContinuouslyOperator">
    <ng-container *rxLet="isContinuously$; let isContinuously">
      <div class="flex flex-col !w-1/6" *ngIf="isContinuously">
        <span class="label mb-1">{{
          'time-series-identifier.CONTINUOUSLY' | transloco
        }}</span>
        <div>
          <mat-slide-toggle
            formControlName="continuously"
            aria-label="CONTINUOUSLY"
          ></mat-slide-toggle>
        </div>
      </div>

      <mat-form-field class="!w-28" *ngIf="!isContinuously">
        <input
          type="number"
          placeholder="{{ 'time-series-identifier.ADJUSTMENT' | transloco }}"
          aria-label="adjustment"
          formControlName="adjustment"
          matInput
          class="w-full"
          min="-2"
          max="7"
        />
      </mat-form-field>
    </ng-container>
  </ng-container>

  <!-- CalculationType -->
  <div class="flex flex-col !w-1/6">
    <span class="label mb-1">{{
      'time-series-identifier.widgets.settings.CALCULATIONTYPE' | transloco
    }}</span>
    <div>
      <mat-button-toggle-group
        formControlName="calculationType"
        name="calculationType"
        aria-label="Delta Type"
      >
        <mat-button-toggle value="RELATIVE">{{
          'time-series-identifier.widgets.settings.CALCULATIONTYPE_RELATIVE'
            | transloco
        }}</mat-button-toggle>
        <mat-button-toggle value="ABSOLUTE">{{
          'time-series-identifier.widgets.settings.CALCULATIONTYPE_ABSOLUTE'
            | transloco
        }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- Ignore Gaps -->
  <div class="flex flex-col !w-1/6">
    <span class="label mb-1">{{
      'time-series-identifier.widgets.settings.GAPS' | transloco
    }}</span>
    <div>
      <mat-slide-toggle
        formControlName="ignoreGaps"
        aria-label="Ignore Gaps"
      ></mat-slide-toggle>
    </div>
  </div>
</div>

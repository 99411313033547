import { Injectable } from '@angular/core';
import {
  BaseState,
  BaseStore,
} from '@backoffice-frontend/shared/ui-akita-state';
import { StoreConfig } from '@datorama/akita';
import { Product } from '../models/product';

const stateName = 'product';

export interface ProductState extends BaseState<Product> {}

function createInitialState(): ProductState {
  return {
    active: null,
    searchTerm: null,
    readOnly: false,
    filters: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: stateName })
export class ProductStore extends BaseStore<Product> {
  constructor() {
    super(createInitialState());
  }
}

import { Observable, ReplaySubject, share, timer } from 'rxjs';
const CACHE_TIMEOUT = 10 * 1000;
export function cache<T>(
  timeout?: number
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: () => timer(timeout | CACHE_TIMEOUT),
      })
    );
}

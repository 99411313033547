import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Route, RouterModule } from '@angular/router';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { HknIdentifierFormComponent } from './hkn-identifier-form.component';

const routes: Route[] = [
  {
    path: '',
    component: HknIdentifierFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatInputModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    FormContainerModule,
    ActionButtonModule,
  ],
  declarations: [HknIdentifierFormComponent],
  exports: [HknIdentifierFormComponent],
})
export class HknIdentifierFormModule {}

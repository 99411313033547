<div class="confirm-delete">
  <h1 mat-dialog-title *ngIf="!customTitle">
    <fa-icon
      class="sidebar-icon"
      style="color: #ef5350"
      [icon]="faDeleteIcon"
    ></fa-icon>
    {{ 'common.confirm.DELETE_HEADER' | transloco }}
  </h1>
  <h1 mat-dialog-title *ngIf="customTitle">
    <fa-icon
      class="sidebar-icon"
      style="color: #ef5350"
      [icon]="faDeleteIcon"
    ></fa-icon>
    {{ customTitle | transloco }}
  </h1>
  <div mat-dialog-content *ngIf="!customMessage">
    {{ 'common.confirm.DELETE_BODY' | transloco }}
  </div>
  <div mat-dialog-content *ngIf="customMessage">
    {{ customMessage | transloco }}
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="dialogRef.close(false)">
      {{ 'common.NO' | transloco }}
    </button>
    <button mat-button (click)="dialogRef.close(true)">
      {{ 'common.YES' | transloco }}
    </button>
  </div>
</div>

// import { CommonModule } from '@angular/common';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
// import {
//   TranslocoConfig,
//   TranslocoModule,
//   TranslocoService,
//   TRANSLOCO_CONFIG,
//   TRANSLOCO_LOADER,
// } from '@ngneat/transloco';
// import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
// import {
//   TranslocoPersistLangModule,
//   TRANSLOCO_PERSIST_LANG_STORAGE,
// } from '@ngneat/transloco-persist-lang';
// import {
//   PERSIST_TRANSLATIONS_STORAGE,
//   TranslocoPersistTranslationsModule,
// } from '@ngneat/transloco-persist-translations';
// import { LanguageHttpInterceptor } from './language.interceptor';
// import { TranslocoHttpLoader } from './transloco-loader';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_LOADER,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { LanguageHttpInterceptor } from './language.interceptor';
import { TranslocoHttpLoader } from './transloco-loader';

const defaultLang = 'de';
const httpLoader = {
  provide: TRANSLOCO_LOADER,
  useClass: TranslocoHttpLoader,
};

function preloadUser(translocoService: TranslocoService) {
  return () => {
    const supportedBrowserLang =
      // TranslocoRootModule.availableLangs.find((x) => x.id === getBrowserLang())?.id ??
      defaultLang;

    const lang = localStorage.getItem('translocoLang') ?? supportedBrowserLang;

    // if (TranslocoRootModule.availableLangs.length === 0) {
    //   lang = supportedBrowserLang;
    // }

    translocoService.setActiveLang(lang);
  };
}

@NgModule({
  imports: [],
})
export class TranslocoRootModule {
  static availableLangs = new Array<any>();
  static defaultLang = '';

  static forRoot(): ModuleWithProviders<TranslocoRootModule> {
    return {
      ngModule: TranslocoRootModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: preloadUser,
          deps: [TranslocoService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LanguageHttpInterceptor,
          multi: true,
        },
        httpLoader,
      ],
    };
  }
}
export const translocoProviders = [
  provideTransloco({
    loader: TranslocoHttpLoader,
    config: {
      availableLangs: [
        { id: 'de', label: 'DE' },
        { id: 'en', label: 'EN' },
      ],
      reRenderOnLangChange: true,
      fallbackLang: 'de',
      defaultLang: 'de',
      missingHandler: {
        useFallbackTranslation: false,
      },
      // interpolation: ['<<<', '>>>']
    },
  }),
  //   provideTranslocoMessageformat(),
  provideTranslocoLocale({
    langToLocaleMapping: {
      en: 'en-US',
      de: 'de-DE',
    },
  }),
  // provideTranslocoPersistTranslations({
  //   loader: TranslocoHttpLoader,
  //   storage: {
  //     useValue: localStorage,
  //   },
  // }),

  provideTranslocoPersistLang({
    storage: {
      useValue: localStorage,
    },
  }),

  //   httpLoader,
];

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { TerminQuery } from '../+state/termin.query';

export const TERMIN_PAGINATOR = new InjectionToken('TERMIN_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const query = inject(TerminQuery);
    return new PaginatorPlugin(query, { startWith: 0 });
  },
});

type EnergyOrderPermissionValues =
  | 'energy-order.power.admin'
  | 'energy-order.power.order_with_tradedate'
  | 'energy-order.power.open.view'
  | 'energy-order.power.open.edit'
  | 'energy-order.power.open.delete'
  | 'energy-order.power.closed.view'
  | 'energy-order.power.closed.edit'
  | 'energy-order.power.closed.edit-additional-fields'
  | 'energy-order.power.closed.delete'
  | 'energy-order.power.limit-order.view'
  | 'energy-order.power.limit-order.cancel'
  | 'energy-order.power.limit-order.create'
  | 'energy-order.power.limit-order.edit'
  | 'energy-order.power.limit-order.delete'
  | 'energy-order.power.limit-order.show_closed'
  | 'energy-order.power.limit-order.execute_close_base'
  | 'energy-order.power.strategy-message.view'
  | 'energy-order.power.strategy-message.edit'
  | 'energy-order.power.strategy-message.delete'
  | 'energy-order.power.strategy-message.cancel'
  | 'energy-order.power.spot.view'
  | 'energy-order.power.spot.edit'
  | 'energy-order.power.spot.delete'
  | 'energy-order.power.spot.cancel'
  | 'energy-order.power.pending-request.view'
  | 'energy-order.power.pending-request.create'
  | 'energy-order.power.pending-request.delete'
  | 'energy-order.power.request.view'
  | 'energy-order.power.request.show-expired'
  | 'energy-order.power.request.show-closed'
  | 'energy-order.power.request.create'
  | 'energy-order.power.request.edit'
  | 'energy-order.power.request.delete'
  | 'energy-order.power.timerange.expired'
  | 'energy-order.spread.open.view'
  | 'energy-order.spread.open.edit'
  | 'energy-order.spread.open.delete'
  | 'energy-order.spread.closed.edit'
  | 'energy-order.spread.closed.edit-additional-fields'
  | 'energy-order.spread.closed.delete'
  | 'energy-order.power.spread.limit-order.view'
  | 'energy-order.power.spread.limit-order.edit'
  | 'energy-order.power.spread.limit-order.create'
  | 'energy-order.power.spread.limit-order.delete'
  | 'energy-order.power.spread.strategy-message.edit'
  | 'energy-order.power.spread.spot.edit'
  | 'energy-order.hkn.view'
  | 'energy-order.hkn.edit'
  | 'energy-order.hkn.edit-additional-fields'
  | 'energy-order.hkn.cancel'
  | 'energy-order.hkn.delete'
  | 'energy-order.hkn-limit.view'
  | 'energy-order.hkn-limit.edit'
  | 'energy-order.hkn-limit.delete'
  | 'energy-order.hkn-limit.create'
  | 'energy-order.gas.admin'
  | 'energy-order.gas.order_with_tradedate'
  | 'energy-order.gas.open.view'
  | 'energy-order.gas.open.edit'
  | 'energy-order.gas.open.delete'
  | 'energy-order.gas.closed.view'
  | 'energy-order.gas.closed.edit'
  | 'energy-order.gas.closed.edit-additional-fields'
  | 'energy-order.gas.closed.delete'
  | 'energy-order.gas.limit-order.view'
  | 'energy-order.gas.limit-order.cancel'
  | 'energy-order.gas.limit-order.edit'
  | 'energy-order.gas.limit-order.create'
  | 'energy-order.gas.limit-order.delete'
  | 'energy-order.gas.limit-order.show_closed'
  | 'energy-order.gas.limit-order.execute_close_base'
  | 'energy-order.gas.strategy-message.view'
  | 'energy-order.gas.strategy-message.edit'
  | 'energy-order.gas.strategy-message.delete'
  | 'energy-order.gas.strategy-message.cancel'
  | 'energy-order.gas.spot.view'
  | 'energy-order.gas.spot.delete'
  | 'energy-order.gas.spot.cancel'
  | 'energy-order.gas.pending-request.view'
  | 'energy-order.gas.pending-request.create'
  | 'energy-order.gas.pending-request.delete'
  | 'energy-order.gas.request.view'
  | 'energy-order.gas.request.show-expired'
  | 'energy-order.gas.request.show-closed'
  | 'energy-order.gas.request.create'
  | 'energy-order.gas.request.edit'
  | 'energy-order.gas.request.delete'
  | 'energy-order.gas.timerange.expired'
  | 'energy-order-mail.power-createcontract'
  | 'energy-order-mail.gas-createcontract';

export class EnergyOrderPowerPermissions {
  public static readonly energyOrderPowerAdmin: EnergyOrderPermissionValues =
    'energy-order.power.admin';
  public static readonly energyOrderPowerOrderWithTradeDate: EnergyOrderPermissionValues =
    'energy-order.power.order_with_tradedate';
  public static readonly energyOrderPowerOpenView: EnergyOrderPermissionValues =
    'energy-order.power.open.view';
  public static readonly energyOrderPowerOpenEdit: EnergyOrderPermissionValues =
    'energy-order.power.open.edit';
  public static readonly energyOrderPowerOpenDelete: EnergyOrderPermissionValues =
    'energy-order.power.open.delete';
  public static readonly energyOrderPowerClosedView: EnergyOrderPermissionValues =
    'energy-order.power.closed.view';
  public static readonly energyOrderPowerClosedEdit: EnergyOrderPermissionValues =
    'energy-order.power.closed.edit';
  public static readonly energyOrderPowerClosedEditAdditionalFields: EnergyOrderPermissionValues =
    'energy-order.power.closed.edit-additional-fields';
  public static readonly energyOrderPowerClosedDelete: EnergyOrderPermissionValues =
    'energy-order.power.closed.delete';
  public static readonly energyOrderPowerLimitView: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.view';
  public static readonly energyOrderPowerLimitCancel: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.cancel';
  public static readonly energyOrderPowerLimitCreate: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.create';
  public static readonly energyOrderPowerLimitEdit: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.edit';
  public static readonly energyOrderPowerLimitDelete: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.delete';
  public static readonly energyOrderPowerLimitShowClosed: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.show_closed';
  public static readonly energyOrderPowerLimitExecuteCloseBase: EnergyOrderPermissionValues =
    'energy-order.power.limit-order.execute_close_base';

  public static readonly energyOrderPowerStrategyMessageView: EnergyOrderPermissionValues =
    'energy-order.power.strategy-message.view';
  public static readonly energyOrderPowerStrategyMessageEdit: EnergyOrderPermissionValues =
    'energy-order.power.strategy-message.edit';
  public static readonly energyOrderPowerStrategyMessageDelete: EnergyOrderPermissionValues =
    'energy-order.power.strategy-message.delete';
  public static readonly energyOrderPowerStrategyMessageCancel: EnergyOrderPermissionValues =
    'energy-order.power.strategy-message.cancel';
  public static readonly energyOrderPowerSpotView: EnergyOrderPermissionValues =
    'energy-order.power.spot.view';
  public static readonly energyOrderPowerSpotEdit: EnergyOrderPermissionValues =
    'energy-order.power.spot.edit';
  public static readonly energyOrderPowerSpotDelete: EnergyOrderPermissionValues =
    'energy-order.power.spot.delete';
  public static readonly energyOrderPowerSpotCancel: EnergyOrderPermissionValues =
    'energy-order.power.spot.cancel';

  public static readonly energyOrderPowerPendingView: EnergyOrderPermissionValues =
    'energy-order.power.pending-request.view';
  public static readonly energyOrderPowerPendingCreate: EnergyOrderPermissionValues =
    'energy-order.power.pending-request.create';
  public static readonly energyOrderPowerPendingDelete: EnergyOrderPermissionValues =
    'energy-order.power.pending-request.delete';
  public static readonly energyOrderPowerRequestView: EnergyOrderPermissionValues =
    'energy-order.power.request.view';
  public static readonly energyOrderPowerRequestShowExpired: EnergyOrderPermissionValues =
    'energy-order.power.request.show-expired';
  public static readonly energyOrderPowerRequestShowClosed: EnergyOrderPermissionValues =
    'energy-order.power.request.show-closed';
  public static readonly energyOrderPowerRequestCreate: EnergyOrderPermissionValues =
    'energy-order.power.request.create';
  public static readonly energyOrderPowerRequestEdit: EnergyOrderPermissionValues =
    'energy-order.power.request.edit';
  public static readonly energyOrderPowerRequestDelete: EnergyOrderPermissionValues =
    'energy-order.power.request.delete';
  public static readonly energyOrderPowerTimeRangeExpired: EnergyOrderPermissionValues =
    'energy-order.power.timerange.expired';

  public static readonly energyOrderPowerSpreadView: EnergyOrderPermissionValues =
    'energy-order.spread.open.view';
  public static readonly energyOrderPowerSpreadEdit: EnergyOrderPermissionValues =
    'energy-order.spread.open.edit';
  public static readonly energyOrderPowerSpreadDelete: EnergyOrderPermissionValues =
    'energy-order.spread.open.delete';
  public static readonly energyOrderPowerSpreadClosedEdit: EnergyOrderPermissionValues =
    'energy-order.spread.closed.edit';
  public static readonly energyOrderPowerSpreadClosedEditAdditionalFields: EnergyOrderPermissionValues =
    'energy-order.spread.closed.edit-additional-fields';
  public static readonly energyOrderPowerSpreadClosedDelete: EnergyOrderPermissionValues =
    'energy-order.spread.closed.delete';
  public static readonly energyOrderPowerSpreadLimitOrderView: EnergyOrderPermissionValues =
    'energy-order.power.spread.limit-order.view';
  public static readonly energyOrderPowerSpreadLimitOrderDelete: EnergyOrderPermissionValues =
    'energy-order.power.spread.limit-order.delete';
  public static readonly energyOrderPowerSpreadLimitOrderCreate: EnergyOrderPermissionValues =
    'energy-order.power.spread.limit-order.create';
  public static readonly energyOrderPowerSpreadStrategyMessageEdit: EnergyOrderPermissionValues =
    'energy-order.power.spread.strategy-message.edit';
  public static readonly energyOrderPowerSpreadSpotEdit: EnergyOrderPermissionValues =
    'energy-order.power.spread.spot.edit';

  public static readonly energyOrderPowerHknView: EnergyOrderPermissionValues =
    'energy-order.hkn.view';
  public static readonly energyOrderPowerHknEdit: EnergyOrderPermissionValues =
    'energy-order.hkn.edit';
  public static readonly energyOrderPowerHknEditAdditionalFields: EnergyOrderPermissionValues =
    'energy-order.hkn.edit-additional-fields';
  public static readonly energyOrderPowerHknCancel: EnergyOrderPermissionValues =
    'energy-order.hkn.cancel';
  public static readonly energyOrderPowerHknDelete: EnergyOrderPermissionValues =
    'energy-order.hkn.delete';
  public static readonly energyOrderPowerHknLimitView: EnergyOrderPermissionValues =
    'energy-order.hkn-limit.view';
  public static readonly energyOrderPowerHknLimitEdit: EnergyOrderPermissionValues =
    'energy-order.hkn-limit.edit';
  public static readonly energyOrderPowerHknLimitDelete: EnergyOrderPermissionValues =
    'energy-order.hkn-limit.delete';
  public static readonly energyOrderPowerHknLimitCreate: EnergyOrderPermissionValues =
    'energy-order.hkn-limit.create';

  public static readonly tabPermisssion = [
    EnergyOrderPowerPermissions.energyOrderPowerOpenView,
    EnergyOrderPowerPermissions.energyOrderPowerClosedView,
    EnergyOrderPowerPermissions.energyOrderPowerLimitView,
    EnergyOrderPowerPermissions.energyOrderPowerPendingView,
    EnergyOrderPowerPermissions.energyOrderPowerRequestView,
    EnergyOrderPowerPermissions.energyOrderPowerSpreadView,
    EnergyOrderPowerPermissions.energyOrderPowerHknView,
    EnergyOrderPowerPermissions.energyOrderPowerHknLimitView,
  ];

  public static readonly sideBarPermisssion = [
    EnergyOrderPowerPermissions.energyOrderPowerAdmin,
  ];
}

export class EnergyOrderGasPermissions {
  public static readonly energyOrderGasAdmin: EnergyOrderPermissionValues =
    'energy-order.gas.admin';
  public static readonly energyOrderGasOrderWithTradeDate: EnergyOrderPermissionValues =
    'energy-order.gas.order_with_tradedate';
  public static readonly energyOrderGasOpenView: EnergyOrderPermissionValues =
    'energy-order.gas.open.view';
  public static readonly energyOrderGasOpenEdit: EnergyOrderPermissionValues =
    'energy-order.gas.open.edit';
  public static readonly energyOrderGasOpenDelete: EnergyOrderPermissionValues =
    'energy-order.gas.open.delete';
  public static readonly energyOrderGasClosedView: EnergyOrderPermissionValues =
    'energy-order.gas.closed.view';
  public static readonly energyOrderGasClosedEdit: EnergyOrderPermissionValues =
    'energy-order.gas.closed.edit';
  public static readonly energyOrderGasClosedEditAdditionalFields: EnergyOrderPermissionValues =
    'energy-order.gas.closed.edit-additional-fields';
  public static readonly energyOrderGasClosedDelete: EnergyOrderPermissionValues =
    'energy-order.gas.closed.delete';
  public static readonly energyOrderGasLimitView: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.view';
  public static readonly energyOrderGasLimitEdit: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.edit';
  public static readonly energyOrderGasLimitCancel: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.cancel';
  public static readonly energyOrderGasLimitCreate: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.create';
  public static readonly energyOrderGasLimitDelete: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.delete';
  public static readonly energyOrderGasLimitShowClosed: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.show_closed';
  public static readonly energyOrderGasLimitExecuteCloseBase: EnergyOrderPermissionValues =
    'energy-order.gas.limit-order.execute_close_base';

  public static readonly energyOrderGasStrategyMessageView: EnergyOrderPermissionValues =
    'energy-order.gas.strategy-message.view';
  public static readonly energyOrderGasStrategyMessageEdit: EnergyOrderPermissionValues =
    'energy-order.gas.strategy-message.edit';
  public static readonly energyOrderGasStrategyMessageDelete: EnergyOrderPermissionValues =
    'energy-order.gas.strategy-message.delete';
  public static readonly energyOrderGasStrategyMessageCancel: EnergyOrderPermissionValues =
    'energy-order.gas.strategy-message.cancel';
  public static readonly energyOrderGasSpotView: EnergyOrderPermissionValues =
    'energy-order.gas.spot.view';
  public static readonly energyOrderGasSpotDelete: EnergyOrderPermissionValues =
    'energy-order.gas.spot.delete';
  public static readonly energyOrderGasSpotCancel: EnergyOrderPermissionValues =
    'energy-order.gas.spot.cancel';

  public static readonly energyOrderGasPendingView: EnergyOrderPermissionValues =
    'energy-order.gas.pending-request.view';
  public static readonly energyOrderGasPendingCreate: EnergyOrderPermissionValues =
    'energy-order.gas.pending-request.create';
  public static readonly energyOrderGasPendingDelete: EnergyOrderPermissionValues =
    'energy-order.gas.pending-request.delete';
  public static readonly energyOrderGasRequestView: EnergyOrderPermissionValues =
    'energy-order.gas.request.view';
  public static readonly energyOrderGasRequestShowExpired: EnergyOrderPermissionValues =
    'energy-order.gas.request.show-expired';
  public static readonly energyOrderGasRequestShowClosed: EnergyOrderPermissionValues =
    'energy-order.gas.request.show-closed';
  public static readonly energyOrderGasRequestCreate: EnergyOrderPermissionValues =
    'energy-order.gas.request.create';
  public static readonly energyOrderGasRequestEdit: EnergyOrderPermissionValues =
    'energy-order.gas.request.edit';
  public static readonly energyOrderGasRequestDelete: EnergyOrderPermissionValues =
    'energy-order.gas.request.delete';
  public static readonly energyOrderGasTimeRangeExpired: EnergyOrderPermissionValues =
    'energy-order.gas.timerange.expired';

  public static readonly tabPermisssion = [
    EnergyOrderGasPermissions.energyOrderGasOpenView,
    EnergyOrderGasPermissions.energyOrderGasClosedView,
    EnergyOrderGasPermissions.energyOrderGasLimitView,
    EnergyOrderGasPermissions.energyOrderGasPendingView,
    EnergyOrderGasPermissions.energyOrderGasRequestView,
  ];

  public static readonly sideBarPermisssion = [
    EnergyOrderGasPermissions.energyOrderGasAdmin,
  ];
}

type ContractPermissionValues =
  | 'contract.power.editcontract'
  | 'contract.power.editwithincludingorders'
  | 'contract.power.executeenergyorder'
  | 'contract.power.executehknorder'
  | 'contract.power.createlimitreport'
  | 'contract.power.deletewithincludingorders'
  | 'contract.power.viewsubsidiaries'
  | 'contract.gas.editcontract'
  | 'contract.gas.editwithincludingorders'
  | 'contract.gas.executeenergyorder'
  | 'contract.gas.createlimitreport'
  | 'contract.gas.deletewithincludingorders'
  | 'contract.gas.viewsubsidiaries'
  | 'contract.power.export'
  | 'contract.gas.export';

export class ContractPermissions {
  public static readonly contractPowerEditContract: ContractPermissionValues =
    'contract.power.editcontract';
  public static readonly contractPowerEditWithIncludingOrders: ContractPermissionValues =
    'contract.power.editwithincludingorders';
  public static readonly contractPowerExecuteEnergyOrder: ContractPermissionValues =
    'contract.power.executeenergyorder';
  public static readonly contractPowerExecuteHknOrder: ContractPermissionValues =
    'contract.power.executehknorder';
  public static readonly contractPowerCreateLimitReport: ContractPermissionValues =
    'contract.power.createlimitreport';
  public static readonly contractPowerDeleteWithIncludingOrders: ContractPermissionValues =
    'contract.power.deletewithincludingorders';
  public static readonly contractPowerViewSubsidiaries: ContractPermissionValues =
    'contract.power.viewsubsidiaries';

  public static readonly contractGasEditContract: ContractPermissionValues =
    'contract.gas.editcontract';
  public static readonly contractGasEditWithIncludingOrders: ContractPermissionValues =
    'contract.gas.editwithincludingorders';
  public static readonly contractGasExecuteEnergyOrder: ContractPermissionValues =
    'contract.gas.executeenergyorder';
  public static readonly contractGasCreateLimitReport: ContractPermissionValues =
    'contract.gas.createlimitreport';
  public static readonly contractGasDeleteWithIncludingOrders: ContractPermissionValues =
    'contract.gas.deletewithincludingorders';
  public static readonly contractGasViewSubsidiaries: ContractPermissionValues =
    'contract.gas.viewsubsidiaries';
}

export class EnergyOrderContractPermissions {
  public static readonly contractPowerEditContract: ContractPermissionValues =
    'contract.power.editcontract';
  public static readonly contractPowerEditWithIncludingOrders: ContractPermissionValues =
    'contract.power.editwithincludingorders';
  public static readonly contractPowerExecuteEnergyOrder: ContractPermissionValues =
    'contract.power.executeenergyorder';
  public static readonly contractPowerExecuteHknOrder: ContractPermissionValues =
    'contract.power.executehknorder';
  public static readonly contractPowerCreateLimitReport: ContractPermissionValues =
    'contract.power.createlimitreport';
  public static readonly contractPowerDeleteWithIncludingOrders: ContractPermissionValues =
    'contract.power.deletewithincludingorders';
  public static readonly contractPowerViewSubsidiaries: ContractPermissionValues =
    'contract.power.viewsubsidiaries';
  public static readonly contractPowerExport: ContractPermissionValues =
    'contract.power.export';

  public static readonly contractGasEditContract: ContractPermissionValues =
    'contract.gas.editcontract';
  public static readonly contractGasEditWithIncludingOrders: ContractPermissionValues =
    'contract.gas.editwithincludingorders';
  public static readonly contractGasExecuteEnergyOrder: ContractPermissionValues =
    'contract.gas.executeenergyorder';
  public static readonly contractGasCreateLimitReport: ContractPermissionValues =
    'contract.gas.createlimitreport';
  public static readonly contractGasDeleteWithIncludingOrders: ContractPermissionValues =
    'contract.gas.deletewithincludingorders';
  public static readonly contractGasViewSubsidiaries: ContractPermissionValues =
    'contract.gas.viewsubsidiaries';
  public static readonly contractGasExport: ContractPermissionValues =
    'contract.gas.export';
}

type EnergyOrderMailPermissionsValues =
  | 'energy-order-mail-power.power-trading-request-create'
  | 'energy-order-mail-power.power-trading-request-cancelpending'
  | 'energy-order-mail-power.power-trading-request-filled'
  | 'energy-order-mail-power.power-trading-request-ordered'
  | 'energy-order-mail-power.power-createorder'
  | 'energy-order-mail-power.power-cancelopenorder'
  | 'energy-order-mail-power.power-cancelopenspreadorder'
  | 'energy-order-mail-power.power-createlimitorder'
  | 'energy-order-mail-power.power-cancellimitorder'
  | 'energy-order-mail-power.power-createorderbasedonlimitorder'
  | 'energy-order-mail-power.power-createhknorder'
  | 'energy-order-mail-power.power-cancelhknorder'
  | 'energy-order-mail-power.power-createhknlimitorder'
  | 'energy-order-mail-power.power-cancelhknlimitorder'
  | 'energy-order-mail-power.power-createhknorderbasedonhknlimitorder'
  | 'energy-order-mail-power.power-createstrategymessage'
  | 'energy-order-mail-power.power-cancelstrategymessage'
  | 'energy-order-mail-power.power-createspot'
  | 'energy-order-mail-power.power-cancelspot'
  | 'energy-order-mail-gas.gas-trading-request-create'
  | 'energy-order-mail-gas.gas-trading-request-cancelpending'
  | 'energy-order-mail-gas.gas-trading-request-filled'
  | 'energy-order-mail-gas.gas-trading-request-ordered'
  | 'energy-order-mail-gas.gas-createorder'
  | 'energy-order-mail-gas.gas-cancelopenorder'
  | 'energy-order-mail-gas.gas-createlimitorder'
  | 'energy-order-mail-gas.gas-cancellimitorder'
  | 'energy-order-mail-gas.gas-createorderbasedonlimitorder'
  | 'energy-order-mail-gas.gas-createstrategymessage'
  | 'energy-order-mail-gas.gas-cancelstrategymessage'
  | 'energy-order-mail-gas.gas-createspot'
  | 'energy-order-mail-gas.gas-cancelspot';

export class EnergyOrderMailPermissions {
  public static readonly energyOrderMailPowerTradingRequestCreate: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-trading-request-create';
  public static readonly energyOrderMailPowerTradingRequestCancelPending: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-trading-request-cancelpending';
  public static readonly energyOrderMailPowerTradingRequestFilled: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-trading-request-filled';
  public static readonly energyOrderMailPowerTradingRequestOrdered: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-trading-request-ordered';

  public static readonly energyOrderMailPowerCreateOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createorder';
  public static readonly energyOrderMailPowerCancelOpenOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelopenorder';
  public static readonly energyOrderMailPowerCancelOpenSpreadOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelopenspreadorder';

  public static readonly energyOrderMailPowerCreateLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createlimitorder';
  public static readonly energyOrderMailPowerCancelLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancellimitorder';
  public static readonly energyOrderMailPowerCreateOrderBasedOnLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createorderbasedonlimitorder';

  public static readonly energyOrderMailPowerCreateHknOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createhknorder';
  public static readonly energyOrderMailPowerCancelHknOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelhknorder';
  public static readonly energyOrderMailPowerCreateHknLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createhknlimitorder';
  public static readonly energyOrderMailPowerCancelHknLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelhknlimitorder';
  public static readonly energyOrderMailPowerCreateHknOrderBasedOnHknLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createhknorderbasedonhknlimitorder';

  public static readonly energyOrderMailPowerCreateStrategyMessage: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createstrategymessage';
  public static readonly energyOrderMailPowerCancelStrategyMessage: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelstrategymessage';
  public static readonly energyOrderMailPowerCreateSpot: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-createspot';
  public static readonly energyOrderMailPowerCancelSpot: EnergyOrderMailPermissionsValues =
    'energy-order-mail-power.power-cancelspot';

  public static readonly energyOrderMailGasTradingRequestCreate: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-trading-request-create';
  public static readonly energyOrderMailGasTradingRequestCancelPending: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-trading-request-cancelpending';
  public static readonly energyOrderMailGasTradingRequestFilled: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-trading-request-filled';
  public static readonly energyOrderMailGasTradingRequestOrdered: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-trading-request-ordered';

  public static readonly energyOrderMailGasCreateOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-createorder';
  public static readonly energyOrderMailGasCancelOpenOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-cancelopenorder';

  public static readonly energyOrderMailGasCreateLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-createlimitorder';
  public static readonly energyOrderMailGasCancelLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-cancellimitorder';
  public static readonly energyOrderMailGasCreateOrderBasedOnLimitOrder: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-createorderbasedonlimitorder';

  public static readonly energyOrderMailGasCreateStrategyMessage: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-createstrategymessage';
  public static readonly energyOrderMailGasCancelStrategyMessage: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-cancelstrategymessage';
  public static readonly energyOrderMailGasCreateSpot: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-createspot';
  public static readonly energyOrderMailGasCancelSpot: EnergyOrderMailPermissionsValues =
    'energy-order-mail-gas.gas-cancelspot';
}

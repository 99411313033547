import { Component, forwardRef } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  EnergySource,
  EnergySourceService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { map } from 'rxjs';

@Component({
  selector: 'backoffice-frontend-energy-source-details',
  templateUrl: './energy-source-details.component.html',
  providers: [
    {
      provide: CanDeactivateForm,
      useExisting: forwardRef(() => EnergySourceDetailsComponent),
    },
  ],
})
export class EnergySourceDetailsComponent extends CanDeactivateForm<EnergySource> {
  formGroup = new UntypedFormGroup({
    id: new FormControl<number>(null),
    name: new UntypedFormControl(null, [Validators.required]),
    costingDaysValid: new FormControl<number>(null, [Validators.required]),
    crmOfferDaysValid: new FormControl<number>(null, [Validators.required]),
    amountLimitInGwh: new FormControl<number>(null, [Validators.required]),
    bandOrdersLowerLimit: new FormControl<number>(null, [Validators.required]),
    forecastHolidayToleranceInPercent: new FormControl<number>(null, [
      Validators.required,
    ]),
    kind: new UntypedFormControl(null),
  });

  public navigationUrl = '/settings/energy-source';
  public baseEquation = '';

  // public value$ = this.activatedRoute.paramMap.pipe(
  //   map((params) => +params.get('id')),
  //   filter((id: ID) => !!id),
  //   switchMap((id: ID) =>
  //     this.formFacade.getById$(id).pipe(indicate(this.isLoading$))
  //   )
  // );

  protected resetValue = {
    isActive: true,
  };

  public currentId$ = this.activatedRoute.parent.paramMap.pipe(
    map((params) => params.get('id'))
  );

  constructor(protected service: EnergySourceService) {
    super(service);
  }

  public delete() {
    this.service.delete$(this.formGroup.controls.id.value).subscribe(() => {
      this.tableStateService?.refresh();
      this.router.navigate(['../../'], {
        relativeTo: this.activatedRoute,
      });
      this.toastService.showSuccess();
    });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'cc-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContainerComponent {
  @HostBinding('class') class = 'cc-form-container';

  @Input()
  public isLoading = false;

  @Input()
  public disabled = false;

  // @Input() public formMinHeight = '100vh';
}

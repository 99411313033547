import { Injectable } from '@angular/core';
import { PagingResponse } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { Supplier } from '../models/supplier';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  private url = this.configService.settings['api'] + 'surcharge/graphql';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public getAll$(): Observable<Supplier[]> {
    const query = `
      query {
        suppliers {
          id
          name
        }
      }
      `;

    return this.graphqlService.query<Supplier[]>(query, null, this.url);
  }

  public getAllNew$(): Observable<PagingResponse<Supplier>> {
    const where = 'where: {}';

    const query = `
                query {
                  suppliersGetAll(
                      ${where}
                    ){
                      items
                      {
                        id
                        name
                      }
                      pageInfo{
                          hasNextPage
                          hasPreviousPage
                      }
                      totalCount
                    }
                }`;

    return this.graphqlService.query<PagingResponse<Supplier>>(query);
  }
}

import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseFormChild
  implements ControlValueAccessor, OnDestroy, AfterViewInit
{
  public formGroup: UntypedFormGroup;

  public subscriptions: Subscription[] = [];

  ngAfterViewInit(): void {
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.formGroup.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get value(): any {
    return this.formGroup.value;
  }

  set value(value: any) {
    this.formGroup.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => ({} as any);
  onTouched: any = () => ({} as any);

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.formGroup.patchValue(value, { onlySelf: true, emitEvent: false });
    } else {
      this.formGroup.reset();
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.formGroup.valid ? null : { passwords: { valid: false } };
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }
}

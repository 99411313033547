import { fromEvent, merge, Observable } from 'rxjs';
import { map, mapTo, startWith } from 'rxjs/operators';

/**
 * Media query results are to be got from this method.
 * *IMPORTANT*
 * -> works for devextreme charts on print and also when switching pages to print
 * -> does not correctly work for ngx-charts, works on open of print preview, but has major issues when selecting different pages to print
 * @param query e.g. 'print'
 * @returns boolean, mediaQuery change event value named "matches"
 */
export const media$ = (query: string): Observable<boolean> => {
  const mediaQuery = window.matchMedia(query);
  return fromEvent(mediaQuery, 'change').pipe(
    startWith(mediaQuery),
    map((list: MediaQueryList) => list.matches)
  );
};

export const observePrintEvents$ = (
  win: Window
): Observable<'beforeprint' | 'afterprint'> =>
  merge(
    fromEvent(win, 'beforeprint').pipe(
      mapTo<Event, 'beforeprint'>('beforeprint')
    ),
    fromEvent(win, 'afterprint').pipe(mapTo<Event, 'afterprint'>('afterprint'))
  );

<ng-template #legalTemplate>
  <div
    class="text-layout-sidenav-text dark:text-layout-dark-sidenav-text text-xs leading-5 font-thin tracking-wider hover:text-gray-600"
  >
    <div class="flex flex-row space-x-1">
      <a class="underline" routerLink="/imprint">
        {{ 'fof.IMPRINT' | transloco }}</a
      >
      <div>·</div>
      <a class="underline" routerLink="/disclaimer">
        {{ 'fof.DISCLAIMER' | transloco }}
      </a>
      <div>·</div>
      <a class="underline" routerLink="/contact">
        {{ 'fof.CONTACT' | transloco }}
      </a>
    </div>
    <div class="mb-4">
      Energie Steiermark AG, Leonhardgürtel 10, 8010 Graz, Österreich ©
      {{ year }}
    </div>
    <div *ngIf="env.env !== 'prod'">Version: {{ env.version }}</div>
    <div class="flex flex-row items-center">
      <fa-icon [icon]="faLanguage" size="2x"></fa-icon>
      <ng-container *ngFor="let lang of langs$ | async">
        <div
          class="ml-6 mr-3 mb-1 text-sm hover:text-primary-color"
          (click)="setLanguage(lang.id)"
          [ngClass]="{
            'text-primary-color cursor-default':
              (languageService.activeLanguage$ | async) === lang.id,
            'text-layout-sidenav-text dark:text-layout-dark-sidenav-text cursor-pointer ':
              (languageService.activeLanguage$ | async) !== lang.id
          }"
        >
          {{ 'common.language.' + lang.label | transloco }}
        </div>
      </ng-container>
    </div>
    <div class="flex flex-row items-center space-x-9">
      <fa-icon [icon]="faCircleHalfStroke" size="2x"></fa-icon>
      <mat-slide-toggle [formControl]="toggleControl"> </mat-slide-toggle>
    </div>
  </div>
</ng-template>
<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
<div
  *ngIf="service.openMenu$ | async"
  class="relative z-40 lg:hidden"
  role="dialog"
  aria-modal="true"
>
  <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
  <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>

  <div class="fixed inset-0 z-40 flex">
    <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
    <div
      @animateSlide
      class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4"
    >
      <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
      <div class="absolute top-0 right-0 -mr-12 pt-2">
        <button
          (click)="service.toggleUserMenu()"
          type="button"
          class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span class="sr-only">Close sidebar</span>
          <!-- Heroicon name: outline/x-mark -->
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div class="flex flex-shrink-0 items-center px-4">
        <img
          class="h-8 w-auto"
          src="assets/img/logo_estmk.svg"
          alt="Energie Steiermark"
        />
      </div>
      <div class="mt-5 h-0 flex-1 overflow-y-auto px-4">
        <app-navigation></app-navigation>
      </div>
      <div class="px-4">
        <ng-template [ngTemplateOutlet]="legalTemplate"></ng-template>
      </div>
    </div>

    <div class="w-14 flex-shrink-0" aria-hidden="true">
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>
</div>

<!-- Static sidebar for desktop -->
<div
  class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-layout-sidenav dark:bg-layout-dark-sidenav lg:pb-4"
>
  <div class="flex flex-row space-x-2 flex-shrink-0 items-center mx-5 my-3">
    <img
      class="h-12 w-auto"
      src="assets/img/logo_estmk.svg"
      alt="Energie Steiermark"
    />
    <div
      class="text-layout-sidenav-text dark:text-layout-dark-sidenav-text font-bold"
    >
      {{ env.name }}
    </div>
  </div>

  <cc-user-menu class="border-y border-gray-200 mx-5 mb-3"></cc-user-menu>

  <!-- Sidebar component, swap this element with another sidebar if you like -->
  <div class="flex flex-col h-full mx-5">
    <div
      class="flex h-0 flex-1 flex-col overflow-y-auto border-b border-gray-200 text-layout-sidenav-text"
    >
      <!-- Navigation -->
      <app-navigation></app-navigation>
    </div>

    <ng-template [ngTemplateOutlet]="legalTemplate"></ng-template>
  </div>
</div>

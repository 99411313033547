import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { FofCompanyService } from '../infrastructure/fof-company.service';

@Injectable({ providedIn: 'root' })
export class FofPublicFacade {
  constructor(private service: FofCompanyService) {}

  getAllKeyAccountManagersForCompany$(companyId: ID) {
    return this.service.getAllKeyAccountManagersForCompany$(companyId);
  }
}

<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="progress-bar-spacer" *ngIf="!isLoading"></div>
<div
  class="flex flex-row w-full justify-between bg-white dark:bg-layout-dark-page-container align-content-center"
  *ngIf="showActionButtons"
>
  <div class="flex justify-end">
    <ng-content select="[table-filter]"></ng-content>
  </div>

  <div>
    <ng-template #templateAdditional>
      <div class="menue-buttons">
        <ng-content
          class="additional-buttons"
          select="[table-buttons]"
        ></ng-content>
      </div>
    </ng-template>
  </div>

  <ng-template #templateStandard> </ng-template>

  <div class="flex flex-row w-fit justify-between">
    <div class="title">
      <ng-content select="[table-title]"></ng-content>
    </div>

    <div class="flex flex-row" *ngIf="(isMobile$ | async) === false">
      <div #wrapper>
        <ng-template [ngTemplateOutlet]="templateAdditional"></ng-template>
      </div>
      <!-- > 2 because ng-template -->
      <div class="divider" *ngIf="wrapper.childNodes.length > 2"></div>
      <div class="flex flex-row" class="standard-buttons">
        <ng-template [ngTemplateOutlet]="templateStandard"></ng-template>
      </div>
    </div>
    <div class="pr-2" *ngIf="isMobile$ | async">
      <button type="button" mat-button [matMenuTriggerFor]="menu">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div #wrapper>
          <ng-template [ngTemplateOutlet]="templateAdditional"></ng-template>
        </div>
        <div
          class="divider-horizontal"
          *ngIf="wrapper.childNodes.length > 0"
        ></div>
        <ng-template [ngTemplateOutlet]="templateStandard"></ng-template>
      </mat-menu>
    </div>
  </div>
</div>
<ng-content></ng-content>

import { InjectionToken } from '@angular/core';

export const ENV_TOKEN = new InjectionToken<EnvConfig>('env token');

export interface IConfig {
  env: {
    name: string;
  };
}

export interface EnvConfig {
  instrumentationKey: string;
  //TODO create and move to shared/config lib
  name: string;
  env: string;
  api: string;
  graphql: string;
  legacy_api?: string; // TODO: Create a interface in BO/Shared and move to BoEnvConfig
  production: boolean;
  version: string;
  origins: string[];
  auth?: any;
  applicationInsightsinstrumentationKey?: string;
  signalR?: any;
  allowEditDashboard?: boolean;
  healthCheckUrl?: string;
  sentryEnv?: string;
}

import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { CompanyRelationQuery } from './company-relation.query';

export const COMPANY_RELATION_PAGINATOR = new InjectionToken(
  'COMPANY_RELATION_PAGINATOR',
  {
    providedIn: 'root',
    factory: () => {
      const companyRelationQuery = inject(CompanyRelationQuery);
      return new PaginatorPlugin(companyRelationQuery, { startWith: 0 });
    },
  }
);

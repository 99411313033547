import { Component } from '@angular/core';
import {
  NewsTranslated,
  NewsWidgetQuery,
  NewsWidgetStore,
} from '@backoffice-frontend/news/domain';
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'cc-news-widget-dialog',
  templateUrl: './news-widget-dialog.component.html',
  styleUrls: ['./news-widget-dialog.component.scss'],
})
export class NewsWidgetDialogComponent {
  public faChevronRight = faChevronRight;
  public faChevronLeft = faChevronLeft;
  public faTimes = faTimes;

  public selectActive$ = this.query.selectActive();

  public activeIndex: number;
  public lastIndex: number;

  constructor(private query: NewsWidgetQuery, public store: NewsWidgetStore) {
    this.lastIndex = query.getAll().length;

    this.setActiveIndex();
  }

  public previous() {
    this.store.setActive({ prev: true });

    this.setActiveIndex();
  }

  public next() {
    this.store.setActive({ next: true });

    this.setActiveIndex();
  }

  private setActiveIndex() {
    const activeId = this.query.getActiveId();

    this.activeIndex =
      this.query.getAll().findIndex((value: NewsTranslated) => {
        return value.id === activeId;
      }) + 1;
  }
}

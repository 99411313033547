<cc-form-container>
  <ng-container form-buttons *ngVar="id$ | async; let id">
    <cc-save-action-button (onClick)="submit()" class="button-align">
    </cc-save-action-button>
    <cc-delete-action-button [disabled]="id === 'new'" (onClick)="delete(id)">
    </cc-delete-action-button>
  </ng-container>
  <form
    [formGroup]="formGroup"
    form-content
    [patchFormGroupValues]="value$ | async"
  >
    <div formContainerFormRow>
      <div formContainerFormRow>
        <bo-country-dropdown formControlName="country"></bo-country-dropdown>
      </div>

      <mat-form-field>
        <mat-label>{{
          'spot-configuration.spot-configuration-identifier.fields.STOCK_MARKET'
            | transloco
        }}</mat-label>
        <input matInput formControlName="stockMarket" />
      </mat-form-field>
    </div>

    <div formContainerFormRow>
      <bo-energy-source-dropdown
        class="w-full"
        formControlName="energySource"
      ></bo-energy-source-dropdown>
    </div>
  </form>
</cc-form-container>

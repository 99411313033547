import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  AuthConfigService,
  OAuthAuthModule,
} from '@backoffice-frontend/shared/auth/util-oauth';
import { TailwindThemeModule } from '@backoffice-frontend/shared/ui-tailwind';
import {
  LocaleProvider,
  TranslocoRootModule,
} from '@backoffice-frontend/shared/util-transloco';
import { AuthErrorInterceptor } from '@clean-code/shared/auth/util-auth';
import { SplashScreenState } from '@clean-code/shared/common';
import { MatPaginationIntlService } from '@clean-code/shared/components/ui-mat-table';
import { SideNavService } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { ConfigModule, EnvConfig } from '@clean-code/shared/util-config';
import {
  DateHttpInterceptor,
  GraphqlErrorInterceptor,
} from '@clean-code/shared/util-graphql';
import { GlobalErrorHandlerService } from '@clean-code/shared/util/util-error';
import {
  USER_LIMIT_SERVICE,
  UserLimitPersonalService,
} from '@clean-code/user-limit/domain';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { RxState } from '@rx-angular/state';
import * as Sentry from '@sentry/angular-ivy';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { navigationItems } from './pages/navigaton';

export function appInitializerFactory(authConfigService: AuthConfigService) {
  return async () => {
    await authConfigService.initAuth();
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    MatDialogModule,

    ConfigModule.forRoot(environment as EnvConfig),
    TailwindThemeModule.forRoot({
      configUrl: './assets/config/tailwind-theme.config.json',
    }),

    //@ts-ignore
    OAuthModule.forRoot(environment.auth as OAuthModuleConfig),
    //@ts-ignore
    OAuthAuthModule.forRoot(environment.auth as OAuthModuleConfig),
    TranslocoRootModule.forRoot(),
    AppRoutingModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),

    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    DashboardModule,
  ],
  providers: [
    RxState,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AuthConfigService],
      multi: true,
    },
    LocaleProvider, //translations, pipes, etc.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: 'ILoggingService',
    //   useClass: ApplicationInsightsLoginService,
    // },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphqlErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateHttpInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: USER_LIMIT_SERVICE,
      useClass: UserLimitPersonalService,
    },
    SideNavService,
    RxState<SplashScreenState>,
  ],
})
export class AppModule {
  constructor(
    _trace: Sentry.TraceService,
    sideNavService: SideNavService
    // _updateService: UpdateService
  ) {
    sideNavService.addNavigation(navigationItems);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // unregister service worker
          console.log('serviceWorker unregistered');
          registration.unregister();
        }
      });
    }
  }
}

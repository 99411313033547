import {
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { FrontOfficeAuthenticationPermission } from '@clean-code/shared/frontoffice/util-permissions';
import { ENV_TOKEN, EnvConfig } from '@clean-code/shared/util-config';
import { NgxPermissionsService } from 'ngx-permissions';
import { from } from 'rxjs';
import { animateFade, animateSlide } from '../animations/animations';
import { SideNavService } from './../../services/side-nav.service';

@Component({
  selector: 'fof-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [animateFade, animateSlide],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavComponent {
  @HostBinding('class') class = 'fof-side-nav';

  public year: number = new Date().getFullYear();

  public hasFrontOfficeIntPermission$ = from(
    this.permissionService.hasPermission(
      FrontOfficeAuthenticationPermission.frontOfficeIntPermission
    )
  );

  constructor(
    @Inject(ENV_TOKEN) public env: EnvConfig,
    public service: SideNavService,
    public permissionService: NgxPermissionsService
  ) {}

  public toggleUserMenu() {
    this.service.toggleUserMenu();
  }
}

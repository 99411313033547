<mat-progress-bar
  class="progress"
  *ngIf="isLoading"
  mode="indeterminate"
></mat-progress-bar>
<div>
  <div class="flex flex-row">
    <ng-content select="[chart-title]"></ng-content>
    <div class="flex-grow"></div>
    <ng-content class="flex flex-row" select="[chart-buttons]"></ng-content>
  </div>
  <ng-content select="[chart-content]"></ng-content>
</div>

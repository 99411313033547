import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
import dayjs, { Dayjs } from 'dayjs';
import { LineChartDataModel, LineChartModel } from '../models/line-chart.model';
import { MultiChartModel } from '../models/multi-chart.model';

export function transformChartData(
  value: MultiChartModel,
  tailwindThemeService: TailwindThemeService
) {
  const colors = tailwindThemeService.getColors('chart-color');

  const lineSeries = value.lineChartData.map((x, index) => {
    const color = tailwindThemeService.getColor(
      colors[index % (colors.length - 1)]
    );

    return {
      label: x.label,
      color: color,
      pane: x.pane,
      aggregate: x.aggregate,
    };
  });

  const barSeries = value.barChartData.map((x, index) => {
    const color = tailwindThemeService.getColor(
      colors[index % (colors.length - 1)]
    );

    return {
      label: x.label,
      color: color,
      type: 'bar',
      pane: x.pane,
      aggregate: x.aggregate,
    };
  });

  const series = lineSeries.concat(barSeries);
  let yAxisTitle = '';

  const resultMap = new Map<Date, any>();
  value.lineChartData.forEach((value: Partial<LineChartModel>) => {
    yAxisTitle = value.yAxisTitle;

    // this.state.set({ yAxisTitle: value.label });
    // var result = new Map(value.data.map(i => [i.date, i.value]));
    value.data.forEach((data: LineChartDataModel) => {
      let obj = resultMap.get(data.date);

      if (!obj) {
        obj = {};
      }
      obj[value.label] = data.value;

      resultMap.set(data.date, obj);
    });
  });

  value.barChartData.forEach((value: Partial<LineChartModel>) => {
    // var result = new Map(value.data.map(i => [i.date, i.value]));
    value.data.forEach((data: LineChartDataModel) => {
      let obj = resultMap.get(data.date);

      if (!obj) {
        obj = {};
      }
      obj[value.label] = data.value;

      resultMap.set(new Date(data.date), obj);
    });
  });

  const result = new Array<{}>();

  resultMap.forEach((value, key) => {
    let map = new Map([['date', key]]);

    Object.keys(value).forEach(function (property) {
      map.set(property, value[property]);
    });
    const obj = Object.fromEntries(map);

    result.push(obj);
  });

  const keys = Array.from(resultMap.keys()).map((v) => new Date(v));
  keys.sort((date1, date2) => date1.getTime() - date2.getTime());

  const [firstKey] = keys;
  const lastKey = keys.at(-1);
  let startValue: Dayjs = null;
  let endValue: Dayjs = null;

  if (firstKey) {
    startValue = dayjs(firstKey).subtract(1, 'd');
  }

  if (lastKey) {
    endValue = dayjs(lastKey).add(1, 'd');
  }

  const chartData = {
    series,
    result,
    yAxisTitle,
    visualRange: [startValue, endValue],
  };
  return chartData;
}

import { UntypedFormArray, UntypedFormControl, ValidatorFn } from '@angular/forms';

export function HknValueValidator(amountInMWh: number): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: any } | null => {
    let sumAmount = 0;
    formArray.controls.forEach((x: UntypedFormControl) => {
      sumAmount += x.value.amountInMWh;
    });

    return sumAmount === amountInMWh
      ? null
      : { amountError: 'costing.offer.contract.error.AMOUNT_NOT_VALID' };
  };
}

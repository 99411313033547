import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { ConfirmModule } from '@clean-code/shared/components/ui-confirm';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { PipesModule } from '@clean-code/shared/ui-pipes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NewsWidgetDialogComponent } from './components/news-widget-dialog/news-widget-dialog.component';
import { NewsWidgetComponent } from './widget.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,

    FontAwesomeModule,
    TranslocoModule,
    TranslocoLocaleModule,

    ProgressBarModule,
    ConfirmModule,
    PipesModule,
  ],
  declarations: [NewsWidgetComponent, NewsWidgetDialogComponent],
  exports: [NewsWidgetComponent],
})
export class NewsFeatureWidgetModule {}

<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
<div
  class="flex flex-col lg:flex-row h-full space-x-2 md:space-x-0"
  *ngIf="data$ | async; let data"
>
  <cc-line-chart [data]="data.chart"></cc-line-chart>
  <div class="flex flex-col w-full lg:w-1/4" *ngIf="data.table.length > 0">
    <h4 class="text-center">
      {{ 'market-dashboard-details.COMPARISON' | transloco }}
    </h4>
    <table class="table-fixed">
      <tbody>
        <tr *ngFor="let item of data.table">
          <td class="md:w-56">
            {{ item.name }}
          </td>
          <td class="md:w-28">{{ item.value | number : '.2-2' }}</td>
          <td>{{ item.unit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

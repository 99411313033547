import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService, NavigationState } from '@clean-code/shared/common';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cc-dashboard-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageContainerComponent {
  @HostBinding('class') class = 'cc-dashboard-page-container';

  @ContentChildren(CanDeactivateForm<any>)
  public forms: QueryList<CanDeactivateForm<any>>;

  @Input()
  public isLoading: boolean;
  // @Input()
  // public classes: string[] = [];

  @Input()
  public showTitle = true;

  public value: string;
  public pageTitle$: Observable<NavigationState> = this.service.pageTitle$;

  public pageIcon$: Observable<any> = combineLatest([
    this.activatedRoute.data,
    this.activatedRoute.parent.data,
  ]).pipe(
    map(([data, parentData]) => parentData['titleIcon'] || data['titleIcon'])
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    public service: NavigationService
  ) {}

  public canDeactivate(): boolean {
    let canDeactivate = true;
    this.forms.forEach((form: CanDeactivateForm<any>) => {
      if (form.formGroup.dirty) {
        canDeactivate = false;
      }
    });
    return canDeactivate;
  }
}

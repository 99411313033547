import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export class DateHelper {
  public static toLocalDate(date: Date) {
    if (!(date instanceof Date)) {
      throw new Error('DateHelper.toLocalDate() expects a Date object');
    }

    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  public static toDate(value: string | Date): Date {
    const dateString = DateHelper.toDateString(value);
    const date = dayjs(dateString, 'DD.MM.YYYY');
    return date.toDate();
  }

  public static toDateString(value: string | Date): string {
    //why was this here in the first place?
    return this.convertToLocalDateString(value);
  }

  public static toDateTimeString(value: Date | Dayjs | string): string {
    return dayjs(value).format('DD.MM.YYYY HH:mm');
  }

  public static convertToLocalDateString(value: string | Date | Dayjs): string {
    let date: Date = null;
    if (typeof value === 'string' || value instanceof String) {
      date = dayjs(value, 'DD.MM.YYYY').toDate();
    } else {
      date = dayjs(value).toDate();
    }

    return date.toLocaleDateString('DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalysisLocalized } from '../entities/analysis';
import { FofAnalysisDataService } from './../infrastructure/fof-analysis.data.service';

@Injectable({ providedIn: 'root' })
export class AnalysisWidgetFacade {
  constructor(
    private service: FofAnalysisDataService,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}

  //DataTableParameters not implemented server side!
  getAll$(params: DataTableParameters): Observable<AnalysisLocalized[]> {
    return this.service
      .getAll$(params)
      .pipe(map((value: PaginationResponse<AnalysisLocalized>) => value.data));
  }

  getLatest$(): Observable<AnalysisLocalized[]> {
    return this.service.getLatest$();
  }

  downloadPdf$(id: ID) {
    const url = `${this.configService.settings['api']}analysis/download/${id}`;

    const headers = new HttpHeaders({
      'Access-Control-Expose-Headers': 'Content-Disposition',
    });

    return this.httpClient.get(url, {
      responseType: 'blob' as 'json',
      observe: 'response',
      headers: headers,
    });
  }
}

import { Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { NgxPermissionsService } from 'ngx-permissions';
@Injectable({
  providedIn: 'root',
})
export class EnumService {
  private areaUrl = this.configService.settings['api'] + 'area/graphql';
  private loadProfileUrl =
    this.configService.settings['api'] + 'loadprofile/graphql';
  private forecastUrl = this.configService.settings['api'] + 'forecast/graphql';
  private forwardPriceCurveUrl =
    this.configService.settings['api'] + 'forwardpricecurve/graphql';
  private offerVariantUrl =
    this.configService.settings['api'] + 'offer/graphql';
  private orderGasUrl = this.configService.settings['api'] + 'ordergas/graphql';
  private orderPowerUrl =
    this.configService.settings['api'] + 'orderpower/graphql';
  private limitReportPowerUrl =
    this.configService.settings['api'] + 'limitreportpower/graphql';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService,
    private permissionService: NgxPermissionsService
  ) {}

  // public getCountries$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "Country"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.areaUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getEnergieSources$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "EnergySourceEnum"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return combineLatest([
  //     from(this.permissionService.hasPermission(SystemClaims.KAM_POWER)),
  //     from(this.permissionService.hasPermission(SystemClaims.KAM_GAS)),
  //     from(
  //       this.permissionService.hasPermission(SystemClaims.ENERGY_SOURCE_POWER)
  //     ),
  //     from(
  //       this.permissionService.hasPermission(SystemClaims.ENERGY_SOURCE_GAS)
  //     ),
  //   ]).pipe(
  //     switchMap(
  //       ([
  //         hasKamPowerPermission,
  //         hasKamGasPermission,
  //         hasPowerPermission,
  //         hasGasPermission,
  //       ]) =>
  //         this.graphqlService.query<EnumValues>(query, null, this.areaUrl).pipe(
  //           map((value: EnumValues) => value.enumValues),
  //           map((values: EnumValue[]) => {
  //             if (hasKamPowerPermission && hasKamGasPermission) {
  //               return values;
  //             }
  //             if (hasPowerPermission && hasGasPermission) {
  //               return values;
  //             } else if (hasKamPowerPermission) {
  //               return values.filter((x) => x.name === EnergySourceEnum.Power);
  //             } else if (hasKamGasPermission) {
  //               return values.filter((x) => x.name === EnergySourceEnum.Gas);
  //             } else if (hasPowerPermission) {
  //               return values.filter((x) => x.name === EnergySourceEnum.Power);
  //             } else if (hasGasPermission) {
  //               return values.filter((x) => x.name === EnergySourceEnum.Gas);
  //             }

  //             return [];
  //           })
  //         )
  //     )
  //   );
  // }

  // public getEnergieSourcesWithCustomPermission$(
  //   powerPermisssion: string,
  //   gasPermission: string
  // ): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "EnergySourceEnum"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return combineLatest([
  //     from(this.permissionService.hasPermission(powerPermisssion)),
  //     from(this.permissionService.hasPermission(gasPermission)),
  //   ]).pipe(
  //     switchMap(([hasPowerPermission, hasGasPermission]) =>
  //       this.graphqlService.query<EnumValues>(query, null, this.areaUrl).pipe(
  //         map((value: EnumValues) => value.enumValues),
  //         map((values: EnumValue[]) => {
  //           if (hasPowerPermission && hasGasPermission) {
  //             return values;
  //           } else if (hasPowerPermission) {
  //             return values.filter((x) => x.name === EnergySourceEnum.Power);
  //           } else if (hasGasPermission) {
  //             return values.filter((x) => x.name === EnergySourceEnum.Gas);
  //           }

  //           return [];
  //         })
  //       )
  //     )
  //   );
  // }

  // public getEnergieSourcesUnits$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "EnergySourceUnit"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.loadProfileUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "LimitType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.loadProfileUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitViolationTreatmentTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "LimitViolationTreatmentType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.loadProfileUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getStructureTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "Structure"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getPriceBasisTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "PriceBasis"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getSurchargeTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "ForwardPriceCurveSurcharge"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getDeliveryPurchaseTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "DeliveryPurchase"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }`;

  //   return this.graphqlService
  //     .query<EnumValues>(query)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getForecastSettingTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "ForecastSettingType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.forecastUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getBalancingVariants$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "BalancingVariant"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.offerVariantUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getTimeFrames$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "TimeFrame"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.offerVariantUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getTradeMomentTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "TradeMomentType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getGasTradeMomentTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "GasTradeMomentType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderGasUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getTradeRanges$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "TradeRange"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderGasUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getTradeTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "TradeType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderGasUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitStrategy$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "LimitStrategy"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.limitReportPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitReportSpreadFormulaTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "LimitReportSpreadFormulaTypeEnum"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.limitReportPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitReportState$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "LimitReportState"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.limitReportPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getBuyingProcesses$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "BuyingProcess"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.limitReportPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getOrderTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "OrderType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderGasUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getBandTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "BandType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getStrategyMessagePoolTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "StrategyMessagePoolTypeEnum"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getPureSpreadSecurityTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "PureSpreadSecurityType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getSpreadSecurityTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //     query {
  //       __type(name: "SpreadSecurityType"){
  //         name
  //         enumValues {
  //           name
  //         }
  //       }
  //     }
  //   `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getOrderLimitTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //       query {
  //         __type(name: "OrderLimitType"){
  //           name
  //           enumValues {
  //             name
  //           }
  //         }
  //       }
  //     `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getOrderLimitBaseTypes$(): Observable<EnumValue[]> {
  //   const query = `
  //       query {
  //         __type(name: "OrderLimitBaseType"){
  //           name
  //           enumValues {
  //             name
  //           }
  //         }
  //       }
  //     `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getSurchargeAffiliations$(): Observable<EnumValue[]> {
  //   const query = `
  //       query {
  //         __type(name: "SurchargeAffiliation"){
  //           name
  //           enumValues {
  //             name
  //           }
  //         }
  //       }
  //     `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // public getLimitStrategies$(): Observable<EnumValue[]> {
  //   const query = `
  //       query {
  //         __type(name: "LimitStrategy"){
  //           name
  //           enumValues {
  //             name
  //           }
  //         }
  //       }
  //     `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, this.orderPowerUrl)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }

  // getEnumType$(enumType: string, url: string): Observable<EnumValue[]> {
  //   const query = `
  //       query {
  //         __type(name: "${enumType}"){
  //           name
  //           enumValues {
  //             name
  //           }
  //         }
  //       }
  //     `;

  //   return this.graphqlService
  //     .query<EnumValues>(query, null, url)
  //     .pipe(map((value: EnumValues) => value.enumValues));
  // }
}

<div
  class="border-b border-gray-200 sm:flex sm:items-center sm:justify-between"
>
  <div class="flex-1 min-w-0">
    <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
      {{ (selectActive$ | async)?.name }}
    </h1>
  </div>
  <div class="mt-4 flex sm:mt-0 sm:ml-4">
    <fa-icon
      size="2x"
      [icon]="faTimes"
      (click)="previous()"
      mat-dialog-close
    ></fa-icon>
  </div>
</div>

<div class="bg-white flex-1 flex justify-between sm:justify-end">
  <fa-icon [icon]="faChevronLeft" (click)="previous()"></fa-icon>
  <div class="flex w-full"></div>
  <fa-icon [icon]="faChevronRight" (click)="next()"></fa-icon>
</div>
<mat-dialog-content class="mat-typography">
  <form
    [formGroup]="formGroup"
    [patchFormGroupValues]="selectActive$ | async"
    readonly
  >
    <div class="flex flex-col mt-6 space-y-2">
      <div class="cc-input">
        <label for="start">
          {{ 'info.termin.START' | transloco }}
        </label>
        <div>
          <input
            localeDate
            type="date"
            readonly
            type="text"
            formControlName="start"
            name="start"
          />
        </div>
      </div>

      <div class="cc-input">
        <label for="end">
          {{ 'info.termin.END' | transloco }}
        </label>
        <div>
          <input
            localeDate
            type="date"
            readonly
            type="text"
            formControlName="end"
            name="end"
          />
        </div>
      </div>

      <div class="cc-checkbox">
        <div class="flex items-center h-5">
          <input
            aria-describedby="comments-description"
            name="internal"
            type="checkbox"
            formControlName="internal"
          />
        </div>
        <div>
          <label for="internal">
            {{ 'info.termin.INTERNAL' | transloco }}</label
          >
        </div>
      </div>

      <div class="cc-textarea">
        <label for="location">
          {{ 'info.termin.LOCATION' | transloco }}
        </label>
        <div class="mt-1">
          <textarea
            formControlName="location"
            name="location"
            rows="3"
          ></textarea>
        </div>
      </div>

      <div class="cc-textarea">
        <label for="description">
          {{ 'info.termin.DESCRIPTION' | transloco }}
        </label>
        <div class="mt-1">
          <textarea
            formControlName="description"
            name="description"
            rows="3"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HknTypeService, SupplierService } from '@backoffice/costing/util-api';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { NormCubicMeterQuery } from '../../+state/norm-cubic-meter.query';
import { NormCubicMeterFormFacade } from '../../application/norm-cubic-meter-form.facade';
import { NormCubicMeter } from '../../models/norm-cubic-meter';

@Component({
  selector: 'backoffice-frontend-norm-cubic-meter-form',
  templateUrl: './norm-cubic-meter-form.component.html',
})
export class NormCubicMeterFormComponent extends CanDeactivateForm<NormCubicMeter> {
  public navigationUrl = 'settings/norm-cubic-meter';

  public suppliers$ = this.supplierService.getAll$();
  public hknTypes$ = this.hknTypeService.search$({});

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    year: new UntypedFormControl(null, [Validators.required]),
    factor: new UntypedFormControl(null, [Validators.required]),
    updatedBy: new UntypedFormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    updatedDate: new UntypedFormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
  });

  constructor(
    private supplierService: SupplierService,
    private hknTypeService: HknTypeService,
    private hknIdentifierQuery: NormCubicMeterQuery,
    service: NormCubicMeterFormFacade
  ) {
    super(service);
  }
}
